import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import {SelectChangeEvent} from "@mui/material/Select";
import {GraphType, AvaiGraphTypes_f} from "../../../interfaces/traffic_accidents/GraphType";
import TGraphContent from "../../../components/Graphs/TGraphContent";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../components/PageHeader/PageHeader";
import image from '../../../assets/css/images/police.jpg';
import PageForm from "../../../components/PageHeader/PageForm";


export default function TGraphs() {
    const {t} = useTranslation("traffic");
    const [graphOption, SetGraphOption] = useState<string[]>(["0"]);
    const [graphTypes, SetGraphTypes] = useState<GraphType[]>(AvaiGraphTypes_f(t));
    const [currentGraphType, SetCurrentGraphType] = useState<GraphType>(graphTypes[0]);
    const OnChangeGrahpOptionHandler = (event : SelectChangeEvent<typeof graphOption>) => {
        const {target: {value}} = event;
        SetGraphOption(typeof value === "string" ? value.split(",") : value);
    }
    useEffect(() => {
        SetCurrentGraphType(graphTypes[parseInt(graphOption[0])]);
    }, [graphOption]);
    useEffect(() => {
        SetCurrentGraphType(graphTypes[parseInt(graphOption[0])]);
    }, [graphTypes]);

    useEffect(function() {
        SetGraphTypes(AvaiGraphTypes_f(t));  
    }, [t('locale')]);

  return (
    <PageForm  label={t("TGraphs.nadpis")} image={image}>
    <div className='box-12'>
        <div>
            <div>
                <InputLabel id="GraphOptionLabel">{t("TGraphs.graf")}</InputLabel>
                <Select
                    value={graphOption}
                    onChange={OnChangeGrahpOptionHandler}
                    labelId="GraphOptionLabel"
                    label="Graf"
                    autoWidth
                >
                    {
                        graphTypes.map((graphType, i) => <MenuItem key={graphType.key} value={i.toString()}>{graphType.name}</MenuItem>)
                    }
                </Select>
            </div>
        </div>
        <TGraphContent endpoint={currentGraphType.endpoint} name={currentGraphType.name} graphData={currentGraphType.graphData}/>
    </div>
    </PageForm>
  );
}
