import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import './TMap.css';
import css from '../../traffic/TReportCenter/Report.module.css';
import {Fragment, useEffect, useState} from "react";
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3";
import MapServiceTAP from '../../../services/MapServiceTAP';
import Settings from '../../../components/Settings/Settings';
import SFilterSettings from '../../../components/Search/SFilterSettings';
import ShowBox from '../../../components/ShowBox';
import SFilterValueDraw from '../../../components/Search/SFilterValueDraw';
import SearchServiceTAP from '../../../services/SearchServiceTAP';
import { SearchFilter, SearchFilterValue } from '../../../interfaces/SearchFilter';
import filterItemsTrafficPeds from '../../../interfaces/ConstFilterTrafficPeds';
import { MdLocalPolice } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import { FaMap } from 'react-icons/fa';
import { Grow, GrowProps, Snackbar, Stack } from '@mui/material';
// import CircularProgress from '@mui/material/CircularProgress';


interface TAPMapData {
  id: string;
  x: string;
  y: string;
  nasledokUsmrtenie?: string;
  nasledokTazkeZranenie?: string;
  nasledokLahkeZranenie?: string;
  nasledokBezZraneni?: string;
  kategoriaChodca?: string;
  stavChodca?: string;
  chovanieChodca?: string;
  situaciaVMiesteNehody?: string;
  datum?: string;
}

export default function TAPMap() {
  const { t } = useTranslation("traffic");
  const [ mapDataTAP, setMapDataTAP ] = useState<TAPMapData[] | null>(null);
  const [ filter, setFilter ] = useState<SearchFilter[]>(filterItemsTrafficPeds);
  const [ filterValues, setFilterValues ] = useState<SearchFilterValue[]>([{
    type: {
        value: "AND",
        label: "A súčastne"
    },
    value: [
        {
            type: {
                value: "AND",
                label: "A súčastne"
            },
            atribute: {
                value: "datum",
                label: "Dátum"
            },
            option: {
                value: "DATE_BETWEEN",
                label: "je v rozsahu dátumov"
            },
            value: [
                "2023-01-01T00:00:00.000Z",
                "2023-12-30T23:59:59.000Z"
            ]
        }
    ]
  }]);

  const [ loading , setLoading ] = useState(true);
  const [ showHeatmap, setShowHeatmap ] = useState(true);
  const [ showSnackbar, setShowSnackbar ] = useState(false);
  const [ countValues, setCountValues ] = useState(0);
  const [progress, setProgress] = useState(0);

  const handleFilterValuesChange = (newValues: SearchFilterValue[]) => {
    setFilterValues(newValues);
  }

  const handleFilterChange = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
    setFilter(newFilter);
    setFilterValues(newValues);
  }

  const handleImportData = (data: any) => {
      setFilter(data?.filter);
      setFilterValues(data?.filterValues);
  }

  useEffect(() => {
    setLoading(true);
    const fval = filterValues?.map((value) => ({
      type: value.type.value,
      value: value.value.map((val) => ({
          type: val.type.value,
          atr: val.atribute.value,
          option: val.option.value,
          value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
      }))
    }));

    MapServiceTAP.getMapData(JSON.stringify(fval))
    .then((resp: any) => {
        setMapDataTAP(resp.data.data);
        setCountValues(resp.data.data.length);
        setLoading(false);
        setShowSnackbar(true);
    })
    .catch(() => { setLoading(false); });
  }, [filterValues]);

  const heatmapOptions = {
    radius: 16,
    blur: 35,
    maxZoom: 18,
    minOpacity: 0.2,
    maxOpacity: 1
  };

  const dataToFile = {
    filter: filter,
    filterValues: filterValues
  };

  const toggleMap = () => {
    setShowHeatmap(!showHeatmap);
  };

  const heatmapData = mapDataTAP?.filter((data) => !isNaN(parseFloat(data.x)) && !isNaN(parseFloat(data.y)))
    .map((data: TAPMapData) => [parseFloat(data.x), parseFloat(data.y)] as [number, number]);

  const icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
  });
    
  const getMarkerColor = (nasledokUsmrtenie: string, nasledokTazkeZranenie: string, nasledokLahkeZranenie: string, nasledokBezZraneni: string) => {
    if (parseInt(nasledokUsmrtenie) > 0) return 'red';
    if (parseInt(nasledokTazkeZranenie) > 0) return 'orange';
    if (parseInt(nasledokLahkeZranenie) > 0) return 'yellow';
    if (parseInt(nasledokBezZraneni) > 0) return 'green';
    return 'blue';
  };

  function allConsequencesNull(nasledokUsmrtenie: string, nasledokTazkeZranenie: string, nasledokLahkeZranenie: string, nasledokBezZraneni: string) {
    return (parseInt(nasledokUsmrtenie) === 0 && parseInt(nasledokTazkeZranenie) === 0 && parseInt(nasledokLahkeZranenie) === 0 && parseInt(nasledokBezZraneni) === 0);
  }

  function maxOneConsequence(nasledokUsmrtenie: string, nasledokTazkeZranenie: string, nasledokLahkeZranenie: string, nasledokBezZraneni: string) {
    return (parseInt(nasledokUsmrtenie) + parseInt(nasledokTazkeZranenie) + parseInt(nasledokLahkeZranenie) + parseInt(nasledokBezZraneni)) < 2;
  }

  function MultipleMarkers() {
    if (!mapDataTAP) return null;
    return (
      <Fragment>
        {mapDataTAP?.filter((data) => !isNaN(parseFloat(data.x)) && !isNaN(parseFloat(data.y))).map((data, index) => (
          <Marker key={index} position={[parseFloat(data.x), parseFloat(data.y)]} icon={new L.Icon({
            iconSize: [25, 41],
            iconAnchor: [10, 41],
            popupAnchor: [2, -40],
            iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${getMarkerColor(data.nasledokUsmrtenie || '0', data.nasledokTazkeZranenie || '0', data.nasledokLahkeZranenie || '0', data.nasledokBezZraneni || '0')}.png`,
            shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
          })}>
            <Popup>
              <table>
                <tbody>
                  <tr>
                    <td><strong>{t("Map.nehodaDatum")}</strong></td>
                    <td>{data.datum || 'N/A'}</td>
                  </tr>
                  {maxOneConsequence(data.nasledokUsmrtenie || '0', data.nasledokTazkeZranenie || '0', data.nasledokLahkeZranenie || '0', data.nasledokBezZraneni || '0') && (
                    <tr>
                      <td><strong>{t("Map.kategoriaChodca")}</strong></td>
                      <td>{data.kategoriaChodca || 'N/A'}</td>
                    </tr>
                  )}
                  <tr>
                    <td><strong>{t("Map.stavChodca")}</strong></td>
                    <td>{data.stavChodca || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.chovanieChodca")}</strong></td>
                    <td>{data.chovanieChodca || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td style={{ minWidth: '170px' }}><strong>{t("Map.situaciaVMiesteNehody")}</strong></td>
                    <td>{data.situaciaVMiesteNehody || 'N/A'}</td>
                  </tr>
                  {allConsequencesNull(data.nasledokUsmrtenie || '0', data.nasledokTazkeZranenie || '0', data.nasledokLahkeZranenie || '0', data.nasledokBezZraneni || '0') ? (
                    <></>
                  ) : (
                    <>
                      <tr>
                        <td><strong>{t("Map.chodcovUsmrt")}</strong></td>
                        <td>
                          <div style={{
                                  display: 'inline-block',
                                  width: '14px',
                                  height: '14px',
                                  borderRadius: '7px',
                                  margin: '0px',
                                  backgroundColor: 'red',
                                  textAlign: 'center' 
                                }}>
                            <span style={{ color: 'white' }}>{data.nasledokUsmrtenie || 'N/A'}</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{t("Map.chodcovTazZran")}</strong></td>
                        <td>
                          <div style={{
                                display: 'inline-block',
                                width: '14px',
                                height: '14px',
                                borderRadius: '7px',
                                margin: '0px',
                                backgroundColor: 'orange',
                                textAlign: 'center' 
                              }}>
                            <span style={{ color: 'white' }}>{data.nasledokTazkeZranenie || 'N/A'}</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{t("Map.chodcovLahZran")}</strong></td>
                        <td>
                          <div style={{
                              display: 'inline-block',
                              width: '14px',
                              height: '14px',
                              borderRadius: '7px',
                              margin: '0px',
                              backgroundColor: '#E6CF01',//FFD700
                              textAlign: 'center' 
                            }}>
                            <span style={{ color: 'white' }}>{data.nasledokLahkeZranenie || 'N/A'}</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>{t("Map.chodcovBezZran")}</strong></td>
                        <td> 
                          <div style={{
                            display: 'inline-block',
                            width: '14px',
                            height: '14px',
                            borderRadius: '7px',
                            margin: '0px',
                            backgroundColor: 'green',
                            textAlign: 'center' 
                          }}>
                              <span style={{ color: 'white' }}>{data.nasledokBezZraneni || 'N/A'}</span>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </Popup>
          </Marker>
        ))}
      </Fragment>
    );
  }

  function GrowTransition(props: GrowProps) {
    return <Grow {...props} />;
  }
  
  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway')
      return;

    setShowSnackbar(false);
  };

  function updateProgressBar(processed: any, total: any, elapsed: any) {
    if (elapsed > 1000) {
      // if it takes more than a second to load, display the progress bar:
      // progress.style.display = 'block';
      // progressBar.style.width = Math.round(processed/total*100) + '%';
      setProgress(Math.round(Number(processed/total*100)))
      setLoading(true);
    }

    if (processed === total) {
      // all markers processed - hide the progress bar:
      // progress.style.display = 'none';
      setProgress(0);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="box-12 py-2 text-right form">
        <Settings 
            dataKey={"TAPSearch"}
            dataToFile={dataToFile}
            onImportData={handleImportData}
            saveOnRefresh={false}
            loadOnRefresh={false}
        />
        <SFilterSettings 
            filter={filter} 
            filterValues={filterValues}
            onFilterChange={handleFilterChange} 
            endpointService={SearchServiceTAP}
        />
      </div>
      <ShowBox className="box-12 py-2" name="">
        <SFilterValueDraw 
          filterValues={filterValues} 
          onChange={handleFilterValuesChange} 
        />
      </ShowBox>

      {/* <Stack spacing={2} direction="row">
      <CircularProgress variant="determinate" value={progress} /></Stack> */}

      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <h1 style={{ fontSize: '26px' }}>
          <strong>{showHeatmap ? t("Map.heatNadpis") : t("Map.baseNadpis")}</strong>
        </h1>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <FaMap name='pinIcon' size={24} style={{ marginRight: '10px', color: 'black', cursor: 'pointer' }} />
          <div className={`toggle-switch ${showHeatmap ? 'checked' : ''}`} onClick={toggleMap}>
            <div className="slider"/>
          </div>
        </div>
      </div>
      {/* <Stack spacing={2} direction="row">
      <CircularProgress variant="determinate" value={progress} /></Stack> */}
      <div id="map">
      <ShowBox className="box-12 py-2" name="">
        <MapContainer center={[49.8, 15.5]} zoom={7.5} style={{ zIndex: 1, width: '100%'}} maxZoom={18}>
          { loading && <div className={css.loading}>
            <div><MdLocalPolice /> {t("Traffic.loading")}</div>
          </div> }
          {showHeatmap && mapDataTAP !== null && mapDataTAP.length > 0 &&
              <HeatmapLayer
                fitBoundsOnLoad
                fitBoundsOnUpdate
                points={heatmapData}
                latitudeExtractor={(point: any) => point[0]}
                longitudeExtractor={(point: any) => point[1]}
                key={Math.random() + Math.random()}
                intensityExtractor={(point: any) => parseFloat(point[0])}
                {...heatmapOptions}
              />
          }

          {!showHeatmap && mapDataTAP !== null && mapDataTAP.length > 0 &&
              <MarkerClusterGroup /*chunkProgress={updateProgressBar}*/ chunkedLoading maxClusterRadius={100} removeOutsideVisibleBounds disableClusteringAtZoom={17} spiderfyDistanceMultiplier={2}>
                <MultipleMarkers />
              </MarkerClusterGroup>
          }
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </ShowBox>
      </div>
      {/* <Stack spacing={2} direction="row">
      <CircularProgress variant="determinate" value={progress} /></Stack> */}

      <Snackbar
        open={showSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={GrowTransition}
        message={t("Map.pocetNehod", { count: countValues })}
        onClose={handleCloseSnackbar}
        autoHideDuration={5000}
        color="secondary"
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center",
            color: 'white',
            backgroundColor: '#1F98F5',
          }
        }}
      />
    </>
  );
}

