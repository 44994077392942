import ReactECharts from 'echarts-for-react';
import { useState, useEffect, useCallback, DependencyList, EffectCallback, useRef } from 'react';
import css from './Graph.module.css';
import { MdLocalPolice } from 'react-icons/md';
import { FaTimes, FaEdit, FaFileUpload } from 'react-icons/fa';
import { GraphData } from '../../interfaces/Graphs';
import { SearchFilterValue } from '../../interfaces/SearchFilter';
import { ExpressionValue } from '../Expressions/Expressions';
import NotificationService from '../../services/NotificationService';
import useUser from "../../use/useUser";

interface IProps {
    type: 'bar' | 'line' | 'pie' | string;
    showLabels: boolean | "value" | "true" | "false";
    longXLabels?: boolean;//nastavit na true ak maju byt popisky v uhle/pouzivane pre dlhe popisky
    values?: Array<Array<Number>>;
    xNames?: Array<string>; // pre kazde data
    valueNames?: Array<string>; // pre kazdy subor dat
    colors?: Array<string>;
    texts?: Array<string>;
    width?: string;
    height?: string;
    group?: string;
    dynamicValues?: GraphData[];
    filterValues?: SearchFilterValue[];
    endpointService?: any;
    onEdit?: () => void;
    onRemove?: () => void;
    onExportToReport?: () => void;
    customExpressions?: ExpressionValue[];
    settingTAP?: boolean;//sluzi pre vyber nastaveni, ktore sa maju zobrazit v dopravnych nehodach - chodci
    editMode?: boolean;//zobrazenie tlacidiel pre editaciu a odstranenie grafu
    analyse?: boolean;
    reportCenter?: boolean;//urcuje nastavenia pre lepsie zobrazenie grafu v Report Center
};

Graph.defaultProps = {
  editMode: true,
};

export default function Graph(props : IProps) {
  const [ user, { isAdmin, isLoggedIn, logout } ] = useUser();

    const [ loading , setLoading ] = useState(false);
    const [ values, setValues ] = useState(props.values || []);
    const [ xNames, setXNames ] = useState(props.xNames || []);
    const [ valueNames, setValueNames ] = useState(props.valueNames || []);

    const checkFinish = useCallback((fin: any) => {
      if (fin === props.dynamicValues?.length) {
        setLoading(false);
      }
    }, [props.dynamicValues]);

    const decodeSimpleData = useCallback((data: any) => {
      let tempVal: any[] = [];
      let tempXNames: any[] = [];
      data.forEach((data: any) => {
        tempXNames.push(data.name1);
        tempVal.push(data.value);
      });
      setValues([tempVal]);
      setXNames(tempXNames);
      setValueNames([props.dynamicValues?.[0].title || ""]);
    }, [props.dynamicValues]);

    const decodeMultiData = useCallback((data: any) => {
      let tempObj: any = {};
      let uniqueS1: any = new Set();
      let uniqueS2: any = new Set();
      data.forEach((data: any) => {
        if (!tempObj[data.s2]) {
          tempObj[data.s2] = {};
        }
        tempObj[data.s2].name2 = data.name2;
        if (data.s1) {
          tempObj[data.s2][data.s1] = { name1: data.name1, value: data.value };
          uniqueS1.add(data.s1);
          uniqueS2.add(data.s2);
        }
      });

      if(props.settingTAP) {
        uniqueS1 = Array.from(uniqueS1);
        uniqueS2 = Array.from(uniqueS2);
      } else {
        uniqueS1 = Array.from(uniqueS1).sort();
        uniqueS2 = Array.from(uniqueS2).sort();
      }

      let tempValuesNames: any[] = [];
      let tempValArray: any[] = [];
      uniqueS2.forEach((s2: string) => {
        tempValuesNames.push(tempObj[s2].name2 || "");
        let tempVal: any[] = [];
        tempValArray.push(tempVal);
        uniqueS1.forEach((s1: string) => {
          tempVal.push(tempObj[s2][s1]?.value || 0);
        });
      });
      uniqueS1 = uniqueS1.map((s1: any) => {
        const s2 = uniqueS2.find((s2: any) => tempObj[s2][s1]?.name1 || "");
        return tempObj[s2][s1]?.name1;
      });
      setValues(tempValArray);
      setValueNames(tempValuesNames);
      setXNames(uniqueS1);
    }, [ ]);

    const decodeDatasetData = useCallback((fin: any, datasetData: any, name: any, data: any) => {
      datasetData[name] = {};
      data.forEach((d: any) => {
        datasetData[name][d.s1] = d;
      });
      if (fin === (props.dynamicValues?.length || 0) - 1) {
        let uniqueS1: any = new Set();
        Object.entries(datasetData).forEach((entry: any) => {
          Object.entries(entry[1]).forEach((s1: any) => uniqueS1.add(s1[1].s1));
        });
        uniqueS1 = Array.from(uniqueS1);

        let tempValuesNames: any[] = [];
        let tempValArray: any[] = [];
        Object.entries(datasetData).forEach((entry: any) => {
          tempValuesNames.push(entry[0]);
          let tempVal: any[] = [];
          tempValArray.push(tempVal);
          uniqueS1.forEach((s1: any) => {
            tempVal.push(entry[1][s1]?.value || 0);
          });
        });
        setValues(tempValArray);
        setValueNames(tempValuesNames);
        setXNames(uniqueS1);
      }
    }, [props.dynamicValues]);

    useEffect(() => {
      const abortCtrl = new AbortController();
      let fin = 0;
      let datasetData: any = {};
      if (props.dynamicValues) {
        setLoading(true); 
        props.dynamicValues.forEach((dataset) => {
          // const fltValues = props.reportCenter ? (dataset.filter || []).concat(props.filterValues || []) : dataset.filter ? dataset.filter : props.filterValues;
          const fltValues = props.reportCenter ? (dataset.filter) : (dataset.filter ? dataset.filter : props.filterValues);
          const fval = fltValues?.map((value) => ({
              type: value.type.value,
              value: value.value.map((val) => ({
                  type: val.type.value,
                  atr: val.atribute.value,
                  option: val.option.value,
                  value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
              }))
          }));
          const fvalReportGlobal = props.reportCenter ? props.filterValues?.map((value) => ({
            type: value.type.value,
            value: value.value.map((val) => ({
                type: val.type.value,
                atr: val.atribute.value,
                option: val.option.value,
                value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
            }))
          })) : [];
          props.endpointService.getGraphData(dataset.dataFirst, dataset.dataSecond, dataset.dataFirstInterval, dataset.dataSecondInterval
            , dataset.dataFirstIntervalBy, dataset.dataSecondIntervalBy, dataset.nullValues, props.group, fval, fvalReportGlobal
            , props.customExpressions, abortCtrl.signal)
          .then((response: any) => {
            if (props.dynamicValues?.length !== 1) {
              decodeDatasetData(fin, datasetData, dataset.title, response.data.data);
            } else {
              if (dataset.dataSecond) {
                decodeMultiData(response.data.data);
              } else {
                decodeSimpleData(response.data.data);
              }
            }
            ++fin;
            checkFinish(fin);
          })
          .catch(() => {
            !props.reportCenter && NotificationService.error("Graf nebolo možné vytvoriť na základe vašich nastavení.");
            ++fin;
            checkFinish(fin);
          });
        });
      }
      return () => abortCtrl.abort();
    }, [props.dynamicValues, props.filterValues, props.endpointService, props.group, checkFinish, decodeSimpleData, decodeMultiData, decodeDatasetData, props.customExpressions, props.reportCenter]);

    useEffect(() => {
      if (props.values) {
        setValues(props.values);
      }
    }, [props.values]);

    useEffect(() => {
      if (props.valueNames) {
        setValueNames(props.valueNames);
      }
    }, [props.valueNames]);

    useEffect(() => {
      if (props.xNames) {
        setXNames(props.xNames);
      }
    }, [props.xNames]);

    const barOption = {
        grid: props.longXLabels ? {
            y: 60,
            y2: 150,
        } : {containLavel: true, left: 80},
        title: {
            show: true,
            text: props.texts?.[0],
            left: "center",
            top: 10
        },
        legend: {
          top: "auto",
          bottom: 0,
          type: 'scroll'
        },
        tooltip: {},
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            saveAsImage: { show: true },
          },
        },
        xAxis: {
          type: "category",
          data: xNames,
          name: props.texts?.[2],
          nameLocation: "center",
          nameTextStyle: {
            lineHeight: 36,
            fontWeight: 'bold'
          },
          axisLabel: {
            rotate: props.longXLabels ? 20 : 0,
            interval: props.longXLabels ? 0 : 'auto'
          },
          nameGap: props.longXLabels ? 103 : 15
        },
        yAxis: {
          type: "value",
          name: props.texts?.[1],
          nameLocation: "center",
          nameTextStyle: {
            lineHeight: 106,
            fontWeight: 'bold'
          }
        },
        series: values.map((value, index) => (
          {
            label: {
              show: !(!props.showLabels || props.showLabels === 'false'),
              rotate: props.type === "bar" ? 90 : 0,
              align: 'bottom',
              position: 'insideBottomRight',
              fontSize: 16,
              formatter: props.showLabels === "value" ? '{c}' : '{a}: {c}',
            },
            name: valueNames[index],
            data: value,
            color: props.colors?.[index],
            type: props.type,
          }
        )),
    };

    return <div className={css.graph} style={props.reportCenter ? { height: '100%', width: '100%', margin: 0 } : { width: props.width || '100%' }}>
        {isLoggedIn() && props.editMode && props.settingTAP && 
        <div className={`${css.share} nonDraggable`} onClick={props.onExportToReport}>
            <FaFileUpload />
        </div>}
        {props.editMode && <div className={`${css.edit} nonDraggable`} onClick={props.onEdit}>
            <FaEdit />
        </div>}
        {props.editMode && !props.analyse && <div className={`${css.closeTop} ${css.close} nonDraggable`} onClick={props.onRemove}>
            <FaTimes />
        </div>}
        { loading && <div className={css.loading}>
            <div><MdLocalPolice /> Načítavam...</div>
        </div> }
        <ReactECharts option={barOption} notMerge={true} style={ props.reportCenter ? {height: '100%', width: '100%'} : { height:props.height || '300px'}}/>
    </div>;
}