import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

  interface ExpandableTableRowProps {
    children: React.ReactNode;
    expandComponent: React.ReactNode;
    level?: number;
  }

  export const ExpandableTableRow: React.FC<ExpandableTableRowProps> = ({ children, expandComponent, level = 0 }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    return (
      <>
        <TableRow >
          <TableCell style={{backgroundColor:"lightgray", paddingLeft: `${level * 20 + 5}px`}} padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <FontAwesomeIcon icon={faChevronDown} size="sm" style={{maxWidth:"16px"}} /> : <FontAwesomeIcon icon={faChevronRight} size="sm" style={{maxWidth:"16px"}} />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && 
          (expandComponent)}
      </>
    );
  };

// type HandleAxis = 'x' | 'y' | 'both';

// interface ResizeHandleProps {
//   handleAxis: HandleAxis;
//   ref: React.Ref<HTMLDivElement>;
// }
// const CustomResizeHandle: React.FC<ResizeHandleProps> = ({ handleAxis, ref }) => {
//   const styles: Styles = {
//     position: 'absolute',
//     cursor: 'pointer',
//     backgroundColor: '#007bff',
//     color: 'white',
//     padding: '5px',
//     border: 'none',
//     borderRadius: '5px',
//   };

//   switch (handleAxis) {
//     case 'x':
//       styles.left = 0;
//       styles.top = '50%';
//       styles.transform = 'translateY(-50%)';
//       break;
//     case 'y':
//       styles.top = 0;
//       styles.left = '50%';
//       styles.transform = 'translateX(-50%)';
//       break;
//     case 'both':
//       styles.bottom = 0;
//       styles.right = 0;
//       break;
//   }

//   return (
//     <div ref={ref} style={styles}>
//       Resize
//     </div>
//   );
// };