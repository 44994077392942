import Title from '../../../components/Title/Title';
import { FaSadCry } from "react-icons/fa";

export default function AccessForbidden() {
  return (
    <div className='box-12'>
      <Title align="center">ERROR 403 - Prístup zamietnutý!</Title>
      <FaSadCry className="errorSmile"></FaSadCry>
      <div className="box-12 text-center errorCaption">
        Na zobrazenie požadovanej stránky nemáte dostatočné práva, ak nie ste prihlásený, prihláste sa a skúste znova.
        <br></br><br></br>
        Ak problém pretrváva, kontaktujte administrátora webu.
      </div>
    </div>
  );
}