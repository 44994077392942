import css from './LangSwitch.module.css';
import useUser from '../../use/useUser';

export default function User() {
    const [ user ] = useUser();

    return (
    <div>
        { user && <>
            <div className={css.title}>
                { user.name } { user.surname }
            </div>
            <div className={css.title}>
                { user.email }
            </div>
            { user.role === 1 && <div className={css.title}>
                Admin
            </div> }
            <div className='line'></div>
        </> }
    </div>
    ) 
}