import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import iDataset from "../../interfaces/iDataset";
import { SearchFilter, SearchFilterValue } from "../../interfaces/SearchFilter";
import CustomModal from "../Modal/CustomModal";
import SFilterSettings from "../Search/SFilterSettings";
import { Button } from "../StyleComponents/StyleComponents";
import BasicStatistics from "./BasicStatistics";
import Histogram from "./Histogram";
import SearchServiceTC from './../../services/SearchServiceTC';
import SpecifyDataModal from "./SpecifyDataModal";
import ShowBox from "../ShowBox";
import SFilterValueDraw from "../Search/SFilterValueDraw";
import { useTranslation } from 'react-i18next';
import Distrubution from "./Distrubution";
import Expressions from "../Expressions/Expressions";
import Settings from "../Settings/Settings";
import MultiGraphs from "../MultiGraphs/MultiGraphs";
import { GraphValue } from "../../interfaces/Graphs";
import Prediction from "./Prediction";

interface IProps {
  dataset: iDataset;
  idx: Number;
  deleteDataset: Function;
  duplicateDataset: Function;
  renameDataset: Function;
  setDataset: Function;
}

const hoverTabs = {
  "&:hover": {
    backgroundColor: 'lightgray'
  }, 
  borderRight: "solid gray 2px",
  transition: "0.5s",
  cursor: "pointer"
};

export default function Dataset(props: IProps) {

  const { t } = useTranslation("crimes");
  const [showModal, setShowModal] = useState(false);
  const [showModalSpecify, setShowModalSpecify] = useState(false);
  const [specify, setSpecify] = useState("MM.YYYY"); // toto ymenit na vlastný výraz
  const [newDatasetName, setNewDatasetName] = useState(props.dataset.title);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (showModal === true)
      setNewDatasetName(props.dataset.title);
  }, [showModal, props]);

  const handleFilterChange = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
    const newDataset = props.dataset;
    newDataset.filter = newFilter;
    newDataset.filterValues = newValues;
    props.setDataset(props.idx, newDataset);
  }

  const handleGraphValueChange = (newGraphs: GraphValue[]) => {
    const newDataset = props.dataset;
    const tempArr = [];
    tempArr.push(newGraphs[newGraphs.length - 1]);
    newDataset.graphValues = tempArr;
    props.setDataset(props.idx, newDataset);
  }

  const handleExpressionChange = (newExp: any[]) => {
    const newDataset = props.dataset;
    newDataset.expressionValues = newExp;
    props.setDataset(props.idx, newDataset);
  }

  const handleImportData = (data: any) => {
    console.log(data);
    const newDataset = props.dataset;
    if (data?.filter) {
        newDataset.filter = data.filter;
    }
    if (data?.filterValues) {
        newDataset.filterValues = data.filterValues;
    }
    if (data?.graphValues) {
        //newDataset.graphValues = data.graphValues;
        const tempArr = [];
        tempArr.push(data.graphValues[data.graphValues.length - 1]);
        newDataset.graphValues = tempArr;
    }
    if (data?.expressionValues) {
        newDataset.expressionValues = data.expressionValues;
    }
    props.setDataset(props.idx, newDataset);
}

  const handleFilterValuesChange = (newValues: SearchFilterValue[]) => {
    const newDataset = props.dataset;
    newDataset.filterValues = newValues;
    props.setDataset(props.idx, newDataset);
  }

  const tabShow = <Grid container marginBottom={4} borderRadius="6px" overflow="hidden">
  <Grid item md={3} sx={ hoverTabs } onClick={() => setActiveTab(1)}>
    <Typography variant="body1" padding={2}>{t("analyzis.baseStatistics")}</Typography>
  </Grid>
  <Grid item md={3} sx={ hoverTabs } onClick={() => setActiveTab(2)}>
    <Typography variant="body1" padding={2}>{t("analyzis.histogram")}</Typography>
  </Grid>
  <Grid item md={3} sx={ hoverTabs } onClick={() => setActiveTab(3)}>
    <Typography variant="body1" padding={2}>{t("analyzis.prediction")}</Typography>
  </Grid>
  <Grid item md={3} sx={ hoverTabs } style={{borderRight: "solid gray 0px" }} onClick={() => setActiveTab(4)}>
    <Typography variant="body1" padding={2}>{t("analyzis.probability")}</Typography>
  </Grid>
</Grid>;

  const dataToFile = {
    filter: props.dataset.filter,
    filterValues: props.dataset.filterValues,
    sort: { page: 1, perPage: 5, label: "", order: true },
    graphValues: props.dataset.graphValues,
    expressionValues: props.dataset.expressionValues
  };

  return (
  <>
    <Grid sx={{ border: "solid gray 2px", borderRadius: "16px" }} marginBottom={4} padding={2}>
      <Typography variant="h6">{props.dataset.title}</Typography>

      <Grid sx={{ textAlign: "left" }} marginBottom={2}>
        {/*<Typography variant="body1">{t("analyzis.filterInfo")}</Typography>*/}
        <Grid textAlign={ "left" }>
          <Expressions 
              filter={props.dataset.filter}
              expressionValues={props.dataset.expressionValues}
              onChange={handleExpressionChange}
              endpointService={SearchServiceTC}
          />
          <Settings 
              dataKey="TCSearch"
              dataToFile={dataToFile}
              onImportData={handleImportData}
              saveOnRefresh={false}
              loadOnRefresh={false}
          />
          <SFilterSettings 
              filter={props.dataset.filter} 
              filterValues={props.dataset.filterValues}
              withVisibleOption
              onFilterChange={handleFilterChange} 
              endpointService={SearchServiceTC}
          />
          <ShowBox className="box-12 py-2" name="filtre">
              <SFilterValueDraw 
                  filterValues={props.dataset.filterValues} 
                  onChange={handleFilterValuesChange} 
              />
          </ShowBox>
          <ShowBox className="box-12 py-2" name={t("search.hideGraphs") + ""}>
              <MultiGraphs 
                  filter={props.dataset.filter}
                  customExpressions={props.dataset.expressionValues}
                  filterValues={props.dataset.filterValues}
                  graphValues={props.dataset.graphValues}
                  onChange={handleGraphValueChange}
                  endpointService={SearchServiceTC} 
                  analzyse
              />
          </ShowBox>
        </Grid>
        {/* <Typography variant="body1">{t("analyzis.NaNInfo")}</Typography>
        <Button onClick={() => setShowModalSpecify(true)}>{t("analyzis.NaNButton")}</Button>  */}
  
      </Grid>
      { props.dataset.graphValues.length > 0 ? <Grid>
        {tabShow}
        { activeTab === 1 && <BasicStatistics dataset={props.dataset}/>}
        { activeTab === 2 && <Histogram dataset={props.dataset}/>}
        { activeTab === 3 && <Prediction dataset={props.dataset}/>}
        { activeTab === 4 && <Distrubution dataset={props.dataset}/>}
      </Grid> 
      :
      <div>{t("analyzis.createGraphFirst")}</div>}
      
      <Grid sx={{ textAlign: "right" }}>
        <Button onClick={() => props.duplicateDataset()}>{t("analyzis.duplicateButton")}</Button>
        <Button onClick={() => setShowModal(true)}>{t("analyzis.nameButton")}</Button>
        <Button onClick={() => props.deleteDataset()}>{t("analyzis.deleteButton")}</Button>
      </Grid>
    </Grid>

    <CustomModal
      show={showModal}
      title={t("analyzis.insertName")}
      width="500px"
      onClose={() => setShowModal(false)}
    >
      <Grid marginBottom={2} marginTop={2}>
        <input type="text" placeholder={t("analyzis.placeholderName") + ""} value={newDatasetName} onChange={(e) => setNewDatasetName(e.target.value)}/>
      </Grid>
      <Grid sx={{ textAlign: "right" }}>
        <Button onClick={() => setShowModal(false)}>{t("analyzis.closeButton")}</Button>
        <Button onClick={() => { props.renameDataset(props.idx, newDatasetName); setShowModal(false); }}>{t("analyzis.changeButton")}</Button>
      </Grid>
    </CustomModal>

    <SpecifyDataModal 
      specify={specify} 
      show={showModalSpecify} 
      submit={(e: string) => { setSpecify(e); setShowModalSpecify(false); }} 
      onClose={() => setShowModalSpecify(false)}/>
  </>
  );
}
