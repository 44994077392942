import axios, { AxiosResponse } from "axios";
import { SearchService } from "../interfaces/SearchService";
import qs from 'qs';
import { ExpressionValue } from "../components/Expressions/Expressions";

const searchService: SearchService = {
    getTableData: (page: number, perPage: number, label: string, order: boolean, search: string, custom?: ExpressionValue, abort?: AbortController): Promise<AxiosResponse<any, any>> => {
        const paramsSerializer = (par: any) => qs.stringify(par);
        return axios.get(process.env.REACT_APP_API_URL + "/tc/search", {
            params: {
                page,
                perPage,
                order: label,
                asc: order,
                search: JSON.stringify(search),
                custom: JSON.stringify(custom)
            },
            signal: abort?.signal,
            paramsSerializer
        });
    },

    getFilterData: (label: string, value: string): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/search/unique", {
            params: {
                atr: label,
                search: value
            }
        });
    },

    getGraphData: (atr1: string, atr2: string, atr1Interval: string, atr2Interval: string, atr1IntervalBy: string, atr2IntervalBy: string, nullValues: boolean, group: string, search: string, searchReportGlobal?: string, custom?: ExpressionValue, signal?: any): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/graph", {
            params: {
                atr1: atr1,
                atr2: atr2,
                atr1interval: atr1Interval,
                atr2interval: atr2Interval,
                atr1intervalBy: atr1IntervalBy === "" ? undefined : atr1IntervalBy,
                atr2intervalBy: atr2IntervalBy === "" ? undefined : atr2IntervalBy,
                group: group,
                search: JSON.stringify(search),
                custom: JSON.stringify(custom),
            },
            signal
        });
    },

    verifyOwnExpression: (expression: string[]): Promise<AxiosResponse<any, any>>  => {
        const paramsSerializer = (par: any) => qs.stringify(par);
        return axios.get(process.env.REACT_APP_API_URL + "/tc/expressions/verify", {
            params: {
                expression,
            },
            paramsSerializer
        });
    }
}

export default searchService;