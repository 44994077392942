import axios from "axios";
import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import image from '../../../../assets/css/images/police.jpg';
import HeaderCarousel from "../../../../components/HeaderCarousel/HeaderCarousel";
import { GET_ALL_STATES, CODE_PARAM, STATE_INFO_SITE } from "../../../../interfaces/europe/Constants";
import { getImage } from "../../../../interfaces/europe/Functions";
import { State } from "../../../../interfaces/europe/interfaces";
import './States.css';
import { useTranslation } from "react-i18next";

export default function States() {

    const { t } = useTranslation("europe");

    //RESTS
    const [states, setStates] = React.useState<State[]>([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_ALL_STATES).then((response) => {
            setStates(response.data);
        });
    }, []);


    // ORDER
    let i = 0;
    function getNumber() {
        i = i + 1;
        return i;
    }

    return (
        <div>

            <HeaderCarousel name="States" image={image} />

            <table className="table table-striped underLabel">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col"></th>
                        <th scope="col">{t("stateInfo.code")}</th>
                        <th scope="col">{t(`map.state`)}</th>
                        <th className="text-center" scope="col">{t(`stateInfo.showMore`)}</th>
                    </tr>
                </thead>
                <tbody>
                    {states.map(((state) =>
                        <tr key={state.id}>
                            <th >{getNumber()}</th>
                            <th className="flag"><img src={getImage(state.code)} alt={"Flag"} /></th>
                            <th>{state.code}</th>
                            <th>{t(`states.${state.code}`)}</th>
                            <th className="text-center"> <Link to={STATE_INFO_SITE + state.id + CODE_PARAM + state.code}><strong><BsArrowRight /></strong></Link></th>
                        </tr>
                    ))}
                </tbody>
            </table>
           
        </div>

    );
}
