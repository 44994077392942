export { Privileges };

class Privileges {
    private traffic: boolean;
    private crimes: boolean;
    private manager: boolean;
    private admin : boolean;

    constructor(traffic: boolean, crimes: boolean, manager: boolean, admin: boolean) {

        this.traffic = traffic;
        this.crimes = crimes;
        this.manager = manager;
        this.admin = admin;
    }
   
    public setPrivilegesTrafic = (privileges: boolean): void => {
        this.traffic = privileges;
    }

    public setPrivilegesCrimes = (privileges: boolean): void => {
        this.crimes = privileges;
    }

    public setPrivilegesManager = (privileges: boolean): void => {
        this.manager = privileges;
    }

    public setPrivilegesAdmin = (privileges: boolean): void => {
        this.admin = privileges;
    }

    public getPrivilegesTrafic = (): boolean => {
        return this.traffic;
    }

    public getPrivilegesCrimes = (): boolean => {
        return this.crimes;
    }

    public getPrivilegesManager = (): boolean => {
        return this.manager;
    }

    public getPrivilegesAdmin = (): boolean => {
        return this.admin;
    }
}

