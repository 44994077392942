import ReactECharts from "echarts-for-react"

import { useState, useEffect } from 'react'

import SelectionData from "../../interfaces/Graphs/SelectionData";
import GraphData from "../../interfaces/Graphs/GraphData";
import GraphService_DN from "../../services/GraphService_DN";
import './GraphStyles.css';

import { getRegions, getYears } from "../../interfaces/traffic_accidents/FormControllerData";

import GraphController from "./GraphController";
import { useTranslation } from "react-i18next";
import { MdLocalPolice } from "react-icons/md";

interface GraphProps {
    baseUrl_API: string,
    GraphData: GraphData[],
    GraphSeriesName: string
}


const RegionName = "Region";
const YearName = "Year";

export default function GraphComponent(props: GraphProps) {
    const [IsLoadingData, SetIsLoadingData] = useState<boolean>(false);
    //const [Regions] = useState<SelectionData[]>(getRegions());
    //const [Years] = useState<SelectionData[]>(getYears());
    const [Data, SetData] = useState<number[]>(props.GraphData.map(item => item.Data));
    const [axisData, SetAxisData] = useState<string[]>(props.GraphData.map((item) => item.Title));
    const barOption = {
        legend: {
            top: "bottom",
        },
        tooltip: {},
        toolbox: {
            show: true,
            orient: "vertical",
            left: "right",
            top: "center",
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                saveAsImage: { show: true },
            },
        },
        xAxis: {
            type: "category",
            data: axisData
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                name: props.GraphSeriesName,
                data: Data,
                type: "bar",
            },
        ],
    };


    const { t } = useTranslation("traffic");

    useEffect(() => {
        SetAxisData(props.GraphData.map(item => item.Title));
    },[props.GraphData]);

    const GraphControllerProps =
        [
            {
                label: t("Graph.regiony"),//"Regióny",
                useIterval: false,
                selectionData: getRegions(),
                name: RegionName
            },
            {
                label: t("Graph.roky"),
                useIterval: true,
                selectionData: getYears(),
                name: YearName
            }
        ];
    function FetchApi(data_url: string) {
        SetIsLoadingData(true);
        GraphService_DN.FetchDataFrom_API(data_url)
            .then((res: any) => {
                if (res.data !== undefined) {
                    if (Array.isArray(res.data)) {
                        let data: GraphData[] = GraphService_DN.AssignDataArray(res.data, props.GraphData);
                        SetData(data.map(item => item.Data));
                    }
                    else {
                        let data: GraphData[] = GraphService_DN.AssignData(res.data, props.GraphData);
                        SetData(data.map(item => item.Data));
                    }
                }
                SetIsLoadingData(false);
            })
            .catch((e: any) => {
                console.log(e);
                SetIsLoadingData(false);
            });
    }

    const FetchGraphData = (data_url: string) => {
        console.log(process.env.REACT_APP_API_URL_DN + `${data_url}`);
        FetchApi(data_url);
    };


    function GenerateUrl(state: any) {
        let region_data_url = "";
        let year_data_url = "";
        switch (state[RegionName].Option) {
            case 0:
                region_data_url = "all";
                break;
            case 1:
                region_data_url = state[RegionName].Data[0];
                break;
            case 2:
                region_data_url = state[RegionName].Data[0];
                break;
            default:
                region_data_url = "all";
                break;
        }
        switch (state[YearName].Option) {
            case 0:
                if (region_data_url === "all") {
                    year_data_url = "all";
                }
                else {
                    year_data_url = "-1";
                }
                break;
            case 1:
                let code = "-1";
                if (state[YearName].Data[0] !== "all") {
                    code = state[YearName].Data[0];
                }
                year_data_url = code;
                break;
            case 2:
                year_data_url = state[YearName].Data[0];
                break;
            default:
                year_data_url = "all";
                break;
        }
        return `/${props.baseUrl_API}/${region_data_url}/${year_data_url}`;
    }

    const onClick = (state: any) => {
        let data_url = GenerateUrl(state);
        console.log(data_url);
        FetchGraphData(data_url);
    };

    return (
        <div className='box-12'>
            <GraphController GraphControls={GraphControllerProps} OnClick={onClick} />
            <div className="graph_wrapper">
                { IsLoadingData && <div className="loading">
                    <div><MdLocalPolice /> Načítavam...</div>
                </div> }
            
                <ReactECharts option={barOption} />
            </div>

        </div>
    );
}
