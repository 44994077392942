import './Logo.css';
import { MdFingerprint } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface LogoProps {
  method?: any;
}

export default function Logo(props: LogoProps) {
  const navigate = useNavigate();
  /* const isEuropeSection = window.location.href.includes('europe');
  if (!isEuropeSection) { */
    return (
      <div onClick={() => { props.method(); navigate("/")}} style={{ cursor: "pointer" }}>
        <div className="box-12 text-center logo">
          <MdFingerprint />
        </div>
        <div className="box-12 text-center logoText">
          KrimiS
          <div className='line'></div>
        </div>
      </div>
    );
/*  } else {
    return (
      <div><div className="box-12 text-center logo">
        <MdFingerprint />
      </div>
        <div className="box-12 text-center logoText">


          Europe Crime
          <div className='line'></div>
        </div>
      </div>
    );
  } */
}
