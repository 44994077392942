import './Cards.css';
import { ReactNode } from 'react';

interface IProps {
    children: ReactNode;
};

export default function CardsBox(props: IProps) {
    return (
        <div className='cards-box'>
            {props.children}
        </div>
    )
}