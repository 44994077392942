import React, { useEffect, useMemo } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import ReportSectionPlaceholder from '../TReportSectionPlaceholder';
import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { Button } from "../../../../components/StyleComponents/StyleComponents";
import "./Grid.module.css";
import {v4 as uuidv4} from 'uuid';

interface IProps {
  sectionsDraw: any[];
  editMode: boolean;
  handleAddSection: (newSection: any) => void;
  setSections: (sections: any[]) => void;
}

const ReportGrid = (props: IProps) => {

  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const { t } = useTranslation("traffic");
  const [layout, setLayout] = React.useState([] as any[]);

  useEffect(() => {
      const loadedLayout = props.sectionsDraw.map((section) => section.props.data.layoutData);
      setLayout(loadedLayout);
  }, [props.sectionsDraw]);


  const sectionsDrawGrid = props.sectionsDraw.map((section, idx) => {
    let resizeHandles = 'se';
    return (
      <div key={section.props.data.layoutData?.i} data-grid={{ ...section.props.data.layoutData, resizeHandles: [`${resizeHandles}`] }}>
        {section}
      </div>
    );
  });
  
  function getNextSectionPosition() {
    if (layout.length === 0) {
      // If layout is empty, return initial position
      return { x: 0, y: 0, w: 3, h: 2, minW: 3, minH: 2 };
    }
  
    // Sort layout by row and then by x coordinate
    const sortedLayout = [...layout].sort((a, b) => a.y - b.y || a.x - b.x);
  
    // Find the last row
    const lastRowY = sortedLayout[sortedLayout.length - 1].y;
  
    // Find sections in the last row
    const lastRowSections = sortedLayout.filter((section) => section.y === lastRowY);
  
    // Find the section with the maximum x coordinate in the last row
    const lastSection = lastRowSections.reduce(
      (maxXSection, section) => (section.x > maxXSection.x ? section : maxXSection),
      { x: -Infinity, y: -Infinity, w: 0, h: 0 }
    );
  
    // Calculate the position for the new section
    const nextSectionX = lastSection.x + lastSection.w;
    const nextSectionY = lastSection.y;
  
    // Check if the new section fits into the last row
    if (nextSectionX + 2 <= 12) {
      return { x: nextSectionX, y: nextSectionY, w: 3, h: 2, minW: 3, minH: 2 };
    } else {
      // If it doesn't fit, insert into a new row at x: 0
      const newRowY = lastRowY + lastSection.h;
      return { x: 0, y: newRowY, w: 3, h: 2, minW: 3, minH: 2 };
    }
  }

  const handleAddSection = () => {
    const nextSectionPositions = {...getNextSectionPosition(), minW: 1, minH: 1, i: uuidv4()};
    props.handleAddSection({layoutData: nextSectionPositions, type: {type: "text"}, textData: ''});
    setLayout((prevLayout: any) => [...prevLayout, nextSectionPositions]);
  };

  // const onLayoutChange = (layout: any) => {
  //   setLayout(layout);
  //   const updatedJsonData = {
  //     "sections": props.sections.map((section: any) => {
  //       const layoutDataToUpdate = layout.find((data: any) => data?.i === section.layoutData?.i);
  //       if (layoutDataToUpdate) {
  //         return {
  //           ...section,
  //           layoutData: { ...section.layoutData, ...layoutDataToUpdate }
  //         };
  //       }
  //       return section;
  //     })
  //   };
  //   props.setSections(updatedJsonData.sections);
  // };

  const onLayoutChange = (layout: any) => {
    setLayout(layout);

    const updatedJsonData = {
      "sections": props.sectionsDraw.map((sectionDraw: any) => {
        if(sectionDraw.props.data === undefined) return null;
        const layoutDataToUpdate = layout.find((data: any) => data?.i === sectionDraw.props.data.layoutData?.i);
        if (layoutDataToUpdate) {
          return {
            ...sectionDraw.props.data,
            layoutData: { ...sectionDraw.props.data.layoutData, ...layoutDataToUpdate }
          };
        }
        return sectionDraw.props.data;
      })
    };

    if(updatedJsonData.sections[0] !== null) {
      props.setSections(updatedJsonData.sections);
    }
  };

  return (
    <>
    {props.editMode && 
      <Grid sx={{ textAlign: "right" }}>
        <Button onClick={handleAddSection}>
            <div style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                <FaPlus size="15" style={{margin: '5px'}}/>
                {t("Traffic.newSection")}
            </div>
        </Button>
      </Grid>}

      <ResponsiveGridLayout draggableCancel={".nonDraggable"} onLayoutChange={onLayoutChange} isDraggable={props.editMode} isResizable={props.editMode} cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}>
        {sectionsDrawGrid}
        {props.editMode &&
        <div key={'add'} data-grid={{...getNextSectionPosition(), isDraggable: false, isResizable: false}}>
          <ReportSectionPlaceholder createReportSection={handleAddSection} />
        </div>}
      </ResponsiveGridLayout>
    </>
  );
};

export default ReportGrid;