import { Grid, Typography } from "@mui/material";
import PageHeader from "./PageHeader";
import image from '../../assets/css/images/police.jpg';

interface IProps {
    image?: any;
    label: any;
    children: any;
}

export default function PageForm(props: IProps) {
    return (
      <Grid>
        <PageHeader label={props.label} image={props.image ? props.image : image}/>
        <Grid textAlign={"center"} mb={4}>
          <Typography variant="h4">{props.label}</Typography>
        </Grid> 
        <Grid marginTop={10} className="none lg-default" />
        <Grid padding={1}>
          {props.children}
        </Grid>
      </Grid>
    )
}