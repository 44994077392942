import { SearchFilter } from '../../interfaces/SearchFilter';
import css from './Search.module.css';
import { FaChevronDown, FaChevronUp, FaFilter } from 'react-icons/fa';
import SFilterModal from './SFilterModal';
import { useState } from 'react';

interface IProps {
    header: SearchFilter;
    allFilters: SearchFilter[];
    order: string;
    asc: boolean;
    onFilterChange: (filter: SearchFilter) => void;
    onOrderChange: (order: string, asc: boolean) => void;
    endpointService: any;
}

export default function STableHead(props: IProps) {

    const [showFilter, setShowFilter] = useState<boolean>(false);

    const showFilterHandler = () => {
        setShowFilter((old) => !old);
    }

    const filterChangeHandler = (data: any) => {
        props.onFilterChange(data);
        setShowFilter((old) => !old);
    }

    const orderChangeHandler = () => {
        if ( props.order === props.header.atr) {
            if (props.asc) {
                props.onOrderChange(props.header.atr, false);
            } else {
                props.onOrderChange("", true);
            }
        } else {
            props.onOrderChange(props.header.atr, true);
        }
    }

    return (
        <th className={ `${css.header} ${css.clickable} ${css.withIcon} ${props.header.active ? `` : `${css.hide}`}` }>
            <span onClick={orderChangeHandler}>
                { props.header.name }
                { props.order === props.header.atr && (
                    props.asc ? <FaChevronDown /> : <FaChevronUp />
                )}
            </span>
            <FaFilter onClick={showFilterHandler}/>
            <SFilterModal 
                show={showFilter}
                name={props.header.name}
                filter={props.header}
                allFilters={props.allFilters}
                onClose={showFilterHandler}
                onConfirm={filterChangeHandler}
                endpointService={props.endpointService}
            />
        </th>
    );
}