import {
  CircleButton,
  HR,
  MenuButton,
} from "../StyleComponents/StyleComponents";
import "./Menu.css";
import Logo from "../Logo/Logo";
import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { Link, useLocation/*, useNavigate*/ } from "react-router-dom";
import { MenuItems, menuItems } from "../../interfaces/ConstMenu";
import { useTranslation } from 'react-i18next';
import LangSwitch from "./LangSwitch";
import useUser from "../../use/useUser";
import { MdOutlineLogout } from "react-icons/md";
import UsersService from "../../services/UsersService";
import User from "./User";


interface MenuProps {
  method?: MouseEventHandler;
  show?: boolean;
}

export default function Menu(props: MenuProps) {
  const location = useLocation();
  const { t } = useTranslation("default");

  const [ user, { isAdmin, isLoggedIn, logout } ] = useUser();
  const [ token, setToken ] = useState();

  const checkPrivilege = (item: MenuItems): boolean => {
    if (item.hidden) return false;
    if (item.unloggedOnly && isLoggedIn()) return false;
    if (item.loggedOnly && !isLoggedIn()) return false;
    if (item.adminOnly && !isAdmin()) return false;
    return true;
  }

  useEffect(() => {
    if (!token)
      return;
    UsersService.extendToken(token)
    .then((res: any) => {
        if (res.data.success) {
          setTimeout(myTimer, 780000); //13min 780000
        } else {
          logout();
        }
    })
    .catch(() => { logout(); });
    
    function myTimer() {
      setToken(undefined);
      setToken(user?.token);
    }
    
  }, [token, setToken, logout, user]);

  useEffect(() => {
    if (!user) {
      setToken(undefined);
      return;
    }
    console.log(user);
    if (!token)
      setToken(user.token);
  }, [user, setToken, token]);

/*
const user = userService.isLoggedIn() && <span>{userService.getUser()?.name} {userService.getUser()?.surname}</span>;
//  const login = !userService.isLoggedIn() && <MenuButton onClick={redirectToLogin}><IoMdPerson />Prihláste sa</MenuButton>;
  const loginEurope = !userService.isLoggedIn() && <MenuButton onClick={redirectToLoginEurope}><IoMdPerson />Log in</MenuButton>;
//  const logout = userService.isLoggedIn() && <MenuButton onClick={logOut}><MdOutlineLogout /> Odhláste sa</MenuButton>;
  const logoutEurope = userService.isLoggedIn() && <MenuButton onClick={logOut}><MdOutlineLogout /> Log out</MenuButton>; */

  const logoutButt = isLoggedIn() && <MenuButton onClick={logout}><MdOutlineLogout />{t("menu.logout")}</MenuButton>;

  const drawMenu = (items: MenuItems[], className: string = ""): ReactNode => {
    console.log(location.pathname, items, location.pathname.includes('/'));
    return items.map((item: MenuItems, index: number) => (
      
      checkPrivilege(item) ?
        <div
          key={index}
          className={
            "box-12 outer-submenu-show " + (location.pathname.includes(item.path) ? "" : "outer-submenu-hide") + (location.pathname === item.path ? "active-path" : "")
          }
        >
          <MenuButton className={`${className}`} onClick={props.method}>
            {item.path && (
              <Link to={item.path} style={{ width: '100%' }}>
                {<item.icon />}  {t(item.label)}
              </Link>
            )}
            {!item.path && (
              <span>
                {<item.icon />}  {t(item.label)}
              </span>
            )}
          </MenuButton>
          {item.submenu &&
            drawMenu(
              item.submenu,
              location.pathname.includes(item.path)
                ? "submenu-show"
                : "submenu-show submenu-hide"
            )}
        </div> : undefined
    ));
  };
  let menuItemsList = null;
  // if (!isEuropeSection) {
    menuItemsList = drawMenu(menuItems);
  /* } else {
    menuItemsList = drawMenu(menuItemsEurope);
  } */

  // if (!isEuropeSection) {
    return (
      <div className={`menu box-12 ${(props.show !== undefined && !props.show) ? "hide-menu" : "" }`} >
        <div className="box-12 px-4 py-3 text-right lg-none">
          <CircleButton onClick={props.method}>
            <HiDotsHorizontal />
          </CircleButton>
        </div>
        <div className="box-12 pt-lg-10">
          <HR />
          <Logo method={props.method} />
        </div>
        <User />
        <LangSwitch />
        <HR />
        <div className="box-12">
          {menuItemsList}
        </div>
        {logoutButt}
        <div className="box-12 py-9">
          <HR />
        </div>
      </div>
    );
  /* } else {
    return (
      <div className="menu box-12">
        <div className="box-12 px-4 py-3 text-right lg-none">
          <CircleButton onClick={props.method}>
            <HiDotsHorizontal />
          </CircleButton>
        </div>
        <div className="box-12 pt-lg-10">
          <HR />
          <Logo />
          <HR />
        </div>
        <div className="box-12">
          {menuItemsList}
        </div>
        {user}
        {loginEurope}
        {logoutEurope}
        <div className="box-12 py-9">
          <HR />
        </div>
      </div>
    );
  } */
}
