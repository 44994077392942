import { SearchFilterValue } from '../../../interfaces/SearchFilter';
import { Sort } from '../../../interfaces/SortWT';
import SearchServiceTAP from '../../../services/SearchServiceTAP';
import STable from '../../Search/STableWT';

interface IProps {
    id: string
    data: any
    editMode: boolean
    globalFilterValues: any[]
    onRemove: (value: any) => void;
    onEdit: (id: any) => void;
    onExport: (id: any) => void;
    handleSortChange?: (sort: Sort, id: any) => void;
    handleFilterValuesAdd?: (filterValues: SearchFilterValue, id: any) => void;
}

export default function TReportGraph(props: IProps) { 
  return (
    <STable
        key={props.id}
        filter={props.data.tableData.filterNames}
        filterValues={props.data.tableData.filter}
        sort={props.data.tableData.sort}
        onFilterAdd={(filterValue: SearchFilterValue) => props.handleFilterValuesAdd && props.handleFilterValuesAdd(filterValue, props.id)}
        onSortChange={(sort: Sort) => props.handleSortChange && props.handleSortChange(sort, props.id)}
        endpointService={SearchServiceTAP}
        iconBar={true}
        reportCenter={true}
        editMode={props.editMode}
        onRemove={() => props.onRemove(props.id)}
        onEdit={() => props.onEdit(props.id)}
        onExportToReport={props.onExport || (() => {})}
        globalFilterValues={props.globalFilterValues}
      />
  );
}