interface IProps {
    image: any;
    label: any;
}

export default function PageHeader(props: IProps) {
    return (
    <div className='carousel-space none lg-default'>
        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
          <div className=" carousel-title-space">
            <div className="carousel-item active">
              <img className="d-block w-100" src={props.image} alt="First slide" />
              <h3 className="carousel-title">{props.label}</h3>
            </div>
          </div>
        </div>
    </div>
    )
}