import axios from "axios";

const importService: any = {
    addNewData: (t1 : string, t2 : string, t19 : string, t22 : string, t24 : string, p20 : string, p25 : string, p26 : string): any => {
        return axios.put(process.env.REACT_APP_API_URL + "/tc/importData/addData", {}, {
            params: {
                t1,
                t2,
                t19,
                t22,
                t24,
                p20,
                p25,
                p26,
            }
        });
    },
}

export default importService;