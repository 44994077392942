import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Chart } from "react-google-charts";
import { FaFilter, FaGreaterThan } from 'react-icons/fa';
import image from '../../../../assets/css/images/nahlasene.jpg';
import { CriminalOffence, FilterCriminalOffencesRequest, FilterCriminalOffencesResponse, ReportedYears, State, Statistics } from '../../../../interfaces/europe/interfaces';
import './CriminalOffenceMap.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect } from 'react';
import { GET_ALL_CO, GET_ALL_REPORTED_YEARS, GET_ALL_STATES, HEADER_WITH_INDEX_AND_COUNTRY, ITEM_HEIGHT, ITEM_PADDING_TOP, MAP_COLORS, MAP_OPTIONS, POST_FILTER_CO_LINK, POST_FILTER_CO_STATISTICS_LINK, TABLE_PAGE_SIZE_15, TABLE_PAGE_SIZE_TEN } from '../../../../interfaces/europe/Constants';
import { numberWithSpaces, floatNumberWithSpaces, getImage, CustomToolbar, getIcon, round } from '../../../../interfaces/europe/Functions';
import HeaderCarousel from '../../../../components/HeaderCarousel/HeaderCarousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";


export default function CriminalOffenceMap() {

    const { t } = useTranslation("europe");

    //RESTS FOR GET
    const [states, setStates] = React.useState<State[]>([]);
    const [reportedYears, setReportedYears] = React.useState<ReportedYears>();
    const [criminalOffences, setCriminalOffences] = React.useState<CriminalOffence[]>([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_STATES).then((response) => {
            setStates(response.data);
        });
    }, []);

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_REPORTED_YEARS).then((response) => {

            setReportedYears(response.data);
        });
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_CO).then((response) => {
            setCriminalOffences(response.data);
        });
    }, []);


    //RESTS POST 
    let requestFilter: FilterCriminalOffencesRequest = { "years": [], "states": [], "criminalOffences": [] };

    const [stateId, setStateIds] = React.useState<number[]>([]);
    const [yearsResult, setYear] = React.useState<number[]>([]);
    const [criminalOffencesCodes, setCriminalOffenceCodes] = React.useState<string[]>([]);
    const [counts, setCounts] = React.useState<FilterCriminalOffencesResponse[]>([]);
    const [mapCode, setMapCode] = React.useState<string>("150");
    const [statistics, setStatistics] = React.useState<Statistics>();

    useEffect(() => {
        requestFilter.years = yearsResult;
        requestFilter.states = stateId;
        requestFilter.criminalOffences = criminalOffencesCodes;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_FILTER_CO_STATISTICS_LINK, requestFilter).then((response) => {
            setStatistics(response.data);
        });
    }, [stateId, yearsResult, criminalOffencesCodes]);

    useEffect(() => {
        requestFilter.years = yearsResult;
        requestFilter.states = stateId;
        requestFilter.criminalOffences = criminalOffencesCodes;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_FILTER_CO_LINK, requestFilter).then((response) => {

            setCounts(response.data);
        });
    }, [stateId, yearsResult, criminalOffencesCodes]);

    //ADDED DATA HEADERS
    const mapped = counts.map(d => [d.codeState.toUpperCase(), `${t("states." + d.codeState.toUpperCase())}`, d.index, d.count]);
    const chartData = [HEADER_WITH_INDEX_AND_COUNTRY, ...mapped];

    //HANDLE FILTER CHANGES

    const handleStateChange = (event: SelectChangeEvent<typeof stateId>) => {

        const {
            target: { value },
        } = event;

        setStateIds(value as number[]);
    };

    const handleYearChange = (event: SelectChangeEvent<typeof yearsResult>) => {
        const {
            target: { value },
        } = event;
        setYear(value as number[]);
    };

    const handleCriminalOffenceChange = (event: SelectChangeEvent<typeof criminalOffencesCodes>) => {
        const {
            target: { value },
        } = event;
        setCriminalOffenceCodes(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleMapChange = (event: SelectChangeEvent) => {
        setMapCode(event.target.value as string);
    };

    const translatedRows = counts.map(row => ({
        ...row,
        nameState: t(`states.${row.codeState}`),
      }));

    //GET TEXT TO FILTERS
    function getState(x: number[]) {
        const filteredCourses = states.filter(state => x.slice(0, 3).some(number => number === state.id));
        const translated = filteredCourses.map(u => t(`states.${u.code}`));
        if (x.length > 3) {
            return translated.join(', ') + ",...";
        }
        return translated.join(', ');
    }

    function getCriminalOffenceName(x: string[]) {
        const filteredCourses = criminalOffences.filter(co => x.slice(0, 3).some(number => number === co.code));
        if (x.length > 3) {

            return filteredCourses.map(u => u.name).join(', ') + ",...";
        }
        return filteredCourses.map(u => u.name).join(', ');
    }

    //TABLE DEFINITIONS
    const columns: GridColDef[] = [
        {
            field: 'flag', headerName: '', width: 75, sortable: false, disableExport: true, filterable: false, renderCell: (params) => {
                return (
                    <div className="flag">
                        <img src={getImage(params.row.codeState)} alt={"Flag"} />

                    </div>
                )
            }
        },
        { field: 'nameState', headerName: `${t("map.state")}`, flex: 1 },
        {
            field: 'count', headerName: `${t("map.count")}`, flex: 1, renderCell: (params) => {
                return (
                    <>{numberWithSpaces(params.row.count)}</>
                )
            }
        },
        {
            field: 'index', headerName: `${t("analyse.crimeIndex")}`, flex: 1, renderCell: (params) => {
                return (
                    <>{floatNumberWithSpaces(params.row.index)}</>
                )
            }
        },
    ];


    const columnsStatistics: GridColDef[] = [
        {
            field: 'icon', headerName: '', align: 'center', headerAlign: 'center', width: 75, sortable: false, disableExport: true, cellClassName: 'icon-cell',
            filterable: false, renderCell: (params) => {
                return (
                    <>{getIcon(params.row.icon)}</>
                )
            }
        },
        { field: 'name', headerName: `${t("statistics.statistic")}`, flex: 1, align: 'center', headerAlign: 'center', headerClassName: 'bold-cell', cellClassName: 'bold-cell' },
        { field: 'value', headerName: `${t("statistics.value")}`, width: 200, align: 'right', headerAlign: 'right' }
    ];

    const rowStatistics = [
        { icon: 'Maximum', name: `${t("statistics.maximum")}`, value: round(statistics?.maximum) },
        { icon: 'Minimum', name: `${t("statistics.minimum")}`, value: round(statistics?.minimum) },
        { icon: 'Mean', name: `${t("statistics.mean")}`, value: round(statistics?.mean) },
        { icon: 'Median', name: `${t("statistics.median")}`, value: round(statistics?.median) },
        { icon: 'Variance', name: `${t("statistics.variance")}`, value: round(statistics?.variance) },
        { icon: 'Kurtosis', name: `${t("statistics.kurtosis")}`, value: round(statistics?.kurtosis) },
        { icon: 'StandardDeviation', name: `${t("statistics.standardDeviation")}`, value: round(statistics?.standardDeviation) },
        { icon: 'Skewness', name: `${t("statistics.skewness")}`, value: round(statistics?.skewness) },
        { icon: 'Percentile25', name: `${t("statistics.percentile25")}`, value: round(statistics?.percentile25) },
        { icon: 'Percentile50', name: `${t("statistics.percentile50")}`, value: round(statistics?.percentile50) },
        { icon: 'Percentile75', name: `${t("statistics.percentile75")}`, value: round(statistics?.percentile75) }
    ];

    //MAP GRAPH OPTIONS
    const options = {
        region: mapCode,
        displayMode: 'regions',
        datalessRegionColor: '#fff',
        colorAxis: {
            colors: [MAP_COLORS[0], MAP_COLORS[1], MAP_COLORS[2]]
        },
    }

    return (<>
        <div className=''>
            <HeaderCarousel name={t("map.title")} image={image} />

            <div className='underLabel '>
                <FaFilter className='my-3'></FaFilter><span className='pl-1'>Filter</span>
            </div>

            <form >
                <div>

                    <div className='d-inline w-100'>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="multiple-checkbox-label-states">{t("analyse.states")}</InputLabel>
                            <Select
                                labelId="multiple-checkbox-label-states"
                                id="multiple-checkbox-states"
                                multiple
                                value={stateId}
                                onChange={handleStateChange}
                                input={<OutlinedInput label={t("analyse.states")} />}
                                renderValue={selected => getState(selected)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            width: 250,
                                        },
                                    }
                                }
                                }
                            >
                                {states.map((state) => (
                                    <MenuItem key={state.name.toString()} value={state.id} >
                                        <Checkbox defaultChecked={true} checked={stateId.indexOf(state.id) > -1} />
                                        <ListItemText primary={t(`states.${state.code}`)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='d-inline'>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="multiple-checkbox-label-years">{t("map.years")}</InputLabel>
                            <Select
                                labelId="multiple-checkbox-label-years"
                                id="multiple-checkbox-years"
                                multiple
                                value={yearsResult}
                                onChange={(e) => { handleYearChange(e); }}
                                input={<OutlinedInput label={t("map.years")} />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            width: 250,
                                        },
                                    }
                                }}
                            >
                                {reportedYears?.years.map((year) => (
                                    <MenuItem key={year.toString()} value={year}>
                                        <Checkbox defaultChecked={true} checked={yearsResult.indexOf(year) > -1} />
                                        <ListItemText primary={year} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='d-inline'>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="multiple-checkbox-label-criminal-offences">{t("map.criminalOffences")}</InputLabel>
                            <Select
                                labelId="multiple-checkbox-label-criminal-offences"
                                id="multiple-checkbox-criminal-offences"
                                multiple
                                value={criminalOffencesCodes}
                                onChange={handleCriminalOffenceChange}
                                input={<OutlinedInput label={t("map.criminalOffences")} />}
                                renderValue={(selected) => getCriminalOffenceName(selected)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            width: 250,
                                        }
                                    },
                                }
                                }
                            >
                                {criminalOffences.map((criminal) => (
                                    <MenuItem key={criminal.code} value={criminal.code}>
                                        <Checkbox defaultChecked={true} checked={criminalOffencesCodes.indexOf(criminal.code) > -1} />
                                        <ListItemText primary={t(`criminalOffence.${criminal.code}.name`)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </div>

                    <div className='d-inline'>
                        <FormControl sx={{ m: 1, width: 250 }}>
                            <InputLabel id="demo-simple-select-label">{t("map.chooseMap")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={mapCode}
                                label={t("map.chooseMap")}
                                onChange={handleMapChange}
                                defaultValue={"150"}
                                sx={{ height: '56px' }}
                            >
                                {MAP_OPTIONS.map((map) => (
                                    <MenuItem key={map.id} value={map.id}>
                                        <ListItemText primary={map.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </form>
        </div >

        <div className="App mb-2">
            <Chart chartType="GeoChart" width="100%" height="600px" data={chartData} options={options} />
        </div>


        <div className="mt-5 col-12">
            <h3 className="mb-4">{t("map.subTitle")}</h3>
            <div style={{ height: 650, width: '100%' }}>

                <DataGrid
                    rows={translatedRows}
                    columns={columns}
                    disableColumnSelector
                    disableSelectionOnClick={true}
                    pageSize={TABLE_PAGE_SIZE_TEN}
                    autoHeight={true}
                    getRowId={(row) => row.idState}

                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'index', sort: 'desc' }],
                        },
                    }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{ toolbar: { allColumns: true } }}
                />


            </div>
        </div>
        <div className="mt-5 col-12">
            <h3 className="mb-4">{t("statistics.title")}</h3>
            <div style={{ height: 720, width: '100%' }}>
                <DataGrid
                    rows={rowStatistics}
                    columns={columnsStatistics}
                    disableColumnSelector
                    disableSelectionOnClick={true}
                    pageSize={TABLE_PAGE_SIZE_15}
                    autoHeight={true}
                    getRowId={(row) => row.name}

                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'index', sort: 'desc' }],
                        },
                    }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{ toolbar: { allColumns: true } }}
                />
            </div>
        </div>

        <div className='info'> {t("statistics.crimeIndex")}</div>
    </>);



}
