import { Button } from "../../../components/StyleComponents/StyleComponents";
import { useState, ChangeEvent } from 'react';
import { MdOutlinePersonAdd } from "react-icons/md";
import { FiLogIn } from "react-icons/fi";
import NotificationService from './../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import UsersService from "../../../services/UsersService";
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";
import image from '../../../assets/css/images/police.jpg';
import { useTranslation } from "react-i18next";
import useUser from "../../../use/useUser";
import PageForm from "../../../components/PageHeader/PageForm";

export default function Login() {
  const { t } = useTranslation("default");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ , { setUserData} ] = useUser();

  const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const passwordChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  const redirectToRegister = (): void => {
    navigate("/registration");
  }

  const checkEmail = (): boolean => {
    // eslint-disable-next-line
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(email);
  }

  const tryToLogin = () => {
    if (email.length > 0 && password.length > 0) {
      if (checkEmail()) {
        UsersService.login(email, password)
        .then((res: any) => {
            if (res.data.success) {
              setUserData(res.data.user);
              navigate("/");
            } else {
              NotificationService.error(t("default.badLoginData"));
            }
        })
        .catch(() => {});
      } else {
        NotificationService.error(t("default.emailErr"));
      }
    } else {
      NotificationService.error(t("default.fillData"));
    }
  }

  return (
    <PageForm label={t("default.loginTitle")} image={image}>
      <Grid container textAlign={'right'}>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <TextField fullWidth value={email} onChange={emailChangeHandler} id="outlined-basic" label={t("default.email")} variant="outlined"  placeholder="example@mail.com" />
          <TextField fullWidth value={password} onChange={passwordChangeHandler} id="outlined-basic" label={t("default.password")} variant="outlined" placeholder={t("default.password") || 'Password'} style={{ marginTop: "18px" }} type="password" />
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked />} label={t("default.rememberMe")} />
          </FormGroup>
          <Grid item xs={12} textAlign={'center'}>
            <Button onClick={tryToLogin}><FiLogIn />{t("default.login")}</Button>
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={4}>
            <Typography variant="body1">{t("default.noAccountQuestion")}</Typography>
            <Button onClick={redirectToRegister}><MdOutlinePersonAdd />{t("default.register")}</Button>
          </Grid>
        </Grid>
      </Grid>
    </PageForm>
  );
}