import { useCookies } from 'react-cookie';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface User {
    token: string;
    role: number;
    id: number;
    email: string;
    name: string;
    surname: string;
}

export default function useUser() {
    const navigate = useNavigate();
    const [ userCookie, setUserCookie, removeCookie ] = useCookies(['user']);

    const setUserData = useCallback((data: User) => {
        setUserCookie("user", data);
    }, [ setUserCookie ]);

    const isLoggedIn = useCallback(() => {
        return userCookie?.user !== undefined && userCookie?.user !== null;
    }, [userCookie]);

    const isAdmin = useCallback((): boolean => {
        return userCookie?.user?.role === 1;
    }, [userCookie.user]);

    const logout = useCallback(() => {
        removeCookie("user");
        navigate("/");
    }, [removeCookie, navigate]);

    return [ userCookie.user, { setUserData, isAdmin, isLoggedIn, logout } ];
}