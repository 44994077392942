import { SearchFilter } from '../../interfaces/SearchFilter';
import SearchData from '../../interfaces/SearchData';
import css from './Search.module.css';
import { ExpressionValue } from '../Expressions/Expressions';

interface IProps {
    filter: SearchFilter[];
    expressions?: ExpressionValue[];
    data: SearchData;
    onDoubleClick?: (item: SearchData) => void;
}

export default function STableRow(props: IProps) {
    const handleDoubleClick = () => {
        if (props.onDoubleClick) {
            props.onDoubleClick(props.data);
        }
    };

    return (
        <tr onDoubleClick={handleDoubleClick}>
            { props.filter.map((h: SearchFilter, idxH: number) =>
                <td key={ idxH } className={ `${h.active ? `` : css.hide}` }>{ props.data[h.atr] }</td>
            )}
            { props.expressions?.map((h: ExpressionValue, idxH: number) =>
                <td key={ idxH } className={ `${h.active ? `` : css.hide}` }>{ props.data[h.label] }</td>
            )}
        </tr>
    );
}