import { SearchFilter, SearchFilterValue } from "../../interfaces/SearchFilter"
import { Button } from "../StyleComponents/StyleComponents";
import { ExpressionValue } from "../Expressions/Expressions";
import { useTranslation } from "react-i18next";
import STableModal from "./STableModal";
import { useState } from "react";

interface IProps {
    filter: SearchFilter[],
    customExpressions?: ExpressionValue[];
    filterValues: SearchFilterValue[];
    withVisibleOption?: boolean;
    onFilterChange: (filter: SearchFilter[], filterValues: SearchFilterValue[]) => void;
    onExpressionChange?: (exp: ExpressionValue[]) => void;
    endpointService: any;
}

export default function SFilterSettings(props: IProps) {
    const { t } = useTranslation("crimes");
    const [ show, setShow ] = useState<boolean>(false);
    
    const handleClick = () => {
        setShow(true);
    }

    return <>
        <Button onClick={handleClick}>{t("analyzis.filterSetting")}</Button>
        <STableModal
            show={show}
            filter={props.filter}
            customExpressions={props.customExpressions}
            filterValues={props.filterValues}
            onFilterChange={props.onFilterChange}
            onExpressionChange={props.onExpressionChange}
            setShow={setShow}
            endpointService={props.endpointService}
            withVisibleOption={props.withVisibleOption}
        />
    </>
}