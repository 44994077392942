import { Grid, Typography } from "@mui/material";

export default function HeaderCarousel(props: any) {
  return (
    <>
      <div className='carousel-space none lg-default'>
        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-title-space">
            <div className="carousel-item active">
              <img className="d-block w-100" src={props.image} alt="First slide" />
              <h3 className="carousel-title">{props.name}</h3>
            </div>
          </div>
        </div>
      </div>

      <Grid textAlign={"center"} mb={4}>
        <Typography variant="h4">{props.name}</Typography>
      </Grid>
    </>
  );
}
