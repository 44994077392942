import axios, { AxiosResponse } from "axios";
import { Cookies } from 'react-cookie';

const Requests = (): any => {

    return {
        get: (url: string): Promise<AxiosResponse<any, any>> => {
            return axios.get(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        post: (url: string, data: any): Promise<AxiosResponse<any, any>> => {
            return axios.post(process.env.REACT_APP_API_URL + url, data, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        delete: (url: string): Promise<AxiosResponse<any, any>> => {
            return axios.delete(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        put: (url: string): Promise<AxiosResponse<any, any>> => {
            return axios.put(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        },
    };
}

export default Requests();