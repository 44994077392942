import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { AiFillSetting } from "react-icons/ai";
import analyzisService from "../../services/StatisticService";
import CustomModal from "../Modal/CustomModal";
import { Button } from "../StyleComponents/StyleComponents";
import CustomTable from "../Table/CustomTable";
import { useTranslation } from 'react-i18next';
import iDataset from "../../interfaces/iDataset";


interface IProps {
    dataset: iDataset;
    //filter: SearchFilter[];
    ///filterValues: SearchFilterValue[];
    //specify: String;
};

export default function BasicStatistics(props: IProps) {

    const { t } = useTranslation("crimes");
    const [statistic, setStatistic] : [any[][], Function] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [decimalPlaces, setDecimalPlaces] = useState(2);
    const header = [t("analyzis.statisticName"), t("analyzis.value")];

    useEffect(() => {
        const abortCtrl = new AbortController();
        const dataset = props.dataset.graphValues[0].data[0];
        const fltValues = dataset.filter ? dataset.filter : props.dataset.filterValues;
        const fval = fltValues.map((value) => ({
            type: value.type.value,
            value: value.value.map((val) => ({
                type: val.type.value,
                atr: val.atribute.value,
                option: val.option.value,
                value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
            }))
        }));
        analyzisService.getBasicStatistic(dataset.dataFirst, dataset.dataSecond, dataset.dataFirstInterval, dataset.dataSecondInterval
            , dataset.dataFirstIntervalBy, dataset.dataSecondIntervalBy, props.dataset.graphValues[0].group, fval
            , props.dataset.expressionValues, decimalPlaces, abortCtrl.signal)
        .then((res: any) => {
            setStatistic([
                ['crimes:analyzis.mean', res.data.data[0].mean], 
                ['crimes:analyzis.error', res.data.data[0].meanstandarterror],
                ['crimes:analyzis.median', res.data.data[0].median], 
                ['crimes:analyzis.modus', res.data.data[0].modee],
                ['crimes:analyzis.deviation', res.data.data[0].standarddeviation], 
                ['crimes:analyzis.variance', res.data.data[0].samplevariance],
                ['crimes:analyzis.range', res.data.data[0].range], 
                ['crimes:analyzis.minimum', res.data.data[0].min],
                ['crimes:analyzis.maximum', res.data.data[0].max], 
                ['crimes:analyzis.sum', res.data.data[0].sum],
                ['crimes:analyzis.count', res.data.data[0].count]
            ]);
        })
        .catch(() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, props.dataset.expressionValues, props.dataset.graphValues, props.dataset.filterValues, props.dataset.filter]);
                    
    return (
        <>
        <Grid marginBottom={4}>
            <Typography variant="body1" fontWeight={"bold"} textAlign={"center"} marginBottom={2}>{t("analyzis.baseStatistics")}</Typography>
            <Grid sx={{ textAlign: "right" }}>
                <Button onClick={() => setShowModal(true)}><AiFillSetting /></Button>
            </Grid>
            <Grid container>
                <Grid item xs={3} />
                <Grid item xs={6}>
                    { statistic?.length > 0 && <CustomTable header={header} body={statistic} />}
                </Grid>
            </Grid>
        </Grid>
        <CustomModal
            show={showModal}
            title={t("analyzis.settings")}
            width="500px"
            onClose={() => setShowModal(false)}
        >
            <Grid marginBottom={2} marginTop={2}>
                <h4>{t("analyzis.decimalCount")}</h4>
                <input type="number" placeholder={t("analyzis.decimalCount") + ""} value={decimalPlaces} onChange={(e) => setDecimalPlaces(parseInt(e.target.value))}/>
            </Grid>
            <Grid sx={{ textAlign: "right" }}>
            <Button onClick={() => setShowModal(false)}>{t("analyzis.closeButton")}</Button>
            <Button onClick={() => setShowModal(false)}>{t("analyzis.saveButton")}</Button>
            </Grid>
        </CustomModal>
        </>
    )
}