
// CONSTANTS

//EXTERNAL_LINKS
export const COUNTRY_EXTERNAL_INFO = "https://restcountries.com/v3.1/alpha/"

// LINKS ON FRONTEND
export const CRIMINAL_OFFENCE_INFO_SITE = "/europe/criminalOffences";
export const CRIMINAL_OFFENCE_REPORT_SITE = "/europe/report";
export const STATES_SITE = "/europe/states";
export const STATE_INFO_SITE = "/europe/states/info/id=";

// LINKS ON BACKEND

//GET
export const GET_TOTAL_CO_IN_YEAR_BY_STATE_ID = "/ReportedCriminalOffences/totalCriminalOffencesInYearsByStateId?stateId=";
export const GET_COUNT_OF_CO_BY_STATE_ID = "/ReportedCriminalOffences/countsCriminalOffencesByStateId?stateId=";
export const GET_STATE_INFO = "/State/getInfo?id=";
export const GET_INDEXES_OF_CO_BY_STATE_ID = "/ReportedCriminalOffences/indexesCriminalOffencesByStateId?stateId=";
export const GET_LAST_INDEXES_CO_BY_STATE_ID = "/ReportedCriminalOffences/lastIndexesCriminalOffencesByStateId?stateId=";
export const GET_ALL_CO_WITH_DETAIL = "/CriminalOffence/allWithDetails";
export const GET_ALL_CO = "/CriminalOffence/all";
export const GET_ALL_REPORTED_YEARS = "/ReportedCriminalOffences/reportedYears";
export const GET_ALL_STATES = "/State/all"
export const GET_ALL_TYPE_OF_FIREARMS = "/TypeOfFirearm/all"
export const GET_PREDICTION = "/ReportedCriminalOffences/predictionTotalCountByStateId";
export const GET_ALL_CCO = "/CriminalOffence/allConvicted";
export const GET_ALL_CCO_DISTINCT = "/ConvictedOffences/allDistinct";
export const GET_CCO_PREDICTION = "/ConvictedOffencesCount/predictionTotalCountByStateId";

//POST

export const POST_FILTER_CO_LINK = "/ReportedCriminalOffences/filterReportedCriminalOffenceIndex";
export const POST_FILTER_CO_STATISTICS_LINK = "/ReportedCriminalOffences/filterReportedCriminalOffenceStatistics";
export const POST_FILTER_CO_IN_STATE_LINK = "/ReportedCriminalOffences/filterCriminalOffenceByStateId";
export const POST_FILTER_CO_IN_STATE_STATS_LINK = "/ReportedCriminalOffences/filterReportedCriminalOffenceStatisticsForState";
export const POST_FILTER_CO_ANALYSES = "/ReportedCriminalOffences/filterCriminalAnalysesByYearsAndLimit";
export const POST_BAC_STATISTIC_ANALYSES = "/ReportedCriminalOffences/getBACStatistics";
export const POST_DRUG_STATISTIC_ANALYSES = "/ReportedCriminalOffences/getDrugsStatistics";
export const POST_STATE_COMPARE_TOTAL_INDEXES = "/ReportedCriminalOffences/compareState/totalIndex";

export const POST_STATE_COMPARE_INFO = "/State/compareStates";
export const POST_STATE_COMPARE_CO = "/ReportedCriminalOffences/compareStates";

export const POST_STATE_COMPARE_TOTAL_CO_IN_YEAR_BY_STATE = "/ReportedCriminalOffences/compareStates/totalCriminalOffencesInYears";
export const POST_STATE_COMPARE_CRIME_INDEX_YEAR_BY_STATE = "/ReportedCriminalOffences/compareStates/totalCrimeIndexInYears";
export const POST_STATE_COMPARE_CRIME_INDEX_BY_CO = "/ReportedCriminalOffences/compareStates/crimeIndexesByCrimeCategory";
export const POST_STATE_COMPARE_CRIME_COUNTS = "/ReportedCriminalOffences/​compareStates/filterReportedCriminalOffenceCounts";
export const POST_STATE_COMPARE_CRIME_COUNTS_STATISTICS = "/ReportedCriminalOffences/compareStates/filterReportedCriminalOffenceCountsStatistics";
export const POST_STATE_COMPARE_CRIME_COUNTS_FOR_YEARS = "/ReportedCriminalOffences/compareStates/filterReportedCriminalOffenceCountsByCodeForStates";
export const POST_STATE_COMPARE_CRIME_COUNTS_BY_CO = "/ReportedCriminalOffences/compareStates/crimeCountsByCrimeCategory";

export const POST_STATE_COMPARE_CONVICTED_TOTAL_CO_IN_YEAR_BY_STATE = "/ConvictedOffencesCount/compareStates/totalCriminalOffencesInYears";
export const POST_STATE_COMPARE_CONVICTED_CRIME_INDEX_YEAR_BY_STATE = "/ConvictedOffencesCount/compareStates/totalCrimeIndexInYears";
export const POST_STATE_COMPARE_CONVICTED_CRIME_INDEX_BY_CO = "/ConvictedOffencesCount/compareStates/crimeIndexesByCrimeCategory";
export const POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS = "/ConvictedOffencesCount/​compareStates/filterConvictedCriminalOffenceCounts";
export const POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS_STATISTICS = "/ConvictedOffencesCount/compareStates/filterConvictedCriminalOffenceCountsStatistics";
export const POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS_FOR_YEARS = "/ConvictedOffencesCount/compareStates/filterConvictedCriminalOffenceCountsByCodeForStates";
export const POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS_BY_CO = "/ConvictedOffencesCount/compareStates/crimeCountsByCrimeCategory";

export const POST_FILTER_CO_ANALYSES_DRUGS = "/ReportedCriminalOffences/filterCriminalAnalysesDrugs";
export const POST_POLICE_OFFICERS_LINK = "/PoliceStaffType/policetypeforstates/policeofficers";
export const POST_POLICE_CIVILIANS_LINK = "/PoliceStaffType/policetypeforstates/civilians";
export const POST_STATE_COMPARE_POLICE_INDEXES = "/PoliceStaffCount/compareStates/policestaffindexcountsInYears";
export const POST_STATE_COMPARE_POLICE_COUNTS = "/PoliceStaffCount/compareStates/policestaffcountsInYears";



//PART OF LINKS
export const CODE_PARAM = "/code=";
export const CO_PARAM = "/crimeoffences";
export const ADD_PARAM = "/add";
export const SLASH = "/";
export const LINK_STATE_COMAPRE_INFO = "/europe/states/compare/info/codes=";
export const LINK_STATE_COMAPRE_CO = "/europe/states/compare/criminalOffences/codes=";
export const LINK_STATE_COMAPRE_POLICE = "/europe/states/compare/police/codes=";
export const LINK_PREDICTION_CO = "/europe/prediction/";
export const LINK_PREDICTION_CCO = "/europe/prediction/convicted";
export const LINK_STATE_COMAPRE_CONVICTED = "/europe/states/compare/convictedCriminalOffences/codes=";
export const POLICE_PARAM = "/police";
export const CONVICTED_PARAM = "/convicted";



//ADMINISTRATION
export const CRIMINAL_OFFENCE_NAME = "CriminalOffence";
export const CRIMINAL_OFFENCE_REMARKS_NAME = "CriminalOffenceRemarks";
export const DRUG_NAME = "Drug";
export const IS_FIREARM_NAME = "IsFirearm";
export const LEGAL_LIMIT_OF_DRUGS_NAME = "LegalLimitOfDrugs";
export const REPORTED_CRIMINAL_OFFENCES_NAME = "ReportedCriminalOffences";
export const STATE_NAME = "State";
export const STATE_POPULATION_NAME = "StatePopulation";
export const STATE_RULEBOOK_NAME = "StateRulebook";
export const SUBCATEGORY_OF_CRIMINAL_OFFENCE_NAME = "SubcategoryOfCriminalOffence";
export const SUBSECTION_OF_CRIMINAL_OFFENCE_NAME = "SubsectionOfCriminalOffence";
export const TYPE_OF_FIREARM_NAME = "TypeOfFirearm";
export const CONVICTED_OFFENCES_COUNT = "ConvictedOffencesCount";
export const CONVICTED_OFFENCES = "ConvictedOffences";
export const POLICE_STAFF_COUNT = "PoliceStaffCount";
export const POLICE_TYPE = "PoliceType";
export const POLICE_STAFF_TYPE = "PoliceStaffType";

// COLORS
export const COLORS = ['#00a0dc', '#6146d9', '#7cb82f', '#dd5143', '#e68523', '#00aeb3', '#edb220', '#dc4b89', '#86888a', '#07b151'];
export const MAP_COLORS = ['#f5f5f5', '#6E66CC', '#0e1420'];
export const PRIMARY_COLOR = "#00a0dc";
export const SECONDARY_COLOR = "#6146d9";

//MAP

export const MAP_OPTIONS = [{ id: 'world', name: 'World' }, { id: '150', name: 'Europe' }, { id: '154', name: 'Northern Europe' }, { id: '155', name: 'Western Europe' }, { id: '151', name: 'Eastern Europe' }, { id: '039', name: 'Southern Europe' }, { id: '145', name: 'Western Asia' }];

//HEADER DATA

export const HEADER_WITH_INDEX_AND_COUNTRY = ["Code", "Country", "Crime index", "Count"];
export const HEADER_FIlTER_ANALYSE = ["Code", "Country", "Filtered Crime index", { role: 'tooltip', p: { html: true } }];//, "Less then value"
export const HEADER_STATE_COMPARE_LOCATION = ["Code", "Country", "Index", { role: 'tooltip', p: { html: true } }];

//CARD CAROUSEL
export const COUNT_OF_CARDS = 3;
export const TABLE_PAGE_SIZE_TEN = 10;
export const TABLE_PAGE_SIZE_15 = 15;
export const TABLE_PAGE_SIZE_5 = 5;

//SELECT CONSTANTS
export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const DRUG_MAX_VALUE = 10;