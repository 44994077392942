import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MdLocalPolice } from "react-icons/md";
import Dataset from "../../../components/DataAnalyzis/Dataset";
import CustomModal from "../../../components/Modal/CustomModal";
import { Button } from "../../../components/StyleComponents/StyleComponents";
import iDataset from "../../../interfaces/iDataset";
import NotificationService from "../../../services/NotificationService";
import image from '../../../assets/css/images/police.jpg';
import { useTranslation } from 'react-i18next';
import PageForm from "../../../components/PageHeader/PageForm";
import filterItems from "../../../interfaces/ConstFilter";
import analyzisService from "../../../services/StatisticService";

export default function Analyzis() {

  const { t } = useTranslation("crimes");
  const [loading] = useState(false);//todo ,setLoading
  const [datasets, setDatasets]: [iDataset[], Function] = useState([]);

  const [corr1, setCorr1] = useState();
  const [corr2, setCorr2] = useState();
  const [corrMatrix, setCorrMatrix] = useState();

  const [showModal, setShowModal] = useState(false);
  const [showModalCorr, setShowModalCorr] = useState(false);
  const [newDatasetName, setNewDatasetName] = useState("");

  useEffect(() => {
    setDatasets([{title: "Dataset 1", basicStatistic: {}, filter: filterItems, filterValues: [], expressionValues: [], graphValues: []}]);
  }, []);

  useEffect(() => {
    if (showModal === true)
      setNewDatasetName("");
  }, [showModal]);

  useEffect(() => {
    setCorr1(undefined);
    setCorr2(undefined);
    setCorrMatrix(undefined);
  }, [showModalCorr]);

  const correlation = () => {
    if (corr1 !== undefined && corr2 !== undefined && datasets[corr1].graphValues.length > 0 && datasets[corr2].graphValues.length > 0) {
      const abortCtrl = new AbortController();
      const dataset = datasets[corr1].graphValues[0].data[0];
      const fltValues = dataset.filter ? dataset.filter : datasets[corr1].filterValues;
      const fval = fltValues.map((value) => ({
          type: value.type.value,
          value: value.value.map((val) => ({
              type: val.type.value,
              atr: val.atribute.value,
              option: val.option.value,
              value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
          }))
      }));

      const bdataset = datasets[corr2].graphValues[0].data[0];
      const bfltValues = bdataset.filter ? bdataset.filter : datasets[corr2].filterValues;
      const bfval = bfltValues.map((value) => ({
          type: value.type.value,
          value: value.value.map((val) => ({
              type: val.type.value,
              atr: val.atribute.value,
              option: val.option.value,
              value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
          }))
      }));

    analyzisService.getCorrelation(
        dataset.dataFirst, dataset.dataSecond, dataset.dataFirstInterval, dataset.dataSecondInterval
      , dataset.dataFirstIntervalBy, dataset.dataSecondIntervalBy, datasets[corr1].graphValues[0].group, fval
      , bdataset.dataFirst, bdataset.dataSecond, bdataset.dataFirstInterval, bdataset.dataSecondInterval
      , bdataset.dataFirstIntervalBy, bdataset.dataSecondIntervalBy, datasets[corr2].graphValues[0].group, bfval
      , datasets[corr1].expressionValues, datasets[corr2].expressionValues, abortCtrl.signal)
    .then((res: any) => {
      console.log(res.data.matrix);
      setCorrMatrix(res.data.matrix);
    })
    .catch(() => {
      NotificationService.error("Chyba", `Datasety nebolo možné korelovať`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps


    } else {
      NotificationService.error("Chyba", `Vo zvoľených datasetoch musí byť vytvorený graf`);
    }
  };

  const addDataset = () => {
    let tempDatasets = [...datasets];
    tempDatasets.push({title: newDatasetName, filter: filterItems, filterValues: [], expressionValues: [], graphValues: [] });
    setDatasets(tempDatasets);
    setShowModal(false);
    NotificationService.success(t("analyzis.succAdded"));
  };

  const setDataset = (idx: any, newDataset: iDataset) => {
    let tempDatasets = [...datasets];
    tempDatasets[idx] = newDataset;
    setDatasets(tempDatasets);
  };

  const deleteDataset = (idx: any) => {
    let tempDatasets = [...datasets];
    tempDatasets.splice(idx, 1);
    setDatasets(tempDatasets);
    NotificationService.success(t("analyzis.succDeleted"));
  };

  const duplicateDataset = (idx: any) => {
    let tempDatasets = [...datasets];
    tempDatasets.push(JSON.parse(JSON.stringify(datasets[idx])));
    setDatasets(tempDatasets);
    NotificationService.success(t("analyzis.succDuplicated"));
  };

  const renameDataset = (idx: any, newName: string) => {
    let tempDatasets = [...datasets];
    tempDatasets[idx].title = newName;
    setDatasets(tempDatasets);
    NotificationService.success(t("analyzis.succChangedName"));
  };

  const datasetsShow = datasets.map((dataset, idx) => (
    <Dataset key={idx} idx={idx} dataset={dataset} deleteDataset={() => deleteDataset(idx)} 
      duplicateDataset={() => duplicateDataset(idx)} renameDataset={renameDataset} setDataset={setDataset}/>
  ));

  const selectItems = datasets.map((item, idx) => 
      <MenuItem key={idx} value={idx}>{item.title}</MenuItem>
  );

  return (
  <PageForm label={t("analyzis.pageTitle")} image={image}>
    <div className='box-12 text-center'>
      { loading && <div className="loading">
        <div><MdLocalPolice /> {t("analyzis.loading")}</div>
      </div> }
      { datasets.length === 0 && <h3>{t("analyzis.infoCreate")}</h3> }
      {datasetsShow}
      <Grid sx={{ textAlign: "right" }}>
        <Button onClick={() => setShowModalCorr(true)}>{t("analyzis.correlationButton")}</Button>
        <Button onClick={() => setShowModal(true)}>{t("analyzis.addButton")}</Button>
      </Grid>

      <CustomModal
        show={showModal}
        title={t("analyzis.insertNameNew")}
        width="500px"
        onClose={() => setShowModal(false)}
      >
        <Grid marginBottom={2} marginTop={2}>
          <input type="text" placeholder={t("analyzis.placeholderName") + ""} value={newDatasetName} onChange={(e) => setNewDatasetName(e.target.value)}/>
        </Grid>
        <Grid sx={{ textAlign: "right" }}>
          <Button onClick={() => setShowModal(false)}>{t("analyzis.closeButton")}</Button>
          <Button onClick={() => addDataset()}>{t("analyzis.addButtonShort")}</Button>
        </Grid>
      </CustomModal>

      <CustomModal
        show={showModalCorr}
        title={t("analyzis.correl_dataset")}
        width="500px"
        onClose={() => setShowModalCorr(false)}
      >
        <Typography variant="h5">{t("analyzis.two_dataset")}</Typography>
        <Grid marginBottom={2} marginTop={2}>
          <Select
              labelId="corr1"
              id="corr1"
              value={corr1}
              onChange={(e: any) => setCorr1(e.target.value)}
              fullWidth
          >
              { selectItems }
          </Select>
        </Grid>
        <Grid marginBottom={2} marginTop={2}>
          <Select
              labelId="corr2"
              id="corr2"
              value={corr2}
              onChange={(e: any) => setCorr2(e.target.value)}
              fullWidth
          >
              { selectItems }
          </Select>
        </Grid>
        { corrMatrix && <Grid marginBottom={2} marginTop={2}>
          <Typography variant="h5" fontWeight={'bold'}>Korelačná matica</Typography>
          <table>
            <tr>
              <th>{corrMatrix[0][0]}</th>
              <th>{corrMatrix[0][1]}</th>
            </tr>
            <tr>
              <th>{corrMatrix[1][0]}</th>
              <th>{corrMatrix[1][1]}</th>
            </tr>
          </table>
          { corrMatrix[1][0] === 0 && <Typography variant="h6"><b>{t('analyzis.addiction')}:</b>&emsp;{t('analyzis.addicton_0')}</Typography> }
          { corrMatrix[1][0] > 0 && <Typography variant="h6"><b>{t('analyzis.addiction')}:</b>&emsp; {t('analyzis.addicton_1')}</Typography> }
          { corrMatrix[1][0] < 0 && <Typography variant="h6"><b>{t('analyzis.addiction')}:</b>&emsp; {t('analyzis.addicton_-1')}</Typography> }

          { Math.abs(corrMatrix[1][0]) < 0.3 && <Typography variant="h6"><b>{t('analyzis.signific')}:</b>&emsp; {t('analyzis.signific_0')}</Typography> }
          { (Math.abs(corrMatrix[1][0]) < 0.5 && Math.abs(corrMatrix[1][0]) >= 0.3) && <Typography variant="h5"><b>{t('analyzis.signific')}:</b>&emsp; {t('analyzis.signific_1')}</Typography> }
          { (Math.abs(corrMatrix[1][0]) < 0.7 && Math.abs(corrMatrix[1][0]) >= 0.5) && <Typography variant="h5"><b>{t('analyzis.signific')}:</b>&emsp; {t('analyzis.signific_2')}</Typography> }
          { (Math.abs(corrMatrix[1][0]) < 0.9 && Math.abs(corrMatrix[1][0]) >= 0.7) && <Typography variant="h5"><b>{t('analyzis.signific')}:</b>&emsp; {t('analyzis.signific_3')}</Typography> }
          { Math.abs(corrMatrix[1][0]) >= 0.9 && <Typography variant="h6"><b>{t('analyzis.signific')}:</b>&emsp; {t('analyzis.signific_4')}</Typography> }
        </Grid> }
        
        <Grid sx={{ textAlign: "right" }}>
          <Button onClick={() => setShowModalCorr(false)}>{t("analyzis.closeButton")}</Button>
          <Button onClick={() => correlation()}>{t("analyzis.compare")}</Button>
        </Grid>
      </CustomModal>
    </div>
  </PageForm>
  );
}