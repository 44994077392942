import Title from '../../../components/Title/Title';
import { FaSadCry } from "react-icons/fa";

export default function PageNotFound() {
  return (
    <div className='box-12'>
      <Title align="center">ERROR 404 - Požadovaná stránka neexistuje!</Title>
      <FaSadCry className="errorSmile"></FaSadCry>
    </div>
  );
}