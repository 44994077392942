import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { Button } from "../components/StyleComponents/StyleComponents";
import { ModalService } from "../services/ModalService";

export default function ModalEmail() {
    const [email, setEmail] = useState("");

    const save = (): void => {
        ModalService.closeAndReturn({
            data: email
        });
    }

    const close = (): void => {
        ModalService.hide();
    }

    return (
        <div className='box-12'>
            <div className='box-12 py-4'>
                <input value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='box-12 text-right'>
                <Button onClick={close}><BsTrash /> Zatvoriť</Button>
                <Button onClick={save}><AiOutlineCheck /> Zdieľať</Button>
            </div>
        </div>
    );
}