import { Button } from "../../../components/StyleComponents/StyleComponents";
import { useState, ChangeEvent } from 'react';
import { passwordStrength } from "check-password-strength";
import NotificationService from './../../../services/NotificationService';
import image from '../../../assets/css/images/police.jpg';
import PageForm from "../../../components/PageHeader/PageForm";
import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useUser from "../../../use/useUser";
import { FaEdit } from "react-icons/fa";
import UsersService from "../../../services/UsersService";

export default function AccountSettings() {
    const { t } = useTranslation("default");
    const [ user, { setUserData } ] = useUser();

    const [name, setName] = useState(user.name || "");
    const [surname, setSurname] = useState(user.surname || "");
    const [email, setEmail] = useState(user.email || "");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordAnother, setPasswordAnotder] = useState("");

    const nameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }

    const surnameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSurname(e.target.value);
    }

    const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const passwordChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const newPasswordChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
    }

    const passwordAnotderChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setPasswordAnotder(e.target.value);
    }

    const checkEmail = (): boolean => {
        // eslint-disable-next-line
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regexp.test(email);
    }

    const changePersonalData = () => {
        if (name.length > 0 && surname.length > 0 && email.length > 0) {
            if (checkEmail()) {
                UsersService.updateUser(email, name, surname)
                .then(() => {
                    let tempUser = {...user};
                    tempUser.email = email;
                    tempUser.name = name;
                    tempUser.surname = surname;
                    setUserData(tempUser);
                    NotificationService.success(t("default.profileChangeSuccess"));
                })
                .catch(() => {
                    NotificationService.error(t("default.failRequest"));
                });
            } else {
                NotificationService.error(t('default.emailErr'));
            }
        } else {
          NotificationService.error(t('default.fillData'));
        }
    }

    const changePassword = () => {
        if (password.length >= 5 && newPassword.length >= 5) {
            if (passwordStrength(password).value !== "Too weak") {
                if (newPassword === passwordAnother) {
                    UsersService.updatePassword(password, newPassword)
                    .then(() => {
                        NotificationService.success(t("default.passChangeSuccess"));
                    })
                    .catch(() => {
                        NotificationService.error(t("default.failRequest"));
                    });
                } else {
                    NotificationService.error(t('default.diffPass'));  
                }
            } else {
            NotificationService.error(t('default.registerPassLow'));  
            }
        } else {
            NotificationService.error(t('default.registerPassMinLength'));
        }
    }

    return (
    <PageForm label={t("default.profile")} image={image}>
      <Grid container textAlign={'center'} spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">{t("default.changePerson")}</Typography>
          <TextField fullWidth value={name} onChange={nameChangeHandler} id="outlined-basic" label={t("default.name")} variant="outlined"  />
          <TextField fullWidth value={surname} onChange={surnameChangeHandler} id="outlined-basic" label={t("default.surname")} variant="outlined"  style={{ marginTop: "18px" }} />
          <TextField fullWidth value={email} onChange={emailChangeHandler} id="outlined-basic" label={t("default.email")} variant="outlined"  placeholder="example@mail.com"  style={{ marginTop: "18px" }} />
          <Grid item xs={12} textAlign={'center'} mt={4} mb={6}>
            <Button onClick={changePersonalData}><FaEdit />{t("default.changeProfile")}</Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">{t("default.changePassword")}</Typography>
          <TextField fullWidth value={password} onChange={passwordChangeHandler} id="outlined-basic" label={t("default.password")} variant="outlined" placeholder={t("default.password") || 'Password'} type="password" />
          <TextField fullWidth value={newPassword} onChange={newPasswordChangeHandler} id="outlined-basic" label={t("default.newPassword")} variant="outlined" placeholder={t("default.passwordAgain") || 'Password again'} style={{ marginTop: "18px" }} type="password" />
          <TextField fullWidth value={passwordAnother} onChange={passwordAnotderChangeHandler} id="outlined-basic" label={t("default.passwordAgain")} variant="outlined" placeholder={t("default.passwordAgain") || 'Password again'} style={{ marginTop: "18px" }} type="password" />
          <Grid item xs={12} textAlign={'center'} mt={4}>
            <Button onClick={changePassword}><FaEdit />{t("default.changePasswordBt")}</Button>
          </Grid>
        </Grid>
      </Grid>
    </PageForm>
    );
}