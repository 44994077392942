import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import crimesSK from './crimes/_sk.json';
import crimesCZ from './crimes/_cz.json';
import crimesEN from './crimes/_en.json';

import europeSK from './europe/_sk.json';
import europeCZ from './europe/_cz.json';
import europeEN from './europe/_en.json';

import trafficSK from './traffic/_sk.json';
import trafficCZ from './traffic/_cz.json';
import trafficEN from './traffic/_en.json';

import defaultSK from './default/_sk.json';
import defaultCZ from './default/_cz.json';
import defaultEN from './default/_en.json';

const resources = {
    sk: {
        default: defaultSK,
        crimes: crimesSK,
        europe: europeSK,
        traffic: trafficSK
    },
    cz: {
        default: defaultCZ,
        crimes: crimesCZ,
        europe: europeCZ,
        traffic: trafficCZ
    },
    en: {
        default: defaultEN,
        crimes: crimesEN,
        europe: europeEN,
        traffic: trafficEN
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "sk",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;

/*
    POUZITIE:

    import { useTranslation } from 'react-i18next';

    // v komponente
    const { t } = useTranslation();

    // tam kde chcem preklad ("crimes:") je prefix vetvy - pozri hore
    { t("crimes:testmsg") }

    // druhe pouzitie (znova crimes je prefix)
    const { t } = useTranslation("crimes");

    { t("testmsg") }
*/