import SelectionData from "../Graphs/SelectionData";

export function getRegions() : SelectionData[] {
    return [
        /*{Type : "Vsetky", Code : "all" },*/
        { Type: "Praha", Code: "0" },
        { Type: "Stredocesky", Code: "1" },
        { Type: "Juhocesky", Code: "2" },
        { Type: "Plzensky", Code: "3" },
        { Type: "Ustecky", Code: "4" },
        { Type: "Kralovohradsky", Code: "5" },
        { Type: "Juhomoravsky", Code: "6" },
        { Type: "Moravskoslezsky", Code: "7" },
        { Type: "Olomoucky", Code: "8" },
        { Type: "Zlinsky", Code: "9" },
        { Type: "Vysocina", Code: "10" },
        { Type: "Pardubicky", Code: "11" },
        { Type: "Liberecky", Code: "12" },
        { Type: "Karlovarsky", Code: "13" },
    ];
}

export function getYears() : SelectionData[] {
    return [
        /*{Type : "Vsetky", Code : "all" },*/
        { Type: "2022", Code: "2022" },
        { Type: "2021", Code: "2021" },
        { Type: "2020", Code: "2020" },
        { Type: "2019", Code: "2019" },
        { Type: "2018", Code: "2018" },
        { Type: "2017", Code: "2017" },
        { Type: "2016", Code: "2016" }
    ];
}