import React from "react";
import { useTranslation } from 'react-i18next';
import { FaTrashAlt, FaCopy/*, FaDownload*/ } from 'react-icons/fa';
// import { FaClone, FaDownload, FaShare, FaTrashAlt } from 'react-icons/fa';
import { IReportDetails } from "../../../interfaces/traffic_accidents/InterfacesTraffic";
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import stripTags from "../../../utils/stripTags";
import Modal from "@material-ui/core/Modal";
import useUser from "../../../use/useUser";
import Stack from '@mui/material/Stack';
// Table
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, Tooltip, Typography } from "@mui/material";
//

export default function TReportDetails(props: IReportDetails) {  
  const [ user ] = useUser(); 
    const { t } = useTranslation("traffic");
    const navigate = useNavigate();
    // const cloneReport = (): void => {};
    // const downloadReport = (): void => {};
    // const convLastUpdated = (
    //     ( props.date_last_updated.getFullYear()) + "-" +
    //     ( props.date_last_updated.getMonth() > 8 ? (props.date_last_updated.getMonth() - (-1)) : "0" + (props.date_last_updated.getMonth() - (-1))) + "-" +
    //     ( props.date_last_updated.getDate() > 9 ? props.date_last_updated.getDate() : "0" + props.date_last_updated.getDate())
    // );

    const goTo = () => {
      navigate("/traffic/report-center/report/" + props.reportId);
    };

    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = (remove: boolean) =>  {
      if (remove) {
        props.actions[0].action(props.reportId)
      }
      setOpen(false)
    };
    return (
      <>
        <Modal
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          {t("Traffic.deleteQuestion")} <b>{props.name}</b>?
          </Typography>
          
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t("Traffic.deleteUndone")}
          </Typography>
          <br/>
          <Button variant="outlined" size="medium" style={{padding: '10px'}} color="error" onClick={() => handleClose(true)}>{t("Traffic.delete")}</Button>
          <Button variant="outlined" size="medium" style={{padding: '10px', marginLeft: "10px"}} onClick={() => handleClose(false)}>{t("Traffic.cancel")}</Button>
        </Box>
      </Modal> 
      <TableRow>
         <TableCell padding="checkbox" />

         <TableCell>
         <Button onClick={goTo}>{props.name}</Button>
         </TableCell>

         <TableCell align="left">{t(`Traffic.${props.type}`)}</TableCell>{/* {props.type} */}
         <TableCell align="left">{props.description ? stripTags(props.description) : props.description || ''}</TableCell>
         <TableCell align="left">{props.date_last_updated}</TableCell>{/* {convLastUpdated} */}
         <TableCell align="left" style={{padding: '0px'}}>
           <>
              {/* {props.actions.map((action: any, idxAct: any) => (
                  <span key={idxAct} onClick={() => action.action(props.idx)}>{action.element}</span>
              ))}  */}
            <Stack
              direction="row"
              spacing={1}
              sx={{py: 1,px: 1.5,}}
            >
              <Tooltip title={t("Traffic.clone")} arrow>
                <IconButton size="small" aria-label="clone" onClick={() => props.actions[1].action(props.reportId, props.globalReport)} >
                  <FaCopy />
                </IconButton>
              </Tooltip>
              {/* <IconButton size="small" aria-label="download" onClick={() => props.actions[2].action(props.idx)} >
                <FaDownload />
              </IconButton> */}
              {props.userId === user.id &&
                <Tooltip title={t("Traffic.delete")} arrow>
                  <IconButton size="small" aria-label="delete" onClick={handleOpen}>
                    <FaTrashAlt />
                  </IconButton>
                </Tooltip>}

              {/* <Button size="medium" style={{width: '5px'}} onClick={handleOpen}><FaCopy/></Button>
              {props.userId === user.id && <Button size="medium" style={{width: '5px'}} onClick={handleOpen}><FaTrashAlt width={''}/></Button>} */}
            </Stack>

           </>
         </TableCell>
       </TableRow>  
      </>

     );
}