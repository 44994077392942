import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Modal from './components/Modal/Modal';
import 'react-notifications-component/dist/theme.css'
import "flatpickr/dist/themes/material_green.css";
import './language/i18n';

import './assets/css/index.css';
import './assets/css/boxing.scss';
import './assets/css/custom.css';
import { ReactNotifications } from 'react-notifications-component';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <ReactNotifications />
    <BrowserRouter>
      <Layout />
      <Modal />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

//reportWebVitals();