import { GraphValue } from "../../interfaces/Graphs";
import { SearchFilter, SearchFilterValue } from "../../interfaces/SearchFilter";
import { Button } from "../StyleComponents/StyleComponents";
import Graph from "./Graph";
import { useState } from 'react';
import GraphModal from "./GraphModal";
import { useTranslation } from 'react-i18next';
import { ExpressionValue } from "../Expressions/Expressions";
import TReportSelectionModal from "../Report/TReportSelectionModal";
import NotificationService from "../../services/NotificationService";
import {v4 as uuidv4} from 'uuid';

interface IProps {
    filter: SearchFilter[];
    customExpressions?: ExpressionValue[];
    filterValues: SearchFilterValue[];
    graphValues: GraphValue[];
    endpointService: any;
    endpointServiceReport?: any;
    onChange: (value: GraphValue[]) => void;
    analzyse?: boolean;
    settingTAP?: boolean;
};

export default function MultiGraphs(props: IProps) {

    const { t } = useTranslation("crimes");
    const [ showModal, setShowModal ] = useState(false);
    const [ showReportModal, setShowReportModal ] = useState(false);
    const [ editedGraphIndex, setEditedGraphIndex ] = useState<number | undefined>(undefined);

    const handleAddGraph = () => {
        setEditedGraphIndex(undefined);
        setShowModal(true);
    };

    const handleSaveGraph = (graph: GraphValue) => {
        setShowModal(false);
        let tempGraphs = [...props.graphValues];
        if (editedGraphIndex !== undefined) {
            tempGraphs[editedGraphIndex] = graph;
        } else {
            tempGraphs.push(graph);
        }
        setEditedGraphIndex(undefined);
        props.onChange(tempGraphs);
    }

    const handleGraphEdit = (idx: number) => {
        setEditedGraphIndex(idx);
        setShowModal(true);
    };

    const handleGraphRemove = (idx: number) => {
        let tempGraphs = [...props.graphValues];
        tempGraphs.splice(idx, 1);
        props.onChange(tempGraphs);
    }

    const handleExportGraph = (id: string) => {
        setShowReportModal(false);
        const data = props.graphValues.filter((graph, idx) => idx === editedGraphIndex);
        const filterValuesToExport = data[0].data[0].filter ?? props.filterValues;
        props.endpointServiceReport.addGraphToReport(id, {graphData: JSON.stringify({...data[0], data: [{...data[0].data[0], filter: filterValuesToExport}]}), filterValues: JSON.stringify(props.filterValues), type:JSON.stringify({type: "graph"}), layoutData:JSON.stringify({ i: uuidv4(), x: 50, y: 50, w: 12, h: 2, minW: 3, minH: 2 })})
        .then((resp: any) => {
            if(resp.status === 200) {
                NotificationService.success(t("settings.succGraphExport"));
            } else {
                NotificationService.error(t("settings.failGraphExport"));
            }
        })
        .catch(() => { NotificationService.error(t("settings.failGraphExport"));});
        setEditedGraphIndex(undefined);
    }

    const handleGraphExportToReport = (idx: number) => {
        setShowReportModal(true);
        setEditedGraphIndex(idx);
    }

    const graphsDraw = props.graphValues.map((graph, idx) => 
        <Graph 
            key={idx}
            texts={graph.texts}
            type={graph.type}
            showLabels={graph.showLabels}
            longXLabels={graph.longXLabels}
            group={graph.group}
            dynamicValues={graph.data}
            filterValues={props.filterValues}
            endpointService={props.endpointService}
            /*values={values} // todo
            xNames={xAxisNames} // todo
            valueNames={valueNames} // todo*/
            customExpressions={props.customExpressions}
            width={`${graph.width?.[0]}${graph.width?.[1]}`}
            height={`${graph.height?.[0]}${graph.height?.[1]}`}
            onEdit={() => handleGraphEdit(idx)}
            onRemove={() => handleGraphRemove(idx)}
            onExportToReport={() => handleGraphExportToReport(idx)}
            settingTAP={props.settingTAP}
            analyse={props.analzyse}
        />
    );

    return <>
        <TReportSelectionModal 
            show={showReportModal}
            endpointService={props.endpointServiceReport}
            onClose={() => setShowReportModal(false)}
            onExport={(id: string) => handleExportGraph(id)}
        />
        <GraphModal 
            show={showModal}
            filter={props.filter}
            customExpressions={props.customExpressions}
            filterValues={props.filterValues}
            graphValue={editedGraphIndex !== undefined ? props.graphValues[editedGraphIndex] : undefined}
            endpointService={props.endpointService}
            onClose={() => setShowModal(false)}
            onSave={handleSaveGraph}
            analzyse={props.analzyse}
            settingTAP={props.settingTAP}
        />
        <div className="box-12">
            { graphsDraw }
        </div>
        {(props.analzyse && props.graphValues.length === 0) || !props.analzyse ?
        <div className="box-12 text-right">
            <Button onClick={handleAddGraph}>{t("graph.addButton")}</Button>
        </div> : ""}
    </>;
}