import Graph from "../../MultiGraphs/Graph";
import SearchServiceTAP from '../../../services/SearchServiceTAP';
import { SearchFilterValue } from "../../../interfaces/SearchFilter";

interface IProps {
    id: string
    data: any
    editMode: boolean
    globalFilterValues: SearchFilterValue[]
    onRemove: (value: any) => void;
    onUpdate: (id: any, sectionData: any) => void;
    onEdit: (id: any) => void;
    onExport: (id: any) => void;
}

export default function TReportGraph(props: IProps) { 
  return (
    <Graph
        key={props.id}
        texts={props.data.graphData.texts}
        type={props.data.graphData.type}
        showLabels={props.data.graphData.showLabels}
        longXLabels={props.data.graphData.longXLabels}
        group={props.data.graphData.group}
        dynamicValues={props.data.graphData.data}
        width={`${props.data.graphData.width?.[0]}${props.data.graphData.width?.[1]}`}
        height={`${props.data.graphData.height?.[0]}${props.data.graphData.height?.[1]}`}
        filterValues={props.globalFilterValues}
        // endpointService={props.data.props.endpointService}
        endpointService={SearchServiceTAP}
        editMode={props.editMode}
        onRemove={() => props.onRemove(props.id)}
        onEdit={() => props.onEdit(props.id)}
        onExportToReport={() => props.onExport(props.id)}
        settingTAP={true}
        reportCenter={true}
      />
  );
}