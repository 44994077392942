import CardsBox from "../../components/Cards/CardsBox";
import image from '../../assets/css/images/police.jpg';
import CardLink from './../../components/Cards/CardLink';
import { BsGraphUp, BsGraphDown } from 'react-icons/bs';
import { FaSearch, FaMap } from 'react-icons/fa';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import PageForm from "../../components/PageHeader/PageForm";

export default function Crimes() {

  const { t } = useTranslation("crimes");

  return (
    <PageForm label={t("home.pageTitle")} image={image}>
      <Typography variant="h4" textAlign={"center"} marginBottom={2}>
        {t("home.title")}
      </Typography>
      <Typography variant="body1" textAlign={"justify"} marginBottom={2}>
        {t("home.text")}
      </Typography>
      <Typography variant="h4" textAlign={"center"} marginBottom={2}>
        {t("home.choosePath")}
      </Typography>
      <CardsBox>
        <CardLink to="/crimes/dashboard" icon={<BsGraphUp />} text={t("home.preview")} />
        <CardLink to="/crimes/map" icon={<FaMap />} text={t("home.map")} />
        <CardLink to="/crimes/search" icon={<FaSearch />} text={t("home.search")} />
        <CardLink to="/crimes/analyzis" icon={<BsGraphDown />} text={t("home.analytics")} />
      </CardsBox>
    </PageForm>
  );
}