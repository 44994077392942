import SearchServiceTAP from '../../../services/SearchServiceTAP';
import analysisService from "../../../services/AnalysisServiceTAP";
import TPredictionGraph from "../../DataAnalyzis/TrafficAccidents/TPredictionGraph";

interface IProps {
    id: string
    data: any
    editMode: boolean
    globalFilterValues: any[]
    onRemove: (value: any) => void;
    onUpdate: (id: any, sectionData: any) => void;
    onEdit: (id: any) => void;
    onExport: (id: any) => void;
}

export default function TReportGraph(props: IProps) { 
  return (
    <TPredictionGraph
        key={props.id}
        texts={props.data.graphData.texts}
        type={props.data.graphData.type}
        showLabels={props.data.graphData.showLabels}
        longXLabels={props.data.graphData.longXLabels}
        group={props.data.graphData.group}
        dynamicValues={props.data.graphData.data}
        width={`${props.data.graphData.width?.[0]}${props.data.graphData.width?.[1]}`}
        height={`${props.data.graphData.height?.[0]}${props.data.graphData.height?.[1]}`}
        filterValues={props.data.filterValues}
        predictionValues={props.data.predictionValues}
        // endpointService={props.data.props.endpointService}
        endpointService={SearchServiceTAP}
        analysisService={analysisService}
        editMode={props.editMode}
        onRemove={() => props.onRemove(props.id)}
        onEdit={() => props.onEdit(props.id)}
        onExportToReport={() => props.onExport(props.id)}
        settingTAP={true}
        reportCenter={true}
      />
  );
}