import { SearchFilter, SearchFilterValue, filterType } from '../../interfaces/SearchFilter';
import STableHead from './STableHead';
import css from './Search.module.css';
import { useState, useEffect } from 'react';
import SearchData from './../../interfaces/SearchData';
import STableRow from './STableRow';
import { MdLocalPolice } from 'react-icons/md';
import STablePaging from './STablePaging';
import { Sort } from '../../interfaces/Sort';
import { ExpressionValue } from '../Expressions/Expressions';

interface IProps {
    filter: SearchFilter[];
    customExpressions?: ExpressionValue[];
    filterValues: SearchFilterValue[];
    sort: Sort;
    onFilterAdd: (filterValues: SearchFilterValue) => void;
    onSortChange: (sort: Sort) => void;
    endpointService: any;
    onDoubleClick?: (item: SearchData) => void;
};

export default function STable(props: IProps) {

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState<SearchData[]>([]);
    const [ maxPage, setMaxPage] = useState(0);

    useEffect(() => {
        const abortCtrl = new AbortController();
        setLoading(true);
        const fval = props.filterValues.map((value) => ({
            type: value.type.value,
            value: value.value.map((val) => ({
                type: val.type.value,
                atr: val.atribute.value,
                option: val.option.value,
                value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
            }))
        }));
        props.endpointService.getTableData(props.sort.page, props.sort.perPage, props.sort.label, 
            props.sort.order, fval, props.customExpressions, abortCtrl)
        .then((response: any) => {
            setMaxPage(response.data.paging.maxPage);
            setData(response.data.data);
            setLoading(false);
        })
        .catch(() => {});
        return () => abortCtrl.abort();
    }, [props.filter, props.filterValues, props.endpointService, props.sort, props.customExpressions]);

    const pageChangeHandler = (page: number) => {
        props.onSortChange({
            ...props.sort,
            page: page
        });
    };

    const perPageChangeHandler = (perPage: number) => {
        props.onSortChange({
            ...props.sort,
            perPage: perPage
        });
    };

    const orderChangeHandler = (order: string, asc: boolean) => {
        props.onSortChange({
            ...props.sort,
            label: order,
            order: asc
        });
    };

    const onFilterChange = (newFilter: any) => {
        props.onFilterAdd(newFilter);
    }

    const headerRow = props.filter.map((h: SearchFilter, idx: number) => 
        <STableHead 
            key={idx} 
            order={props.sort.label} 
            asc={props.sort.order} 
            header={h} 
            allFilters={props.filter}
            onFilterChange={onFilterChange} 
            onOrderChange={orderChangeHandler} 
            endpointService={props.endpointService}
        />
    );

    const customHeaderRow = props.customExpressions?.map((h: ExpressionValue, idx: number) => 
        <STableHead 
            key={idx} 
            order={props.sort.label} 
            asc={props.sort.order} 
            header={{
                atr: h.label,
                name: h.label,
                active: h.active,
                type: filterType.DATE
            }} 
            allFilters={props.filter}
            onFilterChange={onFilterChange} 
            onOrderChange={orderChangeHandler} 
            endpointService={props.endpointService}
        />
    );
    
    const dataRows = data.map((item: SearchData, idx: number) => (
        <STableRow 
            key={idx} 
            filter={props.filter} 
            data={item} 
            expressions={props.customExpressions} 
            onDoubleClick={props.onDoubleClick}
        />
    ));

    return (
        <div className={`box-12 ${css.searchTable}`}>
            <table>
                <thead>
                    <tr>
                        { headerRow }
                        { customHeaderRow }
                    </tr>
                    { dataRows }
                </thead>
            </table>
            { loading && <div className={css.loading}>
                <div><MdLocalPolice /> Načítavam...</div>
            </div> }
            <STablePaging 
                page={props.sort.page}
                perPage={props.sort.perPage}
                maxPage={maxPage}
                onPageChange={pageChangeHandler}
                onPerPageChange={perPageChangeHandler}
            />
        </div>
    );
}