import { Alert, AlertTitle, Box, Grid, InputAdornment, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import analysisService from "../../../services/AnalysisServiceTAP";
import { useTranslation } from 'react-i18next';
import {IDataset, ModelMetrics, PredictionInput, PredictionGraphValues, PredictionRequest} from "../../../interfaces/traffic_accidents/InterfacesTraffic";
import ShowBox from "../../ShowBox";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import InfoIcon from '@mui/icons-material/Info';
import css from '../../MultiGraphs/Graph.module.css';
import { FaFileUpload } from "react-icons/fa";
import { MdLocalPolice } from "react-icons/md";
import useUser from "../../../use/useUser";
import NotificationService from "../../../services/NotificationService";
import {v4 as uuidv4} from 'uuid';
import ReportServiceTAP from '../../../services/ReportsService';
import TReportSelectionModal from "../../Report/TReportSelectionModal";
import { latLng } from "leaflet";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function getTooltipText(text: any, t: any) {
  switch (text) {
      case 'MAE': 
      case 'RMSE':
      case 'MAPE':
          return <Tooltip title={t(`Prediction.${text.toLowerCase()}Eng`)} placement="top"><Typography fontSize={14}>{text}</Typography></Tooltip>;
      case 'Mean Absolute Error':
      case 'Root Mean Squared Error':
      case 'Mean Absolute Percentage Error':
        return <><Typography fontSize={14}>{text}</Typography><Tooltip title={t(`Prediction.${text.replace(/[^A-Z]+/g, "").toLowerCase()}Desc`)} placement="top"><InfoIcon fontSize="small" color="primary"/></Tooltip></>;
      case 'maeDesc':
      case 'rmseDesc':
      case 'mapeDesc':
        return <>
                <Grid container>
                  <Grid item xs={2}/>
                  <Grid item xs={8}>
                    <Typography fontSize={14}>{t(`Prediction.${text.replace("Desc", "")}`)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title={t(`Prediction.${text}`)} placement="top"><InfoIcon fontSize="small" color="primary"/></Tooltip>
                  </Grid>
                </Grid>
               </>;
      default:
        return <Typography fontSize={14}>{text}</Typography>;
  }
}

interface IProps {
  dataset: IDataset;
  inputData?: PredictionGraphValues;
};

export default function Prediction(props: IProps) {
  const [ user, { isAdmin, isLoggedIn, logout } ] = useUser();
  const [ loading , setLoading ] = useState(false);
  const [ showReportModal, setShowReportModal ] = useState(false);
  const predictionModels = ["AR", "SSA"];
  const { t } = useTranslation("traffic");
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState<PredictionGraphValues>();
  const [predict, setPredict] = useState<PredictionGraphValues>();
  const [upperCI, setUpperCI] = useState<PredictionGraphValues>();
  const [lowerCI, setLowerCI] = useState<PredictionGraphValues>();
  const [modelMetrics, setModelMetrics] = useState<ModelMetrics>();
  const [error, setError] = useState<boolean>(false);
  const [predictionRequest, setPredictionRequest] = useState<PredictionRequest>({ "windowSize": 2, "seriesLength": 5/*, "trainSize": 10*/, "horizon": 5, "confidenceLevel": 90, "memorySize": 3 });

  let inputData: PredictionInput = {"predictionGraphValues": props.inputData!, "predictionRequest": predictionRequest, "predictionModel": predictionModels[tabValue]};

  const rowStatistics = [
    { abbreviation: 'MAE', name: t("Prediction.mae"), value: modelMetrics?.mae},
    { abbreviation: 'RMSE', name: t("Prediction.rmse"), value: modelMetrics?.rmse },
    { abbreviation: 'MAPE', name: t("Prediction.mape"), value: modelMetrics?.mape + "%" }
  ]
  const columnsStatistics: GridColDef[] = [
    { field: 'abbreviation', headerName: t("Prediction.abbreviation")!, align: 'center', headerAlign: 'center', width: 100, sortable: false, disableExport: true,  cellClassName: 'abbreviation-cell', filterable: false,
    renderCell: (params: any) => {
      return (
          <>{getTooltipText(params.row.abbreviation, t)}</>
      )
    }},
    { field: 'name', headerName: t("Prediction.metric")!, flex: 1,align: 'center', headerAlign: 'center', headerClassName: 'bold-cell', cellClassName: 'bold-cell',
    renderCell: (params: any) => {
      return (
          <>{getTooltipText(`${params.row.abbreviation.replace(/[^A-Z]+/g, "").toLowerCase()}Desc`, t)}</>
      )
    } },
    { field: 'value', headerName:  t("Prediction.value")!, width: 100,align: 'left', headerAlign: 'left' }
  ];

  const handleWindowSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(event.target.value, 10);

    if (isNaN(newValue) || newValue < 2) newValue = 2;
    // if (newValue > 4) newValue = 4;

    setPredictionRequest((prev) => ({...prev, "windowSize": newValue}));
    let min = 4
    if (predictionRequest.windowSize !== undefined)
        min = 2 * predictionRequest.windowSize + 1;

    // if (predictionRequest.trainSize !== undefined) {
    //     if (isNaN(newValue) || predictionRequest.trainSize < min)
    //       setPredictionRequest((prev) => ({...prev, "trainSize": min}));
    // }
  };

  const handleSeriesLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(event.target.value, 10);
    if (isNaN(newValue) || newValue < 3) newValue = 3;
    setPredictionRequest((prev) => ({...prev, "seriesLength": newValue}));
  };

  const handleHorizonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(event.target.value, 10);
    if (isNaN(newValue) || newValue < 1)
        newValue = 1;

    setPredictionRequest((prev) => ({...prev, "horizon": newValue}));
  };

  // const handleTrainSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   let newValue = parseInt(event.target.value, 10);
  //   let min = 4
  //   if (predictionRequest.windowSize !== undefined)
  //       min = 2 * predictionRequest.windowSize + 1;

  //   if (isNaN(newValue) || newValue < min) newValue = min;

  //   setPredictionRequest((prev) => ({...prev, "trainSize": newValue}));
  // };

  const handleConfidenceLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(event.target.value, 10);
    if (isNaN(newValue) || newValue < 1) newValue = 1;

    if (newValue > 99) newValue = 99;
    
    setPredictionRequest((prev) => ({...prev, "confidenceLevel": newValue}));
  };

  const handleMemorySizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(event.target.value, 10);
    if (isNaN(newValue) || newValue < 1) newValue = 1;

    setPredictionRequest((prev) => ({...prev, "memorySize": newValue}));
  }

  useEffect(() => {
    setLoading(true);
    const abortCtrl = new AbortController();
    analysisService.getPrediction(inputData, abortCtrl.signal)
    .then((res: any) => {
      if(res.data.error) {
        setError(true);
        return;
      } else {
        setError(false);
      }

      setData(res.data.originalData);
      let buffer = [];
      for (let i = 0; i < res.data.originalData.values.length; i++) {
        buffer.push(undefined);
      }
      setLowerCI({...res.data.lowerBoundData, "values": buffer.concat(res.data.lowerBoundData.values)});
      setUpperCI({...res.data.upperBoundData, "values": buffer.concat(res.data.upperBoundData.values)});
      buffer.splice(buffer.length-1);
      setPredict({...res.data.predictedData, "values": buffer.concat(res.data.predictedData.values)});
      setModelMetrics(res.data.evaluationMetrics);    
      setLoading(false);
    })
    .catch(() => { setLoading(false); });


    return () => abortCtrl.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predictionRequest, props.inputData, tabValue]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleExportGraph = (id: string) => {
    setShowReportModal(false);
    ReportServiceTAP.addGraphToReport(id, {graphData: JSON.stringify({...props.dataset.graphValues[0], texts: [t('Prediction.predictions'), t("Prediction.yAxisName"), ""], data: [{...props.dataset.graphValues[0].data[0], filter: props.dataset.filterValues}]}), filterValues: JSON.stringify(props.dataset.filterValues), predictionValues: JSON.stringify(inputData), type: JSON.stringify({type: "prediction_graph"}), layoutData: JSON.stringify({ i: uuidv4(), x: 50, y: 50, w: 12, h: 2, minW: 3, minH: 2 })})
    .then((resp: any) => {
      if (resp.status === 200) {
        NotificationService.success(t("Graph.succGraphExport"));
      } else {
        NotificationService.success(t("Graph.failGraphExport"));
      }
    })
    .catch(() => {NotificationService.success(t("Graph.failGraphExport"));});
  } 

  const handleGraphExportToReport = () => {
      setShowReportModal(true);
  }

  function CustomToolbar() {
    return (
      <Grid container>
          <Grid item xs={3}>
              <GridToolbarContainer>
                  <GridToolbarExport />
              </GridToolbarContainer>
          </Grid>
          <Grid item xs={6}>
              <Typography variant="body1" fontWeight={"bold"} textAlign={"center"} paddingTop={1}>{t("Prediction.evaluationMetrics")}</Typography>
          </Grid>
      </Grid>
    );
  }

  const barOption = {
      legend: {
        top: "auto",
        bottom: 0,
        type: 'scroll',
        data: [t('Prediction.originalData'), t('Prediction.predictedData'), (upperCI?.xAxisValues.length ?? 0) > 0 && t('Prediction.upperConfidenceInterval'), (lowerCI?.xAxisValues.length ?? 0) > 0 && t('Prediction.lowerConfidenceInterval')]
      },
      lineStyle: {
        width: 2
      },
      title: {
          text: t('Prediction.predictions'),
          x: 'center'
        },
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        bottom: 80,
        containLabel: true
    },
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          saveAsImage: { show: true },
        },
      },
      xAxis: {
        type: "category",
        data: data?.xAxisValues.concat(predict?.xAxisValues.slice(1)!)
      },
      yAxis: {
        type: "value",
        name: t("Prediction.yAxisName"),
        nameLocation: 'middle',
        nameTextStyle: {
          lineHeight: 106,
          fontWeight: 'bold'
        }
      },
      dataZoom: [
        {
          startValue: data?.xAxisValues[0],
          bottom: 40,
        },
        {
          type: 'inside',
          bottom: 40,
        }
      ],
      series: [
        {
          name: t('Prediction.originalData'),
          data: data?.values,
          type: "line",
        },
        {
          name: t('Prediction.predictedData'),
          data: predict?.values,
          type: "line",
          color: "green",
        },
        (upperCI?.xAxisValues.length ?? 0) > 0 && {
          name: t('Prediction.upperConfidenceInterval'),
          data: upperCI?.values,
          type: "line",
          color: "red",
        },
        (lowerCI?.xAxisValues.length ?? 0) > 0 && {
          name: t('Prediction.lowerConfidenceInterval'),
          data: lowerCI?.values,
          type: "line",
          color: "red",
        },
        (lowerCI?.xAxisValues.length ?? 0) > 0 && {
          name: 'Lower CI',
          type: 'line',
          data: lowerCI?.values.map(function (item) {
            return item + 0;
          }),
          lineStyle: {
            opacity: 0
          },
            // stack: 'confidence-band', //pouzitie pre vyfarbenie plochy medzi hornym a dolnym intervalom
          symbol: 'none',
          tooltip: {
            show: false
         }
        },
        (upperCI?.xAxisValues.length ?? 0) > 0 && {
          name: 'Upper CI',
          type: 'line',
          data: upperCI?.values.map(function (item, index) {
            return item - lowerCI?.values[index]!;
          }),
          lineStyle: {
            opacity: 0
          },
            // areaStyle: { //pouzitie pre vyfarbenie plochy medzi hornym a dolnym intervalom
            //   color: '#ccc'
            // },
            // stack: 'confidence-band', //pouzitie pre vyfarbenie plochy medzi hornym a dolnym intervalom
          symbol: 'none',
          tooltip: {
            show: false
         }
        },
        {
          type: "line",
          color: "black",
          markLine: {
            symbol: ['none', 'none'],
            label: { show: false },
            data: [{ xAxis: -1+data?.xAxisValues?.length! }]
          },
        }
      ],
  };

    return (
      <>
        <TReportSelectionModal 
            show={showReportModal}
            endpointService={ReportServiceTAP}
            onClose={() => setShowReportModal(false)}
            onExport={(id: string) => handleExportGraph(id)}
        />
        <Grid sx={{ textAlign: "right" }} container>
          <Grid item xs={12}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab label={t("Prediction.autoregression")} sx={{ textTransform: "none" }}/>
              <Tab label={t("Prediction.ssa")} sx={{ textTransform: "none" }}/>
            </Tabs>
          </Grid>
        </Grid>
        <CustomTabPanel value={tabValue} index={1}>
          <ShowBox className="box-12 py-2" name={t("Prediction.settings") + ""}>
            <Grid container spacing={{ xs: 1}} columns={{ xs: 6, sm: 8, md: 16 }} marginBottom={4}>
              {/* <Grid item xs/> */}
              <Grid item xs={6} sm alignContent={"center"} sx={{ justifyContent:'center', display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '1.2em'}}>
                {t("Prediction.parameters")}
              </Grid>
              <Grid item xs={2}>
                <TextField type="number" defaultValue={"2"} label={t("Prediction.windowSize")} variant="outlined" fullWidth value={predictionRequest.windowSize} onChange={handleWindowSizeChange} InputProps={{endAdornment: <Tooltip title={t("Prediction.windowSizeDesc")} placement="top"><InfoIcon fontSize="small" color="primary"/></Tooltip>}}/>
              </Grid>
              <Grid item xs={2}>
                <TextField type="number" defaultValue={"5"} label={t("Prediction.seriesLength")} variant="outlined" fullWidth value={predictionRequest.seriesLength} onChange={handleSeriesLengthChange} InputProps={{endAdornment: <Tooltip title={t("Prediction.seriesLengthDesc")} placement="top"><InfoIcon fontSize="small" color="primary"/></Tooltip>}}/>
              </Grid>
              {/* <Grid item xs={2}>
                <TextField type="number" defaultValue={"10"} label={t("Prediction.trainSize")} variant="outlined" fullWidth value={predictionRequest.trainSize} onChange={handleTrainSizeChange} InputProps={{endAdornment: <Tooltip title={t("Prediction.trainSizeDesc")} placement="top"><InfoIcon fontSize="small" color="primary"/></Tooltip>}}/>
              </Grid> */}
              <Grid item xs={2}>
                <TextField type="number" defaultValue={"5"} label={t("Prediction.horizon")} variant="outlined" fullWidth value={predictionRequest.horizon} onChange={handleHorizonChange} InputProps={{endAdornment: <Tooltip title={t("Prediction.horizonDesc")} placement="top"><InfoIcon fontSize="small" color="primary"/></Tooltip>}}/>
              </Grid>
              <Grid item xs={2}>
                <TextField type="number" defaultValue={"90"} label={t("Prediction.confidenceLevel")} variant="outlined" fullWidth value={predictionRequest.confidenceLevel} onChange={handleConfidenceLevelChange} InputProps={{endAdornment: [<InputAdornment position="start">%</InputAdornment>, <Tooltip title={t("Prediction.confidenceLevelDesc")} placement="top"><InfoIcon fontSize="small" color="primary"/></Tooltip>]}}/>
              </Grid>
              <Grid item xs/>
            </Grid>
          </ShowBox>
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={0}>
        <ShowBox className="box-12 py-2" name={t("Prediction.settings") + ""}>
            <Grid container spacing={{ xs: 1}} columns={{ xs: 6, sm: 8, md: 16 }} marginBottom={4}>
              <Grid item xs={6} sm sx={{ justifyContent:'right', display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '1.2em', paddingRight: '10px'}}>
                {t("Prediction.parameters")}
              </Grid>
              <Grid item xs={2}>
                <TextField type="number" defaultValue={"5"} label={t("Prediction.memorySize")} variant="outlined" fullWidth value={predictionRequest.memorySize} onChange={handleMemorySizeChange} InputProps={{endAdornment: <Tooltip title={t("Prediction.memorySizeDesc")} placement="top"><InfoIcon fontSize="small" color="primary"/></Tooltip>}}/>
              </Grid>
              <Grid item xs={2}>
                <TextField type="number" defaultValue={"5"} label={t("Prediction.horizon")} variant="outlined" fullWidth value={predictionRequest.horizon} onChange={handleHorizonChange} InputProps={{endAdornment: <Tooltip title={t("Prediction.horizonDesc")} placement="top"><InfoIcon fontSize="small" color="primary"/></Tooltip>}}/>
            </Grid>
              <Grid item xs/>
            </Grid>
          </ShowBox>
        </CustomTabPanel>

        {error &&
          <Alert severity="error">
            <AlertTitle >Error</AlertTitle>
            {t('Graph.error')}
          </Alert>
        }

        {!error && 
        <div className={css.graph} style={{ height: '100%', width: '100%', margin: 0 }}>
          {isLoggedIn() &&
          <div className={`${css.share} nonDraggable`} onClick={handleGraphExportToReport}>
              <FaFileUpload />
          </div>}
          { loading && <div className={css.loading}>
              <div><MdLocalPolice />{t("Traffic.loading")}</div>
          </div> }
          <ReactECharts option={barOption} notMerge={true} style={{ height: '350px', paddingBottom: '5px' }}/>
        </div>}


        <ShowBox className="box-12 py-2" name={t("Prediction.evaluationMetrics") + ""}>
          <Grid marginBottom={4} container>
              <Grid item xs/>
              <Grid item xs={10} md={8} lg={6} alignContent={"center"}>
                  <DataGrid
                      rows={rowStatistics}
                      columns={columnsStatistics}
                      disableColumnSelector
                      disableSelectionOnClick={true}
                      pageSize={15}
                      autoHeight={true}
                      getRowId={(row) => row.name}
                      getRowHeight={() => 'auto'}
                      hideFooter
                      initialState={{
                          sorting: {
                              sortModel: [{ field: 'index', sort: 'desc' }],
                          },
                      }}
                      components={{
                          Toolbar: CustomToolbar,
                      }}
                      componentsProps={{ toolbar: { allColumns: true } }}
                  />
              </Grid>
              <Grid item xs/>
          </Grid>
        </ShowBox>
      </>
    )
}