import axios, { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';

const MapServiceTAP = (): any => {

    return {
        getMapData: (search: string): Promise<AxiosResponse<any, any>> => {
            const url = "/tap/map/mapData?search=" + search;
            return axios.get(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        }
    };
}

export default MapServiceTAP();