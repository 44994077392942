import { FaTimes, FaEdit } from 'react-icons/fa';
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import stripTags from "../../utils/stripTags";
import HtmlEditor from "./HtmlEditor";
import {RichTextReadOnly} from "mui-tiptap";
import useExtensions from "./useExtentions";
import css from '../MultiGraphs/Graph.module.css';

interface IStyleProps {
  collapse?: boolean;
  margin?: boolean;
}
const StyledDescription = styled.div<IStyleProps>`
  overflow: ${({ collapse }) => (collapse ? 'hidden' : 'visible')};
  overflow-wrap: break-word;
  ${({ margin }) => margin && 'margin-bottom: 10px;'}
`;
const EditorButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 10px;
`;
const EditorContainer = styled.div`
  width: 100%;
`;
const DescriptionButtonContainer = styled('div')<{ type?: string }>`
  display: inline-flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${props => (props.type === 'card' ? '0' : '10px')};
`;
const CardHeaderWrapper = styled('div')<{ type: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  top: ${props => (props.type === 'card' ? '-30px' : '0px')};
`;

interface IProps {
    editMode: boolean
    textWithTags: any
    updateText: (value: any) => void;
    onRemove: (value: any) => void;
    id?: string
    deleteable: boolean
}

export default function ReportTextbox(props: IProps) { 
  const { t } = useTranslation("traffic");
  const [showEditor, toggleEditor] = useState(false);

  const handleToggle = useCallback(
    (isVisible: boolean) => {
      toggleEditor(isVisible);
    },
    [toggleEditor],
  );
  const extensions = useExtensions({
    placeholder: "d",
  });

  if (props.editMode) {
    return showEditor ? (
      <EditorButtonContainer className='nonDraggable'>
        <EditorContainer>
          <HtmlEditor 
            content={props.textWithTags} 
            updateReportDescription={props.updateText} 
            handleToggleEditor={handleToggle}       
          />
        </EditorContainer>
      </EditorButtonContainer>
    ) : (
      <>
        {stripTags(props.textWithTags) && props.deleteable &&           
          <div className={`${css.closeTop} ${css.edit} nonDraggable`} onClick={() => handleToggle(true)}>
            <FaEdit />
          </div>}
        {props.deleteable && props.editMode && 
          <div className={`${css.closeTop} ${css.close} nonDraggable`} onClick={() => props.onRemove(props.id)}>
            <FaTimes />
          </div>}
        <DescriptionButtonContainer type={''} >
          <CardHeaderWrapper type={''}>
          <StyledDescription>
          <RichTextReadOnly content={props.textWithTags} extensions={extensions} />
          </StyledDescription>
            {stripTags(props.textWithTags) ? !props.deleteable && (
              <Button className='nonDraggable' size="medium" style={{padding: '10px'}} startIcon={<EditIcon />} onClick={() => handleToggle(true)}/>
            ) : (
              <Button className='nonDraggable' size="medium" style={{padding: '10px'}} startIcon={<EditIcon />} onClick={() => handleToggle(true)}>
                {props.deleteable ? t('Traffic.addText') : t('Traffic.addDescription')}
              </Button>
            )}
          </CardHeaderWrapper>
        </DescriptionButtonContainer>
      </>
    );
  }

  return (
    <>
      <StyledDescription margin>
        <RichTextReadOnly content={props.textWithTags} extensions={extensions} />
      </StyledDescription>
    </>
  );
}