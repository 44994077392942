import ReportTextbox from "../ReportTextBox"

interface IProps {
    id: string
    data: any
    editMode: boolean
    onRemove: (value: any) => void;
    onUpdate: (id: any, sectionData: any) => void;
}

export default function TReportTextbox(props: IProps) { 
 
  const onUpdateText = (value: any) => {
    props.onUpdate(props.id, {...props.data, textData: value});
  }

  return (
    <ReportTextbox 
      key={props.id}
      id={props.id}
      editMode={props.editMode}
      textWithTags={props.data.textData}
      deleteable={true}
      onRemove={props.onRemove}
      updateText={onUpdateText}
    />
  );
}