import {Button} from '@mui/material';
import { useTranslation } from 'react-i18next';
import image from '../../../assets/css/images/police.jpg';
import { useEffect, useState } from 'react';
import UsersService from '../../../services/UsersService';
import TableWithPagining from '../../../components/Table/TableWithPagining';

import PageForm from '../../../components/PageHeader/PageForm';
import { Column } from '../../../interfaces/Interfaces';

export default function Users() {
    const { t } = useTranslation("default");
    const [users, setUsers] : [Array<any>, Function] = useState([]);
    const [count, setCount] = useState(0);

    const columns: Column[] = [
        { id: 'id', label: 'Id', minWidth: 170 },
        { id: 'name', label: t('default.name'), minWidth: 170 },
        { id: 'surname', label: t('default.surname'), minWidth: 170 },
        { id: 'email', label: t('default.email'), minWidth: 170 },
        { id: 'role', label: t('default.role'), minWidth: 170, align: 'center', format: (value: number) => value === 1 ? t('default.admin') : t('default.user') },
    ];

    const loadData = (page: number, perPage: number) => {
        UsersService.getUsers("", page , perPage)
        .then((resp: any) => {
            setUsers(resp.data.data);
            setCount(resp.data.totalCount);
        })
        .catch(() => { });
    };
   
    useEffect(() => {
        loadData(0, 10);
    }, []);

    const changeRole = (idx: number) => {
        const role = users[idx].role === 1 ? 2 : 1;
        UsersService.changeRole(users[idx].id, role)
        .then((resp: any) => {
            const tempUsers = [...users];
            tempUsers[idx].role = role;
            setUsers(tempUsers);
        })
        .catch(() => { });

    };

    const actButt = [{
        element: <Button> {t('default.changeRole')} </Button>,
        action: changeRole
    }];

    return (
    <PageForm label={t("menu.userManagement")} image={image}>
        <TableWithPagining columns={columns} rows={users} totalCount={count} actions={actButt} loadData={loadData}/>
    </PageForm>
    );
}