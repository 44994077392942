import axios from "axios";
import React, { useEffect } from "react";
import { FaCheck, FaLightbulb, FaThList, FaWindowClose } from "react-icons/fa";
import { useParams } from "react-router-dom";
import image from '../../../../assets/css/images/pris.png';
import HeaderCarousel from "../../../../components/HeaderCarousel/HeaderCarousel";
import { GET_ALL_CO_WITH_DETAIL } from "../../../../interfaces/europe/Constants";
import { CriminalOffenceDetail } from "../../../../interfaces/europe/interfaces";
import './CriminalOffences.css';
import { useTranslation } from "react-i18next";

export default function CriminalOffences() {

    const { t } = useTranslation("europe");

    //RESTS
    const [criminalOffenceDetail, setCriminalOffenceDetail] = React.useState<CriminalOffenceDetail[]>([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_ALL_CO_WITH_DETAIL).then((response) => {
            setCriminalOffenceDetail(response.data);
        });
    }, []);


    return (
        <>
            <div>

                <HeaderCarousel name={t("criminalOffence.title")} image={image} />

                <div className="acordion-start">
                    <div className="accordion" id="accordionExample">
                        {criminalOffenceDetail.map(((co) =>
                            <div className="card" key={co.code}>
                                <div className="card-header" id={co.code + "h"}>
                                    <h2 className="mb-0">
                                        <button className="btn" type="button" data-toggle="collapse" data-target={"#" + co.code + "c"} aria-expanded="true" aria-controls={co.code + "c"}>
                                            {t(`criminalOffence.${co.code}.name`)}
                                        </button>
                                    </h2>
                                </div>

                                <div id={co.code + "c"} className="collapse" aria-labelledby={co.code + "h"} data-parent="#accordionExample">
                                    <div className="card-body text-left">
                                        <div className="mb-4 ml-3">
                                            <div className="font-weight-bold text-left mb-2 h6">{t("criminalOffence.fitsInto")} </div>
                                            <div className="ml-3 mb-1 mt-1 font-italic">
                                                <FaThList className="mr-2 text-success" />
                                                <span className=""> {t(`criminalOffence.${co.parentCode}.name`)}</span>
                                            </div>
                                        </div>
                                        <div className="mb-4 ml-3">
                                            <div className="font-weight-bold text-left mb-2 h6">{t("criminalOffence.definition")} </div>
                                            <div className="ml-3 mb-1 mt-1 font-italic">
                                                <FaLightbulb className="mr-2 text-warning" />
                                                <span className=""> {t(`criminalOffence.${co.code}.definition`)}</span>
                                            </div>
                                        </div>
                                        <div className="mb-4 ml-3">
                                            <div className="font-weight-bold text-left mb-2 h6">{t("criminalOffence.subCategories")} </div>
                                            {co.subcategoryOfCriminals?.length > 0 ? co.subcategoryOfCriminals.map(((s) =>
                                                <div className="ml-3 mb-1 mt-1 font-italic" key={s.name}>
                                                    <FaCheck className="mr-2 text-info" />
                                                    <span className="">{t(`criminalOffence.${co.code}.${s.code}`)}</span>
                                                </div>
                                            )) : <div className="ml-3 mb-1 mt-1 font-italic">
                                                <FaWindowClose className="mr-2 text-danger" />
                                                <span className="">{t("criminalOffence.noSubCategories")}</span>
                                            </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>

    );
}
