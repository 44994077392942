import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import analyzisService from "../../services/StatisticService";
import CustomModal from "../Modal/CustomModal";
import { Button } from "../StyleComponents/StyleComponents";
import { AiFillSetting } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import iDataset from "../../interfaces/iDataset";

interface IProps {
  dataset: iDataset;
};

export default function Histogram(props: IProps) {

  const { t } = useTranslation("crimes");
  const [toolTip, setToolTip]: [any[string], Function] = useState([]);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [interval, setInterval] = useState('');

  useEffect(() => {
    const abortCtrl = new AbortController();
      const dataset = props.dataset.graphValues[0].data[0];
      const fltValues = dataset.filter ? dataset.filter : props.dataset.filterValues;
      const fval = fltValues.map((value) => ({
          type: value.type.value,
          value: value.value.map((val) => ({
              type: val.type.value,
              atr: val.atribute.value,
              option: val.option.value,
              value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
          }))
    }));
    analyzisService.getHistogram(dataset.dataFirst, dataset.dataSecond, dataset.dataFirstInterval, dataset.dataSecondInterval
      , dataset.dataFirstIntervalBy, dataset.dataSecondIntervalBy, props.dataset.graphValues[0].group, fval
      , props.dataset.expressionValues, interval, abortCtrl.signal)
    .then((res: any) => {
        //  setToolTip(res.data.data.map((item: any) => "od " + item.count_floor));

        const tempToolTip : any = [];
        res.data.data.forEach((item: any, idx: number) => {
          tempToolTip.push(item.count_floor + " - " + (res.data.data.length - 1 > idx ? res.data.data[idx + 1].count_floor : "nekonečno"));

        });
        setToolTip(tempToolTip);

        setData(res.data.data.map((item: any) => item.count));
    })
    .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataset.expressionValues, props.dataset.graphValues, props.dataset.filterValues, props.dataset.filter, showModal]);


  const barOption = {
      legend: {
        top: "bottom",
      },
      title: {
          text: 'Histrogram',
          x: 'center'
        },
      tooltip: {},
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          saveAsImage: { show: true },
        },
      },
      xAxis: {
        type: "category",
        data: toolTip,
        //  name: "tesasdasdas asdasdt",
      },
      yAxis: {
        type: "value",
        name: t("analyzis.valuesCount"),
      },
      series: [
        {
          label: {
            show: true,
            rotate: 90,
            align: 'bottom',
            position: 'insideBottomRight',
            fontSize: 16,
            formatter: '{c}',
          },
          data: data,
          type: "bar",
        }
      ],
    };

    return (
      <>
        <Grid sx={{ textAlign: "right" }}>
            <Button onClick={() => setShowModal(true)}><AiFillSetting /></Button>
        </Grid>
        <Grid>
            <ReactECharts option={barOption} />
        </Grid>
        <CustomModal
          show={showModal}
          title={"Histogram - nastavenie"}
          width="500px"
          onClose={() => setShowModal(false)}
        >
          <Grid marginBottom={2} marginTop={2}>
              <h4>Počet intervalov do ktorých budú rozdelené dáta (Ak je hodnota prázdna zvolí sa najvhodnejší)</h4>
              <input type="number" placeholder="Počet intervalov" value={interval} onChange={(e) => setInterval(e.target.value)}/>
          </Grid>
          <Grid sx={{ textAlign: "right" }}>
          <Button onClick={() => setShowModal(false)}>Zatvoriť</Button>
          <Button onClick={() => setShowModal(false)}>Uložiť</Button>
          </Grid>
        </CustomModal>
      </>
    )
}