import { Collapse } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    default?: boolean;
    children: any;
    className?: any;
    name?: string;
}

export default function ShowBox(props: IProps) {

    const { t } = useTranslation("crimes");
    const [ show, setShow ] = useState(props.default === undefined ? true : props.default);

    const switchShow = () => {
        setShow(old => !old);
    }

    return <div className={props.className}>
        <div className='text-right' onClick={switchShow} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{ show ? t("search.hide") : t("search.show") } {props.name}</div>
        <Collapse in={show}>{ props.children }</Collapse>
    </div>
}