import { useEffect, useState } from "react";
import TReportGraph from "./TReportGraph";
import TReportTextbox from "./TReportTextbox";
import TReportCard from "./TReportCard";
import { IReportSection } from "../../../interfaces/traffic_accidents/InterfacesTraffic";
import TReportPredictionGraph from "./TReportPredictionGraph";
import TReportTable from "./TReportTable";

export default function TReportSection(props: IReportSection) { 
    const [section, setSection] = useState(<></>);
    const [editMode, setEditMode] = useState(props.editMode);

    useEffect(() => {
        setEditMode(prevState => !prevState);
    }, [props.editMode]);
 
    useEffect(() => {
        if (props.type === "graph") {
            setSection(
                <TReportGraph
                    key={props.id}
                    id={props.id}
                    data={props.data}
                    globalFilterValues={props.globalFilterValues}
                    editMode={props.editMode}
                    onRemove={props.onRemove}
                    onUpdate={props.onUpdate}
                    onEdit={props.onEditGraph || (() => {})}
                    onExport={props.onExportSection || (() => {})}
                />
            )
        } else if (props.type === "text") {
            setSection(
                <TReportTextbox
                    key={props.id}
                    id={props.id}
                    data={props.data}
                    editMode={props.editMode}
                    onRemove={props.onRemove}
                    onUpdate={props.onUpdate}
                />
            )
        } else if (props.type === "card") {
            setSection(
                <TReportCard
                    key={props.id}
                    id={props.id}
                    data={props.data}
                    globalFilterValues={props.globalFilterValues}
                    editMode={props.editMode}
                    onRemove={props.onRemove}
                    onUpdate={props.onUpdate}
                />
            )
        } else if (props.type === "prediction_graph") {
            setSection(
                <TReportPredictionGraph
                    key={props.id}
                    id={props.id}
                    data={props.data}
                    globalFilterValues={props.globalFilterValues}
                    editMode={props.editMode}
                    onRemove={props.onRemove}
                    onUpdate={props.onUpdate}
                    onEdit={props.onEditGraph || (() => {})}
                    onExport={props.onExportSection || (() => {})}
                />
            )
        } else if (props.type === "table") {
            setSection(
                <TReportTable
                    key={props.id}
                    id={props.id}
                    data={props.data}
                    globalFilterValues={props.globalFilterValues}
                    editMode={props.editMode}
                    onRemove={props.onRemove}
                    onEdit={props.onEditTable || (() => {})}
                    handleFilterValuesAdd={props.handleFilterValuesAdd}
                    handleSortChange={props.handleSortChange}
                    onExport={props.onExportSection || (() => {})}
                />
            )
        }
    }, [editMode, props.data, props.id, props.type, props.globalFilterValues]);

    return (
        <>
            {/* <div style={{paddingRight: '10px', height: '100%'}}>   */}
                {section}   
            {/* </div> */}
        </>
    );
}