import { SearchFilter, SearchFilterValue, filterType } from '../../interfaces/SearchFilter';
import STableHead from './STableHead';
import css from './Search.module.css';
import { useState, useEffect } from 'react';
import SearchData from '../../interfaces/SearchData';
import STableRow from './STableRow';
import { MdLocalPolice } from 'react-icons/md';
import STablePaging from './STablePaging';
import { Sort } from '../../interfaces/SortWT';
import { ExpressionValue } from '../Expressions/Expressions';
import { useTranslation } from 'react-i18next';
import TReportSelectionModal from '../Report/TReportSelectionModal';
import {v4 as uuidv4} from 'uuid';
import NotificationService from '../../services/NotificationService';
import useUser from '../../use/useUser';
import { FaEdit, FaFileUpload, FaTimes } from 'react-icons/fa';
import ReportsService from '../../services/ReportsService';
import { report } from 'process';

interface IProps {
    filter: SearchFilter[];
    customExpressions?: ExpressionValue[];
    filterValues: SearchFilterValue[];
    sort: Sort;
    onFilterAdd: (filterValues: SearchFilterValue) => void;
    onSortChange: (sort: Sort) => void;
    endpointService: any;
    iconBar?: boolean;
    reportCenter?: boolean;
    editMode?: boolean;
    onEdit?: () => void;
    onRemove?: () => void;
    onExportToReport?: (id: any) => void;
    globalFilterValues?: SearchFilterValue[];
};

export default function STable(props: IProps) {
    const { i18n } = useTranslation();
    const [ user, { isAdmin, isLoggedIn, logout } ] = useUser();
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState<SearchData[]>([]);
    const [ maxPage, setMaxPage] = useState(0);
    const [ showReportModal, setShowReportModal ] = useState(false);
    const { t } = useTranslation("crimes");

    useEffect(() => {
        const abortCtrl = new AbortController();
        setLoading(true);
        const fval = props.filterValues.map((value) => ({
            type: value.type.value,
            value: value.value.map((val) => ({
                type: val.type.value,
                atr: val.atribute.value,
                option: val.option.value,
                value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
            }))
        }));
        const fvalReportGlobal = props.reportCenter ? props.globalFilterValues?.map((value) => ({
            type: value.type.value,
            value: value.value.map((val) => ({
                type: val.type.value,
                atr: val.atribute.value,
                option: val.option.value,
                value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
            }))
        })) : [];
        //console.log( "language is in sTable " +props.sort.language);
        props.endpointService.getTableData(props.sort.page, props.sort.perPage, props.sort.label, 
            props.sort.order,props.sort.language, fval, fvalReportGlobal, props.customExpressions, abortCtrl)
        .then((response: any) => {
            setMaxPage(response.data.paging.maxPage);
            setData(response.data.data);
            setLoading(false);
        })
        .catch(() => {});
        return () => abortCtrl.abort();
    }, [props.filter, props.filterValues, props.endpointService, props.sort, props.customExpressions, props.globalFilterValues, props.reportCenter]);

    const pageChangeHandler = (page: number) => {
        props.onSortChange({
            ...props.sort,
            page: page
        });
    };

    const perPageChangeHandler = (perPage: number) => {
        props.onSortChange({
            ...props.sort,
            perPage: perPage
        });
    };

    const orderChangeHandler = (order: string, asc: boolean) => {
        props.onSortChange({
            ...props.sort,
            label: order,
            order: asc
        });
    };

    const onFilterChange = (newFilter: any) => {
        props.onFilterAdd(newFilter);
    }

    const handleExportTable = (id: string) => {
        setShowReportModal(false);
        ReportsService.addTableToReport(id, {tableData: JSON.stringify({filterNames: props.filter, filter: props.filterValues, sort: props.sort}), type:JSON.stringify({type: "table"}), layoutData:JSON.stringify({ i: uuidv4(), x: 50, y: 50, w: 12, h: 3, minW: 3, minH: 2 })})
        .then((resp: any) => {
            if(resp.status === 200) {
                NotificationService.success(t("settings.succTableExport"));
            } else {
                NotificationService.error(t("settings.failTableExport"));
            }
        })
        .catch(() => { NotificationService.error(t("settings.failTableExport"));});
    }

    const headerRow = props.filter.map((h: SearchFilter, idx: number) => 
        <STableHead 
            key={idx} 
            order={props.sort.label} 
            asc={props.sort.order} 
            header={h} 
            allFilters={props.filter}
            onFilterChange={onFilterChange} 
            onOrderChange={orderChangeHandler} 
            endpointService={props.endpointService}
        />
    );

    const customHeaderRow = props.customExpressions?.map((h: ExpressionValue, idx: number) => 
        <STableHead 
            key={idx} 
            order={props.sort.label} 
            asc={props.sort.order} 
            header={{
                atr: h.label,
                name: h.label,
                active: h.active,
                type: filterType.DATE
            }} 
            allFilters={props.filter}
            onFilterChange={onFilterChange} 
            onOrderChange={orderChangeHandler} 
            endpointService={props.endpointService}
        />
    );
    
    const dataRows = data.map((item: SearchData, idx: number) => <STableRow key={idx} filter={props.filter} data={item} expressions={props.customExpressions} />);

    return (
        <div className={"box-12"} style={{width: '100%'}}>
            <TReportSelectionModal 
                show={showReportModal}
                endpointService={ReportsService}
                onClose={() => setShowReportModal(false)}
                onExport={(id: string) => handleExportTable(id)}
            />
            <div className={`box-12 ${props.iconBar && css.iconBar}`} style={props.editMode ? {marginTop: 10} : {margin: 0}}>
                {isLoggedIn() && props.iconBar && props.editMode && 
                <div className={`${css.share} nonDraggable`} onClick={props.reportCenter ? props.onExportToReport : () => setShowReportModal(true)}>
                    <FaFileUpload />
                </div>}
                {props.reportCenter && props.editMode && <div className={`${css.edit} nonDraggable`} onClick={props.onEdit}>
                    <FaEdit />
                </div>}
                {props.reportCenter && props.editMode && <div className={`${css.closeTop} ${css.remove} nonDraggable`} onClick={props.onRemove}>
                    <FaTimes />
                </div>}
                <div className={`box-12 ${css.searchTable}`}>
                    <table>
                        <thead>
                            <tr>
                                { headerRow }
                                { customHeaderRow }
                            </tr>
                            { dataRows }
                        </thead>
                    </table>
                    { loading && <div className={css.loading}>
                        <div><MdLocalPolice /> Načítavam...</div>
                    </div> }
                    <STablePaging 
                        page={props.sort.page}
                        perPage={props.sort.perPage}
                        maxPage={maxPage}
                        onPageChange={pageChangeHandler}
                        onPerPageChange={perPageChangeHandler}
                    />
                </div>
            </div>
        </div>
    );
}