import image from '../../../assets/css/images/police.jpg';
import PageForm from '../../../components/PageHeader/PageForm';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import TReportListSection from './TReportListSection';
import useUser from "../../../use/useUser";
import ReportsService from '../../../services/ReportsService';
import NotificationService from '../../../services/NotificationService';
// import { Button } from "@mui/material";
import { FaPlusCircle, FaTrashAlt, FaCopy } from 'react-icons/fa';
// import { makeStyles } from "@material-ui/core/styles";
import { Button } from "../../../components/StyleComponents/StyleComponents";
import { useNavigate } from 'react-router-dom';
import { IReportDetails } from '../../../interfaces/traffic_accidents/InterfacesTraffic';
import { MdLocalPolice } from 'react-icons/md';
import css from './Report.module.css';

export default function TReportCenter() {  
     const { t } = useTranslation("traffic");
     const [ user ] = useUser();
     const [data, setData] : [Array<any>, Function] = useState([]);
     const [count, setCount] = useState(0);
     const [ loading , setLoading ] = useState(true);

     const [publicReports, setPublicReports] : [Array<any>, Function] = useState([]);
     const [countPublicReports, setCountPublicReports] = useState(0);

     const loadData = () => {
        setLoading(true);
        ReportsService.getAllUserReports("")
        .then((resp: any) => {
            setData(resp.data.data);
            setCount(resp.data.totalCount);
        })
        .catch(() => { });

        ReportsService.getAllPublicReports(/*user.userId*/)
        .then((resp: any) => {
            setPublicReports(resp.data.data);
            setCountPublicReports(resp.data.totalCount);
            setLoading(false);
        })
        .catch(() => {setLoading(false);});

    };

    const removeReport = (reportId: string): void => {
        const report = data.find(report => report.reportId === reportId);
        ReportsService.deleteReport(report.reportId)
        .then((resp: any) => {
            if (resp.data) {
                setData((prevReportsArray: any) =>
                    prevReportsArray.filter((report: any) => report.reportId !== reportId)
                  );
                setCount((prev) => prev - 1)
                NotificationService.success(t("Notification.deleteSuccess"));
            }
        })
        .catch(() => { });
    };

    const cloneReport = (reportId: string, globalReport: boolean): void => {
        // console.log("cloneReport: " + reportId + " globalReport: " + globalReport);
        let cloneReport = {} as IReportDetails;
        if (globalReport) {
            cloneReport = { ...publicReports.find(report => report.reportId === reportId), type: 'Private'};
        } else {
            cloneReport = { ...data.find(report => report.reportId === reportId)};
        }
        cloneReport = {...cloneReport, userId: user.id, name: cloneReport.name + " (clone)", reportId: "undefined", date_last_updated: new Date().toLocaleString(), date_created: new Date().toLocaleString()}

        ReportsService.insertReport(cloneReport)
        .then((resp: any) => {
          setData([...data, {...cloneReport, reportId: resp.data}])
          setCount((prev) => prev + 1)
          NotificationService.success(t("Notification.cloneSuccess"));

        })
        .catch(() => { });
    };
   
    // useEffect(() => {
    //     for (let i = 0; i < data.length; i++) {
    //         console.log(i + ": " + data[i].name + " reportId: " + data[i].reportId)
    //       }
    // }, [data]);

    useEffect(() => {
        loadData();
    }, []);

    const actions = [{
        element: <Button><FaTrashAlt/></Button>,
        action: removeReport 
    },{
        element: <Button><FaCopy/></Button>,
        action: cloneReport 
    }];

    const navigate = useNavigate();
    const handleNewReport = () => {
        navigate("/traffic/report-center/report/undefined");
    };

     return (
        <PageForm label={t("Traffic.trafficReportCenter")} image={image}>
            <Button className="box-12 mx-0" onClick={handleNewReport}>
                <div style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                    <FaPlusCircle style={{margin: '5px'}}/>
                    {t("Traffic.createNewReport")}
                </div>
            </Button>
            <br />
            <br />
            <div className={css.full_size} style={{ height: '100%', width: '100%', margin: 0 }}>
            { loading && <div className={css.loading}>
              <div><MdLocalPolice /> {t("Traffic.loading")}</div>
            </div> }
            <TReportListSection 
                reportDetails={data}
                publicReports={ publicReports }
                totalCount={count+countPublicReports}
                loadData={loadData}
                actions={actions}
            />
            </div>
        </PageForm>
    )
}