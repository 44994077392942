import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import React from "react";
import { AiOutlineStock } from "react-icons/ai";
import { FaCalculator, FaGreaterThan, FaLessThan } from "react-icons/fa";

export function getImage(code: string | undefined) {
    if (code === undefined) {
        return '';
    }
    var flag1 = '/images/' + code.toLocaleLowerCase() + '-flag.gif'
    return flag1;
}

export function getIcon(code: string | undefined) {
    if (code === undefined) {
        return '';
    } else if (code === 'Maximum') {
        return '>';
    } else if (code === 'Minimum') {
        return '<';
    } else if (code === 'Mean') {
        return 'x̄';
    } else if (code === 'Median') {
        return 'x̃';
    } else if (code === 'Variance') {
        return 'σ²';
    } else if (code === 'Kurtosis') {
        return 'κ';
    } else if (code === 'StandardDeviation') {
        return 'σ';
    } else if (code === 'Skewness') {
        return 'γ';
    } else if (code === 'Percentile25') {
        return <span>π<sub>25</sub></span>;
    } else if (code === 'Percentile50') {
        return <span>π<sub>50</sub></span>;
    } else if (code === 'Percentile75') {
        return <span>π<sub>75</sub></span>;
    } else if (code === 'Sum') {
        return '∑';
    } else if (code === 'Count') {
        return '#';
    } else if (code === 'Mode') {
        return 'Mo';
    }


}

export function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export function numberWithSpaces(x: { toString: () => string; }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function floatNumberWithSpaces(x: { toString: () => string; }) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export function round(number: DoubleRange | undefined) {
    if (number === undefined) {
        return (Math.round(0 * 100) / 100).toFixed(2);
    }
    var num = number as number;
    return (Math.round(num * 100) / 100).toFixed(2);
}

export let filterClass = "";
export const handleFilter = () => {
    if (filterClass === "") {
        filterClass = "filter-hidden";
    } else {
        filterClass = "";
    }
};