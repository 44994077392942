import * as React from 'react';
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Typography from '@mui/material/Typography';
import './ReportToolbar.css';
import { IReport } from "../../interfaces/traffic_accidents/InterfacesTraffic";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Switch from "react-switch";
import useUser from "../../use/useUser";
import { Trans } from 'react-i18next'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SearchFilterValue } from '../../interfaces/SearchFilter';

interface IProps {
  globalFilterValues: SearchFilterValue[]
  report: IReport | null,
  unsavedChanges: boolean,
  editMode: boolean,
  loading: boolean,
  isSaving: boolean,
  saveReport: Function,
  editModeFunc: Function,
  openGlobalFilter: Function,
  reportCenterFunc: Function,
  updateReportType: (value: any) => void;
  handleDownloadPDF: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ReportToolbar(props: IProps) {
  const [ user ] = useUser();
  const { t } = useTranslation("traffic");
  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
      backgroundColor: "#ebebeb",
    },
    rightToolbar: {
        marginLeft: "auto",
        marginRight: -12
      },
      menuButton: {
        marginRight: 16,
        marginLeft: -12
      }
  });

  const classes = useStyles();
  const [filterOn, setFilterOn] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if(props.report?.userId !== user.id) {
      props.reportCenterFunc()
    } else {
      if(props.unsavedChanges) {
        setOpen(true)
      } else {
        props.reportCenterFunc()
      }
    }    
  };
  const handleClose = (event: object, reason: string) => {
      if (reason !== 'backdropClick') {
          setOpen(false)
      }
  };
  const handleClose2 = () => {
      setOpen(false)
  };

  React.useEffect (() => {
    if(props.globalFilterValues.length > 0) {
      setFilterOn(true)
    } else {
      setFilterOn(false)
    }
  }, [props.globalFilterValues])

  return(        
    <>
      <Modal
        open={open}
        onClose={(event, reason) => handleClose(event, reason)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("Traffic.loseChanges")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Trans i18nKey="Traffic">
              {t("Traffic.loseChangesText")}
            </Trans>
          </Typography>
          <br/>
          <Button variant="outlined" size="medium" style={{padding: '10px'}} color="warning" onClick={() => props.reportCenterFunc()}>OK</Button>
          <Button variant="outlined" size="medium" style={{padding: '10px', marginLeft: "10px"}} onClick={handleClose2}>{t("Traffic.cancel")}</Button>
        </Box>
      </Modal>   
      <Paper className={classes.root}>
        <Toolbar>
          {props.editMode ? (
          <>
            <Button size="medium" style={{padding: '10px'}} startIcon={<VisibilityIcon />} onClick={() => props.editModeFunc()}>
                {t("Traffic.view")}
            </Button>
            <Stack style={{margin: '10px'}} direction="row" spacing={1} alignItems="center">
              <Switch
                onChange={() => props.updateReportType(props.report?.type === "Public" ? "Private" : "Public")}
                checked={props.report?.type === "Public" ? true : false}
                className="react-switch"
                id="material-switch"
                height={22}
                width={44}
              />
              <Typography>{t("Traffic.shareReport")}</Typography>
            </Stack>
            <Button size="medium" style={{padding: '10px'}} startIcon={filterOn ? (<FilterAltIcon />) : (<FilterAltOutlinedIcon/>)} onClick={() => props.openGlobalFilter()}>
              {t("Traffic.filter")}
            </Button>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              { props.isSaving && <CircularProgress size={30} style={{position: 'absolute'}} /> }
              {props.unsavedChanges && <Button size="medium" style={{padding: '10px'}} startIcon={<SaveIcon />} onClick={() => props.saveReport()} disabled={props.isSaving}>
                {t("Traffic.save")}
              </Button>}
            </Box>
          </>
          ) : (
          <>        
            {!props.loading && props.report?.userId === user.id && 
            <Button size="medium" style={{padding: '10px'}} startIcon={<EditIcon />} onClick={() => props.editModeFunc()}>
              {t("Traffic.edit")}
            </Button>}
            <Button size="medium" style={{padding: '10px'}} startIcon={<DownloadIcon />} onClick={() => props.handleDownloadPDF()}>
              {t("Traffic.downloadPdf")}
            </Button>
          </>
          )}

          <section className={classes.rightToolbar}>
            <Button size="medium" style={{padding: '10px'}} startIcon={<CloseIcon />} onClick={handleOpen}>
              {t("Traffic.closeReport")}
            </Button>
          </section>
        </Toolbar>
      </Paper>
    </>
  );
}