import { useCallback, useEffect, useState } from 'react';
import useUser from '../../../use/useUser';
import { PredictionGraphValues, PredictionInput, PredictionRequest } from '../../../interfaces/traffic_accidents/InterfacesTraffic';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaFileUpload, FaTimes } from 'react-icons/fa';
import { MdLocalPolice } from 'react-icons/md';
import ReactECharts from "echarts-for-react";
import css from '../../MultiGraphs/Graph.module.css';
import { SearchFilterValue } from '../../../interfaces/SearchFilter';
import { GraphData } from '../../../interfaces/Graphs';

interface IProps {
    type: 'bar' | 'line' | 'pie' | string;
    showLabels: boolean | "value" | "true" | "false";
    longXLabels?: boolean;//nastavit na true ak maju byt popisky v uhle/pouzivane pre dlhe popisky
    values?: Array<Array<Number>>;
    xNames?: Array<string>; // pre kazde data
    valueNames?: Array<string>; // pre kazdy subor dat
    texts?: Array<string>;
    width?: string;
    height?: string;
    group?: string;
    dynamicValues?: GraphData[];
    filterValues?: SearchFilterValue[];
    predictionValues?: PredictionInput;
    endpointService?: any;
    analysisService?: any;
    onEdit?: () => void;
    onRemove?: () => void;
    onExportToReport?: () => void;
    settingTAP?: boolean;//sluzi pre vyber nastaveni, ktore sa maju zobrazit v dopravnych nehodach - chodci
    editMode?: boolean;//zobrazenie tlacidiel pre editaciu a odstranenie grafu
    analyse?: boolean;
    reportCenter?: boolean;//urcuje nastavenia pre lepsie zobrazenie grafu v Report Center
};

export default function TPredictionGraph(props: IProps) {
    const [ user, { isAdmin, isLoggedIn, logout } ] = useUser();
    const [ loading , setLoading ] = useState(false);
    // const [ showReportModal, setShowReportModal ] = useState(false);
    // const predictionModels = ["AR", "SSA"];
    const { t } = useTranslation("traffic");
    const [data, setData] = useState<PredictionGraphValues>();
    const [predict, setPredict] = useState<PredictionGraphValues>();
    const [upperCI, setUpperCI] = useState<PredictionGraphValues>();
    const [lowerCI, setLowerCI] = useState<PredictionGraphValues>();
    // const [predictionRequest, setPredictionRequest] = useState<PredictionRequest>({ "windowSize": 2, "seriesLength": 5, "trainSize": 10, "horizon": 5, "confidenceLevel": 90, "memorySize": 3 });
  
    // let inputData: PredictionInput = {"predictionGraphValues": props.inputData!, "predictionRequest": predictionRequest, "predictionModel": predictionModels[tabValue]};
    useEffect(() => {
        setLoading(true);
        props.analysisService.getPrediction(props.predictionValues)
        .then((res: any) => {
          setData(res.data.originalData);
          let buffer = [];
          for (let i = 0; i < res.data.originalData.values.length; i++) {
            buffer.push(undefined);
          }
          setLowerCI({...res.data.lowerBoundData, "values": buffer.concat(res.data.lowerBoundData.values)});
          setUpperCI({...res.data.upperBoundData, "values": buffer.concat(res.data.upperBoundData.values)});
          buffer.splice(buffer.length-1);
          setPredict({...res.data.predictedData, "values": buffer.concat(res.data.predictedData.values)});
          setLoading(false);
        })
        .catch(() => { setLoading(false);});

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [/*predictionRequest, */props.predictionValues/*, tabValue*/]);
    //GRAF
    // const [ values, setValues ] = useState(props.values || []);
    // const [ xNames, setXNames ] = useState(props.xNames || []);
    // const [ valueNames, setValueNames ] = useState(props.valueNames || []);
    // const [ inputData, setInputData ] = useState<PredictionGraphValues>();

    // const checkFinish = useCallback((fin) => {
    //   if (fin === props.dynamicValues?.length) {
    //     setLoading(false);
    //   }
    // }, [props.dynamicValues]);

    const decodeSimpleData = useCallback((data: any) => {
        let tempVal: any[] = [];
        let tempXNames: any[] = [];
        data.forEach((data: any) => {
          tempXNames.push(data.name1);
          tempVal.push(data.value);
        });
        // setValues([tempVal]);//hodnoty v grafe
        // setXNames(tempXNames); //hodnoty na x osi
        // setValueNames([props.texts?.[0] || ""]);//nazov datasetu
        // setInputData({ "values": tempVal, "xAxisValues": tempXNames }) //TODO: neaktualiuzuje sa
    }, [props.dynamicValues]);

    useEffect(() => {
        const abortCtrl = new AbortController();
        if (props.dynamicValues) {
          props.dynamicValues.forEach((dataset: any) => {
            const fltValues = dataset.filter ? dataset.filter : props.filterValues;
            const fval = fltValues?.map((value: any) => ({
                type: value.type.value,
                value: value.value.map((val: any) => ({
                    type: val.type.value,
                    atr: val.atribute.value,
                    option: val.option.value,
                    value: val.value.map((valInVal: any) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
                }))
            }));
            props.endpointService.getGraphData!(dataset.dataFirst, dataset.dataSecond, dataset.dataFirstInterval, dataset.dataSecondInterval
              , dataset.dataFirstIntervalBy, dataset.dataSecondIntervalBy, dataset.nullValues, props.group, fval)
            .then((response: any) => {
              decodeSimpleData(response.data.data);
            })
          });
        }
        return () => abortCtrl.abort();
      }, [props.dynamicValues, props.filterValues]);
    
    // useEffect(() => {
    //   if (props.values) {
    //     setValues(props.values);
    //   }
    // }, [props.values]);

    // useEffect(() => {
    //   if (props.valueNames) {
    //     setValueNames(props.valueNames);
    //   }
    // }, [props.valueNames]);

    // useEffect(() => {
    //   if (props.xNames) {
    //     setXNames(props.xNames);
    //   }
    // }, [props.xNames]);
    //GRAP END

    const barOption = {
      legend: {
        top: "auto",
        bottom: 0,
        type: 'scroll',
        data: [t('Prediction.originalData'), t('Prediction.predictedData'), (upperCI?.xAxisValues.length ?? 0) > 0 && t('Prediction.upperConfidenceInterval'), (lowerCI?.xAxisValues.length ?? 0) > 0 && t('Prediction.lowerConfidenceInterval')]
      },
      lineStyle: {
        width: 2
      },
      title: {
          text: props.texts?.[0] || "",
          x: 'center',
          y: 15
        },
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        bottom: props.texts?.[2] ? 90 : 80,
        left: 50,
        containLabel: true
      },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            saveAsImage: { show: true },
          },
        },
        xAxis: {
          type: "category",
          data: data?.xAxisValues.concat(predict?.xAxisValues.slice(1)!),
          name: props.texts?.[2] || "",
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 30,
            fontWeight: 'bold'
          }
        },
        yAxis: {
          type: "value",
          name: props.texts?.[1] || "",
          nameLocation: 'middle',
          nameTextStyle: {
            lineHeight: 106,
            fontWeight: 'bold'
          }
        },
        dataZoom: [
          {
            startValue: data?.xAxisValues[0],
            bottom: 40,
          },
          {
            type: 'inside',
            bottom: 40,
          }
        ],
        series: [
          {
            name: t('Prediction.originalData'),
            data: data?.values,
            type: "line",
          },
          {
            name: t('Prediction.predictedData'),
            data: predict?.values,
            type: "line",
            color: "green",
          },
          (upperCI?.xAxisValues.length ?? 0) > 0 && {
            name: t('Prediction.upperConfidenceInterval'),
            data: upperCI?.values,
            type: "line",
            color: "red",
          },
          (lowerCI?.xAxisValues.length ?? 0) > 0 && {
            name: t('Prediction.lowerConfidenceInterval'),
            data: lowerCI?.values,
            type: "line",
            color: "red",
          },
          (lowerCI?.xAxisValues.length ?? 0) > 0 && {
            name: 'Lower CI',
            type: 'line',
            data: lowerCI?.values.map(function (item) {
              return item + 0;
            }),
            lineStyle: {
              opacity: 0
            },
            // stack: 'confidence-band', //pouzitie pre vyfarbenie plochy medzi hornym a dolnym intervalom
            symbol: 'none',
            tooltip: {
              show: false
           }
          },
          (upperCI?.xAxisValues.length ?? 0) > 0 && {
            name: 'Upper CI',
            type: 'line',
            data: upperCI?.values.map(function (item, index) {
              return item - lowerCI?.values[index]!;
            }),
            lineStyle: {
              opacity: 0
            },
            // areaStyle: { //pouzitie pre vyfarbenie plochy medzi hornym a dolnym intervalom
            //   color: '#ccc'
            // },
            // stack: 'confidence-band', //pouzitie pre vyfarbenie plochy medzi hornym a dolnym intervalom
            symbol: 'none',
            tooltip: {
              show: false
           }
          },
          {
            type: "line",
            color: "black",
            markLine: {
              symbol: ['none', 'none'],
              label: { show: false },
              data: [{ xAxis: -1+data?.xAxisValues?.length! }]
            },
          }
        ],
    };

    return (
        <div className={css.graph} style={{ height: '100%', width: '100%', margin: 0 }}>
        {isLoggedIn() && props.editMode && props.settingTAP && 
        <div className={`${css.share} nonDraggable`} onClick={props.onExportToReport}>
            <FaFileUpload />
        </div>}
        {/* TODO: editacia predict grafu */}
        {true && props.editMode && <div className={`${css.edit} nonDraggable`} onClick={props.onEdit}>
            <FaEdit />
        </div>}
        {props.editMode && !props.analyse && <div className={`${css.closeTop} ${css.close} nonDraggable`} onClick={props.onRemove}>
            <FaTimes />
        </div>}
          { loading && <div className={css.loading}>
              <div><MdLocalPolice /> {t("Traffic.loading")}</div>
          </div> }
          <ReactECharts option={barOption} notMerge={true} style={{ height: '100%', width: '100%', paddingBottom: '5px' }}/>
        </div>
    );
};
