import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

interface IProps {
    header: any;
    body: any;
};

export default function CustomTable(props: IProps) {

    const { t } = useTranslation();

    return (
        <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#2a2c2c"}}>
            <TableRow>
                {props.header.map((item: any, idx: any) => (
                    <TableCell key={idx} sx={{ color:  "white" }}>{item}</TableCell>
                ))}
            </TableRow>
            </TableHead>
            <TableBody>
            {props.body.map((row: any, idx: any) => (
                <TableRow
                className="tableLine"
                key={idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                {row.map((cell: any, index: any) => (
                    <TableCell key={index} component="th" scope="row" sx={{ padding: "6px"}}>
                        {t(cell) || cell}
                    </TableCell>
                ))}
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    )
}