import { useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
import { SearchFilter, SearchFilterValue } from '../../../interfaces/SearchFilter';
import filterItems from './../../../interfaces/ConstFilter';
import STable from './../../../components/Search/STable';
import SearchServiceTC from './../../../services/SearchServiceTC';
import SFilterSettings from '../../../components/Search/SFilterSettings';
import { Sort } from '../../../interfaces/Sort';
import SFilterValueDraw from './../../../components/Search/SFilterValueDraw';
import MultiGraphs from '../../../components/MultiGraphs/MultiGraphs';
import { GraphValue } from '../../../interfaces/Graphs';
import ShowBox from '../../../components/ShowBox';
import Settings from './../../../components/Settings/Settings';
import Expressions, { ExpressionValue } from './../../../components/Expressions/Expressions';
import image from '../../../assets/css/images/police.jpg';
import { useTranslation } from 'react-i18next';
import PageForm from '../../../components/PageHeader/PageForm';
import ModalMyDetailTC from './../../../modals/ModalMyDetailTC';
import { ModalService } from "../../../services/ModalService";
import SearchData from './../../../interfaces/SearchData';

export default function CSearch() {

    const { t } = useTranslation("crimes");

    const [ filter, setFilter ] = useState<SearchFilter[]>(filterItems);
    const [ filterValues, setFilterValues ] = useState<SearchFilterValue[]>([]);
    const [ sort, setSort ] = useState<Sort>({ page: 1, perPage: 5, label: "", order: true });

    const [ graphValues, setGraphValues ] = useState<GraphValue[]>([]);
    const [ expressionValues, setExpressionValues ] = useState<ExpressionValue[]>([]);

    const [selectedItem, setSelectedItem] = useState<SearchData | null>(null);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  

    const handleFilterChange = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
        setFilter(newFilter);
        setFilterValues(newValues);
    }

    const handleFilterValuesAdd = (newValues: SearchFilterValue) => {
        setFilterValues([...filterValues, newValues]);
    }

    const handleSortChange = (newSort: Sort) => {
        setSort(newSort);
    }

    const handleFilterValuesChange = (newValues: SearchFilterValue[]) => {
        setFilterValues(newValues);
    }

    const handleGraphValueChange = (newGraphs: GraphValue[]) => {
        setGraphValues(newGraphs);
    }

    const handleExpressionChange = (newExp: any[]) => {
        setExpressionValues(newExp);
    }

    const handleImportData = (data: any) => {
        console.log(data);
        if (data?.filter) {
            setFilter(data.filter);
        }
        if (data?.filterValues) {
            setFilterValues(data.filterValues);
        }
        if (data?.sort) {
            setSort(data.sort);
        }
        if (data?.graphValues) {
            setGraphValues(data.graphValues);
        }
        if (data?.expressionValues) {
            setExpressionValues(data.expressionValues);
        }
    }

    const dataToFile = {
        filter: filter,
        filterValues: filterValues,
        sort: sort,
        graphValues: graphValues,
        expressionValues: expressionValues
    };

    const handleRowDoubleClick = (item: any) => {
        console.log("Double-clicked on row:", item);
        setSelectedItem(item);
        setIsDetailModalOpen(true);
    };

    return (
    <PageForm label={t("search.pageTitle")} image={image}>
        <div className="box-12">
            <div className="box-12 py-2 text-right">
                <Expressions 
                    filter={filter}
                    expressionValues={expressionValues}
                    onChange={handleExpressionChange}
                    endpointService={SearchServiceTC}
                />
                {isDetailModalOpen && (
                <ModalMyDetailTC details={selectedItem} />
                )}
                <Settings 
                    dataKey="TCSearch"
                    dataToFile={dataToFile}
                    onImportData={handleImportData}
                    saveOnRefresh={false}
                    loadOnRefresh={false}
                />
                <SFilterSettings 
                    filter={filter} 
                    customExpressions={expressionValues}
                    filterValues={filterValues}
                    withVisibleOption
                    onFilterChange={handleFilterChange} 
                    onExpressionChange={handleExpressionChange}
                    endpointService={SearchServiceTC}
                />
            </div>
            <ShowBox className="box-12 py-2" name={t("search.hideFilters") + ""}>
                <SFilterValueDraw 
                    filterValues={filterValues} 
                    onChange={handleFilterValuesChange} 
                />
            </ShowBox>
            <ShowBox className="box-12 py-2" name={t("search.hideTable") + ""}>
                <STable 
                    filter={filter}
                    customExpressions={expressionValues}
                    filterValues={filterValues}
                    sort={sort}
                    onFilterAdd={handleFilterValuesAdd}
                    onSortChange={handleSortChange}
                    endpointService={SearchServiceTC}
                    onDoubleClick={handleRowDoubleClick} 
                />
            </ShowBox>
            <ShowBox className="box-12 py-2" name={t("search.hideGraphs") + ""}>
                <MultiGraphs 
                    filter={filter}
                    customExpressions={expressionValues}
                    filterValues={filterValues}
                    graphValues={graphValues}
                    onChange={handleGraphValueChange}
                    endpointService={SearchServiceTC} 
                />
            </ShowBox>
        </div>
    </PageForm>
    );
}