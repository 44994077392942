import axios, { AxiosResponse } from "axios";
import { SearchServiceWT } from "../interfaces/SearchServiceWT";
import qs from 'qs';
import { ExpressionValue } from "../components/Expressions/Expressions";

const searchServiceWT: SearchServiceWT = {

    getTableData: (
        page: number, 
        perPage: number, 
        label: string, 
        order: boolean, 
        language: string,
        search: string,
        searchReportGlobal?: string,
        custom?: ExpressionValue, 
        abort?: AbortController

    ): Promise<AxiosResponse<any, any>> => {
        const paramsSerializer = (par: any) => qs.stringify(par);
        return axios.get(process.env.REACT_APP_API_URL + "/dn/search", {
            params: {
                page,
                perPage,
                order: label,
                asc: order,
                search: JSON.stringify(search),
                custom: JSON.stringify(custom),
                language
            },
            signal: abort?.signal,
            paramsSerializer
        });
    },

    getFilterData: (label: string, value: string): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/dn/search/unique", {
            params: {
                atr: label,
                search: value
            }
        });
    }
    
}

export default searchServiceWT;