import { SearchFilterValue } from "../../interfaces/SearchFilter";
import css from './Search.module.css';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface IProps {
    filterValues: SearchFilterValue[];
    onChange: (values: SearchFilterValue[]) => void;
};

export default function SFilterValueDraw(props: IProps) {

    const { t } = useTranslation("crimes");

    const handleRemoveClick = (idx: number) => {
        const temp = [...props.filterValues];
        temp.splice(idx, 1);
        props.onChange(temp);
    };

    const noValues = props.filterValues.length === 0 && <span className={css.noFilters}>{t("filter.noFilter")}</span>;

    const values = props.filterValues.map((item, idx) => <div className={css.filterBox} key={idx}>
        <FaTimes className={css.filterClose} onClick={() => handleRemoveClick(idx)} />
        <div className={css.bigFilter}>{item.type.label}</div>
        { item.value.map((val, idx) => <div key={idx}>
            {val.type.label} <b>'{val.atribute.label}'</b> {val.option.label} <b>
                { val.value.map((valValue) => `'${valValue.label ? valValue.label : isNaN(valValue) ? new Date(valValue).toLocaleDateString() : valValue}' ` )}
            </b>
        </div>) }
    </div>);

    return <div className="box-12">
        { noValues }
        { values }
    </div>
}