import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Chart } from "react-google-charts";
import { FaCheckSquare, FaCross, FaEyeDropper, FaEyeSlash, FaFilter, FaWindowClose } from 'react-icons/fa';
import image from '../../../../assets/css/images/nahlasene.jpg';
import { CriminalOffence, FilterAnalysesRequest, FilterAnalysesResponse, FilterCriminalOffencesByDrugsRequest, FilterCriminalOffencesRequest, FilterCriminalOffencesResponse, FilterDrugAnalysesResponse, LegalLimitOfDrugForState, ReportedYears, State, Statistics } from '../../../../interfaces/europe/interfaces';
import './CriminalOffenceAnalysesDrugs.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect } from 'react';
import { COLORS, DRUG_MAX_VALUE, GET_ALL_CO, GET_ALL_REPORTED_YEARS, GET_ALL_STATES, HEADER_FIlTER_ANALYSE, HEADER_WITH_INDEX_AND_COUNTRY, ITEM_HEIGHT, ITEM_PADDING_TOP, MAP_COLORS, MAP_OPTIONS, POST_BAC_STATISTIC_ANALYSES, POST_DRUG_STATISTIC_ANALYSES, POST_FILTER_CO_ANALYSES, POST_FILTER_CO_ANALYSES_DRUGS, POST_FILTER_CO_LINK, TABLE_PAGE_SIZE_15, TABLE_PAGE_SIZE_TEN } from '../../../../interfaces/europe/Constants';
import { numberWithSpaces, floatNumberWithSpaces, getImage, CustomToolbar, getIcon, round } from '../../../../interfaces/europe/Functions';
import HeaderCarousel from '../../../../components/HeaderCarousel/HeaderCarousel';
import { height } from '@mui/system';
import TextField from '@mui/material/TextField/TextField';
import { BsSquare } from 'react-icons/bs';
import { eventNames } from 'process';
import { Box, FormControlLabel, InputAdornment, Link, Slider, Tab, Tabs } from '@mui/material';
import { number } from 'echarts';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";


export default function CriminalOffenceAnalysesDrugs() {

    //RESTS FOR GET
    const [reportedYears, setReportedYears] = React.useState<ReportedYears>();
    const [canMinLimit, setCanMinLimit] = React.useState<number>(0);
    const [canMaxLimit, setCanMaxLimit] = React.useState<number>(10);
    const [herMinLimit, setHerMinLimit] = React.useState<number>(0);
    const [herMaxLimit, setHerMaxLimit] = React.useState<number>(10);
    const [cocMinLimit, setCocMinLimit] = React.useState<number>(0);
    const [cocMaxLimit, setCocMaxLimit] = React.useState<number>(10);
    const [ecsMinLimit, setEcsMinLimit] = React.useState<number>(0);
    const [ecsMaxLimit, setEcsMaxLimit] = React.useState<number>(10);
    const [ampMinLimit, setAmpMinLimit] = React.useState<number>(0);
    const [ampMaxLimit, setAmpMaxLimit] = React.useState<number>(10);
    const [limit, setLimit] = React.useState<number>(0);

    const { t } = useTranslation("europe");

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_ALL_REPORTED_YEARS).then((response) => {
            setReportedYears(response.data);
        });
    }, []);



    //RESTS POST 
    let requestFilter: FilterCriminalOffencesByDrugsRequest = {
        "years": [], "canMinLimit": 0, "canMaxLimit": 0, "herMinLimit": 0, "herMaxLimit": 0, "ecsMinLimit": 0, "ecsMaxLimit": 0, "ampMinLimit": 0, "ampMaxLimit": 0, "cocMinLimit": 0, "cocMaxLimit": 0, "heroinIncluded": true, "amphetamineIncluded": true, "cannabisIncluded": true, "cocaineIncluded": true,
        "ecstasyIncluded": true
    };

    const [yearsResult, setYear] = React.useState<number[]>([]);
    const [filterResult, setFilterResult] = React.useState<FilterDrugAnalysesResponse>();
    const [mapCode, setMapCode] = React.useState<string>("150");
    const [valueMainTab, setValueMainTabe] = React.useState(1);



    //ADDED DATA HEADERS
    const mapped = filterResult !== undefined ? filterResult?.states.map(d => [d.code.toUpperCase(), `${t("states." + d.code.toUpperCase())}`, d.included === false ? 0 : 1, '<span><strong>index: </strong>' + d.index + '</span></br><span><strong>' + `${t("analyse.drugs.included")}` + '</strong>' + d.included + '</br>' + d.limits.map(l => '<strong>' + l.name + '</strong>: ' + l.allowedLimit).join('<br>')]) : null;
    const chartData = mapped !== null ? [HEADER_FIlTER_ANALYSE, ...mapped] : [HEADER_FIlTER_ANALYSE];

    //HANDLE FILTER CHANGES

    const handleYearChange = (event: SelectChangeEvent<typeof yearsResult>) => {
        const {
            target: { value },
        } = event;
        setYear(value as number[]);
    };

    const handleMapChange = (event: SelectChangeEvent) => {
        setMapCode(event.target.value as string);
    };

    const handleLimitChange = (event: number) => {
        setLimit(event);
    };

    const handleChangeMain = (_event: React.SyntheticEvent, newValue: number) => {
        setValueMainTabe(newValue);
    };

    const handleCanMinLimitChange = (event: number) => {
        setCanMinLimit(event);
    };

    const handleCanMaxLimitChange = (event: number) => {
        setCanMaxLimit(event);
    };

    const handleCocMinLimitChange = (event: number) => {
        setCocMinLimit(event);
    };

    const handleCocMaxLimitChange = (event: number) => {
        setCocMaxLimit(event);
    };

    const handleAmpMinLimitChange = (event: number) => {
        setAmpMinLimit(event);
    };

    const handleAmpMaxLimitChange = (event: number) => {
        setAmpMaxLimit(event);
    };

    const handleHerMinLimitChange = (event: number) => {
        setHerMinLimit(event);
    };

    const handleHerMaxLimitChange = (event: number) => {
        setHerMaxLimit(event);
    };

    const handleEcsMinLimitChange = (event: number) => {
        setEcsMinLimit(event);
    };

    const handleEcsMaxLimitChange = (event: number) => {
        setEcsMaxLimit(event);
    };


    const [checkedCannabis, setCheckedCannabis] = React.useState(true);

    const handleChangeCannabis = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedCannabis(event.target.checked);

    };

    const [checkedEcstasy, setCheckedEcstasy] = React.useState(true);

    const handleChangeEcstasy = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedEcstasy(event.target.checked);

    };

    const [checkedHeroin, setCheckedHeroin] = React.useState(true);

    const handleChangeHeroin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedHeroin(event.target.checked);

    };

    const [checkedAmphetamine, setCheckedAmphetamine] = React.useState(true);

    const handleChangeAmphetamine = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedAmphetamine(event.target.checked);

    };

    const [checkedCocaine, setCheckedCocaine] = React.useState(true);

    const handleChangeCocaine = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedCocaine(event.target.checked);

    };


    // RESTS 
    useEffect(() => {
        requestFilter.years = yearsResult;
        requestFilter.canMinLimit = canMinLimit;
        requestFilter.canMaxLimit = canMaxLimit;
        requestFilter.herMinLimit = herMinLimit;
        requestFilter.herMaxLimit = herMaxLimit;
        requestFilter.cocMinLimit = cocMinLimit;
        requestFilter.cocMaxLimit = cocMaxLimit;
        requestFilter.ecsMinLimit = ecsMinLimit;
        requestFilter.ecsMaxLimit = ecsMaxLimit;
        requestFilter.ampMinLimit = ampMinLimit;
        requestFilter.ampMaxLimit = ampMaxLimit;
        requestFilter.ecsMinLimit = ecsMinLimit;
        requestFilter.ecsMaxLimit = ecsMaxLimit;
        requestFilter.ecstasyIncluded = checkedEcstasy;
        requestFilter.amphetamineIncluded = checkedAmphetamine;
        requestFilter.cannabisIncluded = checkedCannabis;
        requestFilter.cocaineIncluded = checkedCocaine;
        requestFilter.heroinIncluded = checkedHeroin;
        axios.post(process.env.REACT_APP_API_URL + POST_FILTER_CO_ANALYSES_DRUGS, requestFilter).then((response) => {
            setFilterResult(response.data);
        });
    }, [yearsResult, canMinLimit, canMaxLimit, herMinLimit, herMaxLimit, cocMinLimit, cocMaxLimit, ecsMinLimit, ecsMaxLimit, ampMinLimit, ampMaxLimit, ecsMinLimit, ecsMaxLimit, checkedEcstasy, checkedAmphetamine, checkedCannabis, checkedCocaine, checkedHeroin]);


    const [statistics, setStatistics] = React.useState<Statistics>();

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + POST_DRUG_STATISTIC_ANALYSES, filterResult).then((response) => {
            setStatistics(response.data);
        });
    }, [filterResult]);

    
    const columnsStatistics: GridColDef[] = [
        {
            field: 'icon', headerName: '', align: 'center', headerAlign: 'center', width: 75, sortable: false, disableExport: true, cellClassName: 'icon-cell',
            filterable: false, renderCell: (params) => {
                return (
                    <>{getIcon(params.row.icon)}</>
                )
            }
        },
        { field: 'name', headerName: `${t("statistics.statistic")}`, flex: 1, align: 'center', headerAlign: 'center', headerClassName: 'bold-cell', cellClassName: 'bold-cell' },
        { field: 'value', headerName: `${t("statistics.value")}`, width: 200, align: 'right', headerAlign: 'right' }
    ];

    const rowStatistics = [
        { icon: 'Maximum', name: `${t("statistics.maximum")}`, value: round(statistics?.maximum) },
        { icon: 'Minimum', name: `${t("statistics.minimum")}`, value: round(statistics?.minimum) },
        { icon: 'Mean', name: `${t("statistics.mean")}`, value: round(statistics?.mean) },
        { icon: 'Median', name: `${t("statistics.median")}`, value: round(statistics?.median) },
        { icon: 'Variance', name: `${t("statistics.variance")}`, value: round(statistics?.variance) },
        { icon: 'Kurtosis', name: `${t("statistics.kurtosis")}`, value: round(statistics?.kurtosis) },
        { icon: 'StandardDeviation', name: `${t("statistics.standardDeviation")}`, value: round(statistics?.standardDeviation) },
        { icon: 'Skewness', name: `${t("statistics.skewness")}`, value: round(statistics?.skewness) },
        { icon: 'Percentile25', name: `${t("statistics.percentile25")}`, value: round(statistics?.percentile25) },
        { icon: 'Percentile50', name: `${t("statistics.percentile50")}`, value: round(statistics?.percentile50) },
        { icon: 'Percentile75', name: `${t("statistics.percentile75")}`, value: round(statistics?.percentile75) }
    ];



    //FILTER HIDE

    const [filterClass, setFilterClass] = React.useState<string>("");
    const handleFilter = () => {
        if (filterClass === "") {
            // setFilterVisible(false);
            setFilterClass("filter-hidden")
        } else {
            //setFilterVisible(true);
            setFilterClass("")
        }
    };






    //TABLE DEFINITIONS
    const columns: GridColDef[] = [
        {
            field: 'color', headerName: '', width: 50, align: 'center', sortable: false, disableExport: true, filterable: false, renderCell: (params) => {
                if (params.row.id == 1) {
                    return (
                        <>{<BsSquare className="mr-2" style={{ fill: COLORS[9], background: COLORS[9] }} />}</>
                    )
                } else {
                    return (
                        <>{<BsSquare className="mr-2" style={{ fill: COLORS[7], background: COLORS[7] }} />}</>
                    )
                }
            }
        },
        { field: 'status', headerName: 'Status', flex: 1 },
        {
            field: 'index', headerName: `${t("analyse.crimeIndex")}`, flex: 1
        },

    ];

    const columnsStates: GridColDef[] = [

        {
            field: 'flag', headerName: '', width: 75, sortable: false, disableExport: true, filterable: false, renderCell: (params) => {
                return (
                    <div className="flag">
                        <img src={getImage(params.row.code)} alt={"Flag"} />

                    </div>
                )
            }
        },
        { field: 'name', headerName: `${t("map.state")}`, width: 200 },
        {
            field: '', headerName: `${t("analyse.drugs.included")}`, width: 100, align: 'center', sortable: false, disableExport: true, filterable: false, renderCell: (params) => {
                return (
                    <>{params.row.included === true ? <FaCheckSquare className="text-success" /> : <FaWindowClose className=" text-danger" />}</>
                )
            }
        },
        // {
        //     field: 'included', headerName: 'Included', flex: 1, type: 'boolean', align: 'center', headerAlign: 'center'
        // },
        {
            field: 'index', headerName: `${t("analyse.crimeIndex")}`, flex: 1, align: 'center', headerAlign: 'center'
        },
    ];

    function findArrayElementByName(array: LegalLimitOfDrugForState[], title: string) {
        if (array.length > 0) {
            const elem = array.find((element) => {
                return element.name === title;
            });
            if (elem !== undefined && elem !== null)
                return elem.allowedLimit;
        }
        return "-";
    }

    const columnsStatesLimits: GridColDef[] = [
        {
            field: 'flag', headerName: '', width: 75, sortable: false, disableExport: true, filterable: false, renderCell: (params) => {
                return (
                    <div className="flag">
                        <img src={getImage(params.row.code)} alt={"Flag"} />

                    </div>
                )
            }
        },
        { field: 'name', headerName: `${t("map.state")}`, width: 300 },


        {
            field: 'a', headerName: `${t("analyse.drugs.heroin") + "(g)"}`, flex: 1, align: 'center', sortable: false, disableExport: true, filterable: false, hide: !checkedHeroin, renderCell: (params) => {
                return (
                    <>{
                        findArrayElementByName(params.row.limits, "heroin")
                    }</>
                )
            }
        },

        {
            field: 'b', headerName: `${t("analyse.drugs.cocaine") + "(g)"}`, flex: 1, align: 'center', sortable: false, disableExport: true, filterable: false, hide: !checkedCocaine, renderCell: (params) => {
                return (
                    <>{
                        findArrayElementByName(params.row.limits, "cocaine")
                    }</>
                )
            }
        },

        {
            field: 'c', headerName: `${t("analyse.drugs.cannabis") + "(g)"}`, flex: 1, align: 'center', sortable: false, disableExport: true, filterable: false, hide: !checkedCannabis, renderCell: (params) => {
                return (
                    <>{
                        findArrayElementByName(params.row.limits, "cannabis")
                    }</>
                )
            }
        },

        {
            field: 'd', headerName: `${t("analyse.drugs.ecstasy") + "(g)"}`, flex: 1, align: 'center', sortable: false, disableExport: true, filterable: false, hide: !checkedEcstasy, renderCell: (params) => {
                return (
                    <>{
                        findArrayElementByName(params.row.limits, "ecstasy")
                    }</>
                )
            }
        },
        {
            field: 'e', headerName: `${t("analyse.drugs.amphetamines") + "(g)"}`, flex: 1, align: 'center', sortable: false, disableExport: true, filterable: false, hide: !checkedAmphetamine, renderCell: (params) => {
                return (
                    <>{
                        findArrayElementByName(params.row.limits, "amphetamines")
                    }</>
                )
            }
        },
    ];
    /* {f.allowed === true ? <FaCheckSquare className=" text-success" /> : <FaWindowClose className=" text-danger" />}*/
    //MAP GRAPH OPTIONS 
    const options = {
        region: mapCode,
        displayMode: 'regions',
        datalessRegionColor: '#fff',
        legend: 'hidden',
        colorAxis: {
            colors: [COLORS[9], COLORS[7],],

        },
        tooltip: {
            isHtml: true
        }
    }



    return (<>


        <div className=''>

            <HeaderCarousel name={t("analyse.drugs.title")} image={image} />

            <div className='underLabel '>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        onChange={handleChangeMain}
                        value={valueMainTab}
                    >
                        <NavLink to={"/europe/analyses/bac"} ><Tab label={t("analyse.bac")}></Tab></NavLink>
                        <NavLink to={"/europe/analyses/drugs"} className="text-primary"><Tab label={t("analyse.drug")}></Tab></NavLink>

                    </Tabs>
                </Box>
            </div>
            <div className=''>
                <div className='info'> </div>
                {filterClass === "filter-hidden" ?
                    <FaFilter className='my-3' style={{ cursor: 'pointer' }} onClick={handleFilter}></FaFilter>
                    : <FaEyeSlash className='my-3' style={{ cursor: 'pointer' }}> onClick={handleFilter}</FaEyeSlash>}

                <span className='pl-1' style={{ cursor: 'pointer' }} onClick={handleFilter}>Filter</span>
            </div>

            <form className={filterClass}>


                <div className={'d-inline '}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="multiple-checkbox-label-years">{t("map.years")}</InputLabel>
                        <Select
                            labelId="multiple-checkbox-label-years"
                            id="multiple-checkbox-years"
                            multiple
                            value={yearsResult}
                            onChange={(e) => { handleYearChange(e); }}
                            input={<OutlinedInput label={t("map.years")} />}
                            renderValue={(selected) => selected.join(', ')}

                        >
                            {reportedYears?.years.map((year) => (
                                <MenuItem key={year.toString()} value={year}>
                                    <Checkbox defaultChecked={true} checked={yearsResult.indexOf(year) > -1} />
                                    <ListItemText primary={year} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className='d-inline'>
                    <FormControl sx={{ m: 1, width: 250, height: 50 }}>
                        <InputLabel id="mapInputLabel">{t("map.chooseMap")}</InputLabel>
                        <Select
                            labelId="mapInputLabel"
                            id="mapInput"
                            value={mapCode}
                            label="Choose map"
                            onChange={handleMapChange}
                            defaultValue={"150"}

                        >
                            {MAP_OPTIONS.map((map) => (
                                <MenuItem key={map.id} value={map.id}>
                                    <ListItemText primary={map.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className='cannabis'>
                    <div className='filter-info'>{t("analyse.drugs.cannabis")}:</div>
                    <div className='d-inline'>
                        <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                            <TextField
                                id="outlined-number"
                                label="Min"
                                type="number"
                                defaultValue={"0"}
                                disabled={!checkedCannabis}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCanMinLimit(event.target.value as unknown as number);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">g</InputAdornment>,

                                }}
                                inputProps={{
                                    step: 1,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </FormControl>
                    </div >

                    <div className='d-inline'>
                        <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                            <TextField
                                id="outlined-number"
                                label="Max"
                                type="number"
                                defaultValue={"10"}
                                disabled={!checkedCannabis}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCanMaxLimit(event.target.value as unknown as number);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">g</InputAdornment>,


                                }}
                                inputProps={{
                                    step: 1,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </FormControl>
                    </div >
                    <div className='d-inline ml-4'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedCannabis}
                                    onChange={handleChangeCannabis}
                                    inputProps={{ 'aria-label': 'controlled' }}

                                />

                            }
                            label={t("analyse.drugs.included")}
                        />
                    </div >


                    <div className='cannabis'>
                        <div className='filter-info'>{t("analyse.drugs.heroin")}:</div>
                        <div className='d-inline'>
                            <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                                <TextField
                                    id="outlined-number"
                                    label="Min"
                                    type="number"
                                    defaultValue={"0"}
                                    disabled={!checkedHeroin}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setHerMinLimit(event.target.value as unknown as number);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">g</InputAdornment>,


                                    }}
                                    inputProps={{
                                        step: 1,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </FormControl>
                        </div >

                        <div className='d-inline'>
                            <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                                <TextField
                                    id="outlined-number"
                                    label="Max"
                                    type="number"
                                    defaultValue={"10"}
                                    disabled={!checkedHeroin}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setHerMaxLimit(event.target.value as unknown as number);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">g</InputAdornment>,


                                    }}
                                    inputProps={{
                                        step: 1,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </FormControl>
                        </div >
                        <div className='d-inline ml-4'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedHeroin}
                                        onChange={handleChangeHeroin}
                                        inputProps={{ 'aria-label': 'controlled' }}

                                    />

                                }
                                label={t("analyse.drugs.included")}
                            />
                        </div >
                    </div>




                    <div className='cannabis'>
                        <div className='filter-info'>{t("analyse.drugs.cocaine")}:</div>
                        <div className='d-inline'>
                            <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                                <TextField
                                    id="outlined-number"
                                    label="Min"
                                    type="number"
                                    defaultValue={"0"}
                                    disabled={!checkedCocaine}

                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setCocMinLimit(event.target.value as unknown as number);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">g</InputAdornment>,


                                    }}
                                    inputProps={{
                                        step: 1,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </FormControl>
                        </div >

                        <div className='d-inline'>
                            <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                                <TextField
                                    id="outlined-number"
                                    label="Max"
                                    type="number"
                                    defaultValue={"10"}
                                    disabled={!checkedCocaine}

                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setCocMaxLimit(event.target.value as unknown as number);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">g</InputAdornment>,


                                    }}
                                    inputProps={{
                                        step: 1,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </FormControl>
                        </div >
                        <div className='d-inline ml-4'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedCocaine}
                                        onChange={handleChangeCocaine}
                                        inputProps={{ 'aria-label': 'controlled' }}

                                    />

                                }
                                label={t("analyse.drugs.included")}
                            />
                        </div >
                    </div>



                    <div className='cannabis'>
                        <div className='filter-info'>{t("analyse.drugs.ecstasy")}:</div>
                        <div className='d-inline'>
                            <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                                <TextField
                                    id="outlined-number"
                                    label="Min"
                                    type="number"
                                    defaultValue={"0"}
                                    disabled={!checkedEcstasy}

                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setEcsMinLimit(event.target.value as unknown as number);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">g</InputAdornment>,


                                    }}
                                    inputProps={{
                                        step: 1,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </FormControl>
                        </div >

                        <div className='d-inline'>
                            <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                                <TextField
                                    id="outlined-number"
                                    label="Max"
                                    type="number"
                                    defaultValue={"10"}
                                    disabled={!checkedEcstasy}

                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setEcsMaxLimit(event.target.value as unknown as number);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">g</InputAdornment>,


                                    }}
                                    inputProps={{
                                        step: 1,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </FormControl>
                        </div >
                        <div className='d-inline ml-4'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedEcstasy}
                                        onChange={handleChangeEcstasy}
                                        inputProps={{ 'aria-label': 'controlled' }}

                                    />

                                }
                                label={t("analyse.drugs.included")}
                            />
                        </div >
                    </div>



                    <div className='cannabis'>
                        <div className=' filter-info'>{t("analyse.drugs.amphetamines")}:</div>
                        <div className='d-inline'>
                            <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                                <TextField
                                    id="outlined-number"
                                    label="Min"
                                    type="number"
                                    defaultValue={"0"}
                                    disabled={!checkedAmphetamine}

                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setAmpMinLimit(event.target.value as unknown as number);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">g</InputAdornment>,


                                    }}
                                    inputProps={{
                                        step: 1,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </FormControl>
                        </div >

                        <div className='d-inline'>
                            <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                                <TextField
                                    id="outlined-number"
                                    label="Max"
                                    type="number"
                                    defaultValue={"10"}
                                    disabled={!checkedAmphetamine}

                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setAmpMaxLimit(event.target.value as unknown as number);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">g</InputAdornment>,


                                    }}
                                    inputProps={{
                                        step: 1,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </FormControl>
                        </div >
                        <div className='d-inline ml-4'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedAmphetamine}
                                        onChange={handleChangeAmphetamine}
                                        inputProps={{ 'aria-label': 'controlled' }}

                                    />

                                }
                                label={t("analyse.drugs.included")}
                            />
                        </div >
                    </div>



                </div>
            </form>



            <div className="App mb-2">
                <Chart chartType="GeoChart" width="100%" height="600px" data={chartData} options={options} />
            </div>
            <div>
                <BsSquare className="mr-2" style={{ fill: COLORS[9], background: COLORS[9] }} /><span style={{ color: '#555' }}> {t("analyse.drugs.includedText")}</span>
            </div>
            <div>
                <BsSquare className="mr-2" style={{ fill: COLORS[7], background: COLORS[7] }} /><span style={{ color: '#555' }}> {t("analyse.drugs.notIncludedText")}</span>
            </div>



            <div className="mt-5 col-12">
                <h3 className="mb-4">{t("analyse.drugs.subTitle")} </h3>
                <div style={{ height: 250, width: '100%' }}>

                    <DataGrid
                        rows={
                            [
                                { id: 1, status: `${t("analyse.drugs.includedText") + " "}`, index: filterResult?.indexForMoreThenChosen },
                                { id: 2, status: `${t("analyse.drugs.notIncludedText") + " "}`, index: filterResult?.indexForLessThenChosen },
                            ]}
                        columns={columns}
                        disableColumnSelector
                        disableSelectionOnClick={true}
                        hideFooter={true}
                        autoHeight={true}
                        getRowId={(row) => row.id}

                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'index', sort: 'desc' }],
                            },
                        }}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{ toolbar: { allColumns: true } }}
                    />




                </div>
                <div style={{ height: 700, width: '100%', marginTop: 50 }}>
                    <DataGrid
                        rows={filterResult !== undefined ? filterResult?.states : []}
                        columns={columnsStates}
                        disableColumnSelector
                        disableSelectionOnClick={true}
                        autoHeight={true}
                        getRowId={(row) => row.code}
                        pageSize={TABLE_PAGE_SIZE_TEN}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'bac', sort: 'asc' }],
                            },
                        }}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{ toolbar: { allColumns: true } }}
                    />

                </div>


                <h3 className="mt-5">{t("analyse.drugs.limitsText")} </h3>

                <div style={{ height: 700, width: '100%', marginTop: 30 }}>
                    <DataGrid
                        rows={filterResult !== undefined ? filterResult?.states : []}
                        columns={columnsStatesLimits}
                        disableColumnSelector
                        disableSelectionOnClick={true}
                        autoHeight={true}
                        getRowId={(row) => row.code}
                        pageSize={TABLE_PAGE_SIZE_TEN}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'bac', sort: 'asc' }],
                            },
                        }}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{ toolbar: { allColumns: true } }}
                    />

                </div>
            </div>

            <div className="mt-2 col-12">
                <h3 className="mb-4">{t("statistics.title")}</h3>

                <div style={{ height: 720, width: '100%' }}>
                    <DataGrid
                        rows={rowStatistics}
                        columns={columnsStatistics}
                        disableColumnSelector
                        disableSelectionOnClick={true}
                        pageSize={TABLE_PAGE_SIZE_15}
                        autoHeight={true}
                        getRowId={(row) => row.name}

                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'index', sort: 'desc' }],
                            },
                        }}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{ toolbar: { allColumns: true } }}
                    />
                </div>
            </div>

        </div>
        <div className='info'> {t("statistics.crimeIndex")}</div>
    </>);

}
