import { Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { AiFillSetting } from "react-icons/ai";
import analyzisService from "../../../services/AnalysisServiceTAP";
import CustomModal from "../../Modal/CustomModal";
import { Button } from "../../StyleComponents/StyleComponents";
import { useTranslation } from 'react-i18next';
import {IDataset, Statistics} from "../../../interfaces/traffic_accidents/InterfacesTraffic";
import { getIcon } from "../../../interfaces/europe/Functions";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

interface IProps {
    dataset: IDataset;
    values: any[];
};

export default function TStatistics(props: IProps) {

    const { t } = useTranslation("crimes");
    // const [statistic, setStatistic] : [any[][], Function] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [decimalPlaces, setDecimalPlaces] = useState(2);
    const [statistics, setStatistics] = useState<Statistics>();

    useEffect(() => {
        analyzisService.getStatistic(props.values, decimalPlaces) //todo decimal places
        .then((res: any) => {
            setStatistics(res.data);
        })
        .catch(() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values, decimalPlaces]);
    
    
    const columnsStatistics: GridColDef[] = [
        { field: 'icon', headerName: '', align: 'center', headerAlign: 'center', width: 75, sortable: false, disableExport: true,  cellClassName: 'icon-cell', 
        filterable: false, renderCell: (params: any) => {
            return (
                <>{getIcon(params.row.icon)}</>
            )
        } },
        { field: 'name', headerName: t("analyzis.statisticName")!, flex: 1,align: 'center', headerAlign: 'center', headerClassName: 'bold-cell', cellClassName: 'bold-cell' },
        { field: 'value', headerName:  t("analyzis.value")!, width: 200,align: 'left', headerAlign: 'left' }
    ];

    const rowStatistics = [
        { icon: 'Maximum', name: t("analyzis.maximum"), value: statistics?.maximum },
        { icon: 'Minimum', name: t("analyzis.minimum"), value: statistics?.minimum },
        { icon: 'Mean', name: t("analyzis.mean"), value: statistics?.mean },
        { icon: 'Median', name: t("analyzis.median"), value: statistics?.median },
        { icon: 'Mode', name: t("analyzis.mode"), value: statistics?.mode },
        { icon: 'Variance', name: t("analyzis.variance"), value: statistics?.variance },
        { icon: 'StandardDeviation', name: t("analyzis.deviation"), value: statistics?.standardDeviation },
        { icon: 'Kurtosis', name: t("analyzis.kurtosis"), value: statistics?.kurtosis },
        { icon: 'Skewness', name: t("analyzis.skewness"), value: statistics?.skewness },
        { icon: 'Percentile25', name: t("analyzis.percentile(25)"), value: statistics?.percentile25 },
        { icon: 'Percentile50', name: t("analyzis.percentile(50)"), value: statistics?.percentile50 },
        { icon: 'Percentile75', name: t("analyzis.percentile(75)"), value: statistics?.percentile75 },
        { icon: 'Sum', name: t("analyzis.sum"), value: statistics?.sum },
        { icon: 'Count', name: t("analyzis.count"), value: statistics?.count }
    ]

    function CustomToolbar() {
        return (
        <Grid container>
            <Grid item xs={3}>
                <GridToolbarContainer>
                    <GridToolbarExport />
                </GridToolbarContainer>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1" fontWeight={"bold"} textAlign={"center"} paddingTop={1}>{t("analyzis.baseStatistics")}</Typography>
            </Grid>
        </Grid>
        );
    }

    return (
    <>
        <Grid marginBottom={4} container>
            <Grid item xs/>
            <Grid item xs={6} alignContent={"center"}>
                <DataGrid
                    rows={rowStatistics}
                    columns={columnsStatistics}
                    disableColumnSelector
                    disableSelectionOnClick={true}
                    pageSize={15}
                    autoHeight={true}
                    getRowId={(row) => row.name}
                    getRowHeight={() => 'auto'}
                    // sx={{ '--DataGrid-overlayHeight': '300px' }}
                    hideFooter
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'index', sort: 'desc' }],
                        },
                    }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{ toolbar: { allColumns: true } }}
                />
            </Grid>
            <Grid item xs={3} alignContent={"right"} sx={{ textAlign: "right" }}>
                <Button onClick={() => setShowModal(true)}><AiFillSetting /></Button>
            </Grid>
        </Grid>

        <CustomModal
            show={showModal}
            title={t("analyzis.settings")}
            width="500px"
            onClose={() => setShowModal(false)}
        >
            <Grid marginBottom={2} marginTop={2}>
                <h4>{t("analyzis.decimalCount")}</h4>
                <input max={15} min={0} type="number" placeholder={t("analyzis.decimalCount") + ""} value={decimalPlaces} onChange={(e) => setDecimalPlaces(parseInt(e.target.value))}/>
            </Grid>
        </CustomModal>
    </>
    )
}