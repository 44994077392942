import GraphData from "../../interfaces/Graphs/GraphData";
import GraphComponent from "./Graph";
import { useTranslation } from 'react-i18next';

interface GraphContentProps {
    endpoint: string,
    name: string,
    graphData: GraphData[]
}
export default function TGraphContent(props: GraphContentProps) {
    const { t } = useTranslation("traffic");

    return (
        <div>
            <h2 style={{textAlign: "center"}}>{t("TGraphs.nadpis")} :  {props.name}</h2>
            <GraphComponent baseUrl_API={props.endpoint} GraphData={props.graphData} GraphSeriesName={t("TGraphs.pocet")}/>
        </div>
    );
}