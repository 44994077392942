import { useTranslation } from "react-i18next";
import { IReportDetails } from "../../../interfaces/traffic_accidents/InterfacesTraffic";
import TReportDetails from "./TReportDetails";
import { ExpandableTableRow } from "./UI_Components/UI_Components";
// import { FaRegClock } from "react-icons/fa";
// import { Button } from "../../../components/StyleComponents/StyleComponents";
import { makeStyles } from "@material-ui/core/styles";
import ListAltIcon from '@mui/icons-material/ListAlt';
import PublicIcon from '@mui/icons-material/Public';
// Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//
// import './SimpleTable.css';

interface IProps {
  reportDetails: IReportDetails[];
  publicReports: IReportDetails[];
  totalCount: number;
  loadData: Function;
  actions: any;
}

export default function TReportListSection(props: IProps) {
  const { t } = useTranslation("traffic");
  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
  });

  interface Row {
    name: string;
    list: JSX.Element[];
  }

  function createData(name: string, list: JSX.Element[]): Row {
    return { name, list };
  }

  const classes = useStyles();

  const listMyPrivate = props.reportDetails.filter(item => item.type === 'Private').map((item, idx) => (
    <TReportDetails key={idx} {...item} actions={props.actions} loadData={props.loadData} idx={idx} globalReport={false} />
  ));

  const listMyPublic = props.reportDetails.filter(item => item.type === 'Public').map((item, idx) => (
    <TReportDetails key={idx} {...item} actions={props.actions} loadData={props.loadData} idx={idx} globalReport={false}/>
  ));

  const listPublicReports = props.publicReports.filter(item => item.type === 'Public').map((item, idx) => (
    <TReportDetails key={idx} {...item} actions={props.actions} loadData={props.loadData} idx={idx} globalReport={true} />
  ));

  const rows: Row[] = [createData(t("Traffic.reportPrivate"), listMyPrivate)];
  const rowsPublic: Row[] = [createData(t("Traffic.reportPublic"), listMyPublic)];
  const allPublicRows: Row[] = [createData(t("Traffic.reportsPublic"), listPublicReports)];
  const emptyRow = <TableRow><TableCell colSpan={6} style={{fontWeight: "bold", textAlign: "center", backgroundColor: "#f7f5ed" }}>{t("Traffic.noReports")}</TableCell></TableRow>;
  return (
    <Paper className={classes.root}>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow >
            <TableCell width="5%" padding="checkbox" style={{fontWeight: "bold" }}/>
            <TableCell width="15%" style={{fontWeight: "bold" }}>{t("Traffic.reportName")}</TableCell>
            <TableCell width="10%" align="left" style={{fontWeight: "bold" }}>
            {t("Traffic.reportType")}
            </TableCell>
            <TableCell width="50%" align="left" style={{fontWeight: "bold" }}>
            {t("Traffic.reportDescription")}
            </TableCell>
            <TableCell width="15%" align="left" style={{fontWeight: "bold" }}>
            {t("Traffic.reportLastModified")}
            </TableCell>
            <TableCell align="left" style={{fontWeight: "bold" }}>{t("Traffic.reportActions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <ExpandableTableRow
          key="AllReports"
          level={0}
          expandComponent={
            <>
              {rows.map((row: any) => (
                <ExpandableTableRow
                  key={row.name}
                  expandComponent={listMyPrivate.length > 0 ? row.list : emptyRow}
                  level={1}
                >
                  <TableCell
                    style={{ backgroundColor: "lightgray", fontWeight: "bold" }}
                    colSpan={5}
                    component="th"
                    scope="row"
                  >
                    <div style={{display: "flex", alignItems: 'center'}}>
                      {/* {<ListAltIcon style={{ width: "16px", marginRight: '5px' }} />} */}
                      {row.name}
                    </div>
                  </TableCell>
                </ExpandableTableRow>
              ))}
              {rowsPublic.map((row: any) => (
                <ExpandableTableRow
                  key={row.name}
                  expandComponent={listMyPublic.length > 0 ? row.list : emptyRow}
                  level={1}
                >
                  <TableCell
                    style={{ backgroundColor: "lightgray", fontWeight: "bold" }}
                    colSpan={5}
                    component="th"
                    scope="row"
                  >
                    <div style={{display: "flex", alignItems: 'center'}}>
                      {/* {<PublicIcon style={{ width: "16px", marginRight: '5px' }} />} */}
                      {row.name}
                    </div>
                  </TableCell>
                </ExpandableTableRow>
              ))}
            </>
          }
        >
          <TableCell
            style={{ backgroundColor: "lightgray", fontWeight: "bold", paddingLeft:"5px" }}
            colSpan={5}
            component="th"
            scope="row"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Icon or text for "All available reports" */}
              {<ListAltIcon style={{ width: "20px", marginRight: '5px' }} />}
              {t("Traffic.reportMy")}
            </div>
          </TableCell>
        </ExpandableTableRow>
          {allPublicRows.map((row: any) => (
            <ExpandableTableRow
              key={row.name}
              expandComponent={listPublicReports.length > 0 ?
                <>
                 {row.list}
                </> : emptyRow
              }
            >
              <TableCell
                style={{ backgroundColor: "lightgray", fontWeight: "bold", paddingLeft:"5px" }}
                colSpan={5}
                component="th"
                scope="row"
              >
              <div style={{display: "flex", alignItems: 'center'}}>
                {<PublicIcon style={{ width: "20px", marginRight: '5px' }} />}
                {row.name}
              </div>
              </TableCell>
            </ExpandableTableRow>
          ))} 
        </TableBody>
      </Table>
    </Paper>
  );
}
