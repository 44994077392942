import { filterType, SearchFilter } from "./SearchFilter";



const filterItems: SearchFilter[] = [
    { 
        atr: "id", 
        name: "ID", 
        active: false,
        type: filterType.TEXT 
    },
    {
      atr: "oddelenie",
      name: "Oddelenie",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "status",
      name: "Stav (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "status_title",
      name: "Stav",
      active: true,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 0,
    },
    {
      atr: "kvalifikacia",
      name: "Kvalifikácia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "kvalifikacia_title",
      name: "Kvalifikácia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 1,
    },
    {
      atr: "datum_podania",
      name: "Dátum podania",
      active: true,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_podania",
      name: "Typ podania (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "podanie_title",
      name: "Typ podania",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 2,
    },
    {
      atr: "datum_zacatia",
      name: "Dátum začatia",
      active: true,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_zacatia",
      name: "Typ začatia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "zacatia_title",
      name: "Typ začatia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 3,
    },
    {
      atr: "datum_pokracovania_tc",
      name: "Dátum pokračovania TČ",
      active: false,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_pokracovania_tc",
      name: "Typ pokračovania (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "pokracovania_title",
      name: "Typ pokračovania",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 4,
    },
    {
      atr: "datum_ukoncenia",
      name: "Dátum ukončenia",
      active: false,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_ukoncenia",
      name: "Typ ukončenia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "ukoncenia_title",
      name: "Typ ukončenia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 5,
    },
    {
      atr: "vztah_ukoncenia",
      name: "Vzťah ukončenia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "vztah_title",
      name: "Vzťah ukončenia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 6,
    },
    {
      atr: "motiv_ukoncenia",
      name: "Motív ukončenia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "motiv_title",
      name: "Motív ukončenia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 7,
    },
    {
      atr: "datum_obvinenia",
      name: "Dátum obvinenia",
      active: false,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_obvinenia",
      name: "Typ obvinenia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "obvinenia_title",
      name: "Typ obvinenia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 8,
    },
    {
      atr: "datum_rozsirenia",
      name: "Dátum rozšírenia",
      active: false,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_rozsirenia",
      name: "Typ rozšírenia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "rozsirenia_title",
      name: "Typ rozšírenia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 9,
    },
    {
      atr: "datum_pokracovania_p",
      name: "Dátum pokračovania P",
      active: false,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_pokracovania_p",
      name: "Typ pokračovania (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "pokracovania_p_title",
      name: "Typ pokračovania",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 10,
    },
    {
      atr: "datum_prevzatia",
      name: "Dátum prevzatia",
      active: false,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_prevzatia",
      name: "Typ prevzatia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "prevzatie_title",
      name: "Typ prevzatia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 11,
    },
    {
      atr: "sposob_prevzatia",
      name: "Spôsob prevzatia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "sposob_prevzatia_title",
      name: "Spôsob prevzatia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 12,
    },
    {
      atr: "datum_vratenia",
      name: "Dátum vrátenia",
      active: false,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_vratenia_a",
      name: "Typ vrátenia A (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "vratenia_a_title",
      name: "Typ vrátenia A",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 13,
    },
    {
      atr: "typ_vratenia_b",
      name: "Typ vrátenia B (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "vratenia_b_title",
      name: "Typ vrátenia B",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 14,
    },
    {
      atr: "datum_ukoncenia_po_dos",
      name: "Dátum ukončenia PO DOS",
      active: true,
      type: filterType.DATE, //filterType.DATE_BETWEEN,
    },
    {
      atr: "typ_ukoncenia_po_dos",
      name: "Typ ukončenia (kód)",
      active: false,
      type: filterType.TEXT,
    },
    {
      atr: "ukoncenia_po_dos_title",
      name: "Typ ukončenia",
      active: false,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 15,
    },
    {
      atr: "region_title",
      name: "Kraj",
      active: true,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 16,
    },
    {
      atr: "district_title",
      name: "Okres",
      active: true,
      type: filterType.TEXT, //filterType.SELECT,
      sid: 17,
    },
];

export default filterItems;