import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3";
import React, { useEffect, useState } from 'react';
import { FaFilter, FaMap, FaTimes } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import './TMap.css';
import axios from 'axios';
import L from 'leaflet';
import TAPMap from './TAPMap';
import PageForm from '../../../components/PageHeader/PageForm';
import { ToggleButtonGroup } from '@mui/material';
import { Button } from '../../../components/StyleComponents/StyleComponents';
import image from '../../../assets/css/images/police.jpg';
import MarkerClusterGroup from 'react-leaflet-cluster';

interface MapData {
  id: string;
  x: string;
  y: string;
  celkovaHmotnaSkoda : string;
  usmrteneOsoby : string;
  tazkoZraneneOsoby : string;
  lahkoZraneneOsoby : string;
  pricinaNehody : string;
  druhNehody : string;
  datum : string;
  cas : string;
  zavaznost: string;
  kraj : string;
}

interface PrekladDruhNehody {
  [key: string]: {
    TGraphs: {
      hodnotyGrafov: {
        [key: string]: string;
      };
    };
  };
}

interface PrekladPricinaNehody {
  [key: string]: {
    Map: {
      pricinaNehody: {
        [key: string]: string;
      };
    };
  };
}




export default function TMap() {
  const {t} = useTranslation("traffic");
  const [skodaOd, setSkodaOd] = useState<number | undefined>(undefined);
  const [skodaDo, setSkodaDo] = useState<number | undefined>(undefined);
  const [startDate1, setStartDate1] = useState('');
  const [startTime1, setStartTime1] = useState('00:00');
  const [startDate2, setStartDate2] = useState('');
  const [startTime2, setStartTime2] = useState('23:59');
  const [mapData, setMapData] = useState<MapData[] | null>(null);
  const [selectedColor, setSelectedColor] = useState<string>(t("Map.vsetky") ?? ''); 
  const [selectedRegion, setSelectedRegion] = useState<string>(t("Map.vsetky") ?? '');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showHeatmap, setShowHeatmap] = useState(true);
  const [toggleDN, setToggleDN] = useState(true)

  useEffect(() => {
    axios
      .get<MapData[]>('https://localhost:7111/api/MapData')
      .then((res) => {
        setMapData(res.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  


  const handleSkodaOdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    setSkodaOd(isNaN(value) || value < 0 ? undefined : value);
  };

  const handleSkodaDoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    setSkodaDo(isNaN(value) || value < 0 ? undefined : value);
  };

  const handleDateChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate1(e.target.value);
  };

  const handleTimeChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime1(e.target.value);
  };

  const handleDateChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate2(e.target.value);
  };

  const handleTimeChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime2(e.target.value);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };


  const getMarkerColor = (zavaznost: string) => {
    switch (zavaznost) {
      case '3':
        return 'red';
      case '2':
        return 'orange';
      case '1':
        return 'yellow';
      case '0':
        return 'green';
      default:
        return 'blue'; 
    }
  };

  const regionsSk = [
    "Hlavné mesto Praha",
    "Stredočeský kraj",
    "Juhočeský kraj",
    "Plzenský kraj",
    "Úsťanský kraj",
    "Královohradecký kraj",
    "Juhomoravský kraj",
    "Moravsko-sliezsky kraj",
    "Olomoucký kraj",
    "Zlínsky kraj",
    "Kraj Vysočina",
    "Pardubický kraj",
    "Liberecký kraj",
    "Karlovarský kraj",
    "všetky"
  ];
  
  const regionsEn = [
    "Prague Region",
    "Central Bohemian Region",
    "South Bohemian Region",
    "Plzeň Region",
    "Ústí nad Labem Region",
    "Hradec Králové Region",
    "South Moravian Region",
    "Moravia-Silesia Region",
    "Olomouc Region",
    "Zlín Region",
    "Vysočina Region",
    "Pardubice Region",
    "Liberec Region",
    "Karlovy Vary Region",
    "all"
  ];

  const isSkodaInRange = (value: string) => {
    const skodaValue = parseInt(value, 10);
    return (
      (skodaOd === undefined || skodaValue >= skodaOd) &&
      (skodaDo === undefined || skodaValue <= skodaDo)
    );
  };

  const isDateInRange = (dateString: string) => {
    const dateParts = dateString.split('.').map(part => parseInt(part.trim(), 10));
    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); 
  
    const startDate = startDate1 ? new Date(`${startDate1}T00:00:00.000Z`) : null;
    const endDate = startDate2 ? new Date(`${startDate2}T23:59:59.999Z`) : null;
  
    return (!startDate || date >= startDate) && (!endDate || date <= endDate);
  };
  
  
  const isTimeInRange = (timeString: string) => {
    const time = new Date(`2000-01-01T${timeString}`);
    const startTime = new Date(`2000-01-01T${startTime1}`);
    const endTime = new Date(`2000-01-01T${startTime2}`);
  
    return (!startTime1 || time >= startTime) && (!startTime2 || time <= endTime);
  };

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  
  

  const filteredMapData =
  selectedColor === t("Map.vsetky") && selectedRegion === 'všetky'
    ? (mapData || []).filter((data) => isSkodaInRange(data.celkovaHmotnaSkoda) && isDateInRange(data.datum) && isTimeInRange(data.cas))
    : (mapData || []).filter(
        (data) =>
          (selectedColor === t("Map.vsetky") || getMarkerColor(data.zavaznost) === selectedColor) &&
          (selectedRegion === 'všetky' || data.kraj === selectedRegion) &&
          isSkodaInRange(data.celkovaHmotnaSkoda) &&
          isDateInRange(data.datum) &&
          isTimeInRange(data.cas)
    );

  const heatmapData = mapData
  ? mapData.map((data) => ({
      position: [parseFloat(data.y), parseFloat(data.x)] as [number, number], 
      intensity: 1, 
    }))
  : [];

  const shuffledMapData = shuffleArray(filteredMapData).slice(0, 2000);

  const displayRegions = t("locale") === "sk" ? regionsSk : regionsEn;


  const translateDruhNehody = (value: string, locale: string): string => {
    const translations: PrekladDruhNehody = {
      sk: {
        TGraphs: {
          hodnotyGrafov: {
            "zrazkaChodec":"zrážka s chodcom",
            "zrazkaZviera":"zrážka s domácim zvieraťom",
            "zrazkaElektricka":"zrážka s električkou",
            "havaria":"havária",
            "zrazkaLesnaZver":"zrážka s lesnou zverou",
            "inyDruh":"iný druh nehody",
            "zrazkaNekolajoveVozidlo":"zrážka s idúcim nekoľajovým vozidlom",
            "zrazkaZaparkovaneVozidlo":"zrážka s vozidlom zaparkovaným, odstaveným",
            "zrazkaPevnaPrekazka":"zrážka s pevnou prekážkou",
            "zrazkaVlak":"zrážka s vlakom"
          }
        }
      }
    };
  
    const keys = Object.keys(translations[locale]?.TGraphs?.hodnotyGrafov || {});
    const matchingKey = keys.find(key => translations[locale]?.TGraphs?.hodnotyGrafov[key] === value);
  
    return matchingKey ? `TGraphs.hodnotyGrafov.${matchingKey}` : value;
  };


  const translatePricinaNehody = (value: string, locale: string): string => {
    const translations: PrekladPricinaNehody = {
      sk: {
        Map: {
          pricinaNehody: {
            "dajPrednost": "proti príkazu dopravnej značky DAJ PREDNOSŤ",
            "protiiducePrekazka": "protiidúcemu vozidlu pri obchádzaní prekážky",
            "protismer": "jazda po nesprávnej strane vozovky, vjazd do protismeru",
            "nevenovanieRiadeniu": "vodič sa plne nevenoval riadeniu vozidla",
            "nezvladnutieRiadenia": "nezvládnutie riadenia vozidla",
            "strataKolesa": "upadnutie, strata kolesa vozidla (aj rezervného)",
            "vietor": "neprispôsobenie rýchlosti bočnému, nárazovému vetru (aj pri míňaní, predbiehaní vozidiel)",
            "zaradovanie": "pri zaraďovaní do prúdu idúcich vozidiel zo stanice, miesta zastavenia alebo státia",
            "vzdialenost": "nedodržanie bezpečnej vzdialenosti za vozidlom",
            "nasilneZastavenie": "nehoda v dôsledku použitia (polícií) prostriedkov na násilné zastavenie vozidla (zastavovacie pásy, zábrana, vozidlo atp.)",
            "defekt": "defekt pneumatiky spôsobený prerazením alebo náhlym únikom vzduchu",
            "svetla": "porucha osvetľovacej sústavy vozidla (neúčinná, chýbajúca, znečistená a pod.)",
            "rychlost": "neprispôsobenie rýchlosti intenzite (hustote) prevádzky",
            "rychlostViditelnost": "neprispôsobenie rýchlosti viditeľnosti (hmla, súmrak, jazda v noci na tlmené svetlá a pod.)",
            "predbiehanie": "pri predbiehaní došlo k ohrozeniu protiidúceho vodiča vozidla (zlý odhad vzdialenosti potrebnej na predbehnutie a pod.)",
            "predbiehanieVlavo": "predbiehanie vľavo vozidla odbočujúceho vľavo",
            "predbiehanieZnacka": "predbiehanie v miestach, kde je to zakázané dopravnou značkou",
            "ciara": "pri predbiehaní bola prejdená pozdĺžna čiara súvislá",
            "blokovanie": "bránenie v predbiehaní",
            "nesprPredbiehanie": "iný druh nesprávneho predbiehania",
            "otacanie": "pri otáčaní alebo cúvaní",
            "cuvanie": "nesprávne otáčanie alebo cúvanie",
            "nahleBrzdenie": "náhle bezdôvodné zníženie rýchlosti jazdy, zabrzdenia alebo zastavenia",
            "zlaCesta": "vjazd na nespevnenú komunikáciu",
            "porucha": "porucha riadenia",
            "prives": "závada závesu pre príves",
            "nezavinena": "nezavinená vodičom ",
            "rychlostPrekroc": "prekročenie predpísanej rýchlosti stanovenej pravidlami",
            "neprimRychlost": "iný druh neprimeranej rýchlosti",
            "predbiehanieOhrozenie": "pri predbiehaní došlo k ohrozeniu predbiehaného vodiča vozidla (vynútené zaradenie, predbiehaný vodič musel prudko brzdiť, meniť smer jazdy a pod.)",
            "vyhybanie": "vyhýbanie bez dostatočného bočného odstupu (vôľa)",
            "utekVozidla": "samovoľné rozbehnutie nezaisteného vozidla",
            "jednosmerka": "jazda (vjatie) jednosmernou ulicou, cestou (v protismere)",
            "brzdy": "porucha prevádzkovej brzdy",
            "parkovaciaBrzda": "neúčinná alebo nefungujúca parkovacia brzda",
            "neprisRychlost": "neprispôsobenie rýchlosti vlastnostiam vozidla a nákladu",
            "prekrocenieRych": "prekročenie rýchlosti stanovenej dopravnou značkou",
            "predbiehanieBezRozhladu": "predbiehanie bez dostatočného rozhľadu (v neprehľadnej zákrute alebo jej blízkosti, pred vrcholom stúpania a pod.)",
            "prehliadnutieVozidla": "prehliadnutie už predchádzajúceho súbežne idúceho vozidla",
            "stojDajPrednost": "proti príkazu dopravnej značky STÔJ DAJ PREDNOSŤ",
            "odbocovanie": "pri odbočovaní vľavo",
            "elektricka": "električiek, ktoré odbočuje",
            "prechadzanie": "pri prechádzaní z jedného jazdného pruhu do druhého",
            "smerJazdy": "chyby pri udaní smeru jazdy",
            "zbran": "nehoda v dôsledku použitia služobnej zbrane (polícií)",
            "zakrok": "nehoda pri vykonávaní služobného zákroku (prenasledovanie páchateľa atď.)",
            "brzdiacaHadica": "nepripojená alebo poškodená spojovacia hadica na bzrdenie prípojného vozidla",
            "naklad": "nesprávne uloženie nákladu",
            "predbiehVpravo": "predbiehanie vpravo",
            "predbiehOdstup": "predbiehanie bez dostatočného bočného odstupu",
            "vozSprava": "vozidlu prichádzajúcemu sprava",
            "vchadzanie": "pri vchádzaní na ceste",
            "odbocovanieVlavo": "pri odbočovaní vľavo súbežne idúcemu vozidlu",
            "ine": "iný druh nesprávneho spôsobu jazdy",
            "plast": "opotrebenie behúňa plášťa pod stanovenú hranicu",
            "koleso": "zablokovanie kolies v dôsledku mechanickej závady vozidla (zadrený motor, prevodovka, rozvodovka, spadnutá reťaz a pod.)",
            "pruzina": "lom závesu kolesa, pružiny",
            "rychlostVozovka": "neprispôsobenie rýchlosti stavu vozovky (poľadovica, výtlky, blato, mokrý povrch a pod.)",
            "rychlostVozovkaDopTech": "neprispôsobenie rýchlosti dopravno-technickému stavu vozovky (zákruta, klesanie, stúpanie, šírka vozovky a pod.)",
            "cervena": "jazda na \"červenú\" 3-farebného semaforu",
            "chodci": "chodci na vyznačenom prechode",
            "inePrednosti": "iné nedanie prednosti",
            "agresivita": "bezohľadná, agresívna, neohľaduplná jazda",
            "hriadel": "odtrhnutý spojovací hriadeľ",
            "bocnica": "nezaistená alebo poškodená bočnica (aj pri prívese)",
            "inaZavada": "iná technická závada (vzťahuje sa aj na prípojné vozidlá)"
          }
        }
      }
    };
  
    const keys = Object.keys(translations[locale]?.Map?.pricinaNehody || {});
    const matchingKey = keys.find(key => translations[locale]?.Map?.pricinaNehody[key] === value);
  
    return matchingKey ? `Map.pricinaNehody.${matchingKey}` : value;
  };
  
  const heatmapOptions = {
    radius: 18,
    blur: 22,
    maxZoom: 18,
    minOpacity: 0.5,
    maxOpacity: 1
  };

  const toggleMap = () => {
    setShowHeatmap(!showHeatmap);
  };

  const handleToggleDN = () => {
    setToggleDN(!toggleDN)
  };

  const handleToggleDNMain = () => {
    if (!toggleDN) {
      setToggleDN(!toggleDN)
    }
  };

  return (
    <PageForm label={t("Map.nadpis")} image={image}>
    <div className="box-12">
      <div className='box-12 py-2 text-center'>
      <ToggleButtonGroup>
        <Button onClick={handleToggleDNMain} style={{margin: '0px'}}>{t("Search.nadpisNehody")}</Button>
        <Button className={toggleDN ? 'main-button-peds' : ``} onClick={handleToggleDN} style={{margin: '0px'}}>{t("Search.nadpisChodciOnly")}</Button>
      </ToggleButtonGroup>
      </div>
      {toggleDN && (<button
        onClick={handleShowFilters}
        style={{
          padding: '10px 15px',
          fontSize: '14px',
          borderRadius: '5px',
          backgroundColor: 'black',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        {showFilters ? <FaTimes style={{ marginRight: '8px' }} /> : <FaFilter style={{ marginRight: '8px' }} />}
        {showFilters ? t("Map.filtre") : t("Map.filtre")}
      </button>)}

      {toggleDN && showFilters && (
        <div>
          <div style={{ display: 'flex'}}>
        <strong style={{ marginTop: '12px', marginRight: '20px' }}>{t("Map.kraj")}</strong>

        <div style={{ marginRight: '40px' }}>
        {/* Combobox kraj ČR */}
          <select value={selectedRegion} onChange={(e) => setSelectedRegion(e.target.value)} style={{ margin: '0px 0 20px 0', width: '250px' }}>
            {displayRegions.map((region, index) => (
              <option key={index} value={regionsSk[index]}>
                {region}
              </option>
            ))}
          </select>
        </div>
        
        <strong style={{ marginTop: '12px', marginRight: '15px' }}>{t("Map.zavaznost")}</strong>

        <div>      
          {/* Combobox závažnosť nehody */}
          <select value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} style={{ margin: '0px 0 20px 0' }}>
            {[
              { label: t("Map.vsetky"), value: t("Map.vsetky") },
              { label: t("Map.usmrtene"), value: 'red' },
              { label: t("Map.tazkoZranene"), value: 'orange' },
              { label: t("Map.lahkoZranene"), value: 'yellow' },
              { label: t("Map.bezZranenia"), value: 'green' },
            ].map((severity) => (
              <option key={severity.value} value={severity.value}>
                {severity.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      
      <div style={{ display: 'flex', marginBottom: '15px' }}>
        {/* Polia pre zadanie výšky škody nehody */}
        <strong style={{ marginTop: '12px', marginRight: '20px' }}>{t("Map.skoda")}</strong>

        <div style={{ marginRight: '4px' }}>
        <input
            type="number"
            value={skodaOd !== undefined ? skodaOd : ''}
            onChange={handleSkodaOdChange}
            style={{ width: '160px' }}
          />
        </div>
        
        <strong style={{ marginTop: '12px', marginRight: '15px' }}>Kč</strong>

        <div style={{ marginRight: '4px' }}>
          <input
            type="number"
            value={skodaDo !== undefined ? skodaDo : ''}
            onChange={handleSkodaDoChange}
            style={{ width: '160px' }}
          />
        </div>

        <strong style={{ marginTop: '12px'}}>Kč</strong>
      </div>


      <div style={{ display: 'flex', marginBottom: '15px' }}>
        {/* Výber dátumu a času nehody */}
        <strong style={{ marginTop: '12px', marginRight: '20px'}}>{t("Map.nehodyOd")}</strong>

        <div style={{ marginRight: '15px' }}>
          <input type="date" value={startDate1} onChange={handleDateChange1} style={{ width: '160px' }} />
        </div>

        <div>
          <input type="time" value={startTime1} onChange={handleTimeChange1} style={{ width: '110px' }} />
        </div>
      </div>

      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <strong style={{ marginTop: '12px', marginRight: '20px'}}>{t("Map.nehodyDo")}</strong>

        <div style={{ marginRight: '15px' }}>
          <input type="date" value={startDate2} onChange={handleDateChange2} style={{ width: '160px' }} />
        </div>

        <div>
          <input type="time" value={startTime2} onChange={handleTimeChange2} style={{ width: '110px' }} />
        </div>
      </div>
        </div>
      )}

      {toggleDN && mapData === null ? (
        <p>{t("Map.nacitavanie")}</p>
      ) : toggleDN && mapData !== null && mapData.length > 0 ? (
        <MapContainer center={[49.8, 15.5]} zoom={7.5}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* <MarkerClusterGroup chunkedLoading > */}
            { shuffledMapData.map((data, index) => (
              <Marker key={index} position={[parseFloat(data.y), parseFloat(data.x)]}
              icon={new L.Icon({
                iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${getMarkerColor(data.zavaznost)}.png`,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
              })}
              >
                <Popup>
                <strong>{t("Map.nehodaDatum")}</strong>&nbsp; {data.datum || 'N/A'}  {data.cas || 'N/A'} <br/> 
                <strong>{t("Map.nehodaDruh")}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ t(translateDruhNehody(data.druhNehody, "sk")) || 'N/A'} <br/> 
                <strong>{t("Map.nehodaPricina")}</strong>&nbsp; { t(translatePricinaNehody(data.pricinaNehody, "sk")) || 'N/A'} <br/> <br/> 
                <strong>{t("Map.osobyUsmrt")}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ color: 'red' }}>{data.usmrteneOsoby || 'N/A'}</span> <br/> 
                <strong>{t("Map.osobyTazZran")}</strong>&nbsp; <span style={{ color: 'orange' }}>{data.tazkoZraneneOsoby || 'N/A'}</span> <br/> 
                <strong>{t("Map.osobyLahZran")}</strong>&nbsp; <span style={{ color: '#FFD700' }}>{data.lahkoZraneneOsoby || 'N/A'}</span> <br/> <br/>
                <strong>{t("Map.celkovaSkoda")}</strong>&nbsp; {data.celkovaHmotnaSkoda || 'N/A'} Kč
                </Popup>
              </Marker>
            ))}
          {/* </MarkerClusterGroup> */}
        </MapContainer>
      ) : toggleDN && (
        <p>{t("Map.noData")}</p>
      )}
      
      {toggleDN && 
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>         <h1 style={{ fontSize: '26px' }}>
          <strong>{t("Map.heatNadpis")}</strong>
        </h1>

        
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <FaMap name='pinIcon' size={24} style={{ marginRight: '10px', color: 'black', cursor: 'pointer' }} />
        <div className={`toggle-switch ${showHeatmap ? 'checked' : ''}`} onClick={toggleMap}>
          <div className="slider"></div>
        </div>
      </div>

      </div>}
      


      {toggleDN && mapData === null ? (
        <p>{t("Map.nacitavanie")}</p>
      ) : toggleDN && mapData !== null && mapData.length > 0 ? (
        showHeatmap ? (
        <MapContainer center={[49.8, 15.5]} zoom={7.5} scrollWheelZoom={true}>
          <HeatmapLayer
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={heatmapData.map(data => data.position)}
            longitudeExtractor={(point: any) => point[1]}
            latitudeExtractor={(point: any) => point[0]}
            key={Math.random() + Math.random()}
            intensityExtractor={(point: any) => {
              return Math.sqrt(parseFloat(point[0] + point[1])) * 0.02;
            }}
        
            {...heatmapOptions}
          />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
        ) : (
          <MapContainer center={[49.8, 15.5]} zoom={7.5} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {heatmapData.map((data, index) => (
            <Circle
              key={index}
              center={data.position}
              radius={data.intensity * 1000} 
              fillColor="red" 
              fillOpacity={0.5}
              stroke={false}
            />
          ))} 
         
        </MapContainer>
        )
      ) : toggleDN && (
        <p>{t("Map.noData")}</p>
      )}

      {!toggleDN && <TAPMap />}
    </div>
    </PageForm>
  );
}