import { CircleButton } from '../StyleComponents/StyleComponents';
import { HiDotsHorizontal } from 'react-icons/hi';
import Menu from '../Menu/Menu';
import { useState } from 'react';
import './Top.css';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { Privileges } from '../../interfaces/Privileges';
import Requests from '../../services/Requests';

export default function Top() {

  const [isShowingMenu, setShowMenu]: [boolean, Function] = useState(false);
  const [cookies, , removeCookies] = useCookies();

  useEffect(() => {
    if (cookies['SID']) {
      Requests.post("/user/autologin", {})
        .then((resp: any) => {
          if (resp.data.success) {
            let priv = new Privileges(
              resp.data.user.privileges_trafic === '1',
              resp.data.user.privileges_crimes === '1',
              resp.data.user.privileges_manager === '1',
              resp.data.user.privileges_admin === '1');

            resp.data.user.privileges = priv;
            //userService.setUser(resp.data.user);
          } else {
            removeCookies("SID");
          }
        })
        .catch(() => { });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showMenu = (): void => {
    setShowMenu(!isShowingMenu);
  }
 
  return (
    <div className="top box-12 px-4 py-3">
      
      <div className={'box-12 text-right lg-none'}>
        <CircleButton onClick={showMenu}><HiDotsHorizontal /></CircleButton>
      </div>
      <div className={`box-10 box-sm-6 box-md-4 lg-none small-menu ${isShowingMenu ? 'show-menu' : ''}`}>
        <Menu method={showMenu} show={isShowingMenu}/>
      </div>
    </div>
  );    
}


