//import css from './Search.module.css';
import { filterType, SearchFilter } from '../../interfaces/SearchFilter';
import CustomModal from '../Modal/CustomModal';
import Flatpickr from "react-flatpickr";
import { Slovak } from "flatpickr/dist/l10n/sk.js";
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import css from './Search.module.css';
import { useMemo, useState } from 'react';
import { FaCross, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { Button } from '../StyleComponents/StyleComponents';
import { useEffect } from 'react';
import { SearchService } from '../../interfaces/SearchService';
import { useCallback } from 'react';
import { ExpressionValue } from '../Expressions/Expressions';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
    show: boolean;
    name: string;
    filter?: SearchFilter;
    allFilters: SearchFilter[];
    customExpressions?: ExpressionValue[];
    onClose: () => void;
    onConfirm: (data: any) => void;
    endpointService: SearchService;
}

export default function STableFilter(props: IProps) {
    const { t } = useTranslation("crimes");

    const optionsAndOr = useMemo(() => [{ value: "AND", label: t("analyzis.AND") }, { value: "OR", label: t("analyzis.OR")}], [t]);
    const dateOptions = useMemo(() => [
        { value: "DATE_BETWEEN", label: t("analyzis.DATE_BETWEEN") },
        { value: "NOT_DATE_BETWEEN", label: t("analyzis.NOT_DATE_BETWEEN") },
        { value: "EXACT_DATE", label: t("analyzis.EXACT_DATE") },
        { value: "NOT_EXACT_DATE", label: t("analyzis.NOT_EXACT_DATE") },
        { value: "EMPTY", label: t("analyzis.EMPTY") },
        { value: "NOT_EMPTY", label: t("analyzis.NOT_EMPTY") },
    ], [t]);
    const textOptions = useMemo(() => [
        { value: "CONTAINS", label: t("analyzis.CONTAINS") },
        { value: "NOT_CONTAINS", label: t("analyzis.NOT_CONTAINS") },
        { value: "EXACT_TEXT", label: t("analyzis.EXACT_TEXT") },
        { value: "NOT_EXACT_TEXT", label: t("analyzis.NOT_EXACT_TEXT") },
        { value: "EMPTY", label: t("analyzis.EMPTY") },
        { value: "NOT_EMPTY", label: t("analyzis.NOT_EMPTY") },
    ], [t]);
    const customOptions = useMemo(() => [
        { value: "VALUE_BETWEEN", label: t("analyzis.VALUE_BETWEEN") },
        { value: "NOT_VALUE_BETWEEN", label: t("analyzis.NOT_VALUE_BETWEEN") },
        { value: "EXACT_VALUE", label: t("analyzis.EXACT_VALUE") },
        { value: "NOT_EXACT_VALUE", label: t("analyzis.NOT_EXACT_VALUE") }
    ], [t]);

    const [ mainType, setMainType ] = useState<{ value: string, label: string }>(optionsAndOr[0]);
    const [ config, setConfig ] = useState<any>([{
        type: optionsAndOr[0],
        atribute: props.filter ? { value: props.filter.atr, label: props.filter.name } : { value: '', label: '' },
        option: props.filter?.type === filterType.DATE ? dateOptions[0] : textOptions[0] || { value: '', label: '' },
        value: [ ]
    }]);

    const [ values , setValues ] = useState<any>([]);
    const [ valuesTimer, setValuesTimer ] = useState<any>();

    useEffect(() => {
        if (props.show) {
            setConfig([{
                type: optionsAndOr[0],
                atribute: props.filter ? { value: props.filter.atr, label: props.filter.name } : { value: '', label: '' },
                option: (props.filter?.type === filterType.DATE ? dateOptions[0] : props.filter?.type === filterType.TEXT ? textOptions[0] : customOptions[0]) || { value: '', label: '' },
                value: [ ]
            }]);
        }
    }, [ props.show, props.filter, props.endpointService, optionsAndOr, dateOptions, textOptions, customOptions ]);

    const isType = useCallback((atribute: string): string => {
        let result = "TEXT";
        let found = false;
        props.allFilters.forEach((item) => {
            if (item.atr === atribute) {
                result = item.type === filterType.DATE ? "DATE" : "TEXT";
                found = true;
            }
        });
        if (!found) return "CUSTOM";
        return result;
    }, [props.allFilters]);

    /* useEffect(() => {
        config.forEach((cnf: any) => {
            if (!isDateType(cnf.atribute.value)) {
                if (!UniqueValuesStore.getValues()) {
                    props.endpointService.getFilterData(props.filter.atr)
                    .then((resp) => {
                        setValues(resp.data.data);
                    })
                    .catch(() => {
                        setValues([]);
                    });
                }
            }
        });
    }, [props.show, config, isDateType]) */

    const saveFilter = () => {
        let tempConfig: any = [];
        config.forEach((cnf: any) => {
            if (cnf.value.length > 0 || cnf.option.value === 'EMPTY' || cnf.option.value === 'NOT_EMPTY') {
                if (cnf.option.value === "DATE_BETWEEN" || cnf.option.value === "NOT_DATE_BETWEEN") {
                    if (cnf.value.length > 1) {
                        tempConfig.push(cnf);
                    }
                } else {
                    tempConfig.push(cnf);
                }
            }
        });
        setConfig(tempConfig);
        console.log(mainType, config);
        props.onConfirm({ type: mainType, value: tempConfig });
    }

    const clearFilter = () => {
        setConfig([{
            type: optionsAndOr[0],
            atribute: props.filter ? { value: props.filter.atr, label: props.filter.name } : { value: '', label: '' },
            option: props.filter?.type === filterType.DATE ? dateOptions[0] : textOptions[0] || { value: '', label: '' },
            value: [ "" ]
        }]);
    }

    const addFilter = () => {
        setConfig((old: any) => [...old, {
            type: optionsAndOr[0],
            atribute: props.filter ? { value: props.filter.atr, label: props.filter.name } : { value: '', label: '' },
            option: props.filter?.type === filterType.DATE ? dateOptions[0] : textOptions[0] || { value: '', label: '' },
            value: [ ]
            }
        ]);
    }

    const removeFilter = (idx: number) => {
        setConfig((old: any) => {
            let temp = [...old];
            temp.splice(idx, 1);
            return temp;
        });
    }

    const dateChangeHandler = (out: any, idx: number, aridx: number) => {
        setConfig((old: any) => {
            let temp = [...old];
            temp[idx].value[aridx] = out[0];
            return temp;
        });
    }

    const inputChangeHandler = (out: any, idx: number, aridx: number) => {
        setConfig((old: any) => {
            let temp = [...old];
            temp[idx].value[aridx] = out.target.value;
            return temp;
        });
    }

    const valueChangeHandler = (out: any, idx: number) => {
        setConfig((old: any) => {
            let temp = [...old];
            temp[idx].value = [out];
            return temp;
        });
    }

    const typeChangeHandler = (value: any, meta: any) => {
        setConfig((old: any) => {
            old[Number(meta.name)].type = value;
            return [...old];
        });
    }

    const atributeChangeHandler = (value: any, meta: any) => {
        setConfig((old: any) => {
            old[Number(meta.name)].atribute = value;
            old[Number(meta.name)].option = isType(value.value) === "TEXT" ? textOptions[0] : isType(value.value) === "DATE" ? dateOptions[0] : customOptions[0]
            return [...old];
        });
    }

    const optionChangeHandler = (value: any, meta: any) => {
        setConfig((old: any) => {
            old[Number(meta.name)].option = value;
            return [...old];
        });
    }

    const mainTypeChangeHandler = (value: any) => {
        setMainType(value);
    }

    const valueInputChangeHandler = (out: any, idx: number, clear: boolean) => {
        if (clear) {
            setValues([]);
        }
        clearTimeout(valuesTimer);
        let timer = setTimeout(() => {
            props.endpointService.getFilterData(config[idx].atribute.value, out)
            .then((resp: any) => {
                setValues(resp.data.map((item: string) => ({ label: item, value: item})));
            })
            .catch(() => {
                setValues([]);
            });
        }, 500);
        setValuesTimer(timer);
    }

    const selectStyle = {
        styles: { menuPortal: (base: any) => ({ ...base, zIndex: 9999 })},
        menuPortalTarget: document.body
    };

    const expressionOptions = props.customExpressions?.map((item) => ({ value: item.label, label: item.label })) || [];
    const filterOptions = [...props.allFilters.map((item) => ({ value: item.atr, label: item.name })), ...expressionOptions];

    const configView = config.map((item: any, idx: number) => 
        <div key={idx} className={css.filterRow}>
            <div className='box-12'>
                Filter č. { idx + 1 }
            </div>
            <div className='box-5'>
                <Select 
                    name={idx.toString()}
                    value={item.type}
                    options={optionsAndOr}
                    {...selectStyle}
                    onChange={typeChangeHandler}
                    isDisabled={idx === 0}
                />
            </div>
            <div className='box-5 offset-1'>
                <Select 
                    name={idx.toString()}
                    value={item.atribute}
                    options={filterOptions}
                    {...selectStyle}
                    onChange={atributeChangeHandler}
                />
            </div>
            <div className='box-1 text-right'>
                <div className={css.close} onClick={() => removeFilter(idx)}>
                    <FaTrashAlt />
                </div>
            </div>
            <div className='box-5'>
                <Select 
                    name={idx.toString()}
                    value={item.option}
                    options={isType(item.atribute.value) === "DATE" ? dateOptions : isType(item.atribute.value) === "TEXT" ? textOptions : customOptions }
                    {...selectStyle}
                    onChange={optionChangeHandler}
                />
            </div>
            <div className='box-6 offset-1'>
                { isType(item.atribute.value) === "CUSTOM" && item.option.value !== 'EMPTY' && item.option.value !== 'NOT_EMPTY' &&
                    <>
                        <TextField
                            label={t('analyzis.ENTER_VALUE')}
                            placeholder={t('analyzis.ENTER_VALUE') || ""}
                            value={item.value[0] || ""}
                            onChange={(val) => inputChangeHandler(val, idx, 0)}
                        />
                        { (item.option.value === 'VALUE_BETWEEN' || item.option.value === 'NOT_VALUE_BETWEEN') &&
                            <TextField
                                label={t('analyzis.ENTER_VALUE')}
                                placeholder={t('analyzis.ENTER_VALUE') || ""}
                                value={item.value[1] || ""}
                                onChange={(val) => inputChangeHandler(val, idx, 1)}
                            />
                        }
                    </>
                }
                { isType(item.atribute.value) === "DATE" && item.option.value !== 'EMPTY' && item.option.value !== 'NOT_EMPTY' &&
                    <>
                        <Flatpickr
                            options={{
                                locale: Slovak,
                                dateFormat: "j. F Y"
                            }}
                            placeholder={t('analyzis.CHOOSEDATE') || ""}
                            value={item.value[0]}
                            onChange={(val) => dateChangeHandler(val, idx, 0)}
                        />
                        { (item.option.value === 'DATE_BETWEEN' || item.option.value === 'NOT_DATE_BETWEEN') &&
                            <Flatpickr
                                options={{
                                    locale: Slovak,
                                    dateFormat: "j. F Y"
                                }}
                                placeholder={t('analyzis.CHOOSEDATE') || ""}
                                value={item.value[1]}
                                onChange={(val) => dateChangeHandler(val, idx, 1)}
                            />
                        }
                    </>
                }
                { isType(item.atribute.value) === "TEXT" && item.option.value !== 'EMPTY' && item.option.value !== 'NOT_EMPTY' &&
                    <>
                        <Creatable
                            name={idx.toString()}
                            value={item.value}
                            options={values}
                            formatCreateLabel={(inp) => `Hľadať "${inp}"...`}
                            createOptionPosition='first'
                            {...selectStyle}
                            onChange={(val) => valueChangeHandler(val, idx)}
                            onInputChange={(val) => valueInputChangeHandler(val, idx, false)}
                            onMenuOpen={() => valueInputChangeHandler("", idx, true)}
                        />
                    </>
                }
            </div>
        </div>
    );

    return (
    <CustomModal
        show={props.show}
        title={props.name}
        width="500px"
        onClose={props.onClose}
    >
        <div className='box-6 offset-3 mt-2 text-center'>
            <Select
                value={mainType}
                options={optionsAndOr}
                {...selectStyle}
                onChange={mainTypeChangeHandler}
            />
        </div>
        { configView }
        <div className='box-12 mt-2 text-center'>
            <Button onClick={addFilter}><FaPlus />{t('analyzis.ADDNEW')}</Button>
        </div>
        <div className='box-12 mt-2'>
            <Button onClick={clearFilter}><FaPlus style = {{transform: 'rotate(45deg)' }} />{t('analyzis.DELETE')}</Button>
            <Button className='pull-right' onClick={saveFilter}><FaPlus />{t('analyzis.USE')}</Button>
        </div>
    </CustomModal>
    )
}