import css from './Search.module.css';
import { Button } from '../StyleComponents/StyleComponents';
import { AiOutlineCaretRight, AiOutlineCaretLeft } from 'react-icons/ai';

interface IProps {
    page: number;
    perPage: number;
    maxPage: number;
    onPerPageChange: (perPage: number) => void;
    onPageChange: (page: number) => void;
}

export default function STablePaging(props: IProps) {

    const options = [3, 5, 10, 20];

    const prev = (): void => {
        if (props.page <= 1) return;
        props.onPageChange(props.page - 1);
    }

    const next = (): void => {
        if (props.page >= props.maxPage) return;
        props.onPageChange(props.page - (-1));
    }

    const goTo = (newPage: number): void => {
        if (newPage > props.maxPage) return;
        props.onPageChange(newPage);
    }

    return (
        <div className={`box-12 text-right ${css.controll}`}>
            { props.page > 2 &&
            <Button onClick={() => goTo(1)}>1</Button> }
            { props.page > 2 &&
            <span className={css.delim}>...</span> }
            { props.page === props.maxPage && props.page > 2 &&
            <Button onClick={() => prev()}>{ props.page - 2 }</Button> }
            { props.page > 1 &&
            <Button onClick={() => prev()}>{ props.page - 1 }</Button> }
            <Button style={{ backgroundColor: "#555" }}>{ props.page }</Button>
            { props.page < props.maxPage &&
            <Button onClick={() => next()}>{ props.page - (-1) }</Button> }
            { props.page < 2 && props.page + 1 < props.maxPage &&
            <Button onClick={() => goTo(props.page ? props.page - (-2) : 0)}>{ props.page - (-2) }</Button> }
            { props.page < props.maxPage - 1 &&
            <span className={css.delim}>...</span> }
            { props.page < props.maxPage -1 &&
            <Button onClick={() => goTo(props.maxPage)}>{ props.maxPage }</Button> }
            <Button className={css.arrows} onClick={() => prev()}><AiOutlineCaretLeft /></Button>
            <Button onClick={() => next()}><AiOutlineCaretRight /></Button>
            <select value={ props.perPage } onChange={(e) => props.onPerPageChange(Number(e.target.value))}>
                { options.map((val) => <option key={val} value={val}>{val}</option>)}
            </select>
        </div>
    );
}