import axios from 'axios';
import { ChangeEvent, useState } from 'react';
import { MdOutlineInfo } from 'react-icons/md';
import image from '../../../../assets/css/images/nahlasene.jpg';
import HeaderCarousel from '../../../../components/HeaderCarousel/HeaderCarousel';
import { ADD_PARAM, CONVICTED_OFFENCES, CONVICTED_OFFENCES_COUNT, CRIMINAL_OFFENCE_NAME, CRIMINAL_OFFENCE_REMARKS_NAME, DRUG_NAME, IS_FIREARM_NAME, LEGAL_LIMIT_OF_DRUGS_NAME, POLICE_STAFF_COUNT, POLICE_STAFF_TYPE, POLICE_TYPE, REPORTED_CRIMINAL_OFFENCES_NAME, SLASH, STATE_NAME, STATE_POPULATION_NAME, STATE_RULEBOOK_NAME, SUBCATEGORY_OF_CRIMINAL_OFFENCE_NAME, SUBSECTION_OF_CRIMINAL_OFFENCE_NAME, TYPE_OF_FIREARM_NAME } from '../../../../interfaces/europe/Constants';
import { Result } from '../../../../interfaces/europe/interfaces';
import './AdministrationEurope.css';

export default function AdministrationEurope() {

  let isUploading = false;

  //RESTS
  const [result, setResult] = useState<Result>();
  const [file, setFile] = useState<File>();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = async (type: string) => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(process.env.REACT_APP_API_URL + SLASH + type + ADD_PARAM, formData)
      .then((response) => {
        setResult(response.data);
        console.log(response);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log(error);
        } else {
          console.log(error);
        }
      });

    isUploading = true;

  };

  const clearFile = () => {
    if (file !== undefined) {
      setFile(undefined);
    }
    if (result !== undefined) {
      setResult(undefined);
    }
  };


  //ALERT
  const alert = (result !== undefined) ? ((result.allRows === result.insertedRows) ? (<div className="mt-1 alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success!</strong> You loaded all {result.insertedRows} records.
    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>) : (<div className="mt-1 alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Warning!</strong> You loaded {result.insertedRows} from {result.allRows} records.
    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>)) : <div></div>;

  let spinner = <div></div>;

  return (
    <div>

      <HeaderCarousel name="Upload Files" image={image} />

      <div className="accordion" id="accordionExample">
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapseOne">
                Upload Criminal Offences
              </button>
            </h2>
          </div>
          <div id="collapse1" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: criminal_offence_code; name; definition</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(CRIMINAL_OFFENCE_NAME)}> Save </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                Upload Criminal Offence Remarks
              </button>
            </h2>
          </div>
          <div id="collapse2" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: criminal_offence_code; state_id; definition</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(CRIMINAL_OFFENCE_REMARKS_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                Upload Drugs
              </button>
            </h2>
          </div>
          <div id="collapse3" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: Id; name</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(DRUG_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapseTwo">
                Upload Is Firearm
              </button>
            </h2>
          </div>
          <div id="collapse4" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: state_id; firearm_id; is_allowed</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(IS_FIREARM_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapseThree">
                Upload Legal Limit Of Drugs
              </button>
            </h2>
          </div>
          <div id="collapse5" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: state_id; drug_id; allowed_limit</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(LEGAL_LIMIT_OF_DRUGS_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapseThree">
                Upload Reported Criminal Offences
              </button>
            </h2>
          </div>
          <div id="collapse6" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: state_id; criminal_offence_code; year; count</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(REPORTED_CRIMINAL_OFFENCES_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapseThree">
                Upload State
              </button>
            </h2>
          </div>
          <div id="collapse7" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: id; code; name</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(STATE_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapseThree">
                Upload State Population
              </button>
            </h2>
          </div>
          <div id="collapse8" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: state_id; year; population</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(STATE_POPULATION_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapseThree">
                Upload State Rulebook
              </button>
            </h2>
          </div>
          <div id="collapse9" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: state_id; age_of_consent; bac</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(STATE_RULEBOOK_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapseThree">
                Upload Subcategory Of Criminal Offence
              </button>
            </h2>
          </div>
          <div id="collapse10" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: criminal_offence_code; code; name</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(SUBCATEGORY_OF_CRIMINAL_OFFENCE_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapseThree">
                Upload Subsection OfCriminal Offence
              </button>
            </h2>
          </div>
          <div id="collapse11" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: comming soon</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(SUBSECTION_OF_CRIMINAL_OFFENCE_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapseThree">
                Upload Type Of Firearm
              </button>
            </h2>
          </div>
          <div id="collapse12" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: id; name</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(TYPE_OF_FIREARM_NAME)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapseThree">
                Upload Convicted Offences
              </button>
            </h2>
          </div>
          <div id="collapse13" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: code; state_id; name; is_included</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(CONVICTED_OFFENCES)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapseThree">
                Upload Convicted Offences Count
              </button>
            </h2>
          </div>
          <div id="collapse14" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: year; criminal_offence_code; state_id; count</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(CONVICTED_OFFENCES_COUNT)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse15" aria-expanded="true" aria-controls="collapseThree">
                Upload Police Staff Type
              </button>
            </h2>
          </div>
          <div id="collapse15" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: id; name; description</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(POLICE_STAFF_TYPE)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse16" aria-expanded="true" aria-controls="collapseThree">
                Upload Police Staff Count
              </button>
            </h2>
          </div>
          <div id="collapse16" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: year; police_saff_id; state_id; count</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(POLICE_STAFF_COUNT)}>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-left mb-5" >
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button className="btn text-left" onClick={() => clearFile()} type="button" data-toggle="collapse" data-target="#collapse17" aria-expanded="true" aria-controls="collapseThree">
                Upload Police Type
              </button>
            </h2>
          </div>
          <div id="collapse17" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div className="card-body">
              {alert}
              {spinner}
              <div className='uploadText'><span className='uploadIcon'><MdOutlineInfo /></span>Header: code; state_id; police_saff_id; name; is_included</div>
              <label className='uploadLabel' htmlFor="csvFileInput">
                {file == null ? 'Upload' : (file && `${file.name}`)}
              </label>
              <input type={"file"} className={"upload"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleFileChange} style={{ visibility: "hidden" }} />
              <div>
                <div className='uploadButton' onClick={() => handleUploadClick(POLICE_TYPE)}>Save</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}