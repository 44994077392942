import axios, {AxiosResponse} from "axios";
import GraphData from "../interfaces/Graphs/GraphData";

const ResetGraph = (graphData: GraphData[]) => {
    graphData.forEach(item => item.Data = 0);
}

const GraphService_DN = () : any => {
    return {
        FetchDataFrom_API : (data_url : string) : Promise<AxiosResponse<any, any>> => {
            return axios.get(process.env.REACT_APP_API_URL_DN + data_url);
        },
        AssignDataArray : (data : any[], graphData: GraphData[]) : GraphData[] => {
            ResetGraph(graphData);
            data.forEach((el : any) => {
                Object.entries(el.stats).forEach(([key, value]) => {
                    let item : any = graphData.find(item => item.Code === key);
                    item.Data += value;
                });
            });
            return graphData;
        },
        AssignData : (data : any, graphData: GraphData[]) : GraphData[] => {
            ResetGraph(graphData);
            Object.entries(data.stats).forEach(([key, value]) => {
                let item : any = graphData.find(item => item.Code === key);
                item.Data += value;
            });
            return graphData;
        }
    }
};


export default GraphService_DN();