
const criticalValues: Array<number> = [
0,
3.84145882069412, 
5.99146454710798, 
7.81472790325118, 
9.48772903678116, 
11.0704976935164, 
12.591587243744, 
14.0671404493402, 
15.5073130558655, 
16.9189776046205, 
18.3070380532751, 
19.6751375726825, 
21.0260698174831, 
22.3620324948269, 
23.6847913048406, 
24.9957901397286, 
26.2962276048642, 
27.5871116382753, 
28.8692994303926, 
30.1435272056462, 
31.4104328442309, 
32.6705733409173, 
33.9244384714438, 
35.1724616269081, 
36.4150285018073, 
37.6524841334828, 
38.88513865983, 
40.1132720694136, 
41.3371381514274, 
42.5569678042927, 
43.7729718257422, 
44.9853432803651, 
46.1942595202785, 
47.3998839190809, 
48.6023673672942, 
49.8018495682019, 
50.9984601657106, 
52.1923197301029, 
53.3835406229693, 
54.5722277589417, 
55.758479278887, 
56.9423871468241, 
58.124037680868, 
59.3035120268998, 
60.4808865823365, 
61.6562333762796, 
62.8296204114082, 
64.001111972218, 
65.1707689035698, 
66.3386488629688, 
67.5048065495412, 
68.6692939122858, 
69.8321603398481, 
70.9934528337823, 
72.1532161670231, 
73.3114930290833, 
74.4683241593094, 
75.6237484693761, 
76.7778031560615, 
77.9305238052304, 
79.0819444878487, 
80.2320978487627, 
81.3810151888991, 
82.5287265414718, 
83.675260742721, 
84.8206454976567, 
85.9649074412309, 
87.1080721953219, 
88.2501644218741, 
89.391207872508, 
90.5312254348807, 
91.6702391760548, 
92.8082703831077, 
93.9453396011922, 
95.0814666692433, 
96.2166707535038, 
97.350970379033, 
98.4843834593404, 
99.6169273242838, 
100.74861874635, 
101.879473965436, 
103.009508712226, 
104.138738230274, 
105.26717729686, 
106.394840242723, 
107.521740970719, 
108.647892973508, 
109.773309350288, 
110.898002822684, 
112.021985749808, 
113.145270142555, 
114.267867677194, 
115.389789708267, 
116.511047280874, 
117.631651142346, 
118.751611753367, 
119.870939298567, 
120.98964369661, 
122.107734609819, 
123.225221453362, 
124.342113404004, 
125.458419408482, 
126.574148191494, 
127.689308263338, 
128.803907927218, 
129.917955286229, 
131.031458250049, 
132.144424541337, 
133.256861701868, 
134.368777098411, 
135.48017792836, 
136.591071225135, 
137.701463863371, 
138.811362563885, 
139.920773898456, 
141.02970429441, 
142.138160039026, 
143.246147283775, 
144.353672048385, 
145.460740224765, 
146.567357580767, 
147.673529763818, 
148.779262304405, 
149.884560619441, 
150.989430015505, 
152.093875691958, 
153.197902743956, 
154.30151616535, 
155.404720851482, 
156.507521601885, 
157.609923122889, 
158.711930030134, 
159.813546850998, 
160.914778026943, 
162.015627915781, 
163.11610079386, 
164.216200858185, 
165.315932228459, 
166.415298949064, 
167.514304990978, 
168.61295425362, 
169.711250566649, 
170.809197691695, 
171.90679932404, 
173.004059094245, 
174.100980569726, 
175.197567256281, 
176.29382259957, 
177.389749986549, 
178.485352746859, 
179.580634154181, 
180.675597427534, 
181.770245732555, 
182.864582182723, 
183.958609840556, 
185.052331718773, 
186.145750781417, 
187.238869944954, 
188.331692079329, 
189.424220009004, 
190.516456513959, 
191.608404330663, 
192.700066153028, 
193.791444633324, 
194.88254238308, 
195.973361973949, 
197.063905938561, 
198.154176771341, 
199.244176929317, 
200.333908832898, 
201.42337486663, 
202.512577379941, 
203.601518687858, 
204.69020107171, 
205.778626779806, 
206.866798028108, 
207.95471700087, 
209.042385851279, 
210.12980670206, 
211.216981646085, 
212.303912746952, 
213.390602039558, 
214.477051530651, 
215.563263199378, 
216.649238997807, 
217.734980851449, 
218.820490659756, 
219.905770296615, 
220.990821610828, 
222.075646426575, 
223.160246543877, 
224.244623739036, 
225.328779765074, 
226.412716352154, 
227.496435208002, 
228.579938018305, 
229.663226447109, 
230.74630213721, 
231.829166710528, 
232.911821768476, 
233.994268892325, 
235.076509643554, 
236.158545564194, 
237.24037817717, 
238.322008986627, 
239.403439478252, 
240.484671119593, 
241.565705360364, 
242.646543632752, 
243.727187351706, 
244.807637915228, 
245.887896704661, 
246.967965084957, 
248.047844404956, 
249.127535997645, 
250.20704118042, 
251.286361255342, 
252.365497509384, 
253.444451214672, 
254.523223628728, 
255.601815994703, 
256.680229541603, 
257.758465484515, 
258.836525024829, 
259.914409350448, 
260.992119636005, 
262.069657043065, 
263.147022720329, 
264.224217803834, 
265.301243417145, 
266.378100671549, 
267.45479066624, 
268.531314488501, 
269.607673213888, 
270.683867906404, 
271.759899618669, 
272.835769392096, 
273.911478257054, 
274.987027233029, 
276.062417328789, 
277.137649542537, 
278.212724862069, 
279.287644264921, 
280.362408718521, 
281.437019180335, 
282.511476598006, 
283.585781909499, 
284.659936043236, 
285.733939918233, 
286.807794444232, 
287.881500521831, 
288.955059042613, 
290.028470889273, 
291.10173693574, 
292.174858047296, 
293.247835080701, 
294.320668884306, 
295.39336029817, 
296.465910154169, 
297.538319276114, 
298.610588479854, 
299.682718573386, 
300.754710356962, 
301.826564623188, 
302.898282157129, 
303.96986373641, 
305.041310131311, 
306.112622104867, 
307.18380041296, 
308.254845804417, 
309.325759021097, 
310.396540797983, 
311.467191863273, 
312.537712938465, 
313.608104738444, 
314.678367971565, 
315.748503339739, 
316.818511538513, 
317.888393257151, 
318.958149178711, 
320.027779980128, 
321.097286332285, 
322.166668900092, 
323.235928342558, 
324.305065312867, 
325.374080458446, 
326.44297442104, 
327.511747836777, 
328.58040133624, 
329.648935544535, 
330.717351081352, 
331.785648561038, 
332.853828592656, 
333.921891780048, 
334.989838721903, 
336.057670011813, 
337.125386238334, 
338.192987985048, 
339.260475830621, 
340.32785034886, 
341.395112108769, 
342.462261674608, 
343.529299605946, 
344.596226457715, 
345.663042780266, 
346.72974911942, 
347.796346016519, 
348.862834008481, 
349.929213627845, 
350.995485402826, 
352.061649857363, 
353.127707511163, 
354.193658879755, 
355.259504474532, 
356.325244802799, 
357.390880367821, 
358.456411668862, 
359.521839201237, 
360.587163456347, 
361.652384921732, 
362.717504081103, 
363.782521414394, 
364.847437397793, 
365.912252503793, 
366.976967201223, 
368.041581955295, 
369.106097227638, 
370.170513476337, 
371.234831155975, 
372.299050717665, 
373.36317260909, 
374.427197274541, 
375.491125154948, 
376.554956687919, 
377.618692307775, 
378.682332445584, 
379.745877529193, 
380.809327983263, 
381.872684229305, 
382.935946685708, 
383.999115767773, 
385.062191887747, 
386.125175454849, 
387.188066875309, 
388.25086655239, 
389.313574886424, 
390.37619227484, 
391.438719112193, 
392.501155790192, 
393.563502697733, 
394.625760220922, 
395.687928743105, 
396.750008644897, 
397.812000304206, 
398.873904096264, 
399.935720393649, 
400.997449566315, 
402.059091981615, 
403.120648004329, 
404.182117996686, 
405.243502318393, 
406.304801326655, 
407.366015376204, 
408.427144819318, 
409.488190005848, 
410.549151283242, 
411.610028996565, 
412.670823488524, 
413.73153509949, 
414.792164167519, 
415.852711028376, 
416.913176015557, 
417.973559460307, 
419.033861691644, 
420.09408303638, 
421.154223819141, 
422.214284362386, 
423.27426498643, 
424.334166009463, 
425.393987747567, 
426.45373051474, 
427.51339462291, 
428.57298038196, 
429.632488099742, 
430.691918082097, 
431.751270632875, 
432.810546053949, 
433.869744645239, 
434.928866704723, 
435.987912528461, 
437.046882410607, 
438.105776643427, 
439.164595517321, 
440.223339320832, 
441.282008340668, 
442.340602861718, 
443.399123167063, 
444.457569537999, 
445.515942254048, 
446.574241592977, 
447.632467830808, 
448.690621241841, 
449.748702098661, 
450.80671067216, 
451.864647231545, 
452.92251204436, 
453.980305376493, 
455.038027492196, 
456.095678654097, 
457.153259123211, 
458.21076915896, 
459.268209019181, 
460.325578960142, 
461.382879236557, 
462.440110101594, 
463.497271806893, 
464.554364602578, 
465.611388737267, 
466.668344458089, 
467.725232010691, 
468.782051639257, 
469.838803586514, 
470.895488093747, 
471.952105400812, 
473.008655746145, 
474.065139366778, 
475.121556498345, 
476.177907375098, 
477.234192229915, 
478.290411294315, 
479.346564798466, 
480.402652971197, 
481.458676040009, 
482.514634231087, 
483.570527769307, 
484.62635687825, 
485.682121780211, 
486.737822696211, 
487.793459846004, 
488.84903344809, 
489.904543719725, 
490.959990876927, 
492.015375134491, 
493.070696705996, 
494.125955803814, 
495.181152639123, 
496.236287421911, 
497.29136036099, 
498.346371664004, 
499.401321537435, 
500.456210186618, 
501.511037815744, 
502.565804627873, 
503.620510824941, 
504.675156607768, 
505.729742176069, 
506.784267728461, 
507.838733462469, 
508.893139574541, 
509.947486260048, 
511.001773713299, 
512.056002127546, 
513.110171694991, 
514.164282606797, 
515.218335053093, 
516.272329222985, 
517.326265304559, 
518.380143484894, 
519.433963950064, 
520.487726885153, 
521.541432474253, 
522.59508090048, 
523.648672345975, 
524.702206991915, 
525.755685018519, 
526.809106605055, 
527.862471929845, 
528.915781170277, 
529.969034502808, 
531.022232102969, 
532.075374145378, 
533.128460803742, 
534.181492250862, 
535.234468658647, 
536.287390198111, 
537.340257039387, 
538.393069351731, 
539.445827303526, 
540.498531062292, 
541.551180794688, 
542.603776666523, 
543.656318842758, 
544.708807487515, 
545.761242764079, 
546.813624834911, 
547.865953861645, 
548.918230005101, 
549.970453425286, 
551.022624281404, 
552.074742731858, 
553.126808934257, 
554.178823045421, 
555.23078522139, 
556.282695617422, 
557.334554388006, 
558.386361686865, 
559.438117666958, 
560.489822480489, 
561.541476278913, 
562.593079212935, 
563.644631432524, 
564.696133086912, 
565.747584324598, 
566.798985293358, 
567.850336140249, 
568.901637011609, 
569.952888053067, 
571.004089409547, 
572.055241225269, 
573.106343643759, 
574.157396807852, 
575.208400859693, 
576.259355940747, 
577.310262191801, 
578.361119752967, 
579.41192876369, 
580.46268936275, 
581.513401688267, 
582.564065877706, 
583.61468206788, 
584.665250394956, 
585.715770994457, 
586.76624400127, 
587.816669549645, 
588.867047773204, 
589.917378804943, 
590.967662777236, 
592.017899821838, 
593.068090069892, 
594.118233651931, 
595.168330697883, 
596.218381337071, 
597.268385698224, 
598.318343909475, 
599.368256098367, 
600.418122391857, 
601.467942916319, 
602.517717797548, 
603.567447160765, 
604.617131130617, 
605.666769831186, 
606.716363385987, 
607.765911917977, 
608.815415549554, 
609.864874402563, 
610.9142885983, 
611.963658257511, 
613.012983500403, 
614.062264446641, 
615.111501215354, 
616.160693925137, 
617.209842694058, 
618.258947639656, 
619.308008878948, 
620.357026528431, 
621.406000704085, 
622.454931521377, 
623.503819095265, 
624.552663540197, 
625.60146497012, 
626.650223498478, 
627.698939238218, 
628.747612301793, 
629.796242801164, 
630.844830847802, 
631.893376552693, 
632.941880026341, 
633.99034137877, 
635.038760719526, 
636.087138157683, 
637.135473801841, 
638.183767760134, 
639.23202014023, 
640.280231049334, 
641.32840059419, 
642.376528881088, 
643.424616015861, 
644.47266210389, 
645.520667250109, 
646.568631559003, 
647.616555134617, 
648.664438080552, 
649.712280499971, 
650.760082495602, 
651.807844169739, 
652.855565624246, 
653.903246960558, 
654.950888279686, 
655.998489682215, 
657.046051268312, 
658.093573137725, 
659.141055389785, 
660.18849812341, 
661.23590143711, 
662.283265428982, 
663.330590196719, 
664.377875837611, 
665.425122448544, 
666.472330126008, 
667.519498966093, 
668.566629064496, 
669.613720516522, 
670.660773417084, 
671.70778786071, 
672.75476394154, 
673.80170175333, 
674.848601389458, 
675.89546294292, 
676.942286506336, 
677.989072171951, 
679.035820031637, 
680.082530176896, 
681.129202698862, 
682.1758376883, 
683.222435235615, 
684.268995430845, 
685.315518363671, 
686.362004123414, 
687.40845279904, 
688.454864479159, 
689.50123925203, 
690.547577205561, 
691.593878427312, 
692.640143004496, 
693.686371023983, 
694.732562572298, 
695.778717735626, 
696.824836599814, 
697.870919250371, 
698.916965772472, 
699.962976250957, 
701.008950770335, 
702.054889414786, 
703.100792268162, 
704.146659413987, 
705.192490935464, 
706.23828691547, 
707.284047436563, 
708.329772580982, 
709.375462430647, 
710.421117067165, 
711.466736571826, 
712.512321025609, 
713.557870509184, 
714.60338510291, 
715.648864886838, 
716.694309940716, 
717.739720343987, 
718.785096175789, 
719.830437514963, 
720.875744440049, 
721.921017029289, 
722.966255360631, 
724.011459511725, 
725.056629559931, 
726.101765582317, 
727.146867655661, 
728.191935856453, 
729.236970260894, 
730.281970944903, 
731.326937984114, 
732.371871453878, 
733.416771429266, 
734.461637985068, 
735.506471195798, 
736.551271135692, 
737.596037878713, 
738.640771498549, 
739.685472068615, 
740.730139662056, 
741.774774351748, 
742.8193762103, 
743.863945310051, 
744.908481723077, 
745.952985521191, 
746.997456775942, 
748.041895558617, 
749.086301940244, 
750.130675991594, 
751.175017783179, 
752.219327385255, 
753.263604867823, 
754.307850300633, 
755.35206375318, 
756.39624529471, 
757.440394994219, 
758.484512920454, 
759.528599141918, 
760.572653726863, 
761.616676743301, 
762.660668258998, 
763.704628341481, 
764.748557058031, 
765.792454475695, 
766.836320661278, 
767.880155681347, 
768.923959602237, 
769.967732490043, 
771.011474410629, 
772.055185429628, 
773.098865612437, 
774.142515024226, 
775.186133729935, 
776.229721794275, 
777.273279281732, 
778.316806256564, 
779.360302782806, 
780.403768924267, 
781.447204744536, 
782.49061030698, 
783.533985674743, 
784.577330910754, 
785.62064607772, 
786.663931238133, 
787.707186454267, 
788.750411788183, 
789.793607301727, 
790.836773056531, 
791.879909114016, 
792.923015535393, 
793.96609238166, 
795.00913971361, 
796.052157591824, 
797.095146076679, 
798.138105228344, 
799.181035106785, 
800.223935771763, 
801.266807282835, 
802.309649699357, 
803.352463080484, 
804.39524748517, 
805.438002972171, 
806.480729600043, 
807.523427427146, 
808.566096511643, 
809.608736911501, 
810.651348684494, 
811.693931888201, 
812.736486580008, 
813.779012817111, 
814.821510656513, 
815.863980155028, 
816.90642136928, 
817.948834355705, 
818.991219170553, 
820.033575869885, 
821.075904509578, 
822.118205145324, 
823.16047783263, 
824.20272262682, 
825.244939583038, 
826.287128756244, 
827.329290201218, 
828.37142397256, 
829.413530124692, 
830.455608711858, 
831.497659788123, 
832.539683407377, 
833.581679623334, 
834.623648489532, 
835.665590059336, 
836.707504385938, 
837.749391522357, 
838.791251521439, 
839.833084435862, 
840.874890318131, 
841.916669220582, 
842.958421195384, 
844.000146294537, 
845.041844569873, 
846.083516073059, 
847.125160855596, 
848.166778968819, 
849.208370463901, 
850.24993539185, 
851.291473803511, 
852.332985749568, 
853.374471280544, 
854.415930446799, 
855.457363298537, 
856.4987698858, 
857.540150258473, 
858.581504466282, 
859.622832558797, 
860.664134585432, 
861.705410595444, 
862.746660637936, 
863.787884761857, 
864.829083016002, 
865.870255449013, 
866.911402109379, 
867.95252304544, 
868.993618305382, 
870.034687937242, 
871.075731988908, 
872.116750508119, 
873.157743542465, 
874.198711139388, 
875.239653346183, 
876.280570210001, 
877.321461777845, 
878.362328096572, 
879.403169212898, 
880.44398517339, 
881.484776024478, 
882.525541812444, 
883.56628258343, 
884.606998383438, 
885.647689258327, 
886.688355253817, 
887.728996415488, 
888.769612788781, 
889.810204418998, 
890.850771351305, 
891.891313630729, 
892.931831302161, 
893.972324410356, 
895.012792999933, 
896.053237115377, 
897.093656801037, 
898.134052101131, 
899.174423059741, 
900.214769720817, 
901.255092128178, 
902.295390325511, 
903.335664356372, 
904.375914264185, 
905.416140092247, 
906.456341883723, 
907.496519681651, 
908.536673528939, 
909.57680346837, 
910.616909542596, 
911.656991794145, 
912.69705026542, 
913.737084998694, 
914.777096036119, 
915.81708341972, 
916.857047191399, 
917.896987392935, 
918.936904065983, 
919.976797252075, 
921.016666992622, 
922.056513328912, 
923.096336302115, 
924.136135953276, 
925.175912323324, 
926.215665453067, 
927.255395383193, 
928.295102154273, 
929.334785806757, 
930.374446380982, 
931.414083917163, 
932.453698455402, 
933.493290035684, 
934.532858697875, 
935.572404481731, 
936.61192742689, 
937.651427572876, 
938.690904959099, 
939.730359624857, 
940.769791609333, 
941.809200951599, 
942.848587690614, 
943.887951865227, 
944.927293514174, 
945.966612676081, 
947.005909389464, 
948.045183692729, 
949.084435624173, 
950.123665221983, 
951.162872524238, 
952.202057568909, 
953.241220393859, 
954.280361036846, 
955.319479535517, 
956.358575927416, 
957.39765024998, 
958.436702540541, 
959.475732836324, 
960.514741174451, 
961.55372759194, 
962.592692125703, 
963.631634812552, 
964.670555689192, 
965.709454792228, 
966.748332158161, 
967.787187823392, 
968.826021824219, 
969.86483419684, 
970.903624977351, 
971.942394201749, 
972.98114190593, 
974.019868125692, 
975.058572896732, 
976.097256254649, 
977.135918234945, 
978.174558873022, 
979.213178204185, 
980.251776263642, 
981.290353086504, 
982.328908707786, 
983.367443162407, 
984.405956485189, 
985.444448710859, 
986.482919874051, 
987.521370009301, 
988.559799151053, 
989.598207333656, 
990.636594591367, 
991.674960958349, 
992.71330646867, 
993.751631156309, 
994.789935055151, 
995.828218198988, 
996.866480621524, 
997.904722356369, 
998.942943437042, 
999.981143896974, 
1001.0193237695, 
1002.05748308788, 
1003.09562188527, 
1004.13374019473, 
1005.17183804926, 
1006.20991548175, 
1007.247972525, 
1008.28600921172, 
1009.32402557457, 
1010.36202164606, 
1011.39999745867, 
1012.43795304477, 
1013.47588843663, 
1014.51380366646, 
1015.55169876637, 
1016.58957376839, 
1017.62742870446, 
1018.66526360644, 
1019.70307850611, 
1020.74087343515, 
1021.77864842518, 
1022.81640350771, 
1023.8541387142, 
1024.89185407598, 
1025.92954962435, 
1026.9672253905, 
1028.00488140553, 
1029.04251770048, 
1030.0801343063, 
1031.11773125385, 
1032.15530857393, 
1033.19286629724, 
1034.23040445441, 
1035.26792307598, 
1036.30542219243, 
1037.34290183414, 
1038.38036203143, 
1039.41780281453, 
1040.45522421359, 
1041.49262625868, 
1042.53000897981, 
1043.56737240689, 
1044.60471656978, 
1045.64204149822, 
1046.67934722193, 
1047.7166337705, 
1048.75390117348, 
1049.79114946033, 
1050.82837866044, 
1051.86558880311, 
1052.90277991759, 
1053.93995203303, 
1054.97710517852, 
1056.01423938308, 
1057.05135467565, 
1058.08845108508, 
1059.12552864018, 
1060.16258736966, 
1061.19962730217, 
1062.23664846628, 
1063.27365089049, 
1064.31063460324, 
1065.34759963287, 
1066.38454600768, 
1067.42147375589, 
1068.45838290563, 
1069.49527348497, 
1070.53214552193, 
1071.56899904443, 
1072.60583408033, 
1073.64265065742, 
1074.67944880344, 
];

export default criticalValues;