import { Button, Stack } from "@mui/material";
// import { TextFields } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import {
  RichTextEditor,
  TableBubbleMenu,
  type RichTextEditorRef,
  LinkBubbleMenu,
  // MenuButton
} from "mui-tiptap";
import { useRef, useState } from "react";
import useExtensions from "./useExtentions";
import EditorMenuControls from "./EditorMenuControls";
import { useTranslation } from "react-i18next";

interface IProps {
  updateReportDescription: (value: any) => void;
  handleToggleEditor: (value: boolean) => void;
  content: string,
}

export default function HtmlEditor(props: IProps) {
  const { t } = useTranslation("traffic");
  const rteRef = useRef<RichTextEditorRef>(null);
  const [showMenuBar, setShowMenuBar] = useState(true);
  const extensions = useExtensions({
    placeholder: t('Traffic.addContentHere') || "Add your own content here...",
  });
  const closeEditorAndSave = () => {
    props.updateReportDescription(rteRef.current?.editor?.getHTML() ?? "")
    props.handleToggleEditor(false)
  }

  return (
    <div>
      <RichTextEditor
        ref={rteRef}
        extensions={extensions}
        content={props.content}
        renderControls={() => (
          <EditorMenuControls />
        )}
        RichTextFieldProps={{
          MenuBarProps: {
            hide: !showMenuBar,
          },
          footer: (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                borderTopStyle: "solid",
                borderTopWidth: 1,
                borderTopColor: (theme) => theme.palette.divider,
                py: 1,
                px: 1.5,
              }}
            >
                <Button style={{border: showMenuBar ? `2px solid gray` : "", backgroundColor: showMenuBar ? "#f5f4f2" : ""}} className='nonDraggable' onClick={() => setShowMenuBar((currentState: boolean) => !currentState)}><TextFieldsIcon /></Button>
                <Button className='nonDraggable' size="medium" style={{padding: '5px'}} onClick={closeEditorAndSave}><CheckIcon /></Button>
            </Stack>
          ),
        }}
      >
        {() => (
          <>
            <LinkBubbleMenu />
            <TableBubbleMenu />
          </>
        )}
      </RichTextEditor>
    </div>
  );
}