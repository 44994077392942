import { Settings } from "../components/Modal/Modal";

const modalService = (showMethod: Function, contentMethod: Function) => {

    let returnMethod: Function | undefined;

    return {
        show: (settings: Settings): void => {
            showMethod(true);
            contentMethod(settings);
            returnMethod = settings.returnMethod;
        },

        hide: (): void => {
            if (returnMethod) {
                returnMethod({ abort: true });
            }
            showMethod(false);
        },

        closeAndReturn: (info: any): void  => {
            if (returnMethod) {
                returnMethod({
                    abort: false,
                    ...info
                });
            }
            showMethod(false);
        }
    }
}

let ModalService = modalService(() => {}, () => {});

const setModal = (showMethod: Function, contentMethod: Function) => {
    ModalService = modalService(showMethod, contentMethod);
}

export { ModalService, setModal };