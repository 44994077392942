
import { useState, useEffect, useCallback } from 'react';
import Title from './../../../components/Title/Title';
import Requests from '../../../services/Requests';
import NotificationService from '../../../services/NotificationService';
import { FaCheck, FaShare, FaTrashAlt } from 'react-icons/fa';
import { ModalService } from '../../../services/ModalService';
import ModalEmail from '../../../modals/ModalEmail';

interface IExpression {
    id: number;
    exp_name: string;
    exp_original: string;
};

interface IExpressionShare {
    id: number;
    exp_name: string;
    exp_original: string;
    owner: string;
};

export default function Share() {
    const [expressions, setExpressions]: [IExpression[], Function] = useState([]);
    const [expressionsShare, setExpressionsShare]: [IExpressionShare[], Function] = useState([]);

    const load = useCallback(() => {
        Requests.get("/expression/get")
        .then((resp: any) => {
            if (resp.data.success) {
                setExpressions(resp.data.data);
            } else {
                NotificationService.error("Nepodarilo sa načítať výrazy.");
            }
        })
        .catch(() => { });

        Requests.get("/expression/getShared")
        .then((resp: any) => {
            if (resp.data.success) {
                setExpressionsShare(resp.data.data);
            } else {
                NotificationService.error("Nepodarilo sa načítať výrazy.");
            }
        })
        .catch(() => { });
    }, []);

    useEffect(() => {
        load();
    }, [load]);

    const decodeOriginal = (orig: string): string[] => {
        return orig.split(",");
    }

    const output = expressions.map((item, idx) => 
        <tr key={idx}>
            <td>{ item.id }</td>
            <td>{ item.exp_name }</td>
            <td>
                { decodeOriginal(item.exp_original).map((item, idx) => 
                    <span className="expressionFakeButton" key={idx}>{item}</span>
                ) }
            </td>
            <td>
                <FaShare className="expressionCopy" onClick={ () => shareExpression(item) }/>
                <FaTrashAlt className="expressionRemove" onClick={ () => removeExpression(item) } />
            </td>
        </tr>
    );

    const outputShare = expressionsShare.map((item, idx) => 
        <tr key={idx}>
            <td>{ item.exp_name }</td>
            <td>
                { decodeOriginal(item.exp_original).map((item, idx) => 
                    <span className="expressionFakeButton" key={idx}>{item}</span>
                ) }
            </td>
            <td>{ item.owner }</td>
            <td>
                <FaCheck className="expressionCopy" onClick={ () => acceptExpression(item) }/>
                <FaTrashAlt className="expressionRemove" onClick={ () => rejectExpression(item) } />
            </td>
        </tr>
    );

    const removeExpression = (expression: IExpression): void => {
        Requests.get("/expression/remove/" + expression.id)
        .then((resp: any) => {
            if (resp.data.success) {
                load();
                NotificationService.success(resp.data.message);
            } else {
                NotificationService.error(resp.data.message);
            }
        })
        .catch(() => { });
    }

    const shareExpression = (expression: IExpression): void => {
        ModalService.show({
            title: "Zdieľať výraz",
            children: <ModalEmail  />,
            returnMethod: (out: any) => {
                Requests.get("/expression/share/" + expression.id + "/" + out.data)
                .then((resp: any) => {
                    if (resp.data.success) {
                        load();
                        NotificationService.success(resp.data.message);
                    } else {
                        NotificationService.error(resp.data.message);
                    }
                })
                .catch(() => { });
            },
          });
        
    }

    const acceptExpression = (expression: IExpression): void => {
        Requests.get("/expression/accept/" + expression.id)
        .then((resp: any) => {
            if (resp.data.success) {
                load();
                NotificationService.success(resp.data.message);
            } else {
                NotificationService.error(resp.data.message);
            }
        })
        .catch(() => { });
    }

    const rejectExpression = (expression: IExpression): void => {
        Requests.get("/expression/reject/" + expression.id)
        .then((resp: any) => {
            if (resp.data.success) {
                load();
                NotificationService.success(resp.data.message);
            } else {
                NotificationService.error(resp.data.message);
            }
        })
        .catch(() => { });
    }

    return (
        <div className='box-12'>
            <Title align="center">Moje výrazy</Title>
            <div className='box-12 text-left login center'>
                <table className="users">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Názov</th>
                            <th>Výraz</th>
                            <th>Akcia</th>
                        </tr>
                    </thead>
                    <tbody>
                        { output }
                    </tbody>
                </table>
            </div>
            { expressions.length === 0 && 
                <h4>V zozname sa nenachádzajú žiadne výrazy.</h4>
            }
            <Title align="center">Zdielané výrazy</Title>
            <div className='box-12 text-left login center'>
                <table className="users">
                    <thead>
                        <tr>
                            <th>Názov</th>
                            <th>Výraz</th>
                            <th>Od</th>
                            <th>Akcia</th>
                        </tr>
                    </thead>
                    <tbody>
                        { outputShare }
                    </tbody>
                </table>
            </div>
            { expressionsShare.length === 0 && 
                <h4>Nemáte žiadne zdieľané výrazy.</h4>
            }
        </div>
    );
}