import { useTranslation } from 'react-i18next';

interface IProps {
  sumOfAllCrimes: any;
  countOfDistricts?: any;
  meanOfCrimes: any;
  districtWithLeastCrimes: any;
  districtWithMostCrimes: any;
  medianOfCrimes: any;
  stdOfCrimes: any;
}

export default function DistrictStatistics(props: IProps) {
    const { t } = useTranslation("crimes");
    const { 
      sumOfAllCrimes, 
      countOfDistricts, 
      meanOfCrimes, 
      medianOfCrimes, 
      stdOfCrimes, 
      districtWithLeastCrimes, 
      districtWithMostCrimes 
  } = props;

  return (
    <div>
      <h4>{t('districtStatistics.title')}: </h4>
      <ul>
        <li>{t('districtStatistics.countOfDistricts')}: {countOfDistricts} </li>
        <li>{t('districtStatistics.sumOfCrimes')}: {sumOfAllCrimes} </li>
        <li>{t('districtStatistics.average')}: {meanOfCrimes} </li>
        <li>{t('districtStatistics.median')}: {medianOfCrimes} </li>
        <li>{t('districtStatistics.std')}: {stdOfCrimes} </li>
        <li>{t('districtStatistics.lowest')}: {districtWithLeastCrimes} </li>
        <li>{t('districtStatistics.highest')}: {districtWithMostCrimes} </li>
      </ul>
    </div>
  );
};