import { ReactNode } from "react";
import { Link, To } from "react-router-dom";
import Card from "./Card";

interface IProps {
  text: string;
  icon: ReactNode;
  to: To;
}

export default function CardLink(props: IProps) {
  return (
    <Card>
      <Link className="card-link" to={props.to}>
        <div className="box-12 card-link-icon">{props.icon}</div>
        <div className="box-12 card-link-text">{props.text}</div>
      </Link>
    </Card>
  );
}
