import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { filterType, SearchFilter } from '../../interfaces/SearchFilter';
import { Button } from '../StyleComponents/StyleComponents';
import Title from '../Title/Title';
import CustomModal from './../Modal/CustomModal';
import css from './Expressions.module.css';
import { useTranslation } from 'react-i18next';
import NotificationService from '../../services/NotificationService';
import { Checkbox } from '@mui/material';

interface ExpressionValue {
    label: string;
    active: boolean;
    expression: { label: string, value: string }[];
};

export type { ExpressionValue };

interface IProps {
    filter: SearchFilter[];
    expressionValues: ExpressionValue[];
    onChange: (exp: ExpressionValue[]) => void;
    endpointService?: any;
};

export default function Expressions(props: IProps) {

    const { t } = useTranslation("crimes");
    const [ open, setOpen ] = useState(false);
    const [ help, setHelp ] = useState(false);
    const [ helpOption, setHelpOption ] = useState<any>(null);
    const [ expression, setExpression ] = useState<any[]>([]);
    const [ move, setMove ] = useState(false);
    const [ name, setName ] = useState("");

    const options = [
        { label: "expressions.0",               value: "0" }, 
        { label: "expressions.1",               value: "1" }, 
        { label: "expressions.2",               value: "2" }, 
        { label: "expressions.3",               value: "3" }, 
        { label: "expressions.4",               value: "4" }, 
        { label: "expressions.5",               value: "5" }, 
        { label: "expressions.6",               value: "6" }, 
        { label: "expressions.7",               value: "7" }, 
        { label: "expressions.8",               value: "8" }, 
        { label: "expressions.9",               value: "9" }, 
        { label: "expressions.+",               value: "+" }, 
        { label: "expressions.-",               value: "-" }, 
        { label: "expressions.*",               value: "*" }, 
        { label: "expressions./",               value: "/" },
        { label: "expressions.(",               value: "(" }, 
        { label: "expressions.)",               value: ")" }, 
        { label: "expressions.,",               value: "," }, 
        // ak sa upravi filter, tak tieto moznosti nemaju vyznam
        /* { label: "expressions.<",               value: "<" },
        { label: "expressions.>",               value: ">" },
        { label: "expressions.=",               value: "=" }, 
        { label: "expressions.!=",              value: "!=" }, 
        { label: "expressions.<=",              value: "<=" }, 
        { label: "expressions.>=",              value: ">=" }, */
        { label: "expressions.extract_year",    value: "extract_year" }, 
        { label: "expressions.extract_month",   value: "extract_month" }, 
        { label: "expressions.extract_day",     value: "extract_day" },
        { label: "expressions.extract_hour",    value: "extract_hour" }, 
        { label: "expressions.extract_minute",  value: "extract_minute" }, 
        { label: "expressions.extract_second",  value: "extract_second" },
        { label: "expressions.months_between",  value: "months_between" },
        { label: "expressions.trunc",         value: "trunc" },
        { label: "expressions.sysdate",         value: "sysdate" },
        ...props.filter.filter((item) => item.type === filterType.DATE).map((item) => (
        { label: `expressions.${item.atr}`,                     value: item.atr }
        ))
    ];
    /* select * from user_expressions;

    select * from all_expressions; */

    const toggleHelp = () => {
        setHelp((val) => !val);
    }

    const toggleMove = () => {
        setMove((val) => !val);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleSave = () => {
        if (name.includes(" ")) {
            NotificationService.error("Názov obsahuje medzery!");
            return;
        }
        if (name.length < 3) {
            NotificationService.error("Názov je krátky!");
            return;
        }
        if (expression.length === 0) {
            NotificationService.error("Výraz je prázdny!");
            return;
        }
        props.endpointService.verifyOwnExpression(expression.map((item) => item.value))
        .then((resp: any) => {
            if (resp.data === true) {
                NotificationService.success("Výraz je správny.");
                props.onChange([{ label: name, active: false, expression }, ...props.expressionValues]);
            } else if (resp.data === false) {
                NotificationService.error("Výraz nie je správny! Nedokázali sme však identifikovať chybu.");
            } else {
                NotificationService.error("Výraz nie je správny! " + resp.data);
            }
        })
        .catch(() => {
            NotificationService.error("Výraz nie je správny! Nedokázali sme však identifikovať chybu.");
        });
    };

    const handleNameChange = (event: any) => {
        setName(event.target.value);
    }

    const handleClickOption = (option: any) => {
        if (help) {
            setHelpOption(option);
        } else {
            setExpression((old) => ([...old, option]));
        }
    }

    const handleClickExpression = (options: any, idx: number) => {
        const temp = [...expression];
        temp.splice(idx, 1);
        setExpression(temp);
    }

    const handleActiveChange = (exp: ExpressionValue, idx: number) => {
        const expressions = [...props.expressionValues];
        expressions[idx].active = !expressions[idx].active;
        props.onChange(expressions);
    };

    const onDragEnd = (result: any) => {
        const temp = [...expression];
        const tempItem = temp[result.source.index];
        temp.splice(result.source.index, 1);
        temp.splice(result.destination.index, 0, tempItem);
        setExpression(temp);
    }

    const myExpressions = <div className={css.myExpression}>
        { props.expressionValues.map((exp, idx) => <div key={idx} className={css.myExpressionRow}>
            <div className='box-1'>
                <Checkbox checked={exp.active} onChange={() => handleActiveChange(exp, idx)}/>
            </div>
            <div className='box-11 box-md-4 box-hd-2'>{exp.label}</div>
            <div className='box-12 box-md-7 box-hd-9'>
                { exp.expression.map((option, idx) => <button key={idx} className={css.option}>
                    {t(`${option.label}.label`)}
                </button>) }
            </div>
        </div>) }
    </div>;

    const buttons = options.map((option) => <button key={option.label} className={css.option} onClick={() => handleClickOption(option)}>
        {t(`${option.label}.label`)}
    </button>);

    const expressionOutput = expression.map((option, idx) => <button key={idx} className={css.option} onClick={() => handleClickExpression(option, idx)}>
        {t(`${option.label}.label`)}
    </button>);

    const expressionDrag = <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
        {(provided: any, snapshot: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
            {expression.map((option, idx) => (
                <Draggable key={idx} draggableId={idx + ""} index={idx}>
                {(provided: any, snapshot: any) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style, top: `${provided.draggableProps.style.top / 2}`, left: `${provided.draggableProps.style.left / 2}` }}
                        onClick={() => handleClickExpression(option, idx)}
                    >
                        <span className={css.option} onClick={() => handleClickExpression(option, idx)}>{t(`${option.label}.label`)}</span>
                    </div>
                )}
                </Draggable>
            ))}
            {provided.placeholder}
            </div>
        )}
        </Droppable>
    </DragDropContext>;

    return <>
        <CustomModal
            show={open}
            title={t("search.expressionModalTitle")}
            width="800px"
            onClose={handleClose}
        >
            <div className='text-right py-2'>
                <Button onClick={toggleHelp}>{t("search.expressionHelpButton")}</Button>
            </div>
            <div>
                <Title align='center'>{t("search.expressionModalTitle")}</Title>
                { myExpressions }
                { props.expressionValues.length === 0 && <div className={`${css.helpText} text-center`}>{t("search.noExpressionsFound")}</div>}
            </div>
            <div className="text-center py-2">
                <Title align='center'>{t("search.expressionTitle")}</Title>
                { help && <div className={css.helpText}>{t("search.expressionHelpText")}</div> }
                { buttons }
            </div>
            <div className="text-center py-2">
                <Title align='center'>{t("search.expressionTitleNew")}</Title>
                <input type="text" value={name} onChange={handleNameChange} placeholder={t("search.expressionNamePlaceholder") + ""} />
                <div>
                    { !move && expressionOutput }
                    { move && <div className={css.helpText}>{t("search.expressionMoveText")}</div> }
                    { move && expressionDrag }
                </div>
            </div>
            <div className='text-right py-2'>
                <Button onClick={toggleMove}>{t("search.expressionMoveButton")}</Button>
                <Button onClick={handleSave}>{t("search.expressionSaveButton")}</Button>
            </div>
        </CustomModal>
        <Button onClick={handleOpen} style={{ marginBottom: "4px" }}>{t("search.expressionButton")}</Button>

        <CustomModal
            show={helpOption !== null}
            title={t("search.expressionHelpTitle")}
            width="800px"
            onClose={() => setHelpOption(null)}
        >
            <div className="text-center py-2" dangerouslySetInnerHTML={{__html: t(`${helpOption?.label}.description`) || ""}} />
        </CustomModal>
    </>
}