import axios, { AxiosResponse } from "axios";
import { SearchServiceWT } from "../interfaces/SearchServiceWT";
import qs from 'qs';
import { ExpressionValue } from "../components/Expressions/Expressions";

const SearchService: SearchServiceWT = {
    getTableData: (
        page: number, 
        perPage: number, 
        label: string, 
        order: boolean, 
        language: string,
        search: string,
        searchReportGlobal?: string,
        custom?: ExpressionValue, 
        abort?: AbortController

    ): Promise<AxiosResponse<any, any>> => {
        const paramsSerializer = (par: any) => qs.stringify(par);
        return axios.get(process.env.REACT_APP_API_URL + "/tap/search", {
            params: {
                page,
                perPage,
                order: label,
                asc: order,
                search: JSON.stringify(search),
                searchReportGlobal: JSON.stringify(searchReportGlobal),
                custom: JSON.stringify(custom),
                language
            },
            signal: abort?.signal,
            paramsSerializer
        });
    },

    //Get values to SELECT in filter
    getFilterData: (label: string, value: string): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tap/search/unique", {
            params: {
                atr: label,
                search: value
            }
        });
    },

    getGraphData: (atr1: string, atr2: string, atr1Interval: string, atr2Interval: string, atr1IntervalBy: string, atr2IntervalBy: string, nullValues: boolean, group: string, search: string, searchReportGlobal?: string, custom?: ExpressionValue, signal?: any): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tap/graph", {
            params: {
                atr1: atr1,
                atr2: atr2,
                atr1interval: atr1Interval,
                atr2interval: atr2Interval,
                atr1intervalBy: atr1IntervalBy === "" ? undefined : atr1IntervalBy,
                atr2intervalBy: atr2IntervalBy === "" ? undefined : atr2IntervalBy,
                nullValues: nullValues,
                group: group,
                search: JSON.stringify(search),
                searchReportGlobal: JSON.stringify(searchReportGlobal),
                custom: JSON.stringify(custom),
            },
            signal
        });
    },
}

export default SearchService;