import { useTranslation } from 'react-i18next';
import skFlag from '../../assets/css/images/flags/sk.png';
import enFlag from '../../assets/css/images/flags/en.png';
import css from './LangSwitch.module.css';

export default function LangSwitch() {

    const { t, i18n } = useTranslation("default");

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    }

    return (
    <div>
        {/* <div className={css.title}>
            { t("language") }
        </div> */}
        <div className={css.language}>
            <div className={`${css.flagBox} ${i18n.language !== 'sk' ? css.fill : ''}`} onClick={() => changeLanguage("sk")}>
                <img className={css.flag} src={skFlag} alt="SK" />
            </div>
            <div className={`${css.flagBox} ${i18n.language !== 'en' ? css.fill : ''}`} onClick={() => changeLanguage("en")}>
                <img className={css.flag} src={enFlag} alt="EN" />
            </div>
        </div>
    </div>
    );
}