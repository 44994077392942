import CustomModal from "../Modal/CustomModal";
import { Button } from "../StyleComponents/StyleComponents";
import { useState, useEffect, useCallback } from 'react';
import Title from "../Title/Title";
import NotificationService from "../../services/NotificationService";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import UsersService from "../../services/UsersService";
import useUser from "../../use/useUser";
import { useParams } from "react-router-dom";

interface IProps {
    dataKey: string;
    dataToFile: any;
    onImportData: (data: any) => void;
    saveOnRefresh?: boolean;
    loadOnRefresh?: boolean;
}

export default function Settings(props: IProps) {
    const [ user ] = useUser();
    const { t } = useTranslation("crimes");
    const [ show, setShow ] = useState(false);
    const [ name, setName ] = useState("");
    const [ selectValues, setSelectValues ] = useState<any>([]);
    const [ value, setValue ] = useState<any>(null);
    const [ file, setFile ] = useState(null);

    const { id } = useParams();

    const [ savedFiltersDb, setSavedFiltersDb ] = useState<any>([]);
    const [ valueDB, setValueDB ] = useState<any>(null);

    useEffect(() => {
        const onKeyDown = (event: any) => {
            if (event.key === 'F5') {
                const data = window.sessionStorage.getItem(props.dataKey + selectValues?.[0]?.value);
                if (!data || JSON.stringify(props.dataToFile) !== data) {
                    const confirm = window.confirm(t("settings.beforeRefreshMessage") + "");
                    if (confirm) {
                        window.sessionStorage.setItem(props.dataKey + "last", JSON.stringify(props.dataToFile));
                        const arr = JSON.parse(window.sessionStorage.getItem(props.dataKey) || "[]");
                        const exist = arr.find((item: any) => item.value === "last");
                        if (exist) {
                            arr.splice(arr.indexOf(exist), 1);
                        }
                        arr.push({ label: "last (" + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString() + ")", value: "last" });
                        window.sessionStorage.setItem(props.dataKey, JSON.stringify(arr));
                    }
                }
            }
        }
        
        if (props.saveOnRefresh) {
            window.addEventListener('keydown', onKeyDown);
        }

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [ selectValues, props.dataToFile, props.dataKey, props.saveOnRefresh, t ]);

    useEffect(() => {
        if (props.loadOnRefresh) {
            const data = window.sessionStorage.getItem(props.dataKey);
            if (data) {
                const arr = JSON.parse(data || "[]");
                if (arr.length > 0) {
                    const confirm = window.confirm(t("settings.afterLoad") + arr[arr.length - 1].label + "'");
                    if (confirm) {
                        const out = window.sessionStorage.getItem(props.dataKey + arr[arr.length - 1].value);
                        props.onImportData(JSON.parse(out || "{}"));
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [ props.loadOnRefresh, props.dataKey, t ]);

    const load = useCallback(() => {
        const data = window.sessionStorage.getItem(props.dataKey);
        if (data) {
            setSelectValues(JSON.parse(data || "[]").reverse());
        }
    }, [ props.dataKey ])

    useEffect(() => {
        load();
    }, [ load ]);

    useEffect(() => {
        UsersService.getUserFilters('', 0, 10)
        .then((resp: any) => {
            const fl = resp.data.data.map((x: any) => ({label: x.name, value: x.filterId, realVal: x.value}));
            setSavedFiltersDb(fl);
            const itemFromurl = fl.filter((item : any) => item.value + "" === id);
            if (itemFromurl.length > 0) {
                const data = JSON.parse(itemFromurl[0].realVal || "{}");
                props.onImportData(data);
            }
        })
        .catch(() => { });

        if (id === "-1") {
            const data = window.sessionStorage.getItem(props.dataKey);
            if (data) {
                const arr = JSON.parse(data || "[]");
                if (arr.length > 0) {
                    const out = window.sessionStorage.getItem(props.dataKey + arr[arr.length - 1].value);
                    props.onImportData(JSON.parse(out || "{}"));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        setValue(selectValues[0]);
    }, [ selectValues ]);

    const handleClose = () => {
        setShow(false);
    }

    const handleOpen = () => {
        setShow(true);
    }

    const handleExportToFile = () => {
        const data = { ...props.dataToFile };
        data.key = props.dataKey;
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}-${new Date().toLocaleTimeString()}.export`;
        a.click();
        window.URL.revokeObjectURL(url);
        NotificationService.success(t("settings.succExportFile"));
    }

    const handleExportToDb = () => {
        const data = { ...props.dataToFile };
        data.key = props.dataKey;

        UsersService.addUserFilter({value: JSON.stringify(data), name: name})
        .then((resp: any) => {
            NotificationService.success(t("settings.succExport"));
        })
        .catch(() => { });
    }

    const handleExportToMemory = () => {
        if (name.length > 0) {
            window.sessionStorage.setItem(props.dataKey + name, JSON.stringify(props.dataToFile));
            const arr = JSON.parse(window.sessionStorage.getItem(props.dataKey) || "[]");
            const exist = arr.find((item: any) => item.value === name);
            if (exist) {
                // exist.label = name + " (" + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString() + ")";
                arr.splice(arr.indexOf(exist), 1);
            }
            arr.push({ label: name + " (" + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString() + ")", value: name });
            console.log(arr);
            window.sessionStorage.setItem(props.dataKey, JSON.stringify(arr));
            NotificationService.success(`${t("settings.succExportMemory")} '${name}'.`);
            load();
        } else {
            NotificationService.error(t("setting.emptyName"));
        }
    }

    const handleImportFromFile = () => {
        if (file) {
            const data = JSON.parse(file || "{}");
            if (data.key === props.dataKey) {
                props.onImportData(data);
                NotificationService.success(t("settings.succImportFile"));
            } else {
                NotificationService.error(t("settings.failImportFile"));
            }
        }
    }

    const handleImportFromMemory = () => {
        if (value) {
            const data = window.sessionStorage.getItem(props.dataKey + value.value);
            if (data) {
                props.onImportData(JSON.parse(data || "{}"));
                NotificationService.success(t("settings.succImportMemory"));
            } else {
                NotificationService.error(t("settings.failImportWrong"));
            }
        } else {
            NotificationService.error(t("settings.failImportEmpty"));
        }
    }

    const handleImportFromDB = () => {
        if (valueDB) {
            const data = JSON.parse(valueDB.realVal || "{}");
            props.onImportData(data);
            NotificationService.success(t("settings.succImport"));
        } else {
            NotificationService.error(t("settings.failImport"));
        }
    }

    const handleRemoveFromMemory = () => {
        if (value) {
            let arr = JSON.parse(window.sessionStorage.getItem(props.dataKey) || "[]");
            arr = arr.filter((item: any) => item.value !== value.value);
            window.sessionStorage.setItem(props.dataKey, JSON.stringify(arr));
            NotificationService.success(t("settings.succDelete"));
            load();
        } else {
            NotificationService.error(t("settings.failImportEmpty"));
        }
    }

    const handleFileChange = (val: any) => {
        const fr = new FileReader();
        fr.addEventListener('load', (resp: any) => {
            setFile(resp.target.result);
        });
        fr.readAsText(val.target.files[0]);
    }

    const handleNameChange = (e: any) => {
        setName(e.target.value);
    }

    const handleSelectChange = (val: any) => {
        setValue(val);
    }

    const selectStyle = {
        styles: { menuPortal: (base: any) => ({ ...base, zIndex: 9999 })},
        menuPortalTarget: document.body
    };

    return <>
        <CustomModal
            show={show}
            title={t("settings.modalTitle")}
            width="450px"
            onClose={handleClose}
        >
            <div className="text-center py-2">
                { user && <>
                    <Title>{t("settings.database")}</Title>
                    <input type="text" placeholder={t("settings.placeholder") + ""} value={name} onChange={handleNameChange} />
                    <br /><br />
                    <Button className="box-12 mx-0" onClick={handleExportToDb} style={{ marginBottom: "4px" }}>{t("settings.exportToDB")}</Button>
                    <br /><br />
                    <Select
                        value={valueDB}
                        options={savedFiltersDb}
                        {...selectStyle}
                        onChange={((e) => setValueDB(e))}
                    />
                    <br /><br />
                    <Button className="box-12 mx-0" onClick={handleImportFromDB}>{t("settings.importFromDB")}</Button>
                </>}

                <Title>{t("settings.file")}</Title>
                <input type="text" placeholder={t("settings.placeholder") + ""} value={name} onChange={handleNameChange} />
                <br /><br />
                <Button className="box-12 mx-0" onClick={handleExportToFile}>{t("settings.exportToFile")}</Button>
                <br /><br />
                <Button className="box-12 mx-0" onClick={handleImportFromFile}>{t("settings.importFromFile")}</Button>
                <br />
                <input type="file" onChange={handleFileChange} />
                <br /><br />

                <Title>{t("settings.memory")}</Title>
                <input type="text" placeholder={t("settings.placeholder") + ""} value={name} onChange={handleNameChange} />
                <br /><br />
                <Button className="box-12 mx-0" onClick={handleExportToMemory}>{t("settings.exportToMemory")}</Button>
                <br /><br />
                <Select
                    value={value}
                    options={selectValues}
                    {...selectStyle}
                    onChange={handleSelectChange}
                />
                <br /><br />
                <Button className="box-12 mx-0" onClick={handleImportFromMemory}>{t("settings.importFromMemory")}</Button>
                <br /><br />
                <Button className="box-12 mx-0" onClick={handleRemoveFromMemory}>{t("settings.removeFromMemory")}</Button>
                


                
                

            </div>
        </CustomModal>
        <Button onClick={handleOpen} style={{ marginBottom: "4px" }}>{t("settings.mainButton")}</Button>
    </>
}