import Requests from "./Requests";
import axios, { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';

const UsersService = (): any => {

    return {
        login: (email: string, password: string): Promise<AxiosResponse<any, any>> => {
            return Requests.get(`/user/login?email=` + email + `&password=` + password);
        },

        extendToken: (token: string): Promise<AxiosResponse<any, any>> => {
            return Requests.put(`/user/extendToken?token=` + token);
        },

        register: (email: string, name: string, surname: string, password: string): Promise<AxiosResponse<any, any>> => {
            return Requests.put(`/user/createUser?email=` + email + `&name=` + name + `&surname=` + surname + `&password=` + password);
        },

        getUsers: (searchText: string, page: number, perPage: number): Promise<AxiosResponse<any, any>> => {
            return Requests.get("/user/users?searchText=" + searchText + "&page=" + page + "&perPage=" + perPage);
        },

        changeRole: (userId: number, role: number): Promise<AxiosResponse<any, any>> => {
            return Requests.get("/user/changeRole?userId=" + userId + "&role=" + role);
        },

        addUserFilter: (body: any): Promise<AxiosResponse<any, any>> => {
            const url = "/filter/saveFilter";
            return axios.put(process.env.REACT_APP_API_URL + url, body, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        getUserFilters: (searchText: string, page: number, perPage: number): Promise<AxiosResponse<any, any>> => {
            const url = "/filter/getAllFilters?searchText=" + searchText + "&page=" + page + "&perPage=" + perPage;
            return axios.get(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        deletetUserFilter: (filterId: string): Promise<AxiosResponse<any, any>> => {
            const url = "/filter/removeFilter?filterId=" + filterId;
            return axios.delete(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        updateUser: (email: string, name: string, surname: string): Promise<AxiosResponse<any, any>> => {
            const url = "/user/updateUser?email=" + email + "&name=" + name + "&surname=" + surname;
            return axios.post(process.env.REACT_APP_API_URL + url, [], { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        updatePassword: (password: string, newpassword: string): Promise<AxiosResponse<any, any>> => {
            const url = "/user/updatePassword?password=" + password + "&newpassword=" + newpassword;
            return axios.post(process.env.REACT_APP_API_URL + url, [], { headers: { 'SID': new Cookies().get("user")?.token } });
        },
    };
}

export default UsersService();