import { FaDownload, FaRegCopy, FaSave, FaShareAlt, FaTimes, FaTrashAlt, FaUpload } from "react-icons/fa";
import { Button } from "../components/StyleComponents/StyleComponents";
import { ModalService } from "../services/ModalService";
import { KeyboardEvent, useCallback, useEffect } from 'react';
import { useState } from 'react';
import CustomModal from "../components/Modal/CustomModal";
import { useTranslation } from 'react-i18next';

interface SearchData {
    [key: string]: string | number,
    pid: number,
    oddelenie: number,
    status: number,
    status_title: string,
    kvalifikacia: string,
    kvalifikacia_title: string,
    datum_podania: string,
    typ_podania: number,
    podanie_title: string,
    datum_zacatia: string,
    typ_zacatia: number,
    zacatia_title: string,
    datum_pokracovania: string,
    typ_pokracovania_tc: number,
    pokracovania_title: string,
    datum_ukoncenia: string,
    typ_ukoncenia: number,
    ukoncenia_title: string,
    vztah_ukoncenia: number,
    vztah_title: string,
    motiv_ukoncenia: number,
    motiv_title: string,
    datum_obvinenia: string,
    typ_obvinenia: number,
    obvinenia_title: string,
    datum_rozsirenia: string,
    typ_rozsirenia: number,
    rozsirenia_title: string,
    datum_pokracovania_p: string,
    typ_pokracovania_p: number,
    pokracovania_p_title: string,
    datum_prevzatia: string,
    typ_prevzatia: number,
    prevzatie_title: string,
    sposob_prevzatia: number,
    sposob_prevzatia_title: string,
    datum_vratenia: string,
    typ_vratenia_a: number,
    vratenia_a_title: string,
    typ_vratenia_b: string,
    vratenia_b_title: number,
    datum_ukoncenia_po_dos: string,
    typ_ukoncenia_po_dos: number,
    ukoncenia_po_dos_title: string
}

interface IProps {
    details?: SearchData | null;
}



export default function ModalMyDetailTC(props: IProps) {
    const { t } = useTranslation("crimes");
    const [open, setOpen] = useState(false);
    const { details } = props;

    const hasTranslation = (key: keyof SearchData) => {
        const translationKey = `detail.${key}`;
        const translatedLabel = t(translationKey);

        // Skip translation if it contains a dot (".")
        return Boolean(translatedLabel) && !translatedLabel.includes('.');
    };

    const formatDate = (dateString: string | undefined) => {
        if (!dateString) {
            return 'Nie je k dispozícií';
        }

        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('sk-SK', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        return formattedDate;
    };


    const handleClose = (): void => {
        setOpen(false);
        ModalService.hide();
    }

    const load = useCallback(() => {
    }, []);

    useEffect(() => {
        if (open) {
            load();
        }
    }, [open, load]);

    useEffect(() => {
        setOpen(true);
    }, [details]);

    return (
        <CustomModal
            show={open}
            title="Detail"
            width="450px"
            onClose={handleClose}
        >
            <div className='box-12'>
                { }
                <div className="box-12 text-left">
                    {details && (
                        <div className="box-12 text-left" style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '10px' }}>
                            {Object.entries(details).map(([key, value]) => {
                                // Skip rendering if translation does not exist or contains a dot
                                if (!hasTranslation(key as keyof SearchData)) {
                                    return null;
                                }

                                const formattedValue = key.includes('datum') ? formatDate(value as string) : (value || 'Nie je k dispozícií');

                                return (
                                    <>
                                        <div style={{ fontWeight: 'bold' }}>{t(`detail.${key}`)}:</div>
                                        <div>{formattedValue}</div>
                                    </>
                                );
                            })}
                        </div>
                    )}
                    <div className='box-12 text-center' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <hr style={{ width: '100%', border: '1px solid #ccc', margin: '10px 0' }} />
                        <Button onClick={handleClose}><FaTimes /> {t('detail.zatvorit')}</Button>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};