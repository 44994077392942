import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import analyzisService from "../../services/StatisticService";
import { useTranslation } from 'react-i18next';
import criticalValues from "../../interfaces/ConstCriticalValueForChiSqTest";
import iDataset from "../../interfaces/iDataset";

interface IProps {
  dataset: iDataset;
};

export default function Distrubution(props: IProps) {
  const { t } = useTranslation("crimes");
  const [dist, setDist] = useState('normal');

  const [toolTip, setToolTip]: [any[string], Function] = useState([]);
  const [data, setData] = useState([]);
  const [dataForCumul, setDataForCumul] = useState([]);
  const [predictData, setPredictData] = useState([]);
  const [distributionValues, setDistributionValues] : any = useState();

  useEffect(() => {
    const abortCtrl = new AbortController();
    const dataset = props.dataset.graphValues[0].data[0];
    const fltValues = dataset.filter ? dataset.filter : props.dataset.filterValues;
    const fval = fltValues.map((value: any) => ({
        type: value.type.value,
        value: value.value.map((val: any) => ({
            type: val.type.value,
            atr: val.atribute.value,
            option: val.option.value,
            value: val.value.map((valInVal: any) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
        }))
    }));
    analyzisService.getHistogram(dataset.dataFirst, dataset.dataSecond, dataset.dataFirstInterval, dataset.dataSecondInterval
      , dataset.dataFirstIntervalBy, dataset.dataSecondIntervalBy, props.dataset.graphValues[0].group, fval
      , props.dataset.expressionValues, null, abortCtrl.signal)
    .then((res: any) => {
        const tempToolTip : any = [];
        res.data.data.forEach((item: any, idx: number) => {
          tempToolTip.push(item.count_floor + " - " + (res.data.data.length - 1 > idx ? res.data.data[idx + 1].count_floor : "nekonečno"));
        });
        setDataForCumul(res.data.data.map((item: any) => item.count_floor));
        setToolTip(tempToolTip);

        setData(res.data.data.map((item: any) => item.count));
    })
    .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataset]);

  useEffect(() => {
    const abortCtrl = new AbortController();
    const dataset = props.dataset.graphValues[0].data[0];
    const fltValues = dataset.filter ? dataset.filter : props.dataset.filterValues;
    const fval = fltValues.map((value: any) => ({
        type: value.type.value,
        value: value.value.map((val: any) => ({
            type: val.type.value,
            atr: val.atribute.value,
            option: val.option.value,
            value: val.value.map((valInVal: any) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
        }))
    }));
    let count = 0;
    data.forEach((item: any) => count = count + item);
    
    let dataForCumulTemp : Array<number> = [...dataForCumul];
    dataForCumulTemp.push(dataForCumulTemp[dataForCumulTemp.length - 1] + (dataForCumulTemp[1] - dataForCumulTemp[0]));

    analyzisService.getDistribution(dist, dataset.dataFirst, dataset.dataSecond, dataset.dataFirstInterval, dataset.dataSecondInterval
      , dataset.dataFirstIntervalBy, dataset.dataSecondIntervalBy, props.dataset.graphValues[0].group, fval
      , props.dataset.expressionValues, null, abortCtrl.signal)
    .then((res: any) => {
      setDistributionValues(res.data);
      analyzisService.getCumulativeFunction(dataForCumulTemp, dist, (res.data.mu || res.data.a || res.data.lambda), (res.data.sigma || res.data.b || 0))
      .then((resp: any) => {
          setPredictData(resp.data.map((item: any) => item * count));
      })
      .catch(() => {});
    })
    .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataset.expressionValues, props.dataset.graphValues, props.dataset.filterValues, props.dataset.filter, data, dist]);

  const barOption = {
      legend: {
        top: "auto",
        bottom: 0,
        type: 'scroll'
      },
      title: {
        text: t('analyzis.fitDist'),
        x: 'center'
      },
      tooltip: {},
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          saveAsImage: { show: true },
        },
      },
      xAxis: {
        type: "category",
        data: toolTip,
        //  name: "tesasdasdas asdasdt",
      },
      yAxis: {
        type: "value",
        name: t("analyzis.valuesCount"),
      },
      series: [
        {
          /*label: {
            show: true,
            rotate: 90,
            align: 'bottom',
            position: 'insideBottomRight',
            fontSize: 16,
            formatter: '{c}',
          },*/
          data: data,
          type: "bar",
        },
        {
          data: predictData,
          type: "line",
        }
      ],
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setDist(newValue);
    };

    const getError = () => {
      let err = 0;
      data.forEach((item: any, idx: number) => {
        err = err + (Math.pow(item - predictData[idx], 2))
      });
      return err / data.length;
    };

    return (
      <>
        <Grid sx={{ textAlign: "right" }}>
          <Tabs
            value={dist}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            
          >
            <Tab value="normal" label="Normal" sx={{ textTransform: "none" }}/>
            <Tab value="exponential" label="Exponential" sx={{ textTransform: "none" }}/>
            <Tab value="gamma" label="Gamma" sx={{ textTransform: "none" }}/>
            <Tab value="poisson" label="Poisson" sx={{ textTransform: "none" }}/>
            <Tab value="weibull" label="Weibull" sx={{ textTransform: "none" }}/>
          </Tabs>
        </Grid>
        <Grid>
            <ReactECharts option={barOption} notMerge={true}/>
        </Grid>
        <Grid textAlign={"left"}>
            <Typography variant="h5" fontWeight={'bold'}>{t("analyzis.dist")}: {dist}</Typography>
            
            { dist === "exponential" && <>
              <Typography variant="body1"><b>{t("analyzis.priem")} (mu): &emsp; </b>{distributionValues?.mu?.toFixed(3)}</Typography>
              <Typography variant="body1"><b>{t("analyzis.inter")} (95): &emsp; </b>({distributionValues?.interval[0][0]?.toFixed(3)} - {distributionValues?.interval[1][0]?.toFixed(3)})</Typography>
            </> }
            { dist === "gamma" && <>
              <Typography variant="body1"><b>{t("analyzis.shape")} (a): &emsp; </b>{distributionValues?.a?.toFixed(3)}</Typography>
              <Typography variant="body1"><b>{t("analyzis.inter")} (95): &emsp; </b>({distributionValues?.interval[0][0]?.toFixed(3)} - {distributionValues?.interval[1][0]?.toFixed(3)})</Typography>
              <Typography variant="body1"><b>{t("analyzis.scale")} (b): &emsp; </b>{distributionValues?.b?.toFixed(3)}</Typography>
              <Typography variant="body1"><b>{t("analyzis.inter")} (95): &emsp; </b>({distributionValues?.interval[0][1]?.toFixed(3)} - {distributionValues?.interval[1][1]?.toFixed(3)})</Typography>
            </> }
            { dist === "normal" && <>
              <Typography variant="body1"><b>{t("analyzis.priem")} (mu): &emsp; </b>{distributionValues?.mu?.toFixed(3)}</Typography>
              <Typography variant="body1"><b>{t("analyzis.inter")} (95): &emsp; </b>({distributionValues?.interval[0][0]?.toFixed(3)} - {distributionValues?.interval[1][0]?.toFixed(3)})</Typography>
              <Typography variant="body1"><b>{t("analyzis.smer")} (sigma): &emsp; </b>{distributionValues?.sigma?.toFixed(3)}</Typography>
              <Typography variant="body1"><b>{t("analyzis.inter")} (95): &emsp; </b>({distributionValues?.interval[0][1]?.toFixed(3)} - {distributionValues?.interval[1][1]?.toFixed(3)})</Typography>
            </> }
            { dist === "poisson" && <>
              <Typography variant="body1"><b>{t("analyzis.priem")} (lambda): &emsp; </b>{distributionValues?.lambda?.toFixed(3)}</Typography>
              <Typography variant="body1"><b>{t("analyzis.inter")} (95): &emsp; </b>({distributionValues?.interval[0][0]?.toFixed(3)} - {distributionValues?.interval[1][0]?.toFixed(3)})</Typography>
            </> }
            { dist === "weibull" && <>
              <Typography variant="body1"><b>{t("analyzis.shape")} (a): &emsp; </b>{distributionValues?.a?.toFixed(3)}</Typography>
              <Typography variant="body1"><b>{t("analyzis.inter")} (95): &emsp; </b>({distributionValues?.interval[0][0]?.toFixed(3)} - {distributionValues?.interval[1][0]?.toFixed(3)})</Typography>
              <Typography variant="body1"><b>{t("analyzis.scale")} (b): &emsp; </b>{distributionValues?.b?.toFixed(3)}</Typography>
              <Typography variant="body1"><b>{t("analyzis.inter")} (95): &emsp; </b>({distributionValues?.interval[0][1]?.toFixed(3)} - {distributionValues?.interval[1][1]?.toFixed(3)})</Typography>
            </> }
            
            <Typography variant="body1"><b>{t("analyzis.chyba")}: &emsp; </b>{getError()?.toFixed(3)}</Typography>
            <Typography variant="body1"><b>{t("analyzis.volnost")}: &emsp; </b>{data.length}</Typography>
            <Typography variant="body1"><b>{t("analyzis.vynamnost")}: &emsp; </b>0.05</Typography>
            <Typography variant="body1"><b>{t("analyzis.crit")}: &emsp; </b>{criticalValues[data.length > 1000 ? 1000 : data.length]?.toFixed(3)}</Typography>
            <Typography variant="body1"><b>{t("analyzis.state")}: &emsp; </b>{ criticalValues[data.length > 1000 ? 1000 : data.length] >= getError() ? t("analyzis.yes") : t("analyzis.no")}</Typography>
            
        </Grid>
      </>
    )
}