enum filterType {
    NONE,
    TEXT,
    DATE,
};

interface SearchFilter {
    atr: string,
    name: string,
    active: boolean,
    type: filterType,
    sid?: number
}

interface SearchFilterValueIn {
    atribute: { value: string, label: string };
    option: { value: string, label: string };
    type: { value: string, label: string };
    value: any[];
}

interface SearchFilterValue {
    type: { value: string, label: string };
    value: SearchFilterValueIn[];
}

export { filterType };
export type { SearchFilter, SearchFilterValue, SearchFilterValueIn };