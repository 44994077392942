import { useTranslation } from 'react-i18next';
import image from '../../../assets/css/images/police.jpg';
import { useEffect, useState } from 'react';
import UsersService from '../../../services/UsersService';
import TableWithPagining from '../../../components/Table/TableWithPagining';
import { Button } from '../../../components/StyleComponents/StyleComponents';
import NotificationService from '../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import PageForm from '../../../components/PageHeader/PageForm';
import { Column } from '../../../interfaces/Interfaces';

export default function Filters() {
    const { t } = useTranslation("default");
    const navigate = useNavigate();
    const [data, setData] : [Array<any>, Function] = useState([]);
    const [count, setCount] = useState(0);

    const columns: Column[] = [
        { id: 'filterId', label: 'Id', minWidth: 170 },
        { id: 'name', label: t('default.name'), minWidth: 170 },
    ];

    const loadData = (page: number, perPage: number) => {
        UsersService.getUserFilters("", page , perPage)
        .then((resp: any) => {
            setData(resp.data.data);
            setCount(resp.data.totalCount);
        })
        .catch(() => { });
    };
   
    useEffect(() => {
        loadData(0, 10);
    }, []);

    const deleteItem = (idx: number) => {
        UsersService.deletetUserFilter(data[idx].filterId)
        .then((resp: any) => {
            var tempData = [...data];
            tempData.splice(idx, 1);
            setData(tempData);
            NotificationService.Success(t("settings.deleteSuccess"));
        })
        .catch(() => { });
    };

    const goTo = (idx: number) => {
        navigate("/crimes/search/" + data[idx].filterId );
    };

    const actButt = [{
        element: <Button> {t('default.delete')} </Button>,
        action: deleteItem
    },
    {
        element: <Button> {t('default.goTo')} </Button>,
        action: goTo
    }];

    return (
    <PageForm label={t("menu.filterManagement")} image={image}>
        <TableWithPagining columns={columns} rows={data} totalCount={count} actions={actButt} loadData={loadData}/>
    </PageForm>
    );
}