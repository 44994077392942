import { filterType, SearchFilter } from "./SearchFilter";

const filterItemsTraffic: SearchFilter[] = [
  {
    atr: "id_kraj",
    name: "ID_KRAJ",
    active: true,
    type: filterType.TEXT
  },{
    atr: "id",
    name: "ID",
    active: true,
    type: filterType.TEXT
  },{
    atr: "druh_pozemnej_komunikacie",
    name: "DRUH_POZEMNEJ_KOMUNIKACIE",
    active: true,
    type: filterType.TEXT
  },{
    atr: "cislo_pozemnej_komunikacie",
    name: "CISLO_POZEMNEJ_KOMUNIKACIE",
    active: true,
    type: filterType.TEXT
  },{
    atr: "datum",
    name: "DATUM",
    active: true,
    type: filterType.TEXT
  },{
    atr: "weekday",
    name: "WEEKDAY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "druh_nehody",
    name: "DRUH_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "druh_zrazky_iducich_vozidiel",
    name: "DRUH_ZRAZKY_IDUCICH_VOZIDIEL",
    active: true,
    type: filterType.TEXT
  },{
    atr: "druh_pevnej_prekazky",
    name: "DRUH_PEVNEJ_PREKAZKY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "charakter_nehody",
    name: "CHARAKTER_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "zavinenie_nehody",
    name: "ZAVINENIE_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "alkohol_u_vinnika_nehody_pritomny",
    name: "ALKOHOL_U_VINNIKA_NEHODY_PRITOMNY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "hlavne_priciny_nehody",
    name: "HLAVNE_PRICINY_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "nasledky_nehody_usmrtene_osoby",
    name: "NASLEDKY_NEHODY_USMRTENE_OSOBY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "nasledky_nehody_tazko_zranene_osoby",
    name: "NASLEDKY_NEHODY_TAZKO_ZRANENE_OSOBY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "nasledky_nehody_lahko_zranene_osoby",
    name: "NASLEDKY_NEHODY_LAHKO_ZRANENE_OSOBY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "celkova_hmotna_skoda",
    name: "CELKOVA_HMOTNA_SKODA",
    active: true,
    type: filterType.TEXT
  },{
    atr: "druh_povrchu_vozovky",
    name: "DRUH_POVRCHU_VOZOVKY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "stav_povrchu_vozovky_v_dobe_nehody",
    name: "STAV_POVRCHU_VOZOVKY_V_DOBE_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "stav_komunikacie",
    name: "STAV_KOMUNIKACIE",
    active: true,
    type: filterType.TEXT
  },{
    atr: "poveternostne_podmienky_v_dobe_nehody",
    name: "POVETERNOSTNE_PODMIENKY_V_DOBE_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "viditelnost",
    name: "VIDITELNOST",
    active: true,
    type: filterType.TEXT
  },{
    atr: "rozhladove_pomery",
    name: "ROZHLADOVE_POMERY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "delenie_komunikacie",
    name: "DELENIE_KOMUNIKACIE",
    active: true,
    type: filterType.TEXT
  },{
    atr: "situovanie_nehody_na_komunikacii",
    name: "SITUOVANIE_NEHODY_NA_KOMUNIKACII",
    active: true,
    type: filterType.TEXT
  },{
    atr: "riadenie_premavky_v_dobe_nehody",
    name: "RIADENIE_PREMAVKY_V_DOBE_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "miestna_uprava_prednosti_v_jazde",
    name: "MIESTNA_UPRAVA_PREDNOSTI_V_JAZDE",
    active: true,
    type: filterType.TEXT
  },{
    atr: "specificke_miesta_a_objekty_v_mieste_nehody",
    name: "SPECIFICKE_MIESTA_A_OBJEKTY_V_MIESTE_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "smerove_pomery",
    name: "SMEROVE_POMERY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "pocet_zucastnenych_vozidiel",
    name: "POCET_ZUCASTNENYCH_VOZIDIEL",
    active: true,
    type: filterType.TEXT
  },{
    atr: "miesto_dopravnej_nehody",
    name: "MIESTO_DOPRAVNEJ_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "druh_krizujuci_komunikaciu",
    name: "DRUH_KRIZUJUCI_KOMUNIKACIU",
    active: true,
    type: filterType.TEXT
  },{
    atr: "druh_vozidla",
    name: "DRUH_VOZIDLA",
    active: true,
    type: filterType.TEXT
  },{
    atr: "vyrobna_znacka_motoroveho_vozidla",
    name: "VYROBNA_ZNACKA_MOTOROVEHO_VOZIDLA",
    active: true,
    type: filterType.TEXT
  },{
    atr: "rok_vyroby",
    name: "ROK_VYROBY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "charakter_vozidla",
    name: "CHARAKTER_VOZIDLA",
    active: true,
    type: filterType.TEXT
  },{
    atr: "smyk",
    name: "SMYK",
    active: true,
    type: filterType.TEXT
  },{
    atr: "vozidlo_po_nehode",
    name: "VOZIDLO_PO_NEHODE",
    active: true,
    type: filterType.TEXT
  },{
    atr: "unik_provoznych_prepravovanych_hmot",
    name: "UNIK_PROVOZNYCH_PREPRAVOVANYCH_HMOT",
    active: true,
    type: filterType.TEXT
  },{
    atr: "sposob_vyprostenia_osob_z_vozidla",
    name: "SPOSOB_VYPROSTENIA_OSOB_Z_VOZIDLA",
    active: true,
    type: filterType.TEXT
  },{
    atr: "smer_jazdy_alebo_postavenie_vozidla",
    name: "SMER_JAZDY_ALEBO_POSTAVENIE_VOZIDLA",
    active: true,
    type: filterType.TEXT
  },{
    atr: "skoda_na_vozidle",
    name: "SKODA_NA_VOZIDLE",
    active: true,
    type: filterType.TEXT
  },{
    atr: "kategoria_vodica",
    name: "KATEGORIA_VODICA",
    active: true,
    type: filterType.TEXT
  },{
    atr: "stav_vodica",
    name: "STAV_VODICA",
    active: true,
    type: filterType.TEXT
  },{
    atr: "vonkajsie_ovplyvnenie_vodica",
    name: "VONKAJSIE_OVPLYVNENIE_VODICA",
    active: true,
    type: filterType.TEXT
  },{
    atr: "lokalita_nehody",
    name: "LOKALITA_NEHODY",
    active: true,
    type: filterType.TEXT
  },{
    atr: "x",
    name: "X",
    active: true,
    type: filterType.TEXT
  },{
    atr: "y",
    name: "Y",
    active: true,
    type: filterType.TEXT
  }
];

export default filterItemsTraffic;