import { Store } from "react-notifications-component";

const NotificationService = (): any => {
  return {
    success: (title: string, message: string, time: number = 5000): void => {
        Store.addNotification({
          title: title,
          message: message,
          type: "success",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: time,
            onScreen: true,
          },
        });
      },
      error: (title: string, message: string, time: number = 5000): void => {
        Store.addNotification({
          title: title,
          message: message,
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: time,
            onScreen: true,
          },
        });
      },
      warning: (title: string, message: string, time: number = 5000): void => {
        Store.addNotification({
          title: title,
          message: message,
          type: "warning",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: time,
            onScreen: true,
          },
        });
      },
  };
};

export default NotificationService();
