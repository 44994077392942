import { filterType, SearchFilter } from "./SearchFilter";

const filterItemsTrafficPeds: SearchFilter[] = [
  // {
  //   atr: "id",
  //   name: "ID",
  //   active: false,
  //   type: filterType.TEXT
  // },
  {
    atr: "dopravna_nehoda_id",
    name: "Dopravná nehoda ID",
    active: true,
    type: filterType.TEXT
  },{
    atr: "kategoria_chodca",
    name: "Kategória chodca",
    active: true,
    type: filterType.TEXT
  },{
    atr: "stav_chodca",
    name: "Stav chodca",
    active: true,
    type: filterType.TEXT
  },{
    atr: "chovanie_chodca",
    name: "Chovanie chodca",
    active: true,
    type: filterType.TEXT
  },{
    atr: "situacia_v_mieste_nehody",
    name: "Situácia v mieste nehody",
    active: true,
    type: filterType.TEXT
  },{
    atr: "datum",
    name: "Dátum",
    active: true,
    type: filterType.DATE
  },{
    atr: "x_suradnica",
    name: "X súradnica",
    active: false,
    type: filterType.NONE
  },{
    atr: "y_suradnica",
    name: "Y súradnica",
    active: false,
    type: filterType.NONE
  },{
    atr: "druh_pozemnej_komunikacie",
    name: "Druh pozemnej komunikácie",
    active: false,
    type: filterType.TEXT
  },{
    atr: "lokalita_nehody",
    name: "Lokalita nehody",
    active: false,
    type: filterType.TEXT
  },{
    atr: "zavinenie_nehody",
    name: "Zavinenie nehody",
    active: false,
    type: filterType.TEXT
  },{
    atr: "kraj",
    name: "Kraj",
    active: true,
    type: filterType.TEXT
  },{
    atr: "reflexivne_prvky_u_chodca",
    name: "Reflexívne prvky u chodca",
    active: false,
    type: filterType.TEXT
  },{
    atr: "chodec_na_osobnom_prepravniku",
    name: "Chodec na osobnom prepravníku",
    active: false,
    type: filterType.TEXT
  },{
    atr: "alkohol_u_chodca",
    name: "Alkohol u chodca",
    active: false,
    type: filterType.TEXT
  },{
    atr: "druh_drogy_u_chodca",
    name: "Druh drogy u chodca",
    active: false,
    type: filterType.TEXT
  },{
    atr: "pohlavie_osoby",
    name: "Pohlavie osoby",
    active: false,
    type: filterType.TEXT
  },{
    atr: "vek_chodca",
    name: "Vek chodca",
    active: false,
    type: filterType.NONE
  },{
    atr: "statna_prislusnost_chodca",
    name: "Štátna príslušnosť chodca",
    active: false,
    type: filterType.TEXT
  },{
    atr: "poskytnutie_prvej_pomoci",
    name: "Poskytnutie prvej pomoci",
    active: false,
    type: filterType.TEXT
  },{
    atr: "nasledky",
    name: "Následky",
    active: false,
    type: filterType.TEXT
  }
];

export default filterItemsTrafficPeds;