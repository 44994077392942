import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles} from "@material-ui/core/styles";
import ReportsService from '../../../services/ReportsService';
import PageForm from '../../../components/PageHeader/PageForm';
import ReportHeader from '../../../components/Report/ReportHeader';
import ReportToolbar from '../../../components/Report/ReportToolbar';
import { useNavigate } from 'react-router-dom';
import { IReport, /*IReportSection*/ } from "../../../interfaces/traffic_accidents/InterfacesTraffic";
import NotificationService from '../../../services/NotificationService';
import ReportDescription from "../../../components/Report/ReportDescription";
import Paper from "@material-ui/core/Paper";
import TReportSection from "../../../components/Report/TReportSection/TReportSection";
import useUser from "../../../use/useUser";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import GraphModal from "../../../components/MultiGraphs/GraphModal";
import filterItemsTrafficPeds from "../../../interfaces/ConstFilterTrafficPeds";
import SearchServiceTAP from "../../../services/SearchServiceTAP";//TODO: pre použitie ReportCenter pre rôzne datasources k jednolivým sekciám bude treba upraviť Service, ku ktorym sa pripajaju na BE
import { SearchFilter, SearchFilterValue } from "../../../interfaces/SearchFilter";
import { GraphValue } from "../../../interfaces/Graphs";
import TReportSelectionModal from "../../../components/Report/TReportSelectionModal";
import {v4 as uuidv4} from 'uuid';
import SFilterSettingsModal from "../../../components/Search/SFilterSettingsModal";
import { MdLocalPolice } from "react-icons/md";
import css from './Report.module.css';
import STableModal from "../../../components/Search/STableModal";
import { Sort } from "../../../interfaces/SortWT";
import ReportGrid from "./UI_Components/ReportGrid";

export default function TReport() {
    const [ loading , setLoading ] = useState(true);
    const [ isSaving , setIsSaving ] = useState(false);
    const [ showModal, setShowModal ] = useState(false);
    const [ showTableModal, setShowTableModal ] = useState(false);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ globalFilterValues, setGlobalFilterValues ] = useState<SearchFilterValue[]>([]);
    const [ editedSectionIndex, setEditedSectionIndex ] = useState<number | undefined>(undefined);
    const [ showReportModal, setShowReportModal ] = useState(false);
    const [ unsavedChanges, setUnsavedChanges ] = useState(true);
    const [ filter, setFilter ] = useState<SearchFilter[]>(filterItemsTrafficPeds);
    const [ user ] = useUser();
    const useStyles = makeStyles({
      root: {
        width: "100%",
        overflowX: "auto",
        padding: "10px",
      },
    });
    const classes = useStyles();  

    let { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation("traffic");
    const [report, setReport] = React.useState<IReport>({reportId: 'undefined', userId: user.id, name: '', description: '', data: '', type: 'Private', report_filters: "[]", date_created: '', date_last_updated: ''} as IReport);
    const [editMode, setEditMode] = React.useState(false);
    const [sections, setSections] = React.useState([] as any[]);
    
    const [savedReport, setSavedReport] = React.useState<IReport>({reportId: 'undefined', userId: user.id, name: '', description: '', data: '', type: 'Private', report_filters: "[]", date_created: '', date_last_updated: ''} as IReport);
    const [savedSections, setSavedSections] = React.useState([] as any[]);
    const [ savedGlobalFilterValues, setSavedGlobalFilterValues ] = useState<SearchFilterValue[]>([]);

    const updateReportName = (value: any) => {
      setReport((previous) => ({...previous, name: value}));
    }

    const updateReportDescription = (value: any) => {
      setReport((previous) => ({...previous, description: value}));
    }

    const updateReportData = (value: any) => {
      setReport((previous) => ({...previous, data: value}));
    }

    const updateReportType = (value: any) => {
      setReport((previous) => ({...previous, type: value}));
    }

    const updateReportGlobalFilters = (value: any) => {
      setReport((previous) => ({...previous, report_filters: JSON.stringify(value)}));
    }

    const updateReportDateCreated = (value: any) => {
      setReport((previous) => ({...previous, date_created: value}));
    }

    const updateReportDateUpdated = (value: any) => {
      setReport((previous) => ({...previous, date_last_updated: value}));
    }

    const loadData = () => {
      setLoading(true);
      ReportsService.getUserReport(id)
      .then((resp: any) => {
        setGlobalFilterValues(JSON.parse(resp.data.report_filters)) //setGlobalFilterValues musi byt pred setSections, inak sa dva krat zavola useEffect v Graph.tsx, ktory loaduje data cim sa nevykresli Loading overlay
        setReport(resp.data);
        setSections(JSON.parse(resp.data.data).sections)

        setSavedGlobalFilterValues(JSON.parse(resp.data.report_filters))
        setSavedReport(resp.data);
        setSavedSections(JSON.parse(resp.data.data).sections)
        setUnsavedChanges(false);

        setLoading(false);
      })
      .catch(() => {
        setUnsavedChanges(false);
        setLoading(false);        
      });
    }

    const toggleChecked = () => setEditMode(value => !value);

    useEffect(() => {
      if (id === "undefined") {
        setLoading(false);
        updateReportDateCreated(new Date().toLocaleString());
        updateReportDateUpdated(new Date().toLocaleString());
        toggleChecked()
      } else {
        loadData();
      }
    }, []);

  const saveReport = () => {
    if (report.name !== "") {

      setReport((previous) => {
        const updatedReport = { ...previous, date_last_updated: new Date().toLocaleString(), report_filters: JSON.stringify(globalFilterValues)};
        setIsSaving(true);
        if (updatedReport.reportId === null || id === "undefined") {
          ReportsService.insertReport(updatedReport)
            .then((resp: any) => {
              if(resp.status === 200) {
                setUnsavedChanges(false);
                setSavedReport(updatedReport);
                setSavedSections(JSON.parse(updatedReport.data).sections)
                setSavedGlobalFilterValues(globalFilterValues)
                console.log(1)
                NotificationService.success(t("Notification.saveSuccess"));
                setIsSaving(false);

              } else {
                NotificationService.error(t("Notification.saveFail"));
                setIsSaving(false);
              }
            })
            .catch(() => {
              NotificationService.error(t("Notification.saveFail"));
              setIsSaving(false);
            });
        } else {
          ReportsService.updateReport(updatedReport)
          .then((resp: any) => {
              if(resp.status === 200) {
                setUnsavedChanges(false);
                setSavedReport(updatedReport);
                setSavedSections(JSON.parse(updatedReport.data).sections)
                setSavedGlobalFilterValues(globalFilterValues)
                console.log(2)
                NotificationService.success(t("Notification.saveSuccess"));
                      setIsSaving(false);

              } else {
                NotificationService.error(t("Notification.saveFail"));
                      setIsSaving(false);
              }
            })
            .catch(() => { NotificationService.error(t("Notification.saveFail")); setIsSaving(false);});
        }
        return updatedReport;
      });

    } else {
      NotificationService.error(t("Traffic.reportNameError"));
    }
  };

  const goTo = () => {
    navigate("/traffic/report-center/");
  };

  const handleRemoveSection = (idx: number) => {
    setSections(prevSections => prevSections.filter((_, i) => i !== idx));
  }

  const handleAddSection = (newsection: any) => {
    setSections(prevSections => [...prevSections, newsection]);
  }

  const handleUpdateSection = (indexToUpdate: any, updatedSection: any) => {
    const tempDatasets = [...sections];
    tempDatasets[indexToUpdate as number] = updatedSection;
    setSections(tempDatasets);
  };

  const handleGraphEdit = (idx: any) => {
    setEditedSectionIndex(Number(idx));
    setShowModal(true);
  };

  const handleTableEdit = (idx: any) => {
    setEditedSectionIndex(Number(idx));
    setShowTableModal(true);
  };

  const handleDownloadPDF = () => {
    // const inputElement = document.getElementById('to-pdf');
    const inputElement = pdfRef.current;
    if (inputElement) {
      html2canvas(inputElement, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ration = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ration) / 2;

        pdf.addImage(imgData, 'PNG', imgX, 10, imgWidth * ration, imgHeight * ration);
        pdf.save(`${report.name}.pdf`);
      });
    }
  };

  const pdfRef = React.useRef(null);

  useEffect(() => {
    updateReportData(JSON.stringify({ sections: sections }));
  }, [sections]);

  useEffect(() => {
    if (JSON.stringify(report) !== JSON.stringify(savedReport) || JSON.stringify(sections) !== JSON.stringify(savedSections) || JSON.stringify(globalFilterValues) !== JSON.stringify(savedGlobalFilterValues)) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [globalFilterValues, report, savedGlobalFilterValues, savedReport, savedSections, sections]);

  const handleSaveGraph = (graph: GraphValue) => {
    setShowModal(false);
    let tempSections = [...sections];
    if (editedSectionIndex !== undefined) {
      tempSections[editedSectionIndex] = {
        ...tempSections[editedSectionIndex],
        graphData: graph
      };
    } else {
      tempSections.push(graph);
    }
    setSections(tempSections);
    setEditedSectionIndex(undefined);
  }

  const handleExportGraph = (id: string) => {
    setShowReportModal(false);
    const data = sections.filter((graph, idx) => idx === editedSectionIndex);
    ReportsService.addGraphToReport(id, {graphData: JSON.stringify(data[0].graphData), type:JSON.stringify({type: data[0].type.type}), predictionValues: JSON.stringify(data[0].predictionValues), layoutData:JSON.stringify({ i: uuidv4(), x: 50, y: 50, w: 12, h: 2, minW: 3, minH: 2 })})
    .then((resp: any) => {
        if (resp.status === 200) {
          NotificationService.success(t("Graph.succGraphExport"));
        } else {
          NotificationService.success(t("Graph.failGraphExport"));
        }
    })
    .catch(() => {NotificationService.success(t("Graph.failGraphExport"));});
    setEditedSectionIndex(undefined);
  }

  const handleSectionExportToReport = (idx: number) => {
    setShowReportModal(true);
    setEditedSectionIndex(idx);
  }

  const openGlobalFilter = () => {
    setShowFilterModal(true);
  }
  
  //zavola sa ked sa klikne na SAVE v SFilterSettingsModal
  const handleFilterChange = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
    setFilter(newFilter);
    // setFilterValues(newValues);
    setGlobalFilterValues(newValues);
  }

  const handleFilterChangeSection = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
    let tempSections = [...sections];
    if (editedSectionIndex !== undefined) {
      tempSections[editedSectionIndex] = {
        ...tempSections[editedSectionIndex],
        tableData: {
          ...tempSections[editedSectionIndex].tableData,
          filter: newValues,
          filterNames: newFilter
        }
      };
    }
    setSections(tempSections);
    setEditedSectionIndex(undefined);
  }

  const handleFilterValuesAdd = (newValues: SearchFilterValue, idx: number) => {
    console.log(sections[idx].tableData.filter)
    let tempSections = [...sections];
    if (idx !== undefined) {
      tempSections[idx] = {
        ...tempSections[idx],
        tableData: {
          ...tempSections[idx].tableData,
          filter: [
            ...tempSections[idx].tableData.filter,
            newValues
          ]
        }
      };
    }
    setSections(tempSections);
    setEditedSectionIndex(undefined);
  }

  const handleSortChange = (newSort: Sort, idx: number) => {
    let tempSections = [...sections];
    if (idx !== undefined) {
      tempSections[idx] = {
        ...tempSections[idx],
        tableData: {
          ...tempSections[idx].tableData,
          sort: newSort
        }
      };
    }
    setSections(tempSections);
    setEditedSectionIndex(undefined);
  }

  const sectionsDraw = sections.map((section, idx) =>
    <TReportSection //vseobecny "predok" vsetkych typov sekcii
      key={idx}
      id={idx}
      data={section}
      type={section.type.type}
      editMode={editMode}
      globalFilterValues={globalFilterValues}
      onRemove={handleRemoveSection}
      onUpdate={handleUpdateSection}
      onEditGraph={() => handleGraphEdit(idx)}
      onEditTable={() => handleTableEdit(idx)}
      onExportSection={() => handleSectionExportToReport(idx)}
      handleFilterValuesAdd={handleFilterValuesAdd}
      handleSortChange={handleSortChange}
    />
  );

  return (
    <>
    <TReportSelectionModal 
      show={showReportModal}
      endpointService={ReportsService}
      onClose={() => setShowReportModal(false)}
      onExport={(id: string) => handleExportGraph(id)}
      currentReportId={report.reportId}
    />
    {editedSectionIndex !== undefined && (sections[editedSectionIndex].type.type === "prediction_graph" /*TODO: spravit vlastny modal pre prediction_graph */|| sections[editedSectionIndex].type.type === "graph") &&
    <GraphModal 
      show={showModal}
      filter={filterItemsTrafficPeds}
      filterValues={globalFilterValues} //globalne filtre - pre vsetky grafy v reporte
      graphValue={editedSectionIndex !== undefined ? sections[editedSectionIndex].graphData : undefined}
      endpointService={SearchServiceTAP}
      onClose={() => setShowModal(false)}
      onSave={handleSaveGraph}
      settingTAP={true}
      analzyse={editedSectionIndex !== undefined ? (sections[editedSectionIndex].type.type === "prediction_graph" ? true : false) : false}
      reportCenter={true}
    />}
    {editedSectionIndex !== undefined && sections[editedSectionIndex].type.type === "table" &&
    <STableModal
      show={showTableModal}
      filter={editedSectionIndex !== undefined ? sections[editedSectionIndex].tableData.filterNames : filterItemsTrafficPeds}
      // customExpressions={props.customExpressions}
      filterValues={editedSectionIndex !== undefined ? sections[editedSectionIndex].tableData.filter : []}
      withVisibleOption
      onFilterChange={handleFilterChangeSection}
      // onExpressionChange={props.onExpressionChange}
      setShow={setShowTableModal}
      endpointService={SearchServiceTAP}
    />}
    <SFilterSettingsModal //Global filter modal
      filter={filter} 
      filterValues={globalFilterValues}
      onFilterChange={handleFilterChange} 
      endpointService={SearchServiceTAP}
      showModal={showFilterModal}
      handleCloseModal={() => setShowFilterModal(false)}
    />
    <PageForm label={t("Traffic.trafficReportCenter")}>
      <div className={css.full_size} style={{ height: '100%', width: '100%', margin: 0 }}>
        { loading && <div className={css.loading}>
            <div><MdLocalPolice /> {t("Traffic.loading")}</div>
        </div> }
        <div id="to-pdf" ref={pdfRef} className="to-pdf">
          <ReportHeader
            updateReportName={updateReportName}
            editMode={editMode}
            report={report}
          />

          <div data-html2canvas-ignore="true">
            <ReportToolbar
                saveReport={saveReport}
                editModeFunc={toggleChecked}
                reportCenterFunc={goTo}
                updateReportType={updateReportType}
                handleDownloadPDF={handleDownloadPDF}
                openGlobalFilter={openGlobalFilter}
                editMode={editMode}
                report={report}
                unsavedChanges={unsavedChanges}
                loading={loading}
                isSaving={isSaving}
                globalFilterValues={globalFilterValues}
            />
          </div>
          <Paper className={classes.root}>
            <ReportDescription    
              updateReportDescription={updateReportDescription}
              editMode={editMode}
              report={report}
            />
            <ReportGrid sectionsDraw={sectionsDraw} /*sections={sections}*/ handleAddSection={handleAddSection} setSections={setSections} editMode={editMode}/>
          </Paper>
        </div>
      </div>
    </PageForm>
    </>
  );
}