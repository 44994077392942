import { ReactNode, useEffect, useState } from "react";
import { setModal } from "../../services/ModalService";
import { AiOutlineClose } from 'react-icons/ai';

interface Settings {
    title: string,
    children: ReactNode,

    outerClose?: boolean,
    returnMethod?: Function
}

export default function Modal() {

    const [show, setShow]: [boolean, Function] = useState(false);
    const [settings, setSettings]: [Settings, Function] = useState({
        title: "Toto je nadpis",
        children: "Test"
    });

    useEffect(() => {
        setModal(setShow, setSettings);
    }, [])

    const hide = (e: React.MouseEvent): void => {
        setShow(false);
        if (settings.returnMethod) {
            settings.returnMethod({ abort: true });
        }
    }

    const innerClickHandler = (e: React.MouseEvent): void => {
        e.stopPropagation();
    }

    return (
        <div className={`box-12 ${show ? "modal" : "modal hide"}`} onClick={ settings.outerClose === false ? () => {} : hide }>
            <div className="modal-outer">
                <div className="modal-inner" onClick={ innerClickHandler }>
                    <div className="modal-title">
                        { settings.title }
                        <AiOutlineClose onClick={ hide } />
                    </div>
                    <div className="modal-text">
                        { settings.children }
                    </div>
                </div>
            </div>
        </div>
    );
}

export type { Settings };