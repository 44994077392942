
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import image3 from '../../../../assets/css/images/police.jpg';
import HeaderCarousel from '../../../../components/HeaderCarousel/HeaderCarousel';
import { CRIMINAL_OFFENCE_INFO_SITE, CRIMINAL_OFFENCE_REPORT_SITE, STATES_SITE } from '../../../../interfaces/europe/Constants';
import './HomeEurope.css';
import { useTranslation } from "react-i18next";


export default function HomeEurope() {

  const { t } = useTranslation("europe");

  return (
    <>
      <HeaderCarousel name={t("home.title")} image={image3} />

      <div className="underLabel">
      
        <div className="inline-block m-5">
          <Card sx={{ maxWidth: 345, height: 340, }}>
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image='/images/nahlasene.jpg'
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              {t("home.states")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("home.statesInfo")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"> <Link to={STATES_SITE}>{t("home.showMore")}</Link></Button>
            </CardActions>
          </Card>
        </div>
        <div className="inline-block m-5">
          <Card sx={{ maxWidth: 345, height: 340, }}>
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image='/images/pris.png'
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t("home.criminalOffences")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("home.criminalOffencesInfo")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"> <Link to={CRIMINAL_OFFENCE_INFO_SITE}>{t("home.showMore")}</Link></Button>
            </CardActions>
          </Card>
        </div>
        <div className="inline-block m-5">
          <Card sx={{ maxWidth: 345, height: 340, }}>
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image='/images/court.png'
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t("home.rcriminalOffences")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("home.rcriminalOffencesInfo")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"> <Link to={CRIMINAL_OFFENCE_REPORT_SITE}>{t("home.showMore")}</Link></Button>
            </CardActions>
          </Card>


        </div>
      </div>
    </>
  );
}
