import styled from 'styled-components';
import { Button } from '@mui/material';
import { FaPlus } from 'react-icons/fa';

interface IProps {
  createReportSection: () => void;
}

const StyledIconButton = styled(Button)`
  display: flex;
  position: relative;
  padding: 32px 24px 24px 24px;
  transition: width 0.2s;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
  height: 100%;

  @supports (display: grid) {
    grid-column: ${0};
    margin: 0;
  }
`;

const ReportSectionPlaceholder = (props: IProps) => {
  return (
    <StyledIconButton onClick={props.createReportSection}>
      <FaPlus size="70" style={{ margin: '5px' }} />
    </StyledIconButton>
  );
};

export default ReportSectionPlaceholder;
