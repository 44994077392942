import { IReport } from "../../interfaces/traffic_accidents/InterfacesTraffic";
import ReportTextbox from "./ReportTextBox";

interface IProps {
  updateReportDescription: (value: any) => void;
  editMode: boolean,
  report: IReport | null,
}

export default function ReportDescription(props: IProps) {
    
    return (
      <ReportTextbox 
        editMode={props.editMode}
        textWithTags={props.report?.description}
        deleteable={false}
        onRemove={() => {}}
        updateText={props.updateReportDescription}
      />
    );
}