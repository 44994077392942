import { MouseEventHandler, useEffect, useState } from 'react';
import './StyleComponents.css';

interface SizeFormat {
  size: number,
  offset?: number,
  offsetPost?: number,
  paddingY?: number,
  paddingX?: number,
  align?: "center" | "left" | "right" | "justify",
  display?: boolean,
  className?: string
};

interface BoxProps {
  children: any,
  className?: string,
  style?: any,
  xs: SizeFormat
  sm?: SizeFormat,
  md?: SizeFormat,
  lg?: SizeFormat,
  xl?: SizeFormat,
  xxl?: SizeFormat,

  onClick?: MouseEventHandler
};

function Box(props: BoxProps) {

  const mediaSM = window.matchMedia('(min-width: 564px)');
  const mediaMD = window.matchMedia('(min-width: 768px)');
  const mediaLG = window.matchMedia('(min-width: 992px)');
  const mediaXL = window.matchMedia('(min-width: 1200px)');
  const mediaXXL = window.matchMedia('(min-width: 1600px)');
  const [isSM, setSM] = useState(mediaSM.matches);
  const [isMD, setMD] = useState(mediaMD.matches);
  const [isLG, setLG] = useState(mediaLG.matches);
  const [isXL, setXL] = useState(mediaXL.matches);
  const [isXXL, setXXL] = useState(mediaXXL.matches);

  /*useEffect(() => {
    mediaSM.addEventListener("change", e => setSM(e.matches));
    mediaMD.addEventListener("change", e => setMD(e.matches));
    mediaLG.addEventListener("change", e => setLG(e.matches));
    mediaXL.addEventListener("change", e => setXL(e.matches));
    mediaXXL.addEventListener("change", e => setXXL(e.matches));
  });*/

  useEffect(() => {
    const smEL = (e: MediaQueryListEvent) => setSM(e.matches);
    const smMD = (e: MediaQueryListEvent) => setMD(e.matches);
    const smLG = (e: MediaQueryListEvent) => setLG(e.matches);
    const smXL = (e: MediaQueryListEvent) => setXL(e.matches);
    const smXXL = (e: MediaQueryListEvent) => setXXL(e.matches);
    mediaSM.addEventListener("change", smEL);
    mediaMD.addEventListener("change", smMD);
    mediaLG.addEventListener("change", smLG);
    mediaXL.addEventListener("change", smXL);
    mediaXXL.addEventListener("change", smXXL);
    return () => {
      mediaSM.removeEventListener("change", smEL);
      mediaMD.removeEventListener("change", smMD);
      mediaLG.removeEventListener("change", smLG);
      mediaXL.removeEventListener("change", smXL);
      mediaXXL.removeEventListener("change", smXXL);
    };
  }, [mediaSM, mediaMD, mediaLG, mediaXL, mediaXXL]);

  let className = `box ${ props.className ? props.className : ``}`;
  const style: any = {};
  style.width = `calc(${ props.xs.size }% - ${ props.xs.paddingX !== undefined ? 2 * props.xs.paddingX : 0 }px)`;
  if (props.xs.offset) { style.marginLeft = `${ props.xs.offset }%`; }
  if (props.xs.offsetPost) { style.marginRight = `${ props.xs.offsetPost }%`; }
  if (props.xs.paddingX) { style.paddingLeft = `${ props.xs.paddingX }px`; style.paddingRight = `${ props.xs.paddingX }px`; }
  if (props.xs.paddingY) { style.paddingTop = `${ props.xs.paddingY }px`; style.paddingBottom = `${ props.xs.paddingY }px`; }
  if (props.xs.align) { style.textAlign = props.xs.align; }
  if (props.xs.display !== undefined) { style.display = `${ props.xs.display ? `inline-block` : `none` }`; }
  if (props.xs.className) { className += " " + props.xs.className; }

  if (isSM && props.sm) {
    style.width = `calc(${ props.sm.size }% - ${ props.sm.paddingX !== undefined ? 2 * props.sm.paddingX : 0 }px)`;
    if (props.sm.offset) { style.marginLeft = `${ props.sm.offset }%`; }
    if (props.sm.offsetPost) { style.marginRight = `${ props.sm.offsetPost }%`; }
    if (props.sm.paddingX) { style.paddingLeft = `${ props.sm.paddingX }px`; style.paddingRight = `${ props.sm.paddingX }px`; }
    if (props.sm.paddingY) { style.paddingTop = `${ props.sm.paddingY }px`; style.paddingBottom = `${ props.sm.paddingY }px`; }
    if (props.sm.align) { style.textAlign = props.sm.align; }
    if (props.sm.display !== undefined) { style.display = `${ props.sm.display ? `inline-block` : `none` }`; }
    if (props.sm.className) { className += " " + props.sm.className; }
  }
  if (isMD && props.md) {
    style.width = `calc(${ props.md.size }% - ${ props.md.paddingX !== undefined ? 2 * props.md.paddingX : 0 }px)`;
    if (props.md.offset) { style.marginLeft = `${ props.md.offset }%`; }
    if (props.md.offsetPost) { style.marginRight = `${ props.md.offsetPost }%`; }
    if (props.md.paddingX) { style.paddingLeft = `${ props.md.paddingX }px`; style.paddingRight = `${ props.md.paddingX }px`; }
    if (props.md.paddingY) { style.paddingTop = `${ props.md.paddingY }px`; style.paddingBottom = `${ props.md.paddingY }px`; }
    if (props.md.align) { style.textAlign = props.md.align; }
    if (props.md.display !== undefined) { style.display = `${ props.md.display ? `inline-block` : `none` }`; }
    if (props.md.className) { className += " " + props.md.className; }
  }
  if (isLG && props.lg) {
    style.width = `calc(${ props.lg.size }% - ${ props.lg.paddingX !== undefined ? 2 * props.lg.paddingX : 0 }px)`;
    if (props.lg.offset) { style.marginLeft = `${ props.lg.offset }%`; }
    if (props.lg.offsetPost) { style.marginRight = `${ props.lg.offsetPost }%`; }
    if (props.lg.paddingX) { style.paddingLeft = `${ props.lg.paddingX }px`; style.paddingRight = `${ props.lg.paddingX }px`; }
    if (props.lg.paddingY) { style.paddingTop = `${ props.lg.paddingY }px`; style.paddingBottom = `${ props.lg.paddingY }px`; }
    if (props.lg.align) { style.textAlign = props.lg.align; }
    if (props.lg.display !== undefined) { style.display = `${ props.lg.display ? `inline-block` : `none` }`; }
    if (props.lg.className) { className += " " + props.lg.className; }
  }
  if (isXL && props.xl) {
    style.width = `calc(${ props.xl.size }% - ${ props.xl.paddingX !== undefined ? 2 * props.xl.paddingX : 0 }px)`;
    if (props.xl.offset) { style.marginLeft = `${ props.xl.offset }%`; }
    if (props.xl.offsetPost) { style.marginRight = `${ props.xl.offsetPost }%`; }
    if (props.xl.paddingX) { style.paddingLeft = `${ props.xl.paddingX }px`; style.paddingRight = `${ props.xl.paddingX }px`; }
    if (props.xl.paddingY) { style.paddingTop = `${ props.xl.paddingY }px`; style.paddingBottom = `${ props.xl.paddingY }px`; }
    if (props.xl.align) { style.textAlign = props.xl.align; }
    if (props.xl.display !== undefined) { style.display = `${ props.xl.display ? `inline-block` : `none` }`; }
    if (props.xl.className) { className += " " + props.xl.className; }
  }
  if (isXXL && props.xxl) {
    style.width = `calc(${ props.xxl.size }% - ${ props.xxl.paddingX !== undefined ? 2 * props.xxl.paddingX : 0 }px)`;
    if (props.xxl.offset) { style.marginLeft = `${ props.xxl.offset }%`; }
    if (props.xxl.offsetPost) { style.marginRight = `${ props.xxl.offsetPost }%`; }
    if (props.xxl.paddingX) { style.paddingLeft = `${ props.xxl.paddingX }px`; style.paddingRight = `${ props.xxl.paddingX }px`; }
    if (props.xxl.paddingY) { style.paddingTop = `${ props.xxl.paddingY }px`; style.paddingBottom = `${ props.xxl.paddingY }px`; }
    if (props.xxl.align) { style.textAlign = props.xxl.align; }
    if (props.xxl.display !== undefined) { style.display = `${ props.xs.display ? `inline-block` : `none` }`; }
    if (props.xxl.className) { className += " " + props.xxl.className; }
  }

  return (
    <div 
      className={ className }
      style={ style }
      onClick={ props.onClick }
    >
      { props.children }
    </div>
  );
}

interface BaseProps {
  children?: any,
  className?: string
}

interface BaseClickProps {
  children: any,
  className?: string,
  onClick?: MouseEventHandler
}

interface BaseStyleClickProps {
  children: any,
  className?: string,
  style?: any,
  onClick?: MouseEventHandler
}

function HR(props: BaseProps) {
  return (
    <div className={ `hr ${props.className ? props.className : ``}` }>
      { props.children }
      <div className={ `hr-line ${props.className ? props.className : ``}` }></div>
    </div>
  )
}

function MenuButton(props: BaseClickProps) {
  return (
    <button type="button" className={ `menu-button ${props.className ? props.className : ``}` } onClick={ props.onClick }>
      { props.children }
    </button>
  )
}

function CircleButton(props: BaseClickProps) {
  return (
    <button type="button" className={ `circle-button ${props.className ? props.className : ``}` } onClick={props.onClick}>
      { props.children }
    </button>
  )
}

function Button(props: BaseStyleClickProps) {
  return (
    <button type="button" className={ `button ${props.className ? props.className : ``}` } style={ props.style } onClick={props.onClick}>
      { props.children }
    </button>
  )
}

function UserMenuButton(props: BaseClickProps) {
  return (
    <button type="button" className={ `usermenu-button ${props.className ? props.className : ``}` } onClick={ props.onClick }>
      { props.children }
    </button>
  )
}


export { Box, HR, MenuButton, CircleButton, Button, UserMenuButton };