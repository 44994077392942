import GraphControl from "./GraphControl";
import {Button} from "../StyleComponents/StyleComponents";
import {useReducer} from "react";
import SelectionData from "../../interfaces/Graphs/SelectionData";
import { useTranslation } from "react-i18next";

interface EmmitData {
    Name: string,
    Option: string,
    Data: string[]
}
interface GraphControl {
    label: string,
    useIterval: boolean,
    selectionData: SelectionData[],
    name: string
}
interface GraphControllerProps {
    GraphControls: GraphControl[],
    OnClick: Function
}

const initialState = {}

function FuncReducer(state: any, action: any) {
    const {type, data} = action;
    return {
        ...state,
        [type]: state[type] = data
    };
}

export default function GraphController(props: GraphControllerProps) {
    const {t} = useTranslation("traffic");
    const [state, dispatch] = useReducer(FuncReducer, initialState);

    const changedData = (data : EmmitData) => {
        dispatch({type: data.Name, data: data});
    }
    function OnBtnClick() {
        props.OnClick(state);
    }

    return (
        <div className="graph_controls">
            <div className="graph-options">
                {
                    props.GraphControls.map(control => (
                        <GraphControl key={control.name} label={control.label} useInterval={control.useIterval} selectionData={control.selectionData} name={control.name} emitChange={changedData}/>
                    ))
                }
            </div>
            <Button onClick={OnBtnClick}>{t("TGraphs.obnov")}</Button>
        </div>
    );
}