import Menu from '../Menu/Menu';
import Page from '../Page/Page';
import Top from '../Top/Top';

export default function Layout() {

  return (
    <div className='box-12'>
      <div className='box-12 none box-lg-3 lg-default box-fhd-2'>
        <Menu />
      </div>
      <div className='box-12 box-lg-9 box-fhd-10'>
        <div className='box-12'><Top /></div>
        <div className='box-12'><Page /></div>
      </div>
    </div>
  );
}
