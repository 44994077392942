import Modal from 'react-modal';
import css from './Modal.module.css';
import { ReactNode } from 'react';
import { FaTimes } from 'react-icons/fa';


interface IProps {
    children: ReactNode;
    show: boolean;
    title: string;
    width?: string;
    onClose: () => void;
}

export default function CustomModal(props: IProps) {
    return (
    <Modal
        isOpen={props.show}
        className={css.modal}
        style={{ content: { width: props.width } }}
    >
        <div className={`col-12 ${css.head}`}>
            <div className={css.title}>
                {props.title}
            </div>
            <div className={css.close} onClick={props.onClose}>
                <FaTimes />
            </div>
        </div>
        <div className='col-12'>
            <div className={css.content}>
                {props.children}
            </div>
        </div>
    </Modal>
    )
}