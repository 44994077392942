import { SearchFilterValue } from "../../../interfaces/SearchFilter";

interface IProps {
    id: string
    data: any
    editMode: boolean
    globalFilterValues: SearchFilterValue[]
    onRemove: (value: any) => void;
    onUpdate: (id: any, sectionData: any) => void;
}

export default function TReportCard(props: IProps) { 
  return (
    <>
    {props.id}
    {props.data}
    </>
  );
    // return (
  //   <>
  //     <div style={{paddingRight: '10px'}}>  
  //       <div className="card">
  //         <div className="card-header">
  //           {/* <h5 className="card-title">{props.data.textboxData.title}</h5> */}
  //           <div className="card-tools">
  //             <button type="button" className="btn btn-tool" onClick={() => props.onRemove(props.id)}>
  //             <FaTimes />
  //             </button>
  //           </div>
  //         </div>
  //         <div className="card-body">
  //           <p className="card-text">{props.data.textData}</p>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
}