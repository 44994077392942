import { useEffect, useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import CustomModal from "../Modal/CustomModal";
import { Button } from "../StyleComponents/StyleComponents";

interface IProps {
    show: boolean;
    onClose: Function;
    specify: string;
    submit: Function;
};


export default function SpecifyDataModal(props: IProps) {
    const [changedSpecify, setChangedSpecify] = useState(props.specify);
    const [from, setFrom] = useState("datum_zacatia");
    const [to, setTo] = useState("datum_ukoncenia");
    const dates = [{id: 'datum_podania', name: "Dátum podania"}, {id: 'datum_zacatia', name: "Dátum začatia"}, {id: 'datum_pokracovania_tc', name: "Dátum pokračovania TČ"},
        {id: 'datum_ukoncenia', name: "Dátum ukončenia"}, {id: 'datum_obvinenia', name: "Dátum obvinenia"}, {id: 'datum_rozsirenia', name: "Dátum rozšírenia"},
        {id: 'datum_pokracovania_p', name: "Dátum pokračovania P"}, {id: 'datum_prevzatia', name: "Dátum prevzatia"}, {id: 'datum_vratenia', name: "Dátum vrátenia"},
        {id: 'datum_ukoncenia_po_dos', name: "Dátum ukončenia PO DOS"}];

    useEffect(() => {
        if (props.show === true && props.specify.includes('-')) {
            setFrom(props.specify.substring(0, props.specify.indexOf('-')));
            setTo(props.specify.substring(props.specify.indexOf('-') + 1));
        }
    }, [props.show, props.specify]);

    const submit = () => {
        if (changedSpecify === "-") {
            props.submit(from + "-" + to);
        } else {
            props.submit(changedSpecify);
        }
    }

    const selectItems = dates.map((item) => 
        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
    );

    return (
        <CustomModal
            show={props.show}
            title={"Spresnenie dát - zvolte jednu možnosť"}
            width="800px"
            onClose={() => props.onClose()}
        >
            <Typography variant="h6">Počet trestných činov rozdelený po:</Typography>
            <div className='box-8 py-1 box-lg-3' style={{ marginBottom: "32px" }}>
                <input type="checkbox" checked={ changedSpecify === 'DD.MM.YYYY' } onChange={ () => setChangedSpecify('DD.MM.YYYY') } /> Dni
            </div>
            <div className='box-8 py-1 box-lg-3'>
                <input type="checkbox" checked={ changedSpecify === 'WW.YYYY' }  onChange={ () => setChangedSpecify('WW.YYYY') } /> Týždne
            </div>
            <div className='box-8 py-1 box-lg-3'>
                <input type="checkbox" checked={ changedSpecify === 'MM.YYYY' }  onChange={ () => setChangedSpecify('MM.YYYY') } /> Mesiace
            </div>
            <div className='box-8 py-1 box-lg-3'>
                <input type="checkbox" checked={ changedSpecify === 'YYYY' }  onChange={ () => setChangedSpecify('YYYY') } /> Roky
            </div>
            <Typography variant="h6">Alebo dĺžka trvadia trestného činu od do:</Typography>
            <div className='box-8 py-1 box-lg-12' style={{ marginBottom: "12px" }}>
                <input type="checkbox" checked={ changedSpecify.includes('-') }  onChange={ () => setChangedSpecify('-') } /> Aplikovať dlžku trvania
            </div>
            <div className='box-4 text-center px-1'>
                <Select
                    labelId="selectFrom"
                    id="selectFrom"
                    value={from}
                    onChange={(e: any) => setFrom(e.target.value)}
                    fullWidth
                >
                    { selectItems }
                </Select>
            </div>
            <div className='box-4 text-center px-1'>
                <Select
                    labelId="selectTo"
                    id="selectTo"
                    value={to}
                    onChange={(e: any) => setTo(e.target.value)}
                    fullWidth
                >
                    { selectItems }
                </Select>
            </div>
            <Grid sx={{ textAlign: "right" }}>
                <Button onClick={() => props.onClose()}>Zatvoriť</Button>
                <Button onClick={() => submit()}>Zmeniť</Button>
            </Grid>
        </CustomModal>
    )
}