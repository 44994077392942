import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback, useEffect, useState } from "react";
import {IDataset, PredictionInput, PredictionGraphValues} from "../../../interfaces/traffic_accidents/InterfacesTraffic";
import { SearchFilter, SearchFilterValue } from "../../../interfaces/SearchFilter";
import CustomModal from "../../Modal/CustomModal";
import SFilterSettings from "../../Search/SFilterSettings";
import { Button } from "../../StyleComponents/StyleComponents";
import TStatistics from "./TStatistics";
import SearchServiceTAP from '../../../services/SearchServiceTAP';
import ReportServiceTAP from '../../../services/ReportsService';
import ShowBox from "../../ShowBox";
import SFilterValueDraw from "../../Search/SFilterValueDraw";
import { useTranslation } from 'react-i18next';
import Settings from "../../Settings/Settings";
import MultiGraphs from "../../MultiGraphs/MultiGraphs";
import { GraphValue } from "../../../interfaces/Graphs";
import TPrediction from "./TPrediction";

const hoverTabs = {
  "&:hover": {
    backgroundColor: 'lightgray'
  }, 
  borderRight: "solid gray 2px",
  transition: "0.5s",
  cursor: "pointer"
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: "10px",
    marginBottom: "10px",
    backgroundColor: "#FDFDFD",
  },
});

interface IProps {
  idx: Number;
  dataset: IDataset;
  deleteDataset: Function;
  duplicateDataset: Function;
  renameDataset: Function;
  setDataset: Function;
}

export default function TDataset(props: IProps) {
  const [ values, setValues ] = useState<any[]>([]);
  const [ xNames, setXNames ] = useState<any[]>([]);
  const [ valueNames, setValueNames ] = useState<any[]>([]);
  const [ inputData, setInputData ] = useState<PredictionGraphValues>();

  const classes = useStyles();
  const { t } = useTranslation("crimes");
  const [showModal, setShowModal] = useState(false);
  const [newDatasetName, setNewDatasetName] = useState(props.dataset.title);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (showModal === true)
      setNewDatasetName(props.dataset.title);
  }, [showModal, props]);

  const handleFilterChange = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
    const newDataset = props.dataset;
    newDataset.filter = newFilter;
    newDataset.filterValues = newValues;
    props.setDataset(props.idx, newDataset);
  }

  const handleGraphValueChange = (newGraphs: GraphValue[]) => {
    const newDataset = props.dataset;
    const tempArr = [];
    tempArr.push(newGraphs[newGraphs.length - 1]);
    newDataset.graphValues = tempArr;
    props.setDataset(props.idx, newDataset);
  }

  const handleFilterValuesChange = (newValues: SearchFilterValue[]) => {
    const newDataset = props.dataset;
    newDataset.filterValues = newValues;
    props.setDataset(props.idx, newDataset);
  }

  const handleImportData = (data: any) => {
    const newDataset = props.dataset;
    if (data?.filter) {
        newDataset.filter = data.filter;
    }
    if (data?.filterValues) {
        newDataset.filterValues = data.filterValues;
    }
    if (data?.graphValues) {
        const tempArr = [];
        tempArr.push(data.graphValues[data.graphValues.length - 1]);
        newDataset.graphValues = tempArr;
    }
    if (data?.expressionValues) {
        newDataset.expressionValues = data.expressionValues;
    }
    props.setDataset(props.idx, newDataset);
  }

  const tabShow = <Grid container marginBottom={4} borderRadius="6px" overflow="hidden">
                    <Grid item md={6} sx={ hoverTabs } onClick={() => setActiveTab(1)}>
                      <Typography style={{fontWeight: activeTab === 1 ? "bold" : ""}} variant="body1" padding={2}>{t("analyzis.baseStatistics")}</Typography>
                    </Grid>
                    <Grid item md={6} sx={ hoverTabs } style={{borderRight: "0px"}} onClick={() => setActiveTab(3)}>
                      <Typography style={{fontWeight: activeTab === 3 ? "bold" : ""}} variant="body1" padding={2}>{t("analyzis.prediction")}</Typography>
                    </Grid>
                  </Grid>;

  const dataToFile = {
    filter: props.dataset.filter,
    filterValues: props.dataset.filterValues,
    sort: { page: 1, perPage: 5, label: "", order: true },
    graphValues: props.dataset.graphValues,
    expressionValues: props.dataset.expressionValues
  };

  useEffect(() => {
    const abortCtrl = new AbortController();
    var dynamicValues = props.dataset?.graphValues[0]?.data;
    if (dynamicValues) {
      dynamicValues.forEach((dataset: any) => {
        const fltValues = dataset.filter ? dataset.filter : props.dataset.filterValues;
        const fval = fltValues?.map((value: any) => ({
            type: value.type.value,
            value: value.value.map((val: any) => ({
                type: val.type.value,
                atr: val.atribute.value,
                option: val.option.value,
                value: val.value.map((valInVal: any) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
            }))
        }));
        SearchServiceTAP.getGraphData!(dataset.dataFirst, dataset.dataSecond, dataset.dataFirstInterval, dataset.dataSecondInterval
          , dataset.dataFirstIntervalBy, dataset.dataSecondIntervalBy, dataset.nullValues, props.dataset.graphValues[0].group, fval)
        .then((response: any) => {
          decodeSimpleData(response.data.data);
        })
      });
    }
    return () => abortCtrl.abort();
  }, [props.dataset.graphValues, props.dataset.filterValues]);

  const decodeSimpleData = useCallback((data: any) => {
    let tempVal: any[] = [];
    let tempXNames: any[] = [];
    data.forEach((data: any) => {
      tempXNames.push(data.name1);
      tempVal.push(data.value);
    });
    setValues([tempVal]);//hodnoty v grafe
    setXNames(tempXNames); //hodnoty na x osi
    setValueNames([props.dataset.graphValues[0].data?.[0].title || ""]);//nazov datasetu
    setInputData({ "values": tempVal, "xAxisValues": tempXNames })
  }, [props.dataset.graphValues]);

  return (
  <>
    <Paper className={classes.root} elevation={4}>
      <Typography variant="h6">{props.dataset.title}</Typography>

      <Grid sx={{ textAlign: "left" }} marginBottom={2}>
        <Grid textAlign={ "left" }>
          <Settings 
              dataKey="TAPSearch"
              dataToFile={dataToFile}
              onImportData={handleImportData}
              saveOnRefresh={false}
              loadOnRefresh={false}
          />
          <SFilterSettings 
              filter={props.dataset.filter} 
              filterValues={props.dataset.filterValues}
              onFilterChange={handleFilterChange} 
              endpointService={SearchServiceTAP}
          />
          <ShowBox className="box-12 py-2" name={t("search.hideFilters") + ""}>
              <SFilterValueDraw 
                  filterValues={props.dataset.filterValues} 
                  onChange={handleFilterValuesChange} 
              />
          </ShowBox>
          <ShowBox className="box-12 py-2" name={t("search.hideGraphs") + ""}>
              <MultiGraphs 
                  filter={props.dataset.filter}
                  customExpressions={props.dataset.expressionValues}
                  filterValues={props.dataset.filterValues}
                  graphValues={props.dataset.graphValues}
                  onChange={handleGraphValueChange}
                  endpointService={SearchServiceTAP} 
                  endpointServiceReport={ReportServiceTAP}
                  settingTAP = {true}
                  analzyse
              />
          </ShowBox>
        </Grid>

      </Grid>
      { props.dataset.graphValues.length > 0 ? <Grid>
        {tabShow}
        { activeTab === 1 && <TStatistics dataset={props.dataset} values={values}/>}
        { activeTab === 3 && <TPrediction dataset={props.dataset} inputData={inputData}/>}
      </Grid> 
      :
      <div>{t("analyzis.createGraphFirst")}</div>}
      
      <Grid sx={{ textAlign: "right" }}>
        <Button onClick={() => props.duplicateDataset()}>{t("analyzis.duplicateButton")}</Button>
        <Button onClick={() => setShowModal(true)}>{t("analyzis.nameButton")}</Button>
        <Button onClick={() => props.deleteDataset()}>{t("analyzis.deleteButton")}</Button>
      </Grid>
    </Paper>

    <CustomModal
      show={showModal}
      title={t("analyzis.insertName")}
      width="500px"
      onClose={() => setShowModal(false)}
    >
      <Grid marginBottom={2} marginTop={2}>
        <input type="text" placeholder={t("analyzis.placeholderName") + ""} value={newDatasetName} onChange={(e) => setNewDatasetName(e.target.value)}/>
      </Grid>
      <Grid sx={{ textAlign: "right" }}>
        <Button onClick={() => setShowModal(false)}>{t("analyzis.closeButton")}</Button>
        <Button onClick={() => { props.renameDataset(props.idx, newDatasetName); setShowModal(false); }}>{t("analyzis.changeButton")}</Button>
      </Grid>
    </CustomModal>
  </>
  );
}
