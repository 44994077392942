import { Button } from "../../../components/StyleComponents/StyleComponents";
import { useState } from 'react';
import InputDrop from "../../../components/InputDrop/InputDrop";
import { FiUploadCloud } from 'react-icons/fi';
import image from '../../../assets/css/images/police.jpg';
import { LinearProgress } from "@mui/material";
import importService from "../../../services/ImportService";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import PageForm from "../../../components/PageHeader/PageForm";

export default function CAdd() {

  const { t } = useTranslation("crimes");

  const [file, setFile]: [File | undefined, Function] = useState(undefined);
  const [fileContent, setFileContent]: [Array<String>, Function] = useState([]);

  const [send, setSend]: [number, Function] = useState(0);
  const [received, setReceived]: [number, Function] = useState(0);
  const [fails, setFails]: [Array<any>, Function] = useState([]);

  const onFileChangeHandler = (files: File[]) => {
    setFile(files[0]);
    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = () => {
      let res = reader.result ? reader.result + "" : "";
      res = res.replaceAll('"', "");
      const arr = res.split('\r\n');
      arr.splice(0, 1);
      setFileContent(arr);
      setSend(arr.length);
    }
  }

  const upload = (idx : number) => {
    if (idx < fileContent.length) {
      const arr = fileContent[idx].split(',');
      importService.addNewData(arr[0], arr[1], arr[2], arr[3], arr[4], arr[5], arr[6], arr[7],)
      .then((res: any) => {
          if (!res.data.success) {
            setFails((old: any) => [...old, { id: idx, value: fileContent[idx]}]);
          }
          setReceived((old : any) => old + 1);
          upload(idx + 1);
      })
      .catch(() => {
        setReceived((old : any) => old + 1);
        setFails((old: any) => [...old, arr]);
        upload(idx + 1);
      });
    }
  }

  return (
    <PageForm label={t("import.pageTitle")} image={image}>
      <InputDrop onFileDrop={onFileChangeHandler} text={t("import.dropText")} files={file ? [file] : []}/>
      { received === 0 && <div className='box-12 text-center'>
        <Button onClick={() => upload(0)}><FiUploadCloud /> {t("import.uploadButton")}</Button>
      </div> }
      { send !== 0 && <>
        <div>
          {t("import.totalCount")} { send }
        </div>
        <div>
          {t("import.receivedCount")} {received}
        </div>
        <div>
          {t("import.failCount")} {fails.length}
        </div>
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={(received/send) * 100} />
        </Box>
        <Box>
          { fails.map((item) => <div>
            Index: { item.id  } Value: {item.value}
          </div>) }
        </Box>
      </> }
    </PageForm>
  );
}