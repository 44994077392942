import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Chart } from "react-google-charts";
import { FaCross, FaEyeDropper, FaEyeSlash, FaFilter, FaWindowClose } from 'react-icons/fa';
import image from '../../../../assets/css/images/nahlasene.jpg';
import { CriminalOffence, FilterAnalysesRequest, FilterAnalysesResponse, FilterCriminalOffencesRequest, FilterCriminalOffencesResponse, ReportedYears, State, Statistics } from '../../../../interfaces/europe/interfaces';
import './CriminalOffenceAnalyses.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect } from 'react';
import { COLORS, GET_ALL_CO, GET_ALL_REPORTED_YEARS, GET_ALL_STATES, HEADER_FIlTER_ANALYSE, HEADER_WITH_INDEX_AND_COUNTRY, ITEM_HEIGHT, ITEM_PADDING_TOP, MAP_COLORS, MAP_OPTIONS, POST_BAC_STATISTIC_ANALYSES, POST_FILTER_CO_ANALYSES, POST_FILTER_CO_LINK, TABLE_PAGE_SIZE_15, TABLE_PAGE_SIZE_TEN } from '../../../../interfaces/europe/Constants';
import { numberWithSpaces, floatNumberWithSpaces, getImage, CustomToolbar, getIcon, round } from '../../../../interfaces/europe/Functions';
import HeaderCarousel from '../../../../components/HeaderCarousel/HeaderCarousel';
import { height } from '@mui/system';
import TextField from '@mui/material/TextField/TextField';
import { BsSquare } from 'react-icons/bs';
import { eventNames } from 'process';
import { Box, InputAdornment, Link, Tab, Tabs } from '@mui/material';
import { number } from 'echarts';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";



export default function CriminalOffenceAnalysesBac() {

    const { t } = useTranslation("europe");
    
    //RESTS FOR GET
    const [reportedYears, setReportedYears] = React.useState<ReportedYears>();
    const [limit, setLimit] = React.useState<number>(0);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_ALL_REPORTED_YEARS).then((response) => {
            setReportedYears(response.data);
        });
    }, []);


    //RESTS POST 
    let requestFilter: FilterAnalysesRequest = { "years": [], "limit": 0 };

    const [yearsResult, setYear] = React.useState<number[]>([]);
    const [filterResult, setFilterResult] = React.useState<FilterAnalysesResponse>();
    const [mapCode, setMapCode] = React.useState<string>("150");
    const [valueMainTab, setValueMainTabe] = React.useState(0);
    const [statistics, setStatistics] = React.useState<Statistics>();


    useEffect(() => {
        requestFilter.years = yearsResult;
        requestFilter.limit = limit;
        axios.post(process.env.REACT_APP_API_URL + POST_FILTER_CO_ANALYSES, requestFilter).then((response) => {
            setFilterResult(response.data);

        });
    }, [yearsResult, limit]);

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + POST_BAC_STATISTIC_ANALYSES, filterResult).then((response) => {
            setStatistics(response.data);
        });
    }, [filterResult]);

    //ADDED DATA HEADERS
    const mapped = filterResult !== undefined ? filterResult?.states.map(d => [d.code.toUpperCase(), `${t("states." + d.code.toUpperCase())}`, Number(d.bac) <= limit ? 0 : 1, '<span><strong>index: </strong>' + d.index + '</span></br><span><strong>BAC: </strong>' + d.bac]) : null;
    const chartData = mapped !== null ? [HEADER_FIlTER_ANALYSE, ...mapped] : [HEADER_FIlTER_ANALYSE];

    //HANDLE FILTER CHANGES

    const handleYearChange = (event: SelectChangeEvent<typeof yearsResult>) => {
        const {
            target: { value },
        } = event;
        setYear(value as number[]);
    };

    const handleMapChange = (event: SelectChangeEvent) => {
        setMapCode(event.target.value as string);
    };

    const handleLimitChange = (event: number) => {
        setLimit(event);
    };

    const handleChangeMain = (_event: React.SyntheticEvent, newValue: number) => {
        setValueMainTabe(newValue);
    };

    //FILTER HIDE

    const [filterClass, setFilterClass] = React.useState<string>("");
    const handleFilter = () => {
        if (filterClass === "") {
            // setFilterVisible(false);
            setFilterClass("filter-hidden")
        } else {
            //setFilterVisible(true);
            setFilterClass("")
        }
    };

    const columnsStatistics: GridColDef[] = [
        {
            field: 'icon', headerName: '', align: 'center', headerAlign: 'center', width: 75, sortable: false, disableExport: true, cellClassName: 'icon-cell',
            filterable: false, renderCell: (params) => {
                return (
                    <>{getIcon(params.row.icon)}</>
                )
            }
        },
        { field: 'name', headerName: `${t("statistics.statistic")}`, flex: 1, align: 'center', headerAlign: 'center', headerClassName: 'bold-cell', cellClassName: 'bold-cell' },
        { field: 'value', headerName: `${t("statistics.value")}`, width: 200, align: 'right', headerAlign: 'right' }
    ];

    const rowStatistics = [
        { icon: 'Maximum', name: `${t("statistics.maximum")}`, value: round(statistics?.maximum) },
        { icon: 'Minimum', name: `${t("statistics.minimum")}`, value: round(statistics?.minimum) },
        { icon: 'Mean', name: `${t("statistics.mean")}`, value: round(statistics?.mean) },
        { icon: 'Median', name: `${t("statistics.median")}`, value: round(statistics?.median) },
        { icon: 'Variance', name: `${t("statistics.variance")}`, value: round(statistics?.variance) },
        { icon: 'Kurtosis', name: `${t("statistics.kurtosis")}`, value: round(statistics?.kurtosis) },
        { icon: 'StandardDeviation', name: `${t("statistics.standardDeviation")}`, value: round(statistics?.standardDeviation) },
        { icon: 'Skewness', name: `${t("statistics.skewness")}`, value: round(statistics?.skewness) },
        { icon: 'Percentile25', name: `${t("statistics.percentile25")}`, value: round(statistics?.percentile25) },
        { icon: 'Percentile50', name: `${t("statistics.percentile50")}`, value: round(statistics?.percentile50) },
        { icon: 'Percentile75', name: `${t("statistics.percentile75")}`, value: round(statistics?.percentile75) }
    ];

    const translatedRows = (filterResult?.states || []).map(row => ({
        ...row,
        name: t(`states.${row.code}`),
      }));


    //TABLE DEFINITIONS
    const columns: GridColDef[] = [
        {
            field: 'color', headerName: '', width: 50, align: 'center', sortable: false, disableExport: true, filterable: false, renderCell: (params) => {
                if (params.row.id == 1) {
                    return (
                        <>{<BsSquare className="mr-2" style={{ fill: COLORS[9], background: COLORS[9] }} />}</>
                    )
                } else {
                    return (
                        <>{<BsSquare className="mr-2" style={{ fill: COLORS[7], background: COLORS[7] }} />}</>
                    )
                }
            }
        },
        { field: 'status', headerName: 'Status', flex: 1 },


        {
            field: 'index', headerName: `${t("analyse.crimeIndex")}`, flex: 1
        },

    ];

    const columnsStates: GridColDef[] = [

        {
            field: 'color', headerName: '', width: 50, align: 'center', sortable: false, disableExport: true, filterable: false, renderCell: (params) => {
                if (params.row.bac <= limit) {
                    return (
                        <>{<BsSquare className="mr-2" style={{ fill: COLORS[9], background: COLORS[9] }} />}</>
                    )
                } else {
                    return (
                        <>{<BsSquare className="mr-2" style={{ fill: COLORS[7], background: COLORS[7] }} />}</>
                    )
                }
            }
        },
        {
            field: 'flag', headerName: '', width: 75, sortable: false, disableExport: true, filterable: false, renderCell: (params) => {
                return (
                    <div className="flag">
                        <img src={getImage(params.row.code)} alt={"Flag"} />

                    </div>
                )
            }
        },
        { field: 'name', headerName: `${t("map.state")}`, flex: 1 },
        {
            field: 'bac', headerName: 'BAC', flex: 1, type: 'number', align: 'center', headerAlign: 'center'
        },
        {
            field: 'index', headerName: `${t("analyse.crimeIndex")}`, flex: 1, align: 'center', headerAlign: 'center'
        },


    ];

    //MAP GRAPH OPTIONS
    const options = {
        region: mapCode,
        displayMode: 'regions',
        datalessRegionColor: '#fff',
        legend: 'hidden',
        colorAxis: {
            colors: [COLORS[9], COLORS[7],],

        },
        tooltip: {
            isHtml: true
        }
    }



    return (<>


        <div className=''>

            <HeaderCarousel name={t("analyse.title")} image={image} />

            <div className='underLabel '>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        onChange={handleChangeMain}
                        value={valueMainTab}
                    >
                        <NavLink to={"/europe/analyses/bac"} className="text-primary"><Tab label={t("analyse.bac")}></Tab></NavLink>
                        <NavLink to={"/europe/analyses/drugs"}><Tab label={t("analyse.drug")}></Tab></NavLink>

                    </Tabs>
                </Box>
            </div>
            <div className=''>
                <div className='info'> {t("analyse.desc")}</div>
                {filterClass === "filter-hidden" ?
                    <FaFilter className='my-3' style={{ cursor: 'pointer' }} onClick={handleFilter}></FaFilter>
                    : <FaEyeSlash className='my-3' style={{ cursor: 'pointer' }}> onClick={handleFilter}</FaEyeSlash>}

                <span className='pl-1' style={{ cursor: 'pointer' }} onClick={handleFilter}>Filter</span>
            </div>

            <form className={filterClass}>


                <div className={'d-inline '}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="multiple-checkbox-label-years">{t("map.years")}</InputLabel>
                        <Select
                            labelId="multiple-checkbox-label-years"
                            id="multiple-checkbox-years"
                            multiple
                            value={yearsResult}
                            onChange={(e) => { handleYearChange(e); }}
                            input={<OutlinedInput label="Years" />}
                            renderValue={(selected) => selected.join(', ')}

                        >
                            {reportedYears?.years.map((year) => (
                                <MenuItem key={year.toString()} value={year}>
                                    <Checkbox defaultChecked={true} checked={yearsResult.indexOf(year) > -1} />
                                    <ListItemText primary={year} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className='d-inline'>
                    <FormControl sx={{ m: 1, width: 250, height: 50 }}>
                        <InputLabel id="mapInputLabel">{t("map.chooseMap")}</InputLabel>
                        <Select
                            labelId="mapInputLabel"
                            id="mapInput"
                            value={mapCode}
                            label="Choose map"
                            onChange={handleMapChange}
                            defaultValue={"150"}
                        >
                            {MAP_OPTIONS.map((map) => (
                                <MenuItem key={map.id} value={map.id}>
                                    <ListItemText primary={map.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className='d-inline'>
                    <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                        <TextField
                            id="outlined-number"
                            label="BAC Divide value"
                            type="number"
                            defaultValue={"0.000"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setLimit(event.target.value as unknown as number);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">‰</InputAdornment>,


                            }}
                            inputProps={{
                                step: 0.001,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                    </FormControl>
                </div >
            </form>



            <div className="App mb-2">
                <Chart chartType="GeoChart" width="100%" height="600px" data={chartData} options={options} />
            </div>
            <div>
                <BsSquare className="mr-2" style={{ fill: COLORS[9], background: COLORS[9] }} /><span style={{ color: '#555' }}> {t("analyse.less")} {limit} ‰</span>
            </div>
            <div>
                <BsSquare className="mr-2" style={{ fill: COLORS[7], background: COLORS[7] }} /><span style={{ color: '#555' }}> {t("analyse.more")} {limit} ‰</span>
            </div>



            <div className="mt-5 col-12">
                <h3 className="mb-4">{t("analyse.subTitle")} </h3>
                <div style={{ height: 250, width: '100%' }}>

                    <DataGrid
                        rows={
                            [
                                { id: 1, status: `${t("analyse.less")} ${limit} ‰`, index: filterResult?.indexForLessThenChosen },
                                { id: 2, status: `${t("analyse.more")} ${limit} ‰`, index: filterResult?.indexForMoreThenChosen },
                            ]}
                        columns={columns}
                        disableColumnSelector
                        disableSelectionOnClick={true}
                        hideFooter={true}
                        autoHeight={true}
                        getRowId={(row) => row.id}

                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'index', sort: 'desc' }],
                            },
                        }}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{ toolbar: { allColumns: true } }}
                    />




                </div>
                <div style={{ height: 700, width: '100%' }}>
                    <DataGrid
                        rows={translatedRows}
                        columns={columnsStates}
                        disableColumnSelector
                        disableSelectionOnClick={true}
                        autoHeight={true}
                        getRowId={(row) => row.code}
                        pageSize={TABLE_PAGE_SIZE_TEN}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'bac', sort: 'asc' }],
                            },
                        }}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{ toolbar: { allColumns: true } }}
                    />

                </div>
            </div>
            <div className="mt-2 col-12">
                <h3 className="mb-4">{t("statistics.title")}</h3>

                <div style={{ height: 720, width: '100%' }}>
                    <DataGrid
                        rows={rowStatistics}
                        columns={columnsStatistics}
                        disableColumnSelector
                        disableSelectionOnClick={true}
                        pageSize={TABLE_PAGE_SIZE_15}
                        autoHeight={true}
                        getRowId={(row) => row.name}

                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'index', sort: 'desc' }],
                            },
                        }}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{ toolbar: { allColumns: true } }}
                    />
                </div>
            </div>

   


    </div >
        <div className='info'> {t("statistics.crimeIndex")} </div>
    </>);

}
