import './Title.css';
import { ReactNode } from 'react';

interface IProps {
    children: ReactNode;
    align?: "center" | "left" | "right" | "justify";
};

export default function Title(props: IProps) {
    return (
        <div className={`box-12 text-${props.align} title-comp`}>
            {props.children}
        </div>
    );
}