import HeaderCarousel from '../../../../../components/HeaderCarousel/HeaderCarousel';
import image from '../../../../../assets/css/images/nahlasene.jpg';
import './StateCompareInfo.css';
import { Firearm, State, StateCompareRequest, StateInfoDto, drogsLimit, isFirearm } from '../../../../../interfaces/europe/interfaces';
import React, { useEffect, useRef } from 'react';
import { COLORS, GET_ALL_STATES, GET_ALL_TYPE_OF_FIREARMS, HEADER_STATE_COMPARE_LOCATION, ITEM_HEIGHT, ITEM_PADDING_TOP, LINK_STATE_COMAPRE_CO, LINK_STATE_COMAPRE_CONVICTED, LINK_STATE_COMAPRE_INFO, LINK_STATE_COMAPRE_POLICE, MAP_COLORS, POST_FILTER_CO_LINK, POST_STATE_COMPARE_INFO, STATE_INFO_SITE } from '../../../../../interfaces/europe/Constants';
import axios from 'axios';
import { Box, Tabs, Tab, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { LineChart, CartesianGrid, XAxis, YAxis, Legend, Line, ResponsiveContainer } from 'recharts';
import { getImage } from '../../../../../interfaces/europe/Functions';
import Chart from 'react-google-charts';
import { FaCheckSquare, FaWindowClose } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import generatePDF, { Margin, Options } from 'react-to-pdf';

export default function StateCompareInfo() {

    const targetRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation("europe");

    const {codes} = useParams<string>();
    
    //RESTS FOR GET
    const [states, setStates] = React.useState<State[]>([]);
    const [statesInfo, setStatesInfo] = React.useState<StateInfoDto[]>([]);
    const [firearms, setFirearms] = React.useState<Firearm[]>([]);

    //RESTS POST 
    let stateCompareRequest: StateCompareRequest = { "states": [] };
    const [stateIds, setStateIds] = React.useState<number[]>([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_STATES).then((response) => {
            setStates(response.data);
        });
    }, []);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_INFO, stateCompareRequest).then((response) => {
            setStatesInfo(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_TYPE_OF_FIREARMS).then((response) => {
            setFirearms(response.data);
        });

    }, []);

    useEffect(() => {
        if (codes !== undefined && codes !== null && codes !== ":codes") {
            setStateIds([...(codes.split(',').map(Number)).filter(x => x !== null)]);
        }
    }, []);

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleStateChange = (event: SelectChangeEvent<typeof stateIds>) => {
        const {
            target: { value },
        } = event;
        setStateIds(value as number[]);
    };

    const findDrugLimitByName = (name: string, drugs: drogsLimit[]) => {

        let find = drugs.find(el => el.name == name);
        if (find) {
            return find.allowedLimit;
        }

        return '';
    };

    const findFirearmInState = (firearms: isFirearm[], name: string) => {
        let find = firearms.find(el => el.name == name);

        if (find !== undefined) {
            return find.allowed;
        } else {
            return undefined;
        }

    };

    const printResultOfWeapon = (isFirearm: Boolean | undefined) => {

        if (isFirearm === undefined) {
            return "";
        } else {
            if (isFirearm) {
                return <FaCheckSquare className=" text-success" />
            } else {
                return <FaWindowClose className=" text-danger" />
            }
        }
    }

    function getState(x: number[], l: number = 3) {
        const filteredCourses = states.filter(state => x.slice(0, 3).some(number => number === state.id));
        const translated = filteredCourses.map(u => t(`states.${u.code}`));
        if (x.length > l) {
            return translated.join(', ') + ",...";
        }
        return translated.join(', ');
    }

    const mapped = statesInfo.map((state, index) => [state.code.toUpperCase(), `${t("states." + state.code.toUpperCase())}`, (index % COLORS.length), '']);
    const data = [HEADER_STATE_COMPARE_LOCATION, ...mapped];

    const options = {
        region: 150,
        displayMode: 'regions',
        datalessRegionColor: '#fff',
        colorAxis: {
            colors: [COLORS[0], COLORS[1], COLORS[2], COLORS[3], COLORS[4], COLORS[5], COLORS[6], COLORS[7], COLORS[8], , COLORS[9]],
            values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        }
    };
    
    useEffect(() => {
        const handleResize = () => {
            (window as any).google.visualization.drawChart({
              containerId: 'geo-chart-container',
              dataTable: data,
              options: options,
              chartType: 'GeoChart',
            });
          };
    
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [data, options]);

    return (
        <>
        
        <HeaderCarousel className='mb-0' name={t("stateCompare.title")} image={image} />
            
            <div className='underLabel pb-2'>
                {stateIds.length != 0 ? <button className='btn btn-secondary' onClick={() => generatePDF(targetRef, {filename: 'state-compare.pdf'})}>{t("stateCompare.downloadPDF")}</button> : <div></div> }
                <p>{t("stateCompare.subTitle")}</p>
            </div>
            <div className='d-inline'>
                <FormControl sx={{ m: 1, width: 500 }}>
                    <InputLabel id="multiple-checkbox-label-states">{t("stateCompare.states")}</InputLabel>
                    <Select
                        labelId="multiple-checkbox-label-states"
                        id="multiple-checkbox-states"
                        multiple
                        value={stateIds}
                        onChange={handleStateChange}
                        input={<OutlinedInput label="States" />}
                        renderValue={selected => getState(selected)}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                },
                            }
                        }
                        }
                    >
                        {states.map((state) => (
                            <MenuItem key={state.name.toString()} value={state.id} >
                                <Checkbox checked={stateIds.indexOf(state.id) > -1} />
                                <ListItemText primary={t("states." + state.code.toUpperCase())} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {stateIds.length !== 0 ?
            <div>
                <div>
                    <Box sx={{ width: '100%' }}>
                        <Tabs
                            onChange={handleChange}
                            value={value}
                            aria-label="Tabs where selection follows focus"
                            selectionFollowsFocus>
                            <Link to={LINK_STATE_COMAPRE_INFO + stateIds} className="text-primary "><Tab label={t(`stateInfo.information`)} className="font-weight-bold" ></Tab></Link>
                            <Link to={LINK_STATE_COMAPRE_CO + stateIds}><Tab label={t(`stateInfo.criminalOffences`)} ></Tab></Link>
                            <Link to={LINK_STATE_COMAPRE_POLICE + stateIds}><Tab label={t(`stateInfo.police`)}></Tab></Link>
                            <Link to={LINK_STATE_COMAPRE_CONVICTED + stateIds}><Tab label={t(`stateInfo.convictedCriminalOffences`)} ></Tab></Link>

                        </Tabs>
                    </Box>
                </div>
                <div ref={targetRef}>
                    <br />
                    <div className="my-3 col-8 w-100 h-100">
                        <h5>{getState(stateIds, 10)}</h5>
                        <h3 className="mb-3">{t("stateCompare.locations")}</h3>
                        <div id="geo-chart-container" style={{ width: '100%', height: '25%' }}>
                            <Chart chartType="GeoChart" data={data}
                                options={{
                                    region: 150,
                                    displayMode: 'regions',
                                    datalessRegionColor: '#fff',
                                    colorAxis: {
                                        colors: [COLORS[0], COLORS[1], COLORS[2], COLORS[3], COLORS[4], COLORS[5], COLORS[6], COLORS[7], COLORS[8], , COLORS[9]],
                                        values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                                    }
                                }} 
                            />
                        </div>

                    </div>
                    <div className="my-5 col-8 w-100 h-100">
                        <h3 className="mb-4">{t("stateCompare.population")}</h3>
                        <div className="w-100 h-100">
                            <ResponsiveContainer width='100%' height={400}>
                                <LineChart
                                    data={statesInfo}
                                    margin={{
                                        top: 5,
                                        right: 0,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="2 2" />
                                    <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                    <YAxis />
                                    <Legend />
                                    {
                                        statesInfo.map((state, index) => {
                                            return (<Line key={state.id.toString()} name={`${t("states." + state.code)}`} data={state.population} dataKey="population" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                        })
                                    }
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div className="mb-5 col-10">
                        <h3 className="mb-4">{t("stateCompare.rulebook")}</h3>
                        <table className="table table-striped mt-2">
                            <thead>
                                <tr key={"ruleBookHeader"}>
                                    <th className="col-1"></th>
                                    <th className="col-1">{t("map.state")}</th>
                                    <th className="col-4 text-center ">BAC (‰)</th>
                                    <th className="col-4 text-center">{t("stateCompare.ageOfConsent")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    statesInfo.map((state) => {
                                        return (
                                            <tr key={state.id.toString()}>
                                                <th className="flag"><img src={getImage(state.code)} alt={"Flag"} /></th>
                                                <th className="">{t("states." + state.code.toUpperCase())}</th>
                                                <th className="text-center ">{state.bac}</th>
                                                <th className="text-center">{state.ageOfConsent}</th>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="mb-5 col-10">
                        <h3 className="mb-4">{t("stateCompare.legalLimitOfDrugs")}</h3>
                        <table className="table table-striped mt-2">
                            <thead>
                                <tr key={"legalLilitOfDrogsHeader"}>
                                    <th className="col-1"></th>
                                    <th className="col-1">{t("map.state")}</th>
                                    <th className="col-1 text-center ">{t("analyse.drugs.cannabis")}</th>
                                    <th className="col-1 text-center">{t("analyse.drugs.heroin")}</th>
                                    <th className="col-1 text-center">{t("analyse.drugs.cocaine")}</th>
                                    <th className="col-1 text-center">{t("analyse.drugs.ecstasy")}</th>
                                    <th className="col-1 text-center">{t("analyse.drugs.amphetamines")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    statesInfo.map((state) => {
                                        return (
                                            <tr key={state.id.toString()}>
                                                <th className="flag"><img src={getImage(state.code)} alt={"Flag"} /></th>
                                                <th className="">{t("states." + state.code.toUpperCase())}</th>
                                                <th className="text-center ">{findDrugLimitByName('cannabis', state.drogsLimits)}</th>
                                                <th className="text-center">{findDrugLimitByName('heroin', state.drogsLimits)}</th>
                                                <th className="text-center">{findDrugLimitByName('cocaine', state.drogsLimits)}</th>
                                                <th className="text-center">{findDrugLimitByName('ecstasy', state.drogsLimits)}</th>
                                                <th className="text-center">{findDrugLimitByName('amphetamines', state.drogsLimits)}</th>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="mb-5 col-10">
                        <h3 className="mb-4">{t("stateCompare.consideredAWeapon")}</h3>
                        <table className="table table-striped mt-2">
                            <thead>
                                <tr key={"weaponHeader"}>
                                    <th className="">{t("stateCompare.weapon")}</th>
                                    {
                                        statesInfo.map((state) => {
                                            return (
                                                <Tooltip title={state.name} placement="top">
                                                    <th key={state.id.toString()} className="text-center">{state.code}</th>
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    firearms.map((firearm) => {
                                        return (
                                            <tr>
                                                <th className="" key={firearm.id.toString()}>{t("stateCompare.weapons." + firearm.id)}</th>
                                                {
                                                    statesInfo.map((state) => {
                                                        return (
                                                            <th className="text-center" key={state.id.toString()}>
                                                                {printResultOfWeapon(findFirearmInState(state.isFirearms, firearm.name))}
                                                            </th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
                :
                <div></div>
            }
        </>

    );
}