import axios, { AxiosResponse } from "axios";
import { PredictionInput } from "../interfaces/traffic_accidents/InterfacesTraffic";

const analysisService: any = {
    getStatistic: (values: any[], decimalPlaces: number): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tap/analysis/statistic", {
            params: {
                values:  JSON.stringify(values),
                decimalPlaces: decimalPlaces
            }
        });
    },
    getPrediction: (predictionInput: PredictionInput, signal?: AbortSignal): Promise<AxiosResponse<any, any>>  => {
        return axios.post(process.env.REACT_APP_API_URL + "/tap/analysis/prediction", predictionInput, {signal});
    },
}

export default analysisService;