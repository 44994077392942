import { filterType, SearchFilter, SearchFilterValue } from "../../interfaces/SearchFilter"
import CustomModal from "../Modal/CustomModal";
import { Button } from "../StyleComponents/StyleComponents";
import { useState } from 'react';
import SFilterModal from './SFilterModal';
import css from './Search.module.css';
import { FaEye, FaEyeSlash, FaFilter } from "react-icons/fa";
import { useEffect } from 'react';
import SFilterValueDraw from './SFilterValueDraw';
import { ExpressionValue } from "../Expressions/Expressions";
import { useTranslation } from "react-i18next";

interface IProps {
    filter: SearchFilter[],
    customExpressions?: ExpressionValue[];
    filterValues: SearchFilterValue[];
    withVisibleOption?: boolean;
    onFilterChange: (filter: SearchFilter[], filterValues: SearchFilterValue[]) => void;
    onExpressionChange?: (exp: ExpressionValue[]) => void;
    endpointService: any;
    showModal: boolean;
    handleCloseModal: () => void;
}

export default function SFilterSettingsModal(props: IProps) {
    const { t } = useTranslation("crimes");
    const [ show, setShow ] = useState<boolean>(false);
    const [ custFilter, setCustomFilter ] = useState<SearchFilter[]>(JSON.parse(JSON.stringify(props.filter)));
    const [ custValues, setCustValues ] = useState<SearchFilterValue[]>(JSON.parse(JSON.stringify(props.filterValues)));
    const [ filteredItem, setFilteredItem ] = useState<SearchFilter | undefined>(undefined);
    const [ addFilterShow, setAddFilterShow ] = useState<boolean>(false);

    useEffect(() => {
        if (props.showModal) {
            setCustomFilter(JSON.parse(JSON.stringify(props.filter)));
        }
    }, [props.showModal, props.filter]);

    const handleSave = () => {
        props.onFilterChange(custFilter, custValues);
        props.handleCloseModal();
    }

    const changeVisible = (idx: number) => {
        let tempFilter = [...custFilter];
        tempFilter[idx].active = !tempFilter[idx].active;
        setCustomFilter(tempFilter);
    }

    const showAddFilter = () => {
        setAddFilterShow(true);
    }

    const handleItemClick = (idx: number) => {
        setFilteredItem(custFilter[idx]);
        setAddFilterShow(true);
    }

    const handleExpItemClick = (idx: number) => {
        if (props.customExpressions) {
            const item = props.customExpressions[idx];
            setFilteredItem({
                atr: item.label,
                name: item.label,
                active: item.active,
                type: filterType.NONE
            });
            setAddFilterShow(true);
        }
    }

    const handleFilteredClose = () => {
        setFilteredItem(undefined);
        setAddFilterShow(false);
    }

    const handleFilteredConfirm = (value: any) => {
        if (value.value.length > 0 || value.option.value === 'EMPTY' || value.option.value === 'NOT_EMPTY') {
            setCustValues((old) => [...old, value]);
            setFilteredItem(undefined);
        }
        setAddFilterShow(false);
    }

    const handleFilterValuesChange = (newValues: SearchFilterValue[]) => {
        setCustValues(newValues);
    }

    const changeExpVisible = (idx: number) => {
        if (props.customExpressions) {
            let tempExp = [...props.customExpressions];
            tempExp[idx].active = !tempExp[idx].active;
            props.onExpressionChange?.(tempExp);
        }
    }

    const filterItems = custFilter.map((item, idx) => 
        <div key={idx} className={css.filterItem}>
            <div className={css.filterName}>
                {item.name}
            </div>
            <div className={css.filterButtons}>
                { props.withVisibleOption && item.active && <FaEye className={css.iconVisible} onClick={() => changeVisible(idx)} /> }
                { props.withVisibleOption && !item.active && <FaEyeSlash className={css.iconInvisible} onClick={() => changeVisible(idx)} /> }
                <FaFilter className={css.iconNonFilter} onClick={() => handleItemClick(idx)} />
            </div>
        </div>
    );

    const expressionItems = props.customExpressions?.map((item, idx) => 
        <div key={idx} className={css.filterItem}>
            <div className={css.filterName}>
                {item.label}
            </div>
            <div className={css.filterButtons}>
                { props.withVisibleOption && item.active && <FaEye className={css.iconVisible} onClick={() => changeExpVisible(idx)} /> }
                { props.withVisibleOption && !item.active && <FaEyeSlash className={css.iconInvisible} onClick={() => changeExpVisible(idx)} /> }
                <FaFilter className={css.iconNonFilter} onClick={() => handleExpItemClick(idx)} />
            </div>
        </div>
    );

    useEffect(() => {
        setCustValues(prev => Array.from(new Set([...prev, ...props.filterValues])))
    }, [props.filterValues]);

    return <>
        <CustomModal
            show={props.showModal}
            title={t("analyzis.filterSetting")}
            width="800px"
            onClose={props.handleCloseModal}
        >
            <div className='box-12'>
                <Button onClick={handleSave} style={{ float: 'right' }}>{t("analyzis.saveFilter")}</Button>
            </div>
            <div className='box-12' style={{ margin: '12px 0px 6px 0px' }}>
                {props.withVisibleOption && <>
                <div className={css.tooltip}>
                {t("analyzis.showColumn")} - <FaEye className={css.iconVisible} />
                </div>
                <div className={css.tooltip}>
                {t("analyzis.noShowColumn")} - <FaEyeSlash className={css.iconInvisible} />
                </div></>}
                <div className={css.tooltip}>
                {t("analyzis.newFilter")} - <FaFilter className={css.iconNonFilter} />
                </div>
            </div>
            <SFilterValueDraw 
                filterValues={custValues}
                onChange={handleFilterValuesChange} 
            />
            <div className='box-12 text-center'>
                <Button onClick={showAddFilter}>{t("analyzis.addFilter")}</Button>
            </div>
            <div className='box-12' style={{ margin: '0px 0px 12px 0px' }}>
                { filterItems }
                { expressionItems }
            </div>
            <div className='box-12'>
                {/* <Button onClick={props.handleClose}>{t("analyzis.impFilter")}</Button>
                <Button onClick={props.handleClose}>{t("analyzis.expFilter")}</Button> */}
                <Button onClick={handleSave} style={{ float: 'right' }}>{t("analyzis.saveFilter")}</Button>
            </div>
        </CustomModal>
        <SFilterModal 
            show={addFilterShow}
            name={filteredItem?.name || t("analyzis.addFilter")}
            filter={filteredItem}
            customExpressions={props.customExpressions}
            allFilters={props.filter}
            onClose={handleFilteredClose}
            onConfirm={handleFilteredConfirm}
            endpointService={props.endpointService}
        />
    </>
}