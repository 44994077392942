import { Button } from "../../../components/StyleComponents/StyleComponents";
import { useState } from 'react';
import { ChangeEvent } from 'react';
import { MdOutlinePersonAdd } from "react-icons/md";
import { FiLogIn } from "react-icons/fi";
import NotificationService from '../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import { passwordStrength } from "check-password-strength";
import { Grid, TextField, Typography } from "@mui/material";
import image from '../../../assets/css/images/police.jpg';
import { useTranslation } from "react-i18next";
import UsersService from "../../../services/UsersService";
import PageForm from "../../../components/PageHeader/PageForm";

export default function Registration() {
  const { t } = useTranslation("default");
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAnother, setPasswordAnother] = useState("");

  const nameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }

  const surnameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSurname(e.target.value);
  }

  const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const passwordChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  const passwordAnotherChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordAnother(e.target.value);
  }

  const redirectToLogin = (): void => {
    navigate("/login");
  }

  const checkEmail = (): boolean => {
    // eslint-disable-next-line
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(email);
  }

  const tryToRegister = () => {
    if (name.length > 0 && surname.length > 0 && email.length > 0 && password.length > 0 && passwordAnother.length > 0) {
      if (checkEmail()) {
        if (password.length >= 5 || passwordAnother.length >= 5) {
          if (passwordStrength(password).value !== "Too weak") {
            if (password === passwordAnother) {
              UsersService.register(email, name, surname, password)
              .then(() => {
                navigate("/login");
                NotificationService.success(t("default.registerSuccess"));
              })
              .catch(() => {
                NotificationService.error(t("default.registerFail"));
              });
            } else {
              NotificationService.error(t("default.diffPassdiffPass"));  
            }
          } else {
            NotificationService.error(t("default.registerPassLow"));  
          }
        } else {
          NotificationService.error(t("default.registerPassMinLength"));
        }
      } else {
        NotificationService.error(t("default.emailErr"));
      }
    } else {
      NotificationService.error(t("default.fillData"));
    }
  }

  return (
    <PageForm label={t("default.registerTitle")} image={image}>
      <Grid container textAlign={'right'}>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <TextField fullWidth value={name} onChange={nameChangeHandler} id="outlined-basic" label={t("default.name")} variant="outlined"  />
          <TextField fullWidth value={surname} onChange={surnameChangeHandler} id="outlined-basic" label={t("default.surname")} variant="outlined"  style={{ marginTop: "18px" }} />
          <TextField fullWidth value={email} onChange={emailChangeHandler} id="outlined-basic" label={t("default.email")} variant="outlined"  placeholder="example@mail.com"  style={{ marginTop: "18px" }} />
          <TextField fullWidth value={password} onChange={passwordChangeHandler} id="outlined-basic" label={t("default.password")} variant="outlined" placeholder={t("default.password") || 'Password'} style={{ marginTop: "18px" }} type="password" />
          <TextField fullWidth value={passwordAnother} onChange={passwordAnotherChangeHandler} id="outlined-basic" label={t("default.passwordAgain")} variant="outlined" placeholder={t("default.passwordAgain") || 'Password again'} style={{ marginTop: "18px" }} type="password" />
          <Grid item xs={12} textAlign={'center'} mt={4}>
            <Button onClick={tryToRegister}><MdOutlinePersonAdd />{t("default.register")}</Button>
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={4}>
            <Typography variant="body1">{t("default.accountQuestion")}</Typography>
            <Button onClick={redirectToLogin}><FiLogIn />{t("default.login")}</Button>
          </Grid>
        </Grid>
      </Grid>
    </PageForm>
  );
}