import { Route, Routes } from 'react-router-dom';
import { MenuItems, menuItems } from '../../interfaces/ConstMenu';
import { menuItemsEurope } from '../../interfaces/ConstMenuEurope';
import useUser from '../../use/useUser';
import AccessForbidden from './../../pages/default/AccessForbidden/AccessForbidden';
import './Page.css';

export default function Page() {
  const [, { isAdmin, isLoggedIn }] = useUser();

  const routesList: MenuItems[] = [];

  const prepareRoutes = (menu: MenuItems[]) => {
    menu.forEach((item: MenuItems) => {
      routesList.push(item);
      if (item.submenu) {
        prepareRoutes(item.submenu);
      }
    });
  };
  const isEuropeSection = window.location.href.includes('europe');
  if (!isEuropeSection) {
    prepareRoutes(menuItems);
  } else {
    prepareRoutes(menuItemsEurope);
  }


  const checkPrivilege = (item: MenuItems): boolean => {
    if (item.unloggedOnly && isLoggedIn()) return false;
    if (item.loggedOnly && !isLoggedIn()) return false;
    if (item.adminOnly && !isAdmin()) return false;
    return true;
  }

  const routes = routesList.map((item: MenuItems, index: number) =>

    <Route key={index} path={item.path} element={checkPrivilege(item) ? <item.module /> : <AccessForbidden />} />
  );

  return (
    <div className='box-12 box-md-12 px-md-4 py-md-4 box-lg-12 px-lg-5 py-lg-5'>
      <div className="box-12 page">
        <Routes>
          {routes}
        </Routes>
      </div>
    </div>
  );
}
/*
<div className='box-12 box-md-12 px-md-4 py-md-4 box-lg-12 px-lg-5 py-lg-5'>
      <div className="box-12 page">
        <Routes>
          {routes}
        </Routes>
      </div>
    </div>
*/