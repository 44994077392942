import {FormGroup, FormLabel, InputLabel, MenuItem} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {useEffect, useState} from "react";
import SelectionData from "../../interfaces/Graphs/SelectionData";

enum SelectType {
    AllSelect, MultiSelect, IntervalSelect
}

interface GraphControlProps {
    name: string,
    label: string,
    useInterval : boolean,
    selectionData: SelectionData[],
    emitChange: Function
}

export default function GraphControl(props : GraphControlProps) {

    const {t} = useTranslation("traffic");
    const [controlType, SetControlType] = useState<SelectType>(SelectType.AllSelect);
    const [dataSelect, SetDataSelect] = useState<string[]>([]);
    const [dataIntervalFrom, SetDataIntervalFrom] = useState<string[]>(["2021"]);
    const [dataIntervalTo, SetDataIntervalTo] = useState<string[]>(["2022"]);

    const OnChangeHandler = (event : SelectChangeEvent<typeof dataSelect>) => {
        const {target: {value}} = event;
        SetDataSelect(typeof value === "string" ? value.split(",") : value);
    }

    const OnChangeIntervalFromHandler = (event : SelectChangeEvent<typeof dataIntervalFrom>) => {
        const {target: {value}} = event;
        SetDataIntervalFrom(typeof value === "string" ? value.split(",") : value);
    }

    const OnChangeIntervalToHandler = (event : SelectChangeEvent<typeof dataIntervalTo>) => {
        const {target: {value}} = event;
        SetDataIntervalTo(typeof value === "string" ? value.split(",") : value);
    }

    const EmitChanges = (data : Object) => {
        props.emitChange({Name: props.name, Option: controlType, Data: data});
    }
    useEffect(() => {
        switch (controlType) {
            case SelectType.AllSelect:
                EmitChanges({});
                break;
            case SelectType.MultiSelect:
                EmitChanges(dataSelect);
                break;
            case SelectType.IntervalSelect:
                EmitChanges([dataIntervalFrom[0], dataIntervalTo[0]]);
                break;
            default:
                return;
        }
    }, [controlType])
    useEffect(() => {
        EmitChanges(dataSelect);
    }, [dataSelect])
    useEffect(() => {
        EmitChanges([dataIntervalFrom[0], dataIntervalTo[0]]);
    }, [dataIntervalFrom, dataIntervalTo])


    const onControlChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let type;
        switch (event.target.value) {
            case "0":
                type = SelectType.AllSelect;
                break;
            case "1":
                type = SelectType.MultiSelect;
                break;
            case "2":
                type = SelectType.IntervalSelect;
                break;
            default:
                type = SelectType.AllSelect;
                break;
        }
        SetControlType(type);
    };

    return (
        <div className="graph_control">
            <FormControl sx={{m:1, width: 'auto'}}>
                <FormLabel>{props.label}</FormLabel>
                <RadioGroup row>
                    <FormControlLabel value={SelectType.AllSelect} control={<Radio onChange={onControlChangeHandler} checked={controlType === SelectType.AllSelect}/>} label={t("Graph.vsetko")} />
                    <FormControlLabel value={SelectType.MultiSelect} control={<Radio onChange={onControlChangeHandler} checked={controlType === SelectType.MultiSelect}/>} label={t("Graph.vyber")} />
                    {
                        props.useInterval &&
                        (
                            <FormControlLabel value={SelectType.IntervalSelect} control={<Radio onChange={onControlChangeHandler} checked={controlType === SelectType.IntervalSelect}/>} label={t("Graph.interval")} />
                        )
                    }
                </RadioGroup>

                {
                    controlType === SelectType.MultiSelect &&
                    (
                        <FormControl sx={{ m: 1, width: 'auto' }}>
                            <InputLabel id={`${props.label}SelectLabel`}>{props.label}</InputLabel>
                            <Select
                                multiple
                                value={dataSelect}
                                onChange={OnChangeHandler}
                                labelId={`${props.label}SelectLabel`}
                                label={props.label}
                                autoWidth
                            >
                                {
                                    props.selectionData.map((item) => (
                                        <MenuItem
                                            key={item.Type}
                                            value={item.Code}
                                        >
                                            {item.Type}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )
                }
                {
                    controlType === SelectType.IntervalSelect &&
                    (
                        <FormGroup>
                            <FormControl sx={{ m: 1, width: 'auto' }}>
                                <InputLabel id={`${props.label}Interval1`}>{`${props.label +" " + t("Graph.od")} `}</InputLabel>
                                <Select
                                    value={dataIntervalFrom}
                                    onChange={OnChangeIntervalFromHandler}
                                    labelId={`${props.label}Interval1`}
                                    label={`${props.label + " " +t("Graph.do")}`}
                                    autoWidth
                                >
                                    {
                                        props.selectionData.map((item) => (
                                            <MenuItem
                                                key={item.Type}
                                                value={item.Code}
                                            >
                                                {item.Type}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 'auto' }}>
                                <InputLabel id={`${props.label}Interval2`}>{`${props.label+" " + t("Graph.do")}`}</InputLabel>
                                <Select
                                    value={dataIntervalTo}
                                    onChange={OnChangeIntervalToHandler}
                                    labelId={`${props.label}Interval2`}
                                    label={`${props.label + " " + t("Graph.od")}`}
                                    autoWidth
                                >
                                    {
                                        props.selectionData.map((item) => (
                                            <MenuItem
                                                key={item.Type}
                                                value={item.Code}
                                            >
                                                {item.Type}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    )
                }
            </FormControl>
        </div>
    );
}