import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { TablePagination } from '@mui/material';
import { Column } from '../../interfaces/Interfaces';
import { useTranslation } from 'react-i18next';

/* example
 const columns: Column[] = [
    { id: 'id', label: 'Name', minWidth: 170 },
    { id: 'name', label: 'ISO\u00a0Code', minWidth: 100 },
    {
      id: 'population',
      label: 'Population',
      minWidth: 170,
      align: 'right',
      format: (value: number) => value.toLocaleString('en-US'),
    },
    {
      id: 'size',
      label: 'Size\u00a0(km\u00b2)',
      minWidth: 170,
      align: 'right',
      format: (value: number) => value.toLocaleString('en-US'),
    },
    {
      id: 'density',
      label: 'Density',
      minWidth: 170,
      align: 'right',
      format: (value: number) => value.toFixed(2),
    },
  ];

   const rows = [
    { name: "name", code: "name", population: "name", size: "name", density: "name" },
   ];
*/


export interface IProps {
    columns: Column[];
    rows: Array<any>;
    totalCount: number;
    loadData: Function;
    actions?: any;
};

export default function TableWithPagining(props: IProps) {
    const { t } = useTranslation("default");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        props.loadData(newPage, rowsPerPage);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        props.loadData(0, +event.target.value);
      };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: "60vh" }}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    {props.columns.map((column) => (
                        <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    { props.actions && <TableCell style={{fontWeight: "bold", textAlign: "center" }}>
                        {t('default.action')}
                    </TableCell> }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, idx) => {
                        return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                            {props.columns.map((column) => {
                            const value = row[column.id];
                            return (
                                <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                            );
                            })}
                            { props.actions && 
                            <>
                            <TableCell sx={{ textAlign: "center"}} >
                                {props.actions.map((action: any, idxAct: any) => (
                                    <span key={idxAct} onClick={() => action.action(idx)}>{action.element}</span>
                                ))} 
                            </TableCell>
                                
                            </>}
                        </TableRow>
                        );
                    })}
                </TableBody>
                </Table>
            </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Paper>
    )
}