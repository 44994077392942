import "./InputDrop.css";
import Dropzone from "react-dropzone";

interface IProps {
  onFileDrop: (files: File[]) => void;
  text: string;
  files?: File[];
}

export default function InputDrop(props: IProps) {
  return (
    <Dropzone onDrop={props.onFileDrop}>
      {({ getRootProps, getInputProps }) => (
        <div className="input-drop" {...getRootProps()}>
          <input {...getInputProps()} />
          <p>{props.text}</p>
          { props.files && <br /> }
          { props.files && props.files.length === 0 && <p className="input-drop-files">Nie je zvolený žiadny súbor.</p> }
          { props.files && props.files.map((file, idx) => <p key={idx} className="input-drop-files">{file.name}</p>)}
        </div>
      )}
    </Dropzone>
  );
}
