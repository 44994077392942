import { useEffect, useState } from "react";
import CustomModal from "../Modal/CustomModal";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button } from "../StyleComponents/StyleComponents";
import ReportsService from "../../services/ReportsService";

interface IProps {
    show: boolean
    endpointService: any
    onClose: () => void;
    onExport: (id: string) => void;
    currentReportId?: string;
}

export default function TReportSelectionModal(props: IProps) { 
    const [optionsType, setOptionsType] = useState<{ label: string, value: string }[]>([]);

    const selectStyle = {
        styles: { menuPortal: (base: any) => ({ ...base, zIndex: 9999 }), control: (base: any) => ({ ...base, padding: '9px'}) },
        menuPortalTarget: document.body
    };

    const { t } = useTranslation("traffic");
    
    const [ report, setReport ] = useState<{ label: string, value: string }>({} as { label: string, value: string });
    useEffect(() => {
        ReportsService.getAllUserReports("")
        .then((resp: any) => {
            resp.data.data.forEach((element: { name: any; reportId: any; }) => {
                setOptionsType((previous) => [...previous, {label: element.name, value: element.reportId}]);
            });
        })
        .catch(() => { });
      }, []);
    
    const handleReportChange = (value: any) => {
        setReport(value); //value.label == name alebo value.value == id
    }

    return (
        <CustomModal
            show={props.show}
            title={t("Traffic.chooseReport")}
            width="700px"
            onClose={props.onClose}
        >
            <div className='box-12'>
                <div className='box-12 py-1 px-2'>
                    {t("Traffic.chooseReportDesc")}
                </div>
                <div className='box-12 py-1 px-2'>
                    <Select
                        options={props.currentReportId ? optionsType.filter((option) => option.value !== props.currentReportId) : optionsType}
                        value={report}
                        onChange={handleReportChange}
                        {...selectStyle}
                    />
                </div>
                <div className='box-12 mt-2 text-center'>
                    <Button style={{margin: "5px"}} onClick={() => props.onExport(report.value as string)}>{t("Traffic.export")}</Button>
                </div>
            </div>
        </CustomModal>
    );
}