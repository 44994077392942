import axios, { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';

const ReportsService = (): any => {

    return {
        insertReport: (body: any): Promise<AxiosResponse<any, any>> => {
            const url = "/report-center/insertReport";
            return axios.post(process.env.REACT_APP_API_URL + url, body, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        getAllUserReports: (searchText: string): Promise<AxiosResponse<any, any>> => {
            const url = "/report-center/getAllUserReports?searchText=" + searchText;
            return axios.get(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        getAllPublicReports: (userId: string): Promise<AxiosResponse<any, any>> => {
            const url = "/report-center/getAllPublicReports"/*?userId=" + userId*/;
            return axios.get(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        getUserReport: (reportId: string): Promise<AxiosResponse<any, any>> => {
            const url = "/report-center/getUserReport?reportId=" + reportId;
            return axios.get(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        deleteReport: (reportId: string): Promise<AxiosResponse<any, any>> => {
            const url = "/report-center/removeReport?reportId=" + reportId;
            return axios.delete(process.env.REACT_APP_API_URL + url, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        updateReport: (body: any): Promise<AxiosResponse<any, any>> => {
            const url = "/report-center/updateReport";
            return axios.put(process.env.REACT_APP_API_URL + url, body, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        addGraphToReport: (reportId: string, graph: any): Promise<AxiosResponse<any, any>> => {
            const url = "/report-center/addGraphToReport?reportId=" + reportId;
            return axios.put(process.env.REACT_APP_API_URL + url, graph, { headers: { 'SID': new Cookies().get("user")?.token } });
        },

        addTableToReport: (reportId: string, table: any): Promise<AxiosResponse<any, any>> => {
            const url = "/report-center/addTableToReport?reportId=" + reportId;
            return axios.put(process.env.REACT_APP_API_URL + url, table, { headers: { 'SID': new Cookies().get("user")?.token } });
        }
    };
}

export default ReportsService();