import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import analyzisService from "../../services/StatisticService";
import { useTranslation } from 'react-i18next';
import iDataset from "../../interfaces/iDataset";

interface IProps {
  dataset: iDataset;
};

export default function Prediction(props: IProps) {
  const { t } = useTranslation("crimes");
  const [selectAr, setSelectAr] = useState(1);
  const [countOfPredict, setCountOfPredict] = useState(10);
  const [error, setError] = useState(0);
  const [data, setData] = useState([]);
  const [ar, setAr] = useState<any>([]);
  const [predict, setPredict] = useState<any>([]);

  useEffect(() => {
    const abortCtrl = new AbortController();
      const dataset = props.dataset.graphValues[0].data[0];
      const fltValues = dataset.filter ? dataset.filter : props.dataset.filterValues;
      const fval = fltValues.map((value) => ({
          type: value.type.value,
          value: value.value.map((val) => ({
              type: val.type.value,
              atr: val.atribute.value,
              option: val.option.value,
              value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
          }))
    }));
    /*let byTime;
    let byDateOne;
    let byDateTwo;
    if (props.specify.indexOf("-") !== -1) {
        byDateOne = props.specify.substring(0, props.specify.indexOf("-"));
        byDateTwo = props.specify.substring(props.specify.indexOf("-") + 1);
    } else {
        byTime = props.specify;
    }*/
    analyzisService.getPrediction(selectAr, countOfPredict, dataset.dataFirst, dataset.dataSecond, dataset.dataFirstInterval, dataset.dataSecondInterval
      , dataset.dataFirstIntervalBy, dataset.dataSecondIntervalBy, props.dataset.graphValues[0].group, fval
      , props.dataset.expressionValues, abortCtrl.signal)
    .then((res: any) => {
        setError(res.data.error);
        setData(res.data.data);
        let tempAr = [];
        for (let i = 0; i < selectAr; i++) {
          tempAr.push(undefined);
        }
        setAr(tempAr.concat(res.data.ar[0]));

        let tempPredict = [];
        for (let i = 0; i < res.data.data.length; i++) {
          tempPredict.push(undefined);
        }
        setPredict(tempPredict.concat(res.data.predict[0]));
    })
    .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataset.expressionValues, props.dataset.graphValues, props.dataset.filterValues, props.dataset.filter, selectAr, countOfPredict]);

  const handleChangeAr = (event: React.SyntheticEvent, newValue: number) => {
    setSelectAr(newValue);
  };

  const barOption = {
    legend: {
      top: "auto",
      bottom: 0,
      type: 'scroll',
      data: [t('analyzis.origData'), t('analyzis.ar'), t('analyzis.arPredic')]
    },
      title: {
          text: t('analyzis.predictions'),
          x: 'center'
        },
      tooltip: {},
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          saveAsImage: { show: true },
        },
      },
      xAxis: {
        type: "category",
        //  name: "tesasdasdas asdasdt",
      },
      yAxis: {
        type: "value",
        //name: t("analyzis.valuesCount"),
      },
      series: [
        {
          name: t('analyzis.origData'),
          data: data,
          type: "bar",
        },
        {
          name: t('analyzis.ar'),
          data: ar,
          type: "line",
          color: "red"
        },
        {
          name: t('analyzis.arPredic'),
          data: predict,
          type: "line",
          color: "green"
        }
      ],
    };

    return (
      <>
        <Grid sx={{ textAlign: "right" }} container>
          <Grid item xs={6}>
            <Tabs
              value={selectAr}
              onChange={handleChangeAr}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              
            >
              <Tab value={1} label="Ar(1)" sx={{ textTransform: "none" }}/>
              <Tab value={2} label="Ar(2)" sx={{ textTransform: "none" }}/>
              <Tab value={3} label="Ar(3)" sx={{ textTransform: "none" }}/>
            </Tabs>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" sx={{ marginTop: "8px" }} fontWeight="bold">{t('analyzis.percentPredict')}: </Typography>
          </Grid>
          <Grid item xs={3}>
            <input type="number" min={10} value={countOfPredict} onChange={(e) => setCountOfPredict(Number(e.target.value))}/>
          </Grid>

        </Grid>
        
        <Grid mt={2} mb={2}>
            <ReactECharts option={barOption} />
        </Grid>
        <Grid textAlign={"left"}>
            <Typography variant="h6"><b>{t('analyzis.predictError')}: &emsp;</b> {Math.trunc(error)}</Typography>
        </Grid>
      </>
    )
}