import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from '@mui/material/OutlinedInput';
import { IReport } from "../../interfaces/traffic_accidents/InterfacesTraffic";
import { useTranslation } from "react-i18next";

interface IProps {
  report: IReport | null,
  editMode: boolean,
  updateReportName: (value: any) => void;
  }

export default function ReportHeader(props: IProps) {
    const { t } = useTranslation("traffic");
    const useStyles = makeStyles({
        root: {
          width: "100%",
          overflowX: "auto",
          padding: "10px",
        },
      });
    const classes = useStyles();

    const handleTitleChange = (name?: string) => {
        const { updateReportName, report } = props;
        if (report) updateReportName(name);
    };
    
    return(
      <Paper className={classes.root}>
        <div>
          {props.editMode ? (
            <OutlinedInput
              id="filled-hidden-label-normal"
              defaultValue={props.report?.name}
              placeholder={t("Traffic.reportNamePlaceholder") + ""}
              size="small"
              fullWidth
              onChange={(e) => handleTitleChange(e.target.value)}
              required={true}
            />
          ) : (
            <h2>{props.report?.name}</h2>
          )}
        </div>
        {props.report?.date_created && (
            <span>{t("Traffic.reportCreation")} {props.report?.date_created}</span>
        )}
      </Paper>
    );
}