import GraphData from "../Graphs/GraphData";


const baseUrl : string = 'graph';


export interface GraphType {
    key: string,
    name: string,
    endpoint: string,
    graphData: GraphData[]
} 

export function AvaiGraphTypes_f(t: any) {
    return [
        {
            key: 'WeatherGraph',
            name: t("TGraphs.pocasie"),
            endpoint: `${baseUrl}/POVETERNOSTNE_PODMIENKY_V_DOBE_NEHODY`,
            graphData: [
                {Title: t("TGraphs.hodnotyGrafov.nestazene"), Code: "nestazene", Data: 0},
                {Title: t("TGraphs.hodnotyGrafov.hmla"), Code: "hmla",Data: 0},
                {Title: t("TGraphs.hodnotyGrafov.naZacaitkuDazdaSlabyDazdMrholenie"), Code: "naZacaitkuDazdaSlabyDazdMrholenie",Data: 0},
                {Title: t("TGraphs.hodnotyGrafov.dazd"), Code: "dazd",Data: 0},
                {Title: t("TGraphs.hodnotyGrafov.snezenie"), Code: "snezenie",Data: 0},
                {Title: t("TGraphs.hodnotyGrafov.namrazaPoladovica"), Code: "namrazaPoladovica",Data: 0},
                {Title: t("TGraphs.hodnotyGrafov.narazovyVietor"), Code: "narazovyVietor",Data: 0},
                {Title: t("TGraphs.hodnotyGrafov.ineStazene"), Code: "ineStazene",Data: 0},
            ]
        },
    {
        key: 'AlcoholGraph',
        name: t("TGraphs.alkoDrog"),
        endpoint: `${baseUrl}/ALKOHOL_U_VINNIKA_NEHODY_PRITOMNY`,
        graphData:[
            {Title: t("TGraphs.hodnotyGrafov.nie"), Code: "nie", Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.do024"), Code: "do024",Data: 0}]
        },    
        {
        key: 'DruhNehody',
        name: t("TGraphs.druhNehody"),
        endpoint: `${baseUrl}/DRUH_NEHODY`,
        graphData:[
            {Title: t("TGraphs.hodnotyGrafov.zrazkaChodec"), Code: "zrazkaChodec", Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.zrazkaZviera"), Code: "zrazkaZviera",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.zrazkaElektricka"), Code: "zrazkaElektricka",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.havaria"), Code: "havaria",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.zrazkaLesnaZver"), Code: "zrazkaLesnaZver",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.inyDruh"), Code: "inyDruh",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.zrazkaNekolajoveVozidlo"), Code: "zrazkaNekolajoveVozidlo",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.zrazkaZaparkovaneVozidlo"), Code: "zrazkaZaparkovaneVozidlo",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.zrazkaPevnaPrekazka"), Code: "zrazkaPevnaPrekazka",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.zrazkaVlak"), Code: "zrazkaVlak",Data: 0}
        ]
        },
        {
        key: 'DruhZrazkyIducichVozidiel',
        name: t("TGraphs.druhZrazkyIducichVozidiel"),
        endpoint: `${baseUrl}/DRUH_ZRAZKY_IDUCICH_VOZIDIEL`,
        graphData:[
            {Title: t("TGraphs.hodnotyGrafov.bezUvahy1"), Code: "bezUvahy", Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.celovica"), Code: "celovica",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.zBoku"), Code: "zBoku",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.zadok"), Code: "zadok",Data: 0},
            {Title: t("TGraphs.hodnotyGrafov.bocna"), Code: "bocna",Data: 0},
        ]
    },
    {
        key: 'DruhPozemnejKomunikacie',
        name: t("TGraphs.druhPozemnejKomunikacie"),
        endpoint: `${baseUrl}/DRUH_POZEMNEJ_KOMUNIKACIE`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.dialnica"),Code: "dialnica", Data:0},
            {Title: t("TGraphs.hodnotyGrafov.prvaTrieda1"),Code: "prvaTrieda", Data:0},
            {Title: t("TGraphs.hodnotyGrafov.druhaTrieda1"),Code: "druhaTrieda", Data:0},
            {Title: t("TGraphs.hodnotyGrafov.tretiaTrieda1"),Code: "tretiaTrieda", Data:0},
            {Title: t("TGraphs.hodnotyGrafov.uzol"),Code: "uzol", Data:0},
            {Title: t("TGraphs.hodnotyGrafov.sledovana"),Code: "sledovana", Data:0},
            {Title: t("TGraphs.hodnotyGrafov.miestna"),Code: "miestna", Data:0},
            {Title: t("TGraphs.hodnotyGrafov.lesna"),Code: "lesna", Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ostatne"),Code: "ostatne", Data:0},
        ]
    },
    {
        key: 'DruhPevnejPrekazky',
        name: t("TGraphs.druhPevnejPrekazky"),
        endpoint: `${baseUrl}/DRUH_PEVNEJ_PREKAZKY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.strom"), Code:"strom",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.stlp"), Code:"stlp",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.obrubnik"), Code:"obrubnik",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zvodidlo"), Code:"zvodidlo",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ineVoz"), Code:"ineVoz",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.mur"), Code:"mur",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zavora"), Code:"zavora",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.stavba"), Code:"stavba",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.bezUvahy"), Code:"bezUvahy",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ine1"), Code:"ine",Data:0},
        ]
    },
    {
        key: 'CharakterNehody',
        name: t("TGraphs.charakterNehody"),
        endpoint: `${baseUrl}/CHARAKTER_NEHODY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.nasledky"), Code:"nasledky",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.hmotna"), Code:"hmotna",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.nedef1"), Code:"nedef",Data:0},
        ]
    },
    {
        key: 'ZavinenieNehody',
        name: t("TGraphs.zavinenieNehody"),
        endpoint: `${baseUrl}/ZAVINENIE_NEHODY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.vodicMot"), Code:"vodicMot",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vodicNemot"), Code:"vodicNemot",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.chodec"), Code:"chodec",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zviera"), Code:"zviera",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.inyUcastnikCestnejPrem"), Code:"inyUcastnikCestnejPrem",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zavadaKomu"), Code:"zavadaKomu",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.techZavadaVoz"), Code:"techZavadaVoz",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ine2"), Code:"ine",Data:0},
        ]
    },
    {
        key: 'DruhPovrchuVozovky',
        name: t("TGraphs.druhPovrchuVozovky"),
        endpoint: `${baseUrl}/DRUH_POVRCHU_VOZOVKY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.dlazba"), Code:"dlazba",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zivica"), Code:"zivica",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.beton"), Code:"beton",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.panely"), Code:"panely",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.strk"), Code:"strk",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.nespevnenyPovrch"), Code:"nespevnenyPovrch",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.nedef2"), Code:"nedef",Data:0},
        ]
    },
    {
        key: 'StavPovrchuVozovkyVdobeNehody',
        name: t("TGraphs.stavPovrchuVozovky"),
        endpoint: `${baseUrl}/STAV_POVRCHU_VOZOVKY_V_DOBE_NEHODY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.suchyCist"), Code:"suchyCist",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.suchySpin"), Code:"suchySpin",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.mokry"), Code:"mokry",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.blato"), Code:"blato",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.posyp"), Code:"posyp",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.neposyp"), Code:"neposyp",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.olejNafta"), Code:"olejNafta",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.snehVrstva"), Code:"snehVrstva",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zmenaStavu"), Code:"zmenaStavu",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ine3"), Code:"ine",Data:0},
        ]
    },
    {
        key: 'StavKomunikacie',
        name: t("TGraphs.stavKomunikacie"),
        endpoint: `${baseUrl}/STAV_KOMUNIKACIE`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.bezZavad"), Code:"bezZavad",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.sklon"), Code:"sklon",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zlaZnacka"), Code:"zlaZnacka",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zvlnene"), Code:"zvlnene",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.suvVytlky"), Code:"suvVytlky",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.nesuvVytlky"), Code:"nesuvVytlky",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zuzenie"), Code:"zuzenie",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.hrbol"), Code:"hrbol",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.neoznacena"), Code:"neoznacena",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.uzavierka"), Code:"uzavierka",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.uzavierkaKom"), Code:"uzavierkaKom",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zavada"), Code:"zavada",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.nedef3"), Code:"nedef",Data:0},
        ]
    },
    {
        key: 'Viditelnost',
        name: t("TGraphs.viditelnost"),
        endpoint: `${baseUrl}/VIDITELNOST`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.den1"), Code:"den1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.den2"), Code:"den2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.den3"), Code:"den3",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.noc1"), Code:"noc1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.noc2"), Code:"noc2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.noc3"), Code:"noc3",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.noc4"), Code:"noc4",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.nedef4"), Code:"nedef",Data:0},
        ]
    },
    {
        key: 'RozhladovePomery',
        name: t("TGraphs.rozhladovePomery"),
        endpoint: `${baseUrl}/ROZHLADOVE_POMERY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.dobre"), Code:"dobre",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zle1"), Code:"zle1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zle2"), Code:"zle2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zle3"), Code:"zle3",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zle4"), Code:"zle4",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vyhlad"), Code:"vyhlad",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ine4"), Code:"ine",Data:0},
        ]
    },
    {
        key: 'DelenieKomunikacie',
        name: t("TGraphs.delenieKomunikacie"),
        endpoint: `${baseUrl}/DELENIE_KOMUNIKACIE`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.dvoj"), Code:"dvoj",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.troj"), Code:"troj",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.stvorPas"), Code:"stvorPas",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.stvorCiara"), Code:"stvorCiara",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.viac"), Code:"viac",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.rychKom"), Code:"rychKom",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ziadnaZuve"), Code:"ziadnaZuve",Data:0},
        ]
    },
    {
        key: 'SituovanieNehodyNaKomunikacii',
        name: t("TGraphs.situovanieNehodyNaKomunikacii"),
        endpoint: `${baseUrl}/SITUOVANIE_NEHODY_NA_KOMUNIKACII`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.jazdPruh"), Code:"jazdPruh",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.odstPruh"), Code:"odstPruh",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.kraj"), Code:"kraj",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.odboc"), Code:"odboc",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.pomaleVoz"), Code:"pomaleVoz",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.chod"), Code:"chod",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.kol"), Code:"kol",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.mimo1"), Code:"mimo",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.chodCyk"), Code:"chodCyk",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ziadneZuve"), Code:"ziadneZuve",Data:0},
        ]
    },
    {
        key: 'RiadeniePremavkyVdobeNehody',
        name: t("TGraphs.riadeniePremavkyVdobeNehody"),
        endpoint: `${baseUrl}/RIADENIE_PREMAVKY_V_DOBE_NEHODY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.pol"), Code:"pol",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.sign"), Code:"sign",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.miestnaUprava"), Code:"miestnaUprava",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ziadne1"), Code:"ziadne",Data:0},
        ]
    },
    {
        key: 'MiestnaUpravaPrednostiVJazde',
        name: t("TGraphs.miestnaUpravaPrednostiVjazde"),
        endpoint: `${baseUrl}/MIESTNA_UPRAVA_PREDNOSTI_V_JAZDE`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.svet1"), Code:"svet1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.svet2"), Code:"svet2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.pred1"), Code:"pred1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.pred2"), Code:"pred2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.pred3"), Code:"pred3",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ziadna"), Code:"ziadna",Data:0},
        ]
    },
    {
        key: 'SpecifickeMIestaAobjektyVmiesteNehody',
        name: t("TGraphs.specMiestaObjektyMiesteNehody"),
        endpoint: `${baseUrl}/SPECIFICKE_MIESTA_A_OBJEKTY_V_MIESTE_NEHODY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.prechod"), Code:"prechod",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.blizPrecho"), Code:"blizPrecho",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zel1"), Code:"zel1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zel2"), Code:"zel2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.most"), Code:"most",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zast1"), Code:"zast1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zast2"), Code:"zast2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vyjazd"), Code:"vyjazd",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.cerpadlo"), Code:"cerpadlo",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.park"), Code:"park",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ziadne2"), Code:"ziadne",Data:0},
        ]
    },
    {
        key: 'SmerovePomery',
        name: t("TGraphs.smerovePomery"),
        endpoint: `${baseUrl}/SMEROVE_POMERY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.priam1"), Code:"priam1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.priam2"), Code:"priam2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.zakruta"), Code:"zakruta",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.kriz1"), Code:"kriz1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.kriz2"), Code:"kriz2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.kriz3"), Code:"kriz3",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.kruh"), Code:"kruh",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.nedef"), Code:"nedef",Data:0},
        ]
    },
    {
        key: 'DruhKrizujuciKomunikaciu',
        name: t("TGraphs.druhKrizujuciKomunikaciu"),
        endpoint: `${baseUrl}/DRUH_KRIZUJUCI_KOMUNIKACIU`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.prvaTrieda"), Code:"prvaTrieda",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.druhaTrieda"), Code:"druhaTrieda",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.tretiaTrieda"), Code:"tretiaTrieda",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.miestaKom"), Code:"miestaKom",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ucelKom"), Code:"ucelKom",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vetvaMimoKriz"), Code:"vetvaMimoKriz",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.neurc"), Code:"neurc",Data:0},
        ]
    },
    {
        key: 'DruhVozdila',
        name: t("TGraphs.druhVozidla"),
        endpoint: `${baseUrl}/DRUH_VOZIDLA`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.voz00"), Code:"voz00",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz1"), Code:"voz1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz2"), Code:"voz2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz3"), Code:"voz3",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz4"), Code:"voz4",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz5"), Code:"voz5",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz6"), Code:"voz6",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz7"), Code:"voz7",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz8"), Code:"voz8",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz9"), Code:"voz9",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz10"), Code:"voz10",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz11"), Code:"voz11",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz12"), Code:"voz12",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz13"), Code:"voz13",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz14"), Code:"voz14",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz15"), Code:"voz15",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz16"), Code:"voz16",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz17"), Code:"voz17",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz18"), Code:"voz18",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.vvoz0"), Code:"voz0",Data:0},
        ]
    },
    {
        key: 'CharakterVozidla',
        name: t("TGraphs.charakterVozidla"),
        endpoint: `${baseUrl}/CHARAKTER_VOZIDLA`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.voz1"), Code:"voz1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz2"), Code:"voz2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz3"), Code:"voz3",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz4"), Code:"voz4",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz5"), Code:"voz5",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz6"), Code:"voz6",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz7"), Code:"voz7",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz8"), Code:"voz8",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz9"), Code:"voz9",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz10"), Code:"voz10",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz11"), Code:"voz11",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz12"), Code:"voz12",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz13"), Code:"voz13",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz14"), Code:"voz14",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz15"), Code:"voz15",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz16"), Code:"voz16",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz17"), Code:"voz17",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz18"), Code:"voz18",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.voz0"), Code:"voz0",Data:0},
        ]
    },
    {
        key: 'VozidloPoNehode',
        name: t("TGraphs.vozidloPoNehode"),
        endpoint: `${baseUrl}/VOZIDLO_PO_NEHODE`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.bezPoziaru"), Code:"bezPoziaru",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.poziar"), Code:"poziar",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.usielZist"), Code:"usielZist",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.usielNezist"), Code:"usielNezist",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ziadne"), Code:"ziadne",Data:0},
        ]
    },
    {
        key: 'SposobVyprosteniaOsobZvozidla',
        name: t("TGraphs.sposobVyprostenia"),
        endpoint: `${baseUrl}/SPOSOB_VYPROSTENIA_OSOB_Z_VOZIDLA`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.bezNasilia"), Code:"bezNasilia",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.pacidla"), Code:"pacidla",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.specTech"), Code:"specTech",Data:0},
        ]
    },
    {
        key: 'VonkajsieOvplyvnenieVodica',
        name: t("TGraphs.vonkajsieOvplyvnenieVodica"),
        endpoint: `${baseUrl}/VONKAJSIE_OVPLYVNENIE_VODICA`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.nebol"), Code:"nebol",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.oslneny1"), Code:"oslneny1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.oslneny2"), Code:"oslneny2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ovplyv1"), Code:"ovplyv1",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ovplyv2"), Code:"ovplyv2",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.ine"), Code:"ine",Data:0},
        ]
    },
    {
        key: 'LokalitaNehody',
        name: t("TGraphs.lokalitaNehody"),
        endpoint: `${baseUrl}/LOKALITA_NEHODY`,
        graphData: [
            {Title: t("TGraphs.hodnotyGrafov.v"), Code:"v",Data:0},
            {Title: t("TGraphs.hodnotyGrafov.mimo"), Code:"mimo",Data:0},
        ]
    },
    ];
}