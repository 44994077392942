import { ReactNode } from "react";

interface IProps {
  children: ReactNode;
}

export default function Card(props: IProps) {
  return (
    <div className='box-12 px-3 py-3 box-sm-6 px-sm-3 py-sm-3 mx-sm-3 my-sm-3 box-md-4 px-md-3 py-md-3 mx-md-3 my-md-3 box-lg-2 card'>
      {props.children}
    </div>
  );
}
