import CardsBox from './../../../components/Cards/CardsBox';
import CardLink from './../../../components/Cards/CardLink';
import { FaGlobeEurope } from 'react-icons/fa';
import { MdLocalPolice } from 'react-icons/md';
import { FaCar } from 'react-icons/fa';
import image from '../../../assets/css/images/police.jpg';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageForm from '../../../components/PageHeader/PageForm';

export default function Home() {

  const { t } = useTranslation("default");

  return (
    <PageForm label={t("home.pageTitle")} image={image}>
        <Typography variant="h4" textAlign="center" marginBottom={2}>
          {t("home.goalTitle")}
        </Typography>
        <Typography variant="body1" textAlign="justify" marginBottom={2}>
          {t("home.goalText")}
        </Typography>
        <Typography variant="h4" textAlign={"center"} marginBottom={2}>
          {t("home.divisionTitle")}
        </Typography>
        <Typography variant="body1" textAlign={"justify"} marginBottom={2}>
          {t("home.divisionText")}
        </Typography>
        <Typography variant="body1" textAlign={"justify"} marginBottom={2}>
          {t("home.projectInfo")}
        </Typography>
        <Typography variant="h4" textAlign={"center"} marginBottom={2}>
          {t("home.choosePath")}
        </Typography>
        <CardsBox>
          <CardLink to="/crimes" icon={<MdLocalPolice />} text={t("home.crimes")} />
          <CardLink to="/traffic" icon={<FaCar />} text={t("home.traffic")} />
          <CardLink to="/europe" icon={<FaGlobeEurope />} text={t("home.europe")} />
        </CardsBox>
    </PageForm>
  );
}
