import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, CardContent, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Pagination, Select, SelectChangeEvent, Stack, Tab, Tabs, Typography } from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from "axios";
import React, { useEffect, useMemo } from "react";
import { BsChevronLeft, BsSquare } from "react-icons/bs";
import { FaChartArea, FaChartBar, FaChartLine, FaChartPie, FaCheckSquare, FaDashcube, FaDocker, FaFingerprint, FaInfo, FaMinus, FaMinusSquare, FaTable, FaWindowClose } from "react-icons/fa";
import { FiTrendingUp } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Cell, ComposedChart, Legend, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis, Tooltip as TooltipChart } from "recharts";
import { COLORS, COUNT_OF_CARDS, GET_COUNT_OF_CO_BY_STATE_ID, GET_INDEXES_OF_CO_BY_STATE_ID, GET_LAST_INDEXES_CO_BY_STATE_ID, PRIMARY_COLOR, GET_STATE_INFO, GET_TOTAL_CO_IN_YEAR_BY_STATE_ID, GET_ALL_CO, ITEM_HEIGHT, ITEM_PADDING_TOP, POST_FILTER_CO_LINK, POST_FILTER_CO_IN_STATE_LINK, TABLE_PAGE_SIZE_15, POST_FILTER_CO_IN_STATE_STATS_LINK, CODE_PARAM, CO_PARAM, POLICE_PARAM, STATE_INFO_SITE, POST_POLICE_OFFICERS_LINK, POST_POLICE_CIVILIANS_LINK, CONVICTED_PARAM, POST_STATE_COMPARE_POLICE_COUNTS, POST_STATE_COMPARE_POLICE_INDEXES } from "../../../../../interfaces/europe/Constants";
import { CustomToolbar, getIcon, getImage, numberWithSpaces, round } from "../../../../../interfaces/europe/Functions";
import { CountsOfCrimeOffences, CriminalOffence, FilterCriminalOffencesByStateRequest, FilterCriminalOffencesByStateResponse, FilterCriminalOffencesResponse, IndexesOfCrimeOffences, LastIndexesOfCrimeOffences, PoliceForState, StateCompareCountForPolice, StateCompareIndexForPolice, StateCompareRequest, StateInfoDto, Statistics, TotalCrimesInYear } from "../../../../../interfaces/europe/interfaces";
import './StatePolice.css';
import { useTranslation } from "react-i18next";
import { faLessThan } from '@fortawesome/free-solid-svg-icons';


export default function StatePolice() {
    const { t } = useTranslation("europe");

    const { id, code } = useParams();

    //RESTS
    const [stateInfo, setStateInfo] = React.useState<StateInfoDto>();
    const [policeForState, setPoliceForState] = React.useState<PoliceForState[]>([]);
    const [civilianForState, setCivilianForState] = React.useState<PoliceForState[]>([]);




    const [valueIndexOrCounts, setValueIndexOrCounts] = React.useState('1');
    const [valueIndexOrCounts1, setValueIndexOrCounts1] = React.useState('1');

    const [stateIndexesByCategory, setStateIndexesByCategory] = React.useState<StateCompareIndexForPolice[]>([]);
    const [stateCountsByCategory, setStateCountsByCategory] = React.useState<StateCompareCountForPolice[]>([]);


    var flag = getImage(code);


    //GET
    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + GET_STATE_INFO + id).then((response) => {
            setStateInfo(response.data);
        });
    }, []);


    //POST

    const handleChangeCountsOrIndex = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexOrCounts(newValue);
    };

    const handleChangeCountsOrIndex1 = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexOrCounts1(newValue);
    };





    useEffect(() => {


        axios.post(process.env.REACT_APP_API_URL + POST_POLICE_OFFICERS_LINK, stateFilter).then((response) => {
            setPoliceForState(response.data);
        });
    }, []);

    useEffect(() => {


        axios.post(process.env.REACT_APP_API_URL + POST_POLICE_CIVILIANS_LINK, stateFilter).then((response) => {
            setCivilianForState(response.data);
        });
    }, []);


    // PAGING
    const [valueMainTab, setValueMainTabe] = React.useState(2);

    let idArray: (string | undefined)[] = [id];

    // Convert array of strings to array of numbers, filtering out undefined values
    let numberArray: number[] = idArray
        .filter((id): id is string => id !== undefined) // Filter out undefined values
        .map((id) => parseInt(id!, 10));

    let stateFilter: StateCompareRequest = { "states": numberArray }

    const handleChangeMain = (_event: React.SyntheticEvent, newValue: number) => {
        setValueMainTabe(newValue);
    };


    //TABLES
    const policeTypesData = policeForState !== undefined && policeForState.length > 0 && policeForState[0]?.policeTypes.length !== undefined && policeForState[0]?.policeTypes.length > 0 ? <table className="table table-striped">
        <thead>
            <tr>
                <th scope="col-6">{policeForState !== undefined && policeForState.length > 0 ? policeForState[0].policeStaffName : ""} </th>
                <th scope="col-6" className="text-center">{t(`stateCompare.isIncluded`)}</th>
            </tr>
        </thead>
        <tbody>
            {policeForState[0]?.policeTypes.map(((f) =>
                <tr key={f.name}><th className="col-6">{f.name} </th><th className="text-center">{f.isIncluded === null ? <FaMinusSquare className=" text-neutral" /> : (f.isIncluded === true ? <FaCheckSquare className=" text-success" /> : <FaWindowClose className=" text-danger" />)}</th></tr>
            ))}
        </tbody>
    </table> : <span className="ml-4">{t(`stateCompare.dataNot`)}</span>;

    const civiliansTypesData = civilianForState !== undefined && civilianForState.length > 0 && civilianForState[0]?.policeTypes.length !== undefined && civilianForState[0]?.policeTypes.length > 0 ? <table className="table table-striped">
        <thead>
            <tr>
                <th scope="col-6">{civilianForState !== undefined && civilianForState.length > 0 ? civilianForState[0].policeStaffName : ""} </th>
                <th scope="col-6" className="text-center">{t(`stateCompare.isIncluded`)}</th>
            </tr>
        </thead>
        <tbody>
            {civilianForState[0]?.policeTypes.map(((f) =>
                <tr key={f.name}><th className="col-6">{f.name} </th><th className="text-center">{f.isIncluded === null ? <FaMinusSquare className=" text-neutral" /> : (f.isIncluded === true ? <FaCheckSquare className=" text-success" /> : <FaWindowClose className=" text-danger" />)}</th></tr>
            ))}
        </tbody>
    </table> : <span className="ml-4">{t(`stateCompare.dataNot`)}</span>;


    useEffect(() => {

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_POLICE_INDEXES, stateFilter).then((response) => {
            setStateIndexesByCategory(response.data);
        });
    }, [id, code]);

    useEffect(() => {

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_POLICE_COUNTS, stateFilter).then((response) => {
            setStateCountsByCategory(response.data);
        });
    }, [id, code]);


    return (
        <>
            <div className="returnLabel"><Link to="/europe/states"><BsChevronLeft className="returnIcon" />{t(`stateCompare.states`)}</Link></div>

            <div className="mb-4">
                <h1 className="text-uppercase"><img className="mr-2 flag-title-image" src={flag} alt={"Flag"} /> {stateInfo?.name}</h1>
            </div>

            <Box sx={{ width: '100%' }}>
                <Tabs
                    onChange={handleChangeMain}
                    value={valueMainTab}
                >
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code} ><Tab label={t(`stateInfo.information`)} ></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + CO_PARAM}><Tab label={t(`stateInfo.criminalOffences`)} ></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + POLICE_PARAM} className="text-primary "><Tab label={t(`stateInfo.police`)} className="font-weight-bold"></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + CONVICTED_PARAM} ><Tab label={t(`stateInfo.convictedCriminalOffences`)}></Tab></Link>

                </Tabs>
            </Box>


            <div className="my-5 col-10 w-100 h-100 ">


            </div>
            <div className="mb-5 col-8">
                <h3 className="mb-4">{policeForState !== undefined && policeForState.length > 0 ? policeForState[0].policeStaffName : ""}</h3>
                <p className="mb-3">{policeForState !== undefined && policeForState.length > 0 ? policeForState[0].policeStaffDescription : ""}</p>
                {policeTypesData}
            </div>







            <div className="my-5 col-8 w-100 h-100">

                <TabContext value={valueIndexOrCounts1}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeCountsOrIndex1} aria-label="lab API tabs example" className="revertTabs">
                            <Tab icon={<FaChartLine />} value="1" />
                            <Tab icon={<FaChartLine />} value="2" />

                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <h3 className="mb-5 trendLabel">{t("stateCompare.police.title2")}</h3>
                        <div className="w-100 h-100">
                            <LineChart
                                width={800}
                                height={300}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                <YAxis />
                                <TooltipChart />
                                <Legend />
                                {
                                    stateIndexesByCategory.map((state, index) => {
                                        return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="indexPoliceOfficers" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                    })
                                }
                            </LineChart>
                        </div>

                    </TabPanel>
                    <TabPanel value="2">
                        <h3 className="mb-5 trendLabel">{t("stateCompare.police.title3")}</h3>
                        <div className="w-100 h-100">
                            <LineChart
                                width={800}
                                height={300}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                <YAxis />
                                <TooltipChart />
                                <Legend />
                                {
                                    stateCountsByCategory.map((state, index) => {
                                        return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="countPoliceOfficers" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                    })
                                }
                            </LineChart>
                        </div>
                    </TabPanel>
                </TabContext>
            </div>

            <div className="mb-5 col-8">
                <h3 className="mb-4">{civilianForState !== undefined && civilianForState.length > 0 ? civilianForState[0].policeStaffName : ""}</h3>
                <p className="mb-3">{civilianForState !== undefined && civilianForState.length > 0 ? civilianForState[0].policeStaffDescription : ""}</p>
                {civiliansTypesData}
            </div>

            <div className="my-5 col-8 w-100 h-100">
                <TabContext value={valueIndexOrCounts}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeCountsOrIndex} aria-label="lab API tabs example" className="revertTabs">
                            <Tab icon={<FaTable />} value="1" />
                            <Tab icon={<FaTable />} value="2" />

                        </TabList>
                    </Box>
                    <TabPanel value="1">

                        <h3 className="mb-5 trendLabel">{t("stateCompare.police.title1")}</h3>
                        <div className="w-100 h-100">
                            <LineChart
                                width={800}
                                height={300}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                <YAxis />
                                <TooltipChart />
                                <Legend />
                                {
                                    stateIndexesByCategory.map((state, index) => {
                                        return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="indexOfCivilians" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                    })
                                }
                            </LineChart>
                        </div>

                    </TabPanel>
                    <TabPanel value="2">
                        <h3 className="mb-5 trendLabel">{t("stateCompare.police.title4")}</h3>
                        <div className="w-100 h-100">
                            <LineChart
                                width={800}
                                height={300}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                <YAxis />
                                <TooltipChart />
                                <Legend />
                                {
                                    stateCountsByCategory.map((state, index) => {
                                        return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="countOfCivilians" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                    })
                                }
                            </LineChart>
                        </div>
                    </TabPanel>
                </TabContext>
            </div>
            <div className='info'>{t(`statistics.crimeIndex`)}</div>

        </>

    );
}